import { groupBy, maxBy, orderBy } from "lodash";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceActions } from "../../../../redux/slices/insurance";
import { configActions } from "../../../../redux/slices/config";
import {
  checkGVP,
  checkLayoutConfigDesignType,
  getCheapPackageFromList,
  getFrameOnlyKey,
  getStepSelections,
  hasValidPrescription,
} from "../../../ConfigLoader";
import {
  useCurrentBrand,
  useCurrentPackages,
  useCurrentType,
  useStepAlias,
} from "@hooks";
import { workflow } from "../../../models/Workflow";
import { RIAAPICall, RIAAPICallType } from "../core/types/RIAAPICall";
import { DiscountMethod, RIAManager } from "../RIAManager";

export const useRIA = () => {
  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );

  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  const lensesDataContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );

  const configContent = useSelector((state: any) => state.config);

  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );

  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );

  const cartMode = useSelector((state: any) => state.config?.cartMode);

  const frameType = useCurrentType();

  const currentBrand = useCurrentBrand();
  const currentPackages = useCurrentPackages();
  const getAliasStep = useStepAlias();
  const dispatch = useDispatch();

  const [stepChains, setStepChains] = useState(null);
  const [RIAParams, setRIAParams] = useState<{
    N: number;
    K: number;
    discountMethod: DiscountMethod;
  }>(null);

  useEffect(() => {
    if (
      frameType &&
      configPackages &&
      configPackages.length &&
      currentBrand &&
      !stepChains
    ) {
      let isEyeglasses = frameType.toUpperCase() === "EYEGLASSES";
      let isGVP = checkGVP(configPackages);
      let isV5 = configPackages.some(
        (pkg) =>
          pkg.lensPackage.treatmentFamily &&
          pkg.lensPackage.treatmentFamily.trim() !== ""
      );

      if (checkLayoutConfigDesignType(configContent)) {
        if (isEyeglasses) {
          if (isV5) {
            setStepChains(configContent.RIAFlowConfiguration.eyeglassesV5DT);
          } else {
            setStepChains(configContent.RIAFlowConfiguration.eyeglassesDT);
          }
        } else {
          setStepChains(configContent.RIAFlowConfiguration.sunglassesDT);
        }
      } else {
        if (isEyeglasses) {
          if (isGVP) {
            setStepChains(configContent.RIAFlowConfiguration.eyeglassesGVP);
          } else {
            if (isV5) {
              setStepChains(configContent.RIAFlowConfiguration.eyeglassesV5);
            } else {
              setStepChains(configContent.RIAFlowConfiguration.eyeglasses);
            }
          }
        } else {
          if (isGVP) {
            setStepChains(configContent.RIAFlowConfiguration.sunglassesGVP);
          } else {
            setStepChains(configContent.RIAFlowConfiguration.sunglasses);
          }
        }
      }
    }
  }, [frameType, configPackages, currentBrand]);

  const computeRIAFlowTree = () => {
    let packagesCopy = configPackages.map((pkg) => pkg);

    const sortedConfigPackages = packagesCopy
      .filter((pkg) => pkg.lensPackage.insurable)
      .sort((pkg1, pkg2) => {
        if (
          parseFloat(pkg1.lensPackage.listPrice) >
          parseFloat(pkg2.lensPackage.listPrice)
        ) {
          return 1;
        }
        if (
          parseFloat(pkg1.lensPackage.listPrice) <
          parseFloat(pkg2.lensPackage.listPrice)
        ) {
          return -1;
        }
        return 0;
      });

    const frameOnlyKey = getFrameOnlyKey(
      sortedConfigPackages,
      configContent.data
    );

    const flowTree: any[] = [];
    let toVisit: any[] = [];

    let treeLevel = 0;
    toVisit.splice(0, 0, {
      level: 0,
      id: "Type",
      data: stepChains.Type,
      packages: sortedConfigPackages,
    });

    while (toVisit.length > 0) {
      let obj = toVisit.pop();

      if (obj.id === "GVPTreatment") {
        let contentOptions = getConfigByStepName(obj.id);
        let groupedByContent = groupBy(
          obj.packages.filter((pkg) =>
            contentOptions.some((opt) => opt.id === pkg.lensPackage.treatment)
          ),
          "lensPackage.treatment"
        );
        let toPushInTree = [];
        contentOptions.forEach((opt) => {
          let packages = groupedByContent[opt.id];
          if (packages && packages.length) {
            toPushInTree.push({
              option: opt.id,
              key: "treatment",
              packages: packages,
              step: obj.id,
              prevChain: obj.prevChain,
            });

            if (obj.data.next) {
              let testPkg = packages[0];
              let stepToPush = null;
              obj.data.next.forEach((nextStep) => {
                let excludeCheck =
                  nextStep.exclude && nextStep.exclude.length
                    ? nextStep.exclude.every(
                        (excK) => !testPkg.lensPackage[excK]
                      )
                    : true;
                let includeCheck =
                  nextStep.include && nextStep.include.length
                    ? nextStep.include.every(
                        (inclK) =>
                          testPkg.lensPackage[inclK] !== null &&
                          testPkg.lensPackage[inclK] !== undefined &&
                          testPkg.lensPackage[inclK].toString().trim() !== ""
                      )
                    : true;
                if (excludeCheck && includeCheck) {
                  stepToPush = nextStep;
                }
              });
              if (stepToPush) {
                toVisit.splice(0, 0, {
                  level: obj.level + 1,
                  id: stepToPush.step,
                  data: stepChains[stepToPush.step],
                  packages: packages,
                  prevChain: obj.prevChain
                    ? [
                        ...obj.prevChain,
                        {
                          step: obj.id,
                          key: "treatment",
                          option: opt.id,
                          level: obj.level,
                        },
                      ]
                    : [
                        {
                          step: obj.id,
                          key: "treatment",
                          option: opt.id,
                          level: obj.level,
                        },
                      ],
                });
              }
            }
          }
        });

        if (toPushInTree.length) {
          if (!flowTree.find((level) => level.level === treeLevel)) {
            flowTree.push({
              level: treeLevel,
              options: toPushInTree,
            });
          } else {
            flowTree[treeLevel].options.push(...toPushInTree);
          }
        }
        if (!toVisit.find((node) => node.level === treeLevel)) {
          //all the nodes for this level have been visited, so we increase the treeLevel
          treeLevel += 1;
        }
      } else {
        let treatmentFamilyKeys = null;
        if (
          obj.id == "Treatment" &&
          obj.prevChain.find((s) => s.step == "TreatmentsFamily")
        ) {
          let keys = obj.data.next.map((n) => n.key);
          treatmentFamilyKeys = keys;
        } else {
          treatmentFamilyKeys = [obj.id];
        }
        let toPushInTree = [];
        treatmentFamilyKeys.forEach((element) => {
          let contentOptions = getConfigByStepName(element);
          const key =
            obj.data.key !== "treatmentFamily" ||
            !configContent.lensesData.content.treatmentFamily ||
            !Object.keys(configContent.lensesData.content.treatmentFamily)
              ? obj.data.key
              : null;
          let groupedByContent = null;

          if (key) {
            groupedByContent = groupBy(
              obj.packages.filter((pkg) =>
                contentOptions.some((opt) => opt.id === pkg.lensPackage[key])
              ),
              "lensPackage." + key
            );
          } else {
            groupedByContent = {};
            contentOptions.forEach((opt) => {
              let filteringData = obj.data.next.find((el) => {
                if (opt["treatmentKey"]) {
                  return el.key === opt["treatmentKey"];
                } else {
                  return el.key === opt["key"];
                }
              });
              let filteredPackages = obj.packages.filter((pkg) => {
                let pkgHasKey = null;
                if (opt["treatmentKey"]) {
                  pkgHasKey = opt.key
                    ? pkg.lensPackage[opt.key] === opt.id
                    : true;
                } else {
                  pkgHasKey = filteringData.key
                    ? pkg.lensPackage[filteringData.key] === opt.id
                    : true;
                }

                let hasInclude = filteringData.include
                  ? filteringData.include.every(
                      (prop) =>
                        pkg.lensPackage[prop] && pkg.lensPackage[prop] !== ""
                    )
                  : true;
                let hasExclude = filteringData.exclude
                  ? filteringData.exclude.every(
                      (prop) =>
                        !pkg.lensPackage[prop] ||
                        pkg.lensPackage[prop].toString().trim() === ""
                    )
                  : true;
                return pkgHasKey && hasInclude && hasExclude;
              });
              groupedByContent[opt.id] = filteredPackages;
            });
          }

          contentOptions.forEach((opt) => {
            let packages = groupedByContent[opt.id];
            if (packages && packages.length) {
              if (opt.id === frameOnlyKey) {
                //insert frame only as first option
                toPushInTree.splice(0, 0, {
                  option: opt.id,
                  key: key ? key : opt["key"],
                  packages: packages,
                  step: obj.id,
                  prevChain: obj.prevChain,
                });
              } else {
                toPushInTree.push({
                  option: opt.id,
                  key: key ? key : opt["key"],
                  packages: packages,
                  step: obj.id,
                  prevChain: obj.prevChain,
                });
              }
              if (obj.data.next) {
                if (key) {
                  obj.data.next.forEach((nextStep) => {
                    toVisit.splice(0, 0, {
                      level: obj.level + 1,
                      id: nextStep.step,
                      data: stepChains[nextStep.step],
                      packages:
                        currentPrescription && obj.id === "Type"
                          ? packages.filter((pkg) =>
                              hasValidPrescription(pkg, currentPrescription)
                            )
                          : packages,
                      prevChain: obj.prevChain
                        ? [
                            ...obj.prevChain,
                            {
                              step: obj.id,
                              key: key,
                              option: opt.id,
                              level: obj.level,
                            },
                          ]
                        : [
                            {
                              step: obj.id,
                              key: key,
                              option: opt.id,
                              level: obj.level,
                            },
                          ],
                    });
                  });
                } else {
                  //it's treatmentsFamily case
                  let stepToPush = obj.data.next.find((nextStep) => {
                    return opt.treatmentKey
                      ? nextStep.key === opt.treatmentKey
                      : nextStep.key === opt["key"];
                  });
                  if (stepToPush) {
                    toVisit.splice(0, 0, {
                      level: obj.level + 1,
                      id: stepToPush.step,
                      data: stepChains[stepToPush.step],
                      packages:
                        currentPrescription && obj.id === "Type"
                          ? packages.filter((pkg) =>
                              hasValidPrescription(pkg, currentPrescription)
                            )
                          : packages,
                      prevChain: obj.prevChain
                        ? [
                            ...obj.prevChain,
                            {
                              step: obj.id,
                              key: opt["key"],
                              option: opt.id,
                              level: obj.level,
                            },
                          ]
                        : [
                            {
                              step: obj.id,
                              key: opt["key"],
                              option: opt.id,
                              level: obj.level,
                            },
                          ],
                    });
                  }
                }
              }
            }
          });
        });
        if (toPushInTree.length) {
          if (!flowTree.find((level) => level.level === treeLevel)) {
            flowTree.push({
              level: treeLevel,
              options: toPushInTree,
            });
          } else {
            flowTree[treeLevel].options.push(...toPushInTree);
          }
        }
        if (!toVisit.find((node) => node.level === treeLevel)) {
          //all the nodes for this level have been visited, so we increase the treeLevel
          treeLevel += 1;
        }
      }
    }
    return { flowTree, frameOnlyKey };
  };

  const pruneFlowTreeAtCurrentStep = (
    flowTree: any[],
    frameOnlyKey: string
  ) => {
    let stepSelections = getStepSelections(
      getAliasStep,
      true,
      lensesDataContent
    )
      .filter((sel) => sel.originalStep !== "AdvancedPrescription")
      .map((sel) => {
        if (
          sel.originalStep !== "TreatmentsFamily" &&
          sel.originalStep !== "Treatments"
        ) {
          if (sel.originalStep === "LensColor") {
            sel.key = "color";
            return sel;
          }
          return sel;
        }
        if (sel.value === "Clear") {
          sel.key = null;
          return sel;
        } else {
          let titles = {};
          if (lensesDataContent.treatmentFamily) {
            Object.keys(lensesDataContent.treatmentFamily).forEach((tfKey) => {
              titles[lensesDataContent.treatmentFamily[tfKey].title] = {
                value: tfKey,
                key: "treatmentFamily",
              };
            });
          }
          if (lensesDataContent.transition) {
            Object.keys(lensesDataContent.transition).forEach((transKey) => {
              titles[lensesDataContent.transition[transKey].title] = {
                value: transKey,
                key: "transition",
              };
              titles[transKey] = {
                value: transKey,
                key: "transition",
              };
            });
          }
          if (lensesDataContent.blueLight) {
            Object.keys(lensesDataContent.blueLight).forEach((blKey) => {
              titles[lensesDataContent.blueLight[blKey].title] = {
                value: blKey,
                key: "blueLight",
              };
              titles[blKey] = {
                value: blKey,
                key: "blueLight",
              };
            });
          }
          sel.value = titles[sel.title].value;
          sel.key = titles[sel.title].key;
          return sel;
        }
      });

    if (!stepSelections.length) {
      let ret = flowTree.map((level) => {
        level.options = level.options.filter(
          (opt) =>
            opt.step !== "LensColor" &&
            (!opt.prevChain ||
              !opt.prevChain.find((step) => step.step === "LensColor"))
        );
        if (!level.options.length) {
          return null;
        }
        return level;
      });
      ret = ret.filter((level) => level);
      return ret;
    }

    if (workflow.isLastStep()) {
      let currentPackage = null;
      if (currentPackages.length > 1) {
        //this can happen because sometimes currentPackages doesn't update in time
        //so we are left with two addOns (for the other selections it doesn't happen)
        //and we need to distinguish between the packages by looking at stepSelections
        let addOnsSelection = stepSelections.find(
          (sel) => sel.originalStep === "AddOns"
        );
        if (addOnsSelection) {
          let premiumSelected = addOnsSelection.attributes?.isPremium;
          let standardPackage = getCheapPackageFromList(currentPackages);
          let currentPackageParsed = currentPackages.map((pkg) => {
            return {
              frame: pkg.frame,
              lensPackage: {
                ...pkg.lensPackage,
                listPrice: parseFloat(pkg.lensPackage.listPrice),
              },
            };
          });
          let premiumPackage = maxBy(
            currentPackageParsed,
            "lensPackage.listPrice"
          );
          currentPackage = premiumSelected ? premiumPackage : standardPackage;
        } else {
          currentPackage = currentPackages[0];
        }
      } else {
        currentPackage = currentPackages[0];
      }

      let foundTreeOption = null;
      let foundLevel = null;
      flowTree.forEach((level) => {
        level.options.forEach((opt) => {
          let found = opt.packages.find(
            (pkg) =>
              pkg.lensPackage.catEntryId ===
              currentPackage.lensPackage.catEntryId
          );
          if (found) {
            foundTreeOption = opt;
            foundLevel = level.level;
            return;
          }
        });
      });
      if (foundTreeOption) {
        let prevChain = foundTreeOption.prevChain;
        let newFlowTree = [];
        if (prevChain) {
          prevChain.forEach((sel) => {
            let level = flowTree[sel.level];
            level.options = level.options.filter((opt) =>
              opt.step === "Type"
                ? opt.option === frameOnlyKey
                : opt.option === sel.option
            );
            level.options = level.options.filter(
              (opt) =>
                !opt.prevChain ||
                opt.prevChain.every((prev) =>
                  prevChain.find((elem) => prev.option === elem.option)
                )
            );
            if (level.options.length) {
              newFlowTree.push(level);
            }
          });
          newFlowTree.push({
            level: foundLevel,
            options: [foundTreeOption],
          });
          return newFlowTree;
        }
      }
    }

    let ret = flowTree.map((level) => {
      level.options = level.options.filter(
        (opt) =>
          !stepSelections.find((sel) => sel.originalStep === opt.step) ||
          !stepSelections.find(
            (sel) => sel.key === opt.key && sel.value === opt.option
          )
      );

      level.options = level.options.filter(
        (opt) =>
          !opt.prevChain ||
          (stepSelections.length <= opt.prevChain.length &&
            stepSelections.every((sel) =>
              opt.prevChain.find(
                (prev) => prev.key === sel.key && prev.option === sel.value
              )
            )) ||
          (stepSelections.length > opt.prevChain.length &&
            opt.prevChain.every((prev) =>
              stepSelections.find(
                (sel) => sel.key === prev.key && sel.value === prev.option
              )
            ))
      );

      if (!level.options.length) {
        return null;
      }

      return level;
    });

    ret = ret.filter((level) => level);

    let colorStepPassed = stepSelections.find(
      (sel) => sel.originalStep === "LensColor"
    );
    if (!colorStepPassed) {
      ret = ret.map((level) => {
        level.options = level.options.filter(
          (opt) =>
            opt.step !== "LensColor" &&
            (!opt.prevChain ||
              !opt.prevChain.find((step) => step.step === "LensColor"))
        );
        if (!level.options.length) {
          return null;
        }
        return level;
      });
    }

    ret = ret.filter((level) => level);
    return ret;
  };

  const computeRIACallBlocks = (
    firstStepKey: string,
    flowTree: any[],
    frameOnlyKey: string,
    moveForward: boolean
  ) => {
    const processFlowTree = (flowTree: any[]) => {
      let seenCatEntryIDs = new Set<string>();
      return flowTree
        .map((level) => {
          let ret = { level: level.level, options: [] };
          ret.options = level.options
            .map((opt) => {
              let ret = { ...opt, packages: [] };
              let insPrice = opt.packages[0].lensPackage.insPrice;
              if (
                insPrice === undefined ||
                insPrice === null ||
                isNaN(insPrice)
              ) {
                ret.packages = [opt.packages[0]];
              } else if (
                workflow.isLastStep() &&
                RIAParams.discountMethod === DiscountMethod.STANDARD_REVIEW
              ) {
                ret.packages = [opt.packages[0]];
              }
              if (ret.packages.length) {
                let catEntryID = ret.packages[0].lensPackage.catEntryId;
                if (!seenCatEntryIDs.has(catEntryID)) {
                  seenCatEntryIDs.add(catEntryID);
                } else {
                  return null;
                }
              } else {
                return null;
              }
              return ret;
            })
            .filter((opt) => opt);
          if (!ret.options.length) {
            return null;
          }
          return ret;
        })
        .filter((level) => level);
    };

    if (!firstStepKey) {
      return null;
    }

    flowTree = pruneFlowTreeAtCurrentStep(flowTree, frameOnlyKey);

    const N = RIAParams.N;
    const K = RIAParams.K;
    const callBlocks: RIAAPICall[][] = [];

    let processedFlowTree = processFlowTree(flowTree);

    if (
      workflow.isLastStep() &&
      RIAParams.discountMethod === DiscountMethod.STANDARD_REVIEW
    ) {
      //remove the current insPrice from the packages so that the loader will display
      let pkgList = [];
      processedFlowTree.forEach((level) => {
        pkgList = [...pkgList, ...level.options[0].packages];
      });
      pkgList = pkgList.map((pkg) => {
        let newFrame = {
          ...pkg.frame,
          insPrice: null,
        };
        let newLensPackage = {
          ...pkg.lensPackage,
          insPrice: null,
        };
        let toRet = {
          frame: newFrame,
          lensPackage: newLensPackage,
        };
        return toRet;
      });
      dispatch(
        configActions.setInsurancePackage({
          packages: pkgList,
          clearPrice: true,
          frame: configContent.data.frame,
        })
      );
    }

    if (workflow.isLastStep()) {
      let insuranceReviewObject = {};
      if (processedFlowTree.length) {
        processedFlowTree.forEach((level) => {
          if (level.options.length) {
            let step = level.options[0].step;
            let pkg = level.options[0].packages[0];
            if (step !== "Type") {
              insuranceReviewObject[step] = {
                step: step,
                catEntryId: pkg.lensPackage.catEntryId,
              };
            }
          }
        });
      }
      flowTree.forEach((level) => {
        if (level.options.length) {
          let step = level.options[0].step;
          let pkg = level.options[0].packages[0];
          if (step !== "Type") {
            insuranceReviewObject[step] = {
              step: step,
              catEntryId: pkg.lensPackage.catEntryId,
            };
          }
        }
      });
      dispatch(
        insuranceActions.setReviewInsuranceObject(insuranceReviewObject)
      );
    }

    let blockIndex = 0;
    let callIndex = 0;
    let callPackages = 0;
    processedFlowTree.forEach((level) => {
      let frameOnlyOption = level.options[0].option === frameOnlyKey;
      if (frameOnlyOption) {
        if (!callBlocks[blockIndex]) {
          callBlocks[blockIndex] = [
            {
              type: RIAAPICallType.SINGLE_LP,
              packages: level.options[0].packages,
            },
          ];
        } else {
          callBlocks[blockIndex].push({
            type: RIAAPICallType.SINGLE_LP,
            packages: level.options[0].packages,
          });
        }
        callPackages = 0;
        callIndex++;
        if (callIndex === N) {
          callIndex = 0;
          blockIndex++;
        }
        level.options.shift();
      }
      level.options.forEach((opt) => {
        if (!callBlocks[blockIndex]) {
          callBlocks[blockIndex] = [
            {
              type: RIAAPICallType.SINGLE_LP,
              packages: opt.packages,
            },
          ];
          callPackages++;
        } else {
          let lastCall = callBlocks[blockIndex][callIndex];
          if (!lastCall) {
            callBlocks[blockIndex].push({
              type: RIAAPICallType.SINGLE_LP,
              packages: opt.packages,
            });
            callPackages++;
          } else {
            lastCall.packages = lastCall.packages.concat(opt.packages);
            if (lastCall.packages.length > 1) {
              lastCall.type = RIAAPICallType.MULTIPLE_LP;
            }
            callPackages++;
          }
        }
        if (callPackages === K) {
          callIndex++;
          callPackages = 0;
        }

        if (callIndex === N) {
          callIndex = 0;
          callPackages = 0;
          blockIndex++;
        }
      });
    });

    return callBlocks;
  };

  const [callBlocksQueue, setCallBlocksQueue] = useState(null);

  useEffect(() => {
    if (insuranceEnabled && stepChains && currentStep && RIAParams) {
      /* let manager = RIAManager.getInstance(configContent, dispatch, onBlockCompleted);
            manager.test(); */
      if (!cartMode || (cartMode && loadPreselectEnabled == false)) {
        let firstStep = currentStep
          ? currentStep.key
          : configContent.steps &&
            configContent.steps.length &&
            configContent.steps[0].key;
        let { flowTree, frameOnlyKey } = computeRIAFlowTree();
        let callBlocks = computeRIACallBlocks(
          firstStep,
          flowTree,
          frameOnlyKey,
          true
        );
        setCallBlocksQueue(callBlocks);
      }
    }
  }, [
    insuranceEnabled,
    RIAParams,
    stepChains,
    currentPrescription,
    currentStep,
    cartMode,
    loadPreselectEnabled,
  ]);

  const onBlockCompleted = (catEntryIDs: string[]) => {
    let newQueue = [...callBlocksQueue];
    newQueue.shift();
    setCallBlocksQueue(newQueue);
  };

  useEffect(() => {
    if (callBlocksQueue && callBlocksQueue.length) {
      let manager = RIAManager.getInstance(
        configContent,
        RIAParams.discountMethod,
        dispatch,
        (catEntryIDs: string[]) => onBlockCompleted(catEntryIDs)
      );
      let call = callBlocksQueue[0];
      manager.callAPI(call);
    }
  }, [callBlocksQueue]);

  const getConfigByStepName = (stepName: string) => {
    const mapStepNameToContentKey = (stepName: string) => {
      switch (stepName) {
        case "Type":
          return "type";
        case "Brand":
          return "brand";
        case "TransitionColor":
          return "color";
        case "LensColor":
          return "color";
        case "GVPTreatment":
          return "treatment";
        case "DesignType":
          return "designType";
        case "Thickness":
          return "thickness";
        case "AddOns":
          return "antiReflective";
        case "blueLight":
          return "blueLight";
        case "transition":
          return "transition";
      }
      return null;
    };
    let mappedKey = mapStepNameToContentKey(stepName);
    let entries = null;

    if (mappedKey) {
      entries = lensesDataContent ? lensesDataContent[mappedKey] : null;
    }
    if (
      !mappedKey ||
      !entries ||
      Object.keys(lensesDataContent[mappedKey]).length == 0
    ) {
      if (stepName === "Treatment") {
        let blueLightContent = lensesDataContent["blueLight"];
        blueLightContent = Object.keys(blueLightContent).map((key) => {
          let ret = {};
          let contentObj = { ...blueLightContent[key] };
          contentObj.key = "blueLight";
          ret[key] = contentObj;
          return ret;
        });

        let transitionContent = lensesDataContent["transition"];
        transitionContent = Object.keys(transitionContent).map((key) => {
          let ret = {};
          let contentObj = { ...transitionContent[key] };
          contentObj.key = "transition";
          ret[key] = contentObj;
          return ret;
        });
        blueLightContent.forEach((element) => {
          entries = { ...entries, ...element };
        });
        transitionContent.forEach((element) => {
          entries = { ...entries, ...element };
        });
      }
      if (stepName === "TreatmentsFamily") {
        let blueLightContent = lensesDataContent["blueLight"];
        blueLightContent = Object.keys(blueLightContent).map((key) => {
          let ret = {};
          let contentObj = { ...blueLightContent[key] };
          contentObj.key = "blueLight";
          ret[key] = contentObj;
          return ret;
        });

        let transitionContent = lensesDataContent["transition"];
        transitionContent = Object.keys(transitionContent).map((key) => {
          let ret = {};
          let contentObj = { ...transitionContent[key] };
          contentObj.key = "transition";
          ret[key] = contentObj;
          return ret;
        });

        if (
          lensesDataContent["treatmentFamily"] &&
          Object.keys(lensesDataContent["treatmentFamily"]).length > 0
        ) {
          let keys = Object.keys(lensesDataContent["treatmentFamily"]);
          keys.map((k) => {
            let finded = configPackages.find(
              (pkg) => pkg.lensPackage.treatmentFamily == k
            );
            if (finded) {
              let obj = {};
              obj[k] = {
                ...lensesDataContent["treatmentFamily"][k],
                key: "treatmentFamily",
                id: k,
                treatmentKey:
                  finded.lensPackage.transition !== "" &&
                  finded.lensPackage.transition !== null
                    ? "transition"
                    : "blueLight",
              };
              transitionContent = [obj];
            }
          });
        }

        entries = {
          Clear: {
            key: null,
            sequence: 0,
          },
        };
        blueLightContent.forEach((element) => {
          entries = { ...entries, ...element };
        });
        transitionContent.forEach((element) => {
          entries = { ...entries, ...element };
        });

        entries = {
          ...entries,
          Sun: {
            key: "color",
            sequence: 100,
          },
        };
      }
    }

    if (entries) {
      let ret = null;
      if (stepName === "blueLight" || stepName == "transition") {
        ret = Object.entries<Record<string, any>>(entries).map(
          ([key, val]) => ({ ...val, id: key, key: stepName })
        );
      } else {
        ret = Object.entries<Record<string, any>>(entries).map(
          ([key, val]) => ({ ...val, id: key })
        );
      }

      return orderBy(ret, "sequence");
    }
    return null;
  };

  const RIAConfigBaseUrl = useSelector(
    (state: any) => state.config?.baseURLs?.RIAConfig
  );

  useEffect(() => {
    if (currentBrand && RIAConfigBaseUrl) {
      if (process.env.DEV) return;
      let brand = currentBrand === "default" ? "lenscrafters" : currentBrand;
      axios
        .get(RIAConfigBaseUrl)
        .then((res) => {
          let resObj = res.data;
          if (!resObj[brand]) {
            setRIAParams({
              N: resObj["default"].n,
              K: resObj["default"].k,
              discountMethod: resObj["default"].method,
            });
            dispatch(
              insuranceActions.setPricingMethodConfig(
                resObj["default"].method || "STANDARD"
              )
            );
          } else {
            setRIAParams({
              N: resObj[brand].n,
              K: resObj[brand].k,
              discountMethod: resObj[brand].method,
            });
            dispatch(
              insuranceActions.setPricingMethodConfig(
                resObj[brand].method || "STANDARD"
              )
            );
          }
        })
        .catch((err) => {
          console.error(err);
          console.error(
            "Unable to retreive RIA configuration at url: " + RIAConfigBaseUrl
          );
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Code: "RX Configurator: Loading error",
              Error_Details: err,
              Error_Source: "Client",
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          if (!configContent.insuranceModule?.isLogged()) {
            dispatch(insuranceActions.setInsuranceEnabled(false));
          } else {
            configContent.insuranceModule.removeInsuranceBenefits() ?? null;
            dispatch(insuranceActions.setInsuranceEnabled(false));
            /* dispatch(modalsActions.setShowInsuranceErrorPopup(true)); */
          }
        });
    }
  }, [RIAConfigBaseUrl, currentBrand]);
};
