import React from "react";
import "./default.module.scss";

interface LoaderProps {
  greyLoader?: boolean;
}

export function Loader({ greyLoader }: LoaderProps) {
  return (
    <span className={"Loader__container"}>
      <span
        className={!greyLoader ? "Loader__icon" : "Loader__iconNonGreen"}
      ></span>
    </span>
  );
}
