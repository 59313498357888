import React, { useRef } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { SelectConfirm } from "../../CallMyDoctorPrescriptionForm/component";
import { BrandComponent } from "../../common/BrandComponent";
import GenericButton from "../../common/GenericButton";
import { SwitchControl } from "../../common/Switch";
import {
  FilePreview,
  UploadError,
  UploadResult,
} from "../../PrescriptionUploadForm/component";

interface CaliforniaManualInputProps {
  doctorSelected;
  nestedFlow;
  isCalifornian;
  handleSwitch;
  visibilityButtonUploaded;
  onUploadChange /* (handleFileChange) */;
  isUploading;
  file;
  selectedNested;
  isSuccessful;
  isFileTooBigError;
  filePath;
  preloadedFileInfo;
  prescriptionModule;
  prescriptionExternalFunctions;
  onSelectNested;
  openModal;
}

const getSupportedFileExtensions = (prescriptionModule) => {
  if (prescriptionModule && prescriptionModule.fileExtensions) {
    let parsedExt = [];
    prescriptionModule.fileExtensions.forEach((ext) =>
      parsedExt.push("." + ext)
    );
    return parsedExt.join();
  }
};

export function CaliforniaManualInput(props: CaliforniaManualInputProps) {
  const {
    doctorSelected,
    nestedFlow,
    isCalifornian,
    handleSwitch,
    visibilityButtonUploaded,
    onUploadChange,
    isUploading,
    file,
    selectedNested,
    isSuccessful,
    isFileTooBigError,
    filePath,
    preloadedFileInfo,
    prescriptionModule,
    prescriptionExternalFunctions,
    onSelectNested,
    openModal,
  } = props;

  const californianToggle = useTranslate(
    "steps.advancedPrescription.californian.toggle"
  );
  const californianinfo = useTranslate(
    "steps.advancedPrescription.californian.info"
  );
  const californianChooseTitle = useTranslate(
    "steps.advancedPrescription.californian.chooseTitle"
  );
  const californianUploadTitle = useTranslate(
    "steps.advancedPrescription.californian.uploadTitle"
  );
  const californianUploadDesc = useTranslate(
    "steps.advancedPrescription.californian.uploadDesc"
  );
  const californianUploadButton = useTranslate(
    "steps.advancedPrescription.californian.uploadButton"
  );
  const californianCallMyDocTitle = useTranslate(
    "steps.advancedPrescription.californian.callMyDoc"
  );
  const californianCallMyDocButton = useTranslate(
    "steps.advancedPrescription.californian.callMyDocButton"
  );
  const californianYes = useTranslate(
    "steps.advancedPrescription.californian.yes"
  );
  const californianNo = useTranslate(
    "steps.advancedPrescription.californian.no"
  );
  const prescriptionUploaded = useTranslate(
    "steps.advancedPrescription.upload.prescriptionUploaded"
  );
  const somethingWentWrong = useTranslate(
    "steps.advancedPrescription.upload.somethingWentWrong"
  );
  const fileTooBigErrorTitle = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorTitle"
  );
  const fileTooBigErrorDescription = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorDescription"
  );
  const tryAgain = useTranslate("steps.advancedPrescription.upload.tryAgain");
  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const upload = useTranslate("steps.advancedPrescription.upload.upload");

  const fileInputRef = useRef(null);

  const isPrescriptionType =
    prescriptionExternalFunctions?.prescriptionType &&
    prescriptionExternalFunctions?.prescriptionType == "NESTED_CA";

  const getNestedOptions = (nested = ["UPLOAD"]) => {
    return nested.map((el) => {
      let isUploaded = el == "UPLOAD";
      return (
        <div
          className={"CalifornianInput__nestedElement"}
          style={{
            padding: isUploaded && nested.length == 1 ? "0 6px" : "unset",
          }}
          key={el}
        >
          <div className={"CalifornianInput__containerEl"}>
            <div
              className={"CalifornianInput__checkboxContainer"}
              style={{
                display: isUploaded && nested.length == 1 ? "none" : "block",
              }}
            >
              <div
                className={"CalifornianInput__rounded"}
                role={"button"}
                onClick={() => onSelectNested(el)}
                data-analytics_available_call="0"
              >
                {selectedNested == el && (
                  <div className={"CalifornianInput__rounded__selected"} />
                )}
              </div>
            </div>
            <div>
              <div className={"CalifornianInput__title"}>
                {isUploaded
                  ? californianUploadTitle
                  : californianCallMyDocTitle}
              </div>
              {isUploaded && el == selectedNested && (
                <div className={"CalifornianInput__descEl"}>
                  {californianUploadDesc}
                </div>
              )}
              {el == selectedNested && (
                <>
                  <div
                    onClick={() => {
                      if (isUploaded) {
                        if (fileInputRef.current) {
                          fileInputRef.current.click();
                        }
                      } else {
                        openModal();
                      }
                    }}
                    className={
                      "FullPrescriptionForm__innerContainer__applyButton" +
                      (!visibilityButtonUploaded && isUploaded
                        ? "__california"
                        : "") +
                      (!isUploaded && doctorSelected ? "__california" : "")
                    }
                  >
                    {isUploaded
                      ? californianUploadButton
                      : californianCallMyDocButton}
                  </div>
                  {isUploaded && (
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept={getSupportedFileExtensions(prescriptionModule)}
                      onChange={onUploadChange}
                    />
                  )}
                  {file && isUploaded && (
                    <>
                      {isUploading && (
                        <div className={"CalifornianInput__loaderContainer"}>
                          <BrandComponent
                            componentName="Loader"
                            parameter={{
                              greyLoader: true,
                              style: {
                                width: 30,
                                height: 30,
                              },
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {doctorSelected && !isUploaded && selectedNested !== "UPLOAD" && (
            <>
              <SelectConfirm
                selected={doctorSelected}
                handleSelect={() => openModal()}
                //wrongLabel={"CHANGE"}
                //classProps={"californian"}
              />
            </>
          )}
          {file && isUploaded && selectedNested == "UPLOAD" && (
            <>
              {!isUploading && (
                <div className={"FileUpload"}>
                  <div
                    className={
                      "PrescriptionUploadForm__UploadBox__container" +
                      (!isSuccessful && !isUploading ? " error" : "")
                    }
                  >
                    <>
                      <div className="PrescriptionUploadForm__UploadBox__container__topContainer">
                        <UploadResult
                          isSuccessful={isSuccessful}
                          uppercase={
                            isSuccessful || isFileTooBigError ? true : false
                          }
                          message={
                            isSuccessful
                              ? prescriptionUploaded
                              : isFileTooBigError
                              ? fileTooBigErrorTitle
                              : somethingWentWrong
                          }
                        />
                      </div>
                      <div className="PrescriptionUploadForm__UploadBox__container__bottomContainer">
                        {isSuccessful && (
                          <FilePreview
                            handleUploadAgain={() => {
                              if (fileInputRef.current) {
                                fileInputRef.current.click();
                              }
                            }}
                            forceFilePath={filePath}
                            forceFileInfo={preloadedFileInfo}
                          />
                        )}
                        {!isSuccessful && (
                          <UploadError
                            message={
                              isFileTooBigError
                                ? fileTooBigErrorDescription.replace(
                                    "###FILE_SIZE###",
                                    prescriptionModule?.maxFileSize
                                      ? prescriptionModule?.maxFileSize
                                      : 10
                                  )
                                : tryAgain
                            }
                            buttonText={
                              isFileTooBigError ? uploadDifferentFile : upload
                            }
                            onButtonClick={() => {
                              if (fileInputRef.current) {
                                fileInputRef.current.click();
                              }
                            }}
                          />
                        )}
                      </div>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      );
    });
  };

  if (!isPrescriptionType) {
    return <></>;
  }

  return (
    <>
      <div className={"CalifornianInput__toggle"}>
        <div>{californianToggle}</div>
        <div className={"CalifornianInput__switchContainer"}>
          <div className={"CalifornianInput__answerToggle"}>
            {californianNo}
          </div>
          <SwitchControl
            name="PolarSwitch"
            checked={isCalifornian}
            onChange={handleSwitch}
          />
          <div className={"CalifornianInput__answerToggle"}>
            {californianYes}
          </div>
        </div>
      </div>
      {isCalifornian && (
        <>
          <div
            className={"CalifornianInput__toggleDesc"}
            dangerouslySetInnerHTML={{
              __html: californianinfo,
            }}
          />

          <div className={"CalifornianInput__ChooseTitle"}>
            {californianChooseTitle}
          </div>
          {getNestedOptions(nestedFlow)}
        </>
      )}
    </>
  );
}
