//@ts-ignore
export default {
  rayban: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision need, so you can go hussle free",
        },
        advancedPrescription: {
          confirmedTitle: "Prescription",
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            review: {
              title: "Entered manually",
            },
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            addPlaceholder: "None",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pupillaryDistanceSubtitle:
              "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please ",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningValue:
              "Please input your pupillary distance, if you don’t have it you can use the default from the above or you can ",
            pupillaryDistanceMisurePDAction: "measure your PD.",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            commentsTitle: "Comments",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarning2:
                "The selected PD is smaller than average, we suggest double checking your prescription. If you don’t have it, you can add the values written above or you can ",
              missingBaseDir: "Please choose the base direction for your prism",
              missingPrismValues: "Please choose the prism value",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              incompatibleWithPackagesTitle:
                "Sorry! The prescription you've chosen isn't compatible with any of our available lenses for this frame",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
            digitalOptometry: {
              title: "How to measure your PD",
              appSubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
              manuallySubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. You can measure with the app or manually.",
              toggle: {
                app: "with the app",
                manually: "manually",
              },
              opthyDesc:
                "<b>Get your glasses just right with Opthy.</b><br/><p>Opthy measures your Pupillary Distance, allowing you to find the most comfortable, personalized fit.</p><p>App available on iPhone X and above.</p>",
              manuallyContent:
                "<div>If you want, you can measure your PD yourself by following these steps:</div><ul><li>Grab a small ruler in one hand, and have your pencil and paper handy.</li><li>Now position yourself approximately 8 inches (20 cm) away from a mirror.</li><li>Close your left eye and align the 0 over the center of your right pupil.</li><li>Measure the distance from your right to your left pupil.</li><li>The number that lines up directly over your left pupil is your PD (an average PD for an adult ranges between 58 and 65).</li></ul>",
            },
            manualPreviewTitle: "Your prescription details",
            seeDetails: {
              sphere: "SPH",
              cylinder: "CYL",
              axis: "Axis",
              add: "ADD",
              pd: "PD",
              od: "OD",
              os: "OS",
              vertical: "Vertical Prism (Δ)",
              baseDirection: "Base Direction",
              horizontal: "Horizontal Prism (Δ)",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload it now",
              description:
                "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages",
            },
            uploadDifferentFile: "Upload different file",
            changeMethod: "Change sending method",
            prescriptionUploaded: "Prescription Uploaded",
            somethingWentWrong: "Sorry, something went wrong.",
            fileTooBigErrorTitle: "File is too big",
            fileTooBigErrorDescription:
              "Your file size is too big. Maximum upload ###FILE_SIZE### MB",
            tryAgain: "Please try again",
            upload: "Upload",
            pdForm: {
              pupillaryDistance: "PD (Pupillary distance)",
              whatIsIt: "What is it?",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 PD numbers",
            },
            confirmAndContinue: "Confirm & continue",
            filePreviewTitle: "Your prescription",
          },
          doctor: {
            title: "Add your doctor details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your eye doctor by",
            card: {
              title: "Call my doctor",
              description: "We'll get your prescription directly from him/her.",
            },
            addNewDoctorModal: {
              title: "Add new doctor",
              clinicOrDoctorName: "Clinic or doctor name",
              phoneNumber: "Phone number",
              addDoctor: "Add doctor",
            },
            slider: {
              phone: "Phone number",
              clinicOrDoctorName: "Clinic or doctor name",
            },
            table: {
              doctorName: "Doctor name",
              clinicName: "Clinic name",
              address: "Address",
              chooseButton: "Choose",
              phone: "Phone",
              fax: "Fax",
              phoneNumber: "Phone number",
              changeButton: "Change",
              wrongDoctor: "Wrong doctor?",
            },
            pdForm: {
              pupillaryDistance: "PD (Pupillary distance)",
              whatIsIt: "What is it?",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 PD numbers",
            },
            addManuallyOption: {
              didntFind: "Didn't find your doctor?",
              addManually: "Add manually",
            },
            missingInfoError: "Complete the missing informations",
            doctorNotFoundBoxMessage: "We couldn't find your doctor.",
            doctorNotFoundBoxMessageAdd: "Please add more details or ",
            doctorNotFoundBoxMessageLink: "add a new doctor.",
            phoneNumber: "Doctor phone number",
            search: "Search",
            clinicOrDoctorName: "Clinic or doctor name",
            city: "City",
            state: "State",
            changeSubmissionMethod: "Change submission method",
            confirmAndContinue: "Confirm & continue",
            selectedDoctorPageTitle: "Your doctor details",
            doctorPreviewTitle: "We'll call your doctor",
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
            review: {
              title: "We'll ask for it after the checkout",
            },
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, California residents are also required to electronically transmit their valid prescription. Choose an option to proceed.",
            chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
            uploadTitle: "Upload",
            uploadDesc:
              "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Browse",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle:
                "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colors and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          designTypeTitle: "Choose your lens design",
        },
        designType: {
          confirmedTitle: "Design Type",
          title: "Choose your lens quality",
          subtitle:
            "State-of-the-art lenses made with Ray-Ban's unique lens technology, complete with the brand's iconic signature etching.",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Color",
          },
          categories: {
            all: {
              title: "All",
            },
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Color:",
          title: "Choose your lens color",
          titleMobile: "Choose your lens color",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarized only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced color and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishings:",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
          seeDetails: "See details",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
        vatIncluded: "Tax included",
        discountBadgePercentage: "###VALUE######SYMBOL### OFF",
        discountBadgeAmount: "###VALUE######SYMBOL### OFF",
      },
      and: "&",
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceLabelRemove: "Remove benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      insuranceErrorModal: {
        title: "Something went wrong...",
        content:
          "We weren't able to apply your insurance benefits. Your insurance plan will be automatically unsynced in order to continue.",
        confirm: "Confirm",
      },
      grayOutBannerMessage:
        "Some options may be disabled as they are not compatible with your prescription values.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
      error: {
        title: "Something went wrong...",
        description:
          "We’re experiencing some technical difficulties, we apologize. In the meantime, if you have any questions or need assistance, feel free to <a href='#'>contact our customer service.</a>",
        buttonLabel: "Back",
      },
      generic: {
        modal: {
          close: "",
        },
      },
    },
    en_GB: {
      steps: {
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision needs, so you can go hassle free",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest you double check your prescription. If this is correct, you can proceed.",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          doctor: {
            title: "Add your optician details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your optician by",
            card: {
              title: "Call my optician",
              description: "We'll get your prescription directly from him/her.",
            },
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colours and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
          designTypeTitle: "Choose your lens design",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColour",
        },
        lensColor: {
          confirmedTitle: "Colour:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Colour",
          },
          categories: {
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Colour:",
          title: "Choose your lens colour",
          titleMobile: "Choose your lens colour",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarised only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced colour and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishes",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest you double check your prescription.",
        compatibleFrameError:
          "SSorry! The frame you've chosen doesn't support your prescription values.",
        compatibleFrameSubLabel:
          "No worries, we've got frames to suit your prescription.",
        axisError: "The AXIS values you've inserted are not correct!",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD numbers",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
    },
    en_AU: {
      steps: {
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision need, so you can go hussle free",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type..",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest double checking your prescription. If this is correct, you can proceed.",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            card: {
              title: "Upload it now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          doctor: {
            title: "Add your doctor details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your eye doctor by",
            card: {
              title: "Call my doctor",
              description: "We'll get your prescription directly from him/her.",
            },
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",

          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colours and tints.",
            imageFileName: "",
          },
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
          designTypeTitle: "Choose your lens design",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
        },
        color: {
          confirmedTitle: "Colour:",
          title: "Choose your lens colour",
          polarized: "Polarized only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced colour and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        warranty: {
          title: "LensCrafters Care Coverage",
          add: "Add ",
          oneYear: "one-year coverage",
          twoYear: "two-year coverage",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
    },
    en_CH: {
      steps: {
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision needs, so you can go hassle free",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest you double check your prescription. If this is correct, you can proceed.",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          doctor: {
            title: "Add your optician details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your optician by",
            card: {
              title: "Call my optician",
              description: "We'll get your prescription directly from him/her.",
            },
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colours and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
          designTypeTitle: "Choose your lens design",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColour",
        },
        lensColor: {
          confirmedTitle: "Colour:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Colour",
          },
          categories: {
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Colour:",
          title: "Choose your lens colour",
          titleMobile: "Choose your lens colour",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarised only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced colour and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishes",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
    },
    en_MX: {
      steps: {
        prescription: {
          title: "Completa tu receta",
          description:
            "Or we will suggest the right lens for your vision need, so you can go hussle free",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Ojo derecho)",
            },
            leftEye: {
              initials: "OS",
              name: "(Ojo izquierdo)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest double checking your prescription. If this is correct, you can proceed.",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload it now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          doctor: {
            title: "Add your doctor details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your eye doctor by",
            card: {
              title: "Call my doctor",
              description: "We'll get your prescription directly from him/her.",
            },
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
          },
        },
        type: {
          title: "Selecciona tus necesidades de visión",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Selecciona la marca de tus cristales",
          confirmedTitle: "Tipo de micas:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colors and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Grosor:",
          title: "SELECCIONA EL GROSOR DE TUS MICAS",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
          designTypeTitle: "Choose your lens design",
        },
        treatments: {
          addToBag: "AGREGAR AL CARRITO",
          title: "Completa tus cristales",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Resistente a rayones",
            },
            antiReflective: {
              value: "Tratamiento antirreflejante",
            },
            uvProtection: {
              title: "Protección UV",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Color",
          },
          categories: {
            all: {
              title: "Todos",
            },
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Color:",
          title: "Choose your lens color",
          titleMobile: "Choose your lens color",
          titleDesignTypeLayout: "Choose your lens technology ",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarizados",
          applyAndContinue: "Apply",
          subtitle: "Enhanced color and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            polarized:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
            antiReflective:
              "Reduce los reflejos y proporciona un escudo invisible. Resiste el deslumbramiento, el polvo y las manchas para una visión más clara.",
            uvProtection:
              "Resistentes capas protectoras en la parte delantera y trasera de las micas, para una máxima protección contra los rayos UV y rayones.",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishings",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          title: "REVISA TU SELECCIÓN",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Aplicar seguro",
          insuranceButtonLabelRemove: "Elimina beneficios del seguro",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      prescription: {
        toggleLabel:
          "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
        prescriptionTitle:
          "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
        rightEye: "(Ojo derecho)",
        rightEyeInitials: "OD",
        leftEye: "(Ojo izquierdo)",
        leftEyeInitials: "OS",
        readPrescription: "Cómo leer tu receta",
        addLabel: "También tengo un valor de adición",
        applyButton: "Aplicar y continuar",
        submitButton: "Enviar",
        clearButton: "Borrar todo",
        skipButton: "Saltar por ahora",
        compatibleFramesButton: "Comprar armazones compatibles",
        doctorDetails:
          "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
        missingValueError: "Agrega tus valores para continuar",
        positiveNegativeError:
          "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
        compatibleFrameError:
          "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
        compatibleFrameSubLabel:
          "No te preocupes, tenemos armazones que se adaptan a tu receta.",
        axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
        axisSubError: "Estos deben ser entre 0 y 180.",
        axisSubErrorZero:
          "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
        editLabel: "Editar receta",
        pdHelp: "¿Qué es esto?",
        twoPDNumbers: "Tengo dos números de DIP",
        missingInputMessage:
          "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
        defaultInputMessage:
          "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
        skipMessage: "Más adelante, te pediremos completar o cargar tu receta",
        readyMessage: "¡Ya te podemos sugerir los cristales adecuados para ti!",
        bestPrescription: "Los mejores para tu receta",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Necesario para continuar",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "PRECIO DEL ARMAZÓN + MICAS:",
          frame: "Precio del armazón",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluido",
        frameLensStartingAt: "Armazón + micas en",
        frameLens: "Armazón + micas",
        startingAt: "A partir de",
      },
      and: "y",
      noTreatment: "No treatment",
      learnMoreMade: "Ver cómo se hace",
      learnMoreTreatment: "Ver el tratamiento",
      exitTitle: "¿SEGURO QUE QUIERES SALIR?",
      exitSubtitle: "Tu selección de cristales no se guardará",
      exitYes: "Yes, exit",
      exitContinueEditing: "Seguir editando",
      exitSave: "Guardar y seguir comprando",
      insuranceButtonLabel: "Agregar beneficios del seguro",
      insuranceButtonLabelRemove: "Elimina beneficios del seguro",
      insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
      applyingInsurance: "Aplicar beneficios del seguro",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
    },
    es_MX: {
      steps: {
        prescription: {
          description:
            "O te sugeriremos la mica adecuada para tus necesidades de vista y puedas ir sin problemas",
        },
        type: {
          title: "Selecciona tus necesidades de visión",
          prescriptionTitle: "o",
          addToCart: "Agrega al carrito",
          moreInfo: "Más información",
          confirmedTitle: "Tipo de graduación:",
        },
        brand: {
          title: "Selecciona la marca de tus cristales",
          confirmedTitle: "Tipo de micas:",
        },
        thickness: {
          title: "SELECCIONA EL GROSOR DE TUS MICAS",
          confirmedTitle: "Grosor",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          designTypeTitle: "Selecciona el diseño de la mica",
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          confirmedTitle: "Paquete de mica",
        },
        treatments: {
          addToBag: "AGREGAR AL CARRITO",
          title: "Completa tus cristales",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Tratamiento",
          extraTreatments: {
            antiScratch: {
              name: "Antirayones",
              title: "Resistente a rayones",
            },
            antiReflective: {
              value: "Tratamiento antirreflejante",
            },
            uvProtection: {
              title: "Protección UV",
            },
          },
        },
        transitionColor: {
          title: "Color de transición",
          indoor: "INTERIORES",
          outdoor: "EXTERIORES",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Transmisión de luz",
            lightConditions: "Condiciones de luz",
            contrast: "Contraste",
            baseLensColor: "Color de mica de base",
          },
          categories: {
            all: {
              title: "Todos",
            },
            "Ugly category": {
              title: "Categoría Ugly",
            },
            "Cool category": {
              title: "Categoría Cool",
            },
          },
        },
        color: {
          confirmedTitle: "color:",
          title: "Selecciona el color de la mica",
          titleMobile: "Selecciona el color de la mica",
          titleDesignTypeLayout: "Selecciona la tecnología de la mica",
          titleColorCategoryLayout: "Selecciona el tipo de la mica",
          polarized: "Solo polarizado",
          applyAndContinue: "Aplicar",
          subtitle:
            "Color y contraste mejorados para que puedas ver más detalles.",
          tooltip: {
            polarized:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            switch:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            antiScratch:
              "Protege la superficie de las lentes de los impactos fuertes ",
            antiReflective:
              "Reduce los reflejos y proporciona un escudo invisible. Resiste el deslumbramiento, el polvo y las manchas para una visión más clara.",
            uvProtection:
              "Resistentes capas protectoras en la parte delantera y trasera de las micas, para una máxima protección contra los rayos UV y rayones.",
            blueLight:
              "Reduce la exposición a la luz azul de las pantallas digitales y los rayos del sol, lo que puede ayudar a reducir la fatiga ocular.",
          },
        },
        review: {
          title: "REVISA TU SELECCIÓN",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          discountTitle:
            "Se aplica automáticamente un 40 % de descuento en micas graduadas",
          firstLineReview: "Armazón:",
          paymentInstallments: {
            installmentsLabel:
              "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Aplicar seguro",
          insuranceButtonLabelRemove: "Elimina beneficios del seguro",
          oneYearCoverageAdd: "Agregar",
          tooltip:
            "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
        },
        advancedPrescription: {
          title: "¿Tienes una receta?",
          manual: {
            title: "Ingresa tu receta",
            card: {
              title: "Ingrésala de manera manual",
              description:
                "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado.",
            },
            subtitle:
              "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
            rightEye: {
              initials: "OD",
              name: "(Ojo derecho)",
            },
            leftEye: {
              initials: "OI",
              name: "(Ojo izquierdo)",
            },
            sphere: "Esfera (ESF)",
            cylinder: "Cilindro (CIL)",
            axis: "Eje",
            add: "Adición",
            vertical: "Vertical (Δ)",
            baseDirection: "Dirección base",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "DP (Distancia pupilar)",
            pdLeft: "Izquierdo",
            pdRight: "Derecho",
            iHaveTwoPd: "Tengo dos números de DP",
            applyButton: "Continuar",
            howToRead: "Cómo leer tu receta",
            whatIsIt: "¿Qué es?",
            pupillaryDistanceWarningTooltip:
              "Proporcionar texto para esta descripción emergente",
            moreOptions: "Más opciones",
            alerts: {
              axisRangeError:
                "Los valores de EJE que introdujiste son incorrectos. Estos deben ser entre 1 y 180.",
              axisZeroError:
                "Los valores de EJE que introdujiste son incorrectos. Si el valor de CIL. es más alto o bajo que 0, el valor de EJE no puede ser 0.",
              sphPositiveNegativeWarning:
                "Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común. Te sugerimos revisar tu receta. Si es correcto, puedes continuar.",
              missingValues: "Completa los valores que hacen falta",
              requiredToProceed: "Es necesario para continuar",
              incompatibleWithPrescriptionTitle:
                "¡Lo sentimos! El armazón que elegiste no es compatible con tu receta",
              incompatibleWithPrescriptionBody:
                "pero no te preocupes, hay armazones compatibles",
              shopCompatible: "Compra los armazones compatibles",
              clearAll: "Borra todo",
              agreementText:
                "Al hacer clic en esta casilla, confirmo que los valores de la receta ingresados anteriormente se toman de una receta válida (no vencida) que me fue emitida, firmada por un optometrista u oftalmólogo con licencia.",
            },
            continueModal: {
              title: "Envía la receta más tarde",
              message:
                "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              continue: "Sí, continuar",
              cancel: "No, regresar",
            },
          },
          account: {
            card: {
              title: "Obtenerlo de mi cuenta",
              description: "Inicia sesión y selecciona tu receta",
            },
          },
          upload: {
            title: "Tu receta",
            subtitle:
              "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
            card: {
              title: "Cárgala ahora",
              description:
                "Y nosotros nos encargaremos del resto Aceptamos los siguientes formatos: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (10 MB máx.) y iWork Pages",
            },
          },
          doctor: {
            title: "Agrega la información de tu doctor",
            subtitle: "Obtendremos los detalles de tu receta con él/ella",
            searchBy: "Busca a tu oculista por",
            card: {
              title: "Llama a mi doctor",
              description: "Obtendremos tu receta con él/ella",
            },
          },
          later: {
            card: {
              title: "Envíala más tarde",
              description:
                "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
            },
          },
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo de mica",
          title: "Selecciona el tipo de mica",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          blueLight: {
            title: "Filtro de luz azul",
            description:
              "Reduce la exposición a la luz azul de las pantallas digitales, lo que puede ayudar a prevenir la fatiga ocular.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Un par para interiores y exteriores con micas Transitions®: se oscurecen rápidamente y se vuelven transparentes, para que nunca tengas que cambiar de lentes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparente",
            description:
              "Micas tradicionales y translúcidas, perfectas para el uso diario",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description: "Elige entre diferentes colores y tonos de micas.",
            imageFileName: "",
          },
        },
        addOns: {
          confirmedTitle: "Completa tus micas",
          label: "Ya está incluido con tu mica",
          title: "Completa tus micas",
          reviewTitle: "Acabados",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
        },
      },
      prescription: {
        toggleLabel:
          "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
        prescriptionTitle:
          "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
        rightEye: "(Ojo derecho)",
        rightEyeInitials: "OD",
        leftEye: "(Ojo izquierdo)",
        leftEyeInitials: "OS",
        readPrescription: "Cómo leer tu receta",
        addLabel: "También tengo un valor de adición",
        applyButton: "Aplicar y continuar",
        submitButton: "Enviar",
        clearButton: "Borrar todo",
        skipButton: "Saltar por ahora",
        compatibleFramesButton: "Comprar armazones compatibles",
        doctorDetails:
          "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
        missingValueError: "Agrega tus valores para continuar",
        positiveNegativeError:
          "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
        compatibleFrameError:
          "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
        compatibleFrameSubLabel:
          "No te preocupes, tenemos armazones que se adaptan a tu receta.",
        axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
        axisSubError: "Estos deben ser entre 0 y 180.",
        axisSubErrorZero:
          "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
        editLabel: "Editar receta",
        pdHelp: "¿Qué es esto?",
        twoPDNumbers: "Tengo dos números de DIP",
        missingInputMessage:
          "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
        defaultInputMessage:
          "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
        skipMessage: "Más adelante, te pediremos completar o cargar tu receta",
        readyMessage: "¡Ya te podemos sugerir los cristales adecuados para ti!",
        bestPrescription: "Los mejores para tu receta",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Necesario para continuar",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "PRECIO DEL ARMAZÓN + MICAS:",
          frame: "Precio del armazón",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluido",
        frameLensStartingAt: "Armazón + micas en",
        frameLens: "Armazón + micas",
        startingAt: "A partir de",
      },
      and: "y",
      noTreatment: "No treatment",
      learnMoreMade: "Ver cómo se hace",
      learnMoreTreatment: "Ver el tratamiento",
      exitTitle: "¿SEGURO QUE QUIERES SALIR?",
      exitSubtitle: "Tu selección de cristales no se guardará",
      exitYes: "Sí, salir",
      exitContinueEditing: "Seguir editando",
      exitSave: "Guardar y seguir comprando",
      insuranceButtonLabel: "Agregar beneficios del seguro",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
      applyingInsurance: "Aplicar beneficios del seguro",
      frameSize: "Precio del armazón",
      fallbackImageMessageTitle:
        "La vista previa del color de micas no está disponible",
      fallbackImageMessageSubtitle:
        "No te preocupes, recibirás tu armazón con el color de micas que seleccionaste.",
      proceedAsIs: "Proceder tal como están",
      yourSelections: "Tus selecciones",
      editButton: "Editar",
    },
    de_DE: {
      steps: {
        review: {
          insuranceButtonLabel: "Add insurance benefits",
          title: "Auswahl überprüfen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.",
          discountTitle:
            "- 40% auf Korrektionsgläser werden automatisch abgezogen.",
          firstLineReview: "Rahmen:",
          paymentInstallments: {
            installmentsLabel:
              "Ratenzahlung in zinslosen Raten mit ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabelRemove: "Versicherungsleistungen entfernen",
          oneYearCoverageAdd: "Hinzufügen",
          tooltip:
            "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.",
        },
        treatments: {
          addToBag: "In den Warenkorb legen",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandlungen:",
          extraTreatments: {
            antiReflective: {
              value: "Entspiegelung",
            },
            uvProtection: {
              title: "UV-Filter",
            },
            antiScratch: {
              title: "Kratzfest",
              name: "Kratzschutz",
            },
          },
        },
        prescription: {
          title: "Korrektionsgläser ergänzen",
          description:
            "Oder wir empfehlen Ihnen das richtige Brillenglas für Ihre Sehanforderungen, so dass Sie sich ganz entspannt zurücklehnen können.",
        },
        type: {
          title: "Sehanforderungen auswählen",
          confirmedTitle: "Anwenden",
          prescriptionTitle: "Oder",
          addToCart: "In den Einkaufswagen legen",
          moreInfo: "Mehr Info",
        },
        brand: {
          title: "Brillenglasmarke auswählen",
          confirmedTitle: "Lens brand:",
        },
        thickness: {
          title: "Glasstärke auswählen",
          confirmedTitle: "Stärke",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von LensCrafters veredelt und geliefert.",
          designTypeTitle: "Brillenglasdesign auswählen",
        },
        gvpTreatment: {
          title: "Plus-Paket ergänzen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von LensCrafters gefertigt.",
          confirmedTitle: "Gläser-Paket:",
        },
        advancedPrescription: {
          title: "Haben Sie einen Brillenpass?",
          manual: {
            title: "Daten aus dem Brillenpass eingeben",
            card: {
              title: "Manuell eingeben",
              description:
                "Dies ist unsere schnellste und beliebteste Methode. Wir empfehlen Ihnen dann den am besten geeigneten Brillenglastyp.",
            },
            subtitle:
              "Fügen Sie Ihre Korrektionswerte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre Sehanforderungen geeignet ist.",
            rightEye: {
              initials: "OD",
              name: "(Rechtes Augen)",
            },
            leftEye: {
              initials: "OS",
              name: "(Linkes Auge)",
            },
            sphere: "Sphäre (SPH)",
            cylinder: "Zylinder (ZYL)",
            axis: "Achse",
            add: "ADD",
            vertical: "Vertikal (Δ)",
            baseDirection: "Basisrichtung",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillendistanz)",
            pdLeft: "Links",
            pdRight: "Rechts",
            iHaveTwoPd: "Ich habe 2 PD-Nummern",
            applyButton: "Weiter",
            howToRead: "Brillenpass richtig lesen",
            whatIsIt: "Was ist das?",
            pupillaryDistanceWarningTooltip:
              "Text für diesen Kurzhinweis bereitstellen",
            moreOptions: "Weitere Optionen",
            alerts: {
              axisRangeError:
                "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt! Der Wert muss zwischen 1 und 180 liegen.",
              axisZeroError:
                "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt! Wenn der Wert für den Zylinder (CYL) größer oder kleiner als 0 ist, können die Werte für die ACHSE nicht 0 sein.",
              sphPositiveNegativeWarning:
                "Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist. Bitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.",
              missingValues: "Bitte geben Sie die noch fehlenden Daten ein",
              requiredToProceed:
                "Sie müssen dieses Kontrollkästchen aktivieren, um fortzufahren.",
              incompatibleWithPrescriptionTitle:
                "Es tut uns leid! Das von Ihnen gewählte Modell ist nicht für Ihrer Sehstärke erhältlich.",
              incompatibleWithPrescriptionBody:
                "Aber keine Sorge, wir haben auch passende Modelle für Sie.",
              shopCompatible: "Passende Modelle anzeigen",
              clearAll: "Alle löschen",
              agreementText:
                "Durch Anklicken dieses Kontrollkästchens bestätige ich, dass die oben eingegebenen Korrekturwerte einem aktuellen (nicht abgelaufenen) Brillenpass entnommen sind, der mir von einem Optiker oder Augenarzt ausgestellt wurde.",
            },
            continueModal: {
              title: "Brillenpass später senden",
              message:
                "Wenn Sie auf „Weiter“ klicken, überspringen Sie den Schritt „Eingabe der Korrekturwerte“. Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
              continue: "Ja, weiter",
              cancel: "Nein, zurück",
            },
          },
          account: {
            card: {
              title: "Über mein Konto abrufen",
              description:
                "Loggen Sie sich ein und wählen Sie Ihren Brillenpass aus.",
            },
          },
          upload: {
            title: "Ihre Brillenverordnung",
            subtitle:
              "Bevor Sie Ihre Brillenverordnung einreichen, überprüfen Sie bitte das Ablaufdatum, um sicherzustellen, dass sie noch gültig ist.",
            card: {
              title: "Jetzt hochladen",
              description:
                "Um den Rest kümmern wir uns. Es werden folgende Formate akzeptiert: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max. 10 MB) und iWork Pages",
            },
            prescriptionUploaded: "Brillenverodnung wurde hochgeladen",
            fileTooBigErrorTitle: "Datei ist zu groß",
            fileTooBigErrorDescription:
              "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
            changeMethod: "Übermittlungsart ändern",
            uploadDifferentFile: "Eine andere Datei hochladen",
            pdForm: {
              pupillaryDistance: "PD (Pupillendistanz)",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe 2 PD-Nummern",
            },
            confirmAndContinue: "Bestätigen & Fortfahren",
          },
          doctor: {
            title: "Daten zu Ihrem Augenarzt eingeben",
            subtitle:
              "Wir fordern Ihre Korrekturwerte direkt bei ihrem Augenarzt/Ihrer Augenärztin an",
            searchBy: "Augenarzt suchen nach",
            card: {
              title: "Meinen Arzt anrufen",
              description:
                "Wir fordern Ihre Korrekturwerte direkt bei ihm/ihr an",
            },
          },
          later: {
            card: {
              title: "Später senden",
              description:
                "Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
            },
          },
        },
        color: {
          polarized: "Nur polarisiert",
          confirmedTitle: "Farbe:",
          title: "Brillenglasfarbe auswählen",
          titleMobile: "Brillenglasfarbe auswählen",
          titleDesignTypeLayout: "Brillenglastechnologie auswählen",
          titleColorCategoryLayout: "Brillenglastyp auswählen",
          applyAndContinue: "Anwenden",
          subtitle:
            "Verbesserte Farb- und Kontrastsicht und eine bessere Detailerkennung",
          tooltip: {
            polarized:
              "Verringert die Blendung bei besonders hellem Licht und sorgt so für bessere Klarsicht und Schutz der Augen.",
            switch:
              "Verringert die Blendung bei besonders hellem Licht und sorgt so für bessere Klarsicht und Schutz der Augen.",
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen",
            blueLight:
              "Die Belastung durch blaues Licht von digitalen Bildschirmen und Sonnenstrahlen wird reduziert, was zur Verringerung von Augenermüdung beitragen kann.",
          },
        },
        treatmentsFamily: {
          confirmedTitle: "Brillenglastyp:",
          title: "Brillenglastyp auswählen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
          blueLight: {
            title: "Blaufilter",
            description:
              "Verringert die Blaulichtbelastung durch digitale Bildschirme, wodurch eine Ermüdung der Augen verringert werden kann.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Ein Modell für drinnen und draußen mit Transitions®-Gläsern: Sie verdunkeln sich schnell und werden wieder farblos, sodass Sie Ihre Brille nicht wechseln müssen.",
            imageFileName: "",
          },
          clear: {
            title: "Klar",
            description:
              "Klassische, transparente Gläser, perfekt für den täglichen Gebrauch",
            imageFileName: "",
          },
          sun: {
            title: "Sonne",
            description:
              "Wählen Sie aus verschiedenen Gläserfarben und -tönungen.",
            imageFileName: "",
          },
        },
        addOns: {
          confirmedTitle: "Ergänzen Sie Ihre Gläser.",
          label: "Bei Ihren Gläsern bereits inklusive",
          title: "Ergänzen Sie Ihre Gläser.",
          reviewTitle: "Veredelungen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "DRINNEN",
          outdoor: "DRAUSSEN",
        },
        lensColor: {
          confirmedTitle: "Farbe:",
          detailBox: {
            lightTransmission: "Lichtdurchlässigkeit",
            lightConditions: "Lichtverhältnisse",
            contrast: "Kontrast",
            baseLensColor: "Grundfarbe der Gläser",
          },
          categories: {
            all: {
              title: "Alle",
            },
            "Ugly category": {
              title: "Kategorie „Ugly“",
            },
            "Cool category": {
              title: "Kategorie „Cool“",
            },
          },
        },
      },
      prescription: {
        applyButton: "Übernehmen & Weiter",
        toggleLabel:
          "Brillenpass-Werte eingeben. Wir helfen Ihnen dabei, die perfekten Gläser für Ihre Sehanforderungen zu finden",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Rechtes Augen)",
        rightEyeInitials: "OD",
        leftEye: "(Linkes Auge)",
        leftEyeInitials: "OS",
        readPrescription: "Brillenpass richtig lesen",
        addLabel: "Ich habe auch einen ADD-Wert",
        submitButton: "Senden",
        clearButton: "Alle löschen",
        skipButton: "Jetzt überspringen",
        compatibleFramesButton: "Passende Modelle anzeigen",
        doctorDetails:
          "Bei oder nach dem Bestellvorgang fragen wir Sie nach einer Kopie Ihres Brillenpasses oder den erforderlichen Werte der Sehstärke",
        missingValueError: "Werte eingeben und weiter",
        positiveNegativeError:
          "Sie haben einen positiven und einen negativen Wert eingeben. Das ist eher ungewöhnlich. Bitte überprüfen Sie Ihre Eingabe.",
        compatibleFrameError:
          "Es tut uns leid! Doch das von Ihnen ausgewählte Modell ist für Ihre Sehstärke nicht erhältlich.",
        compatibleFrameSubLabel:
          "Keine Sorge. Wir haben Modelle, die zu Ihrer Sehstärke passen.",
        axisError:
          "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt!",
        axisSubError: "Der Wert muss zwischen 0 und 180 liegen.",
        axisSubErrorZero:
          "Wenn der Wert für den Zylinder (CYL) größer oder kleiner als 0 ist, kann der Wert für die ACHSE nicht 0 sein.",
        editLabel: "Werte bearbeiten",
        pdHelp: "Was bedeutet das?",
        twoPDNumbers: "Ich habe zwei PD-Werte",
        missingInputMessage:
          "Fügen Sie die fehlenden Werte hinzu, um den Upload Ihrer Sehstärke abzuschließen. Wir empfehlen daraufhin das am besten geeignete Glas.",
        defaultInputMessage:
          "Fügen Sie Ihre SPH- und CYL-Werte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre",
        skipMessage:
          "Wir werden Sie später bitten, Ihre Werte aus dem Brillenpass einzutragen oder hochzuladen.",
        readyMessage:
          "Jetzt können wir Ihnen das richtige Glas für Sie vorschlagen!",
        bestPrescription: "Die beste Auswahl für Ihre Sehanforderungen",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Erforderlich, um fortzufahren",
      },
      price: {
        totalFrameLens: {
          frame: "Preis für den Rahmen",
          total: "Total",
          frameLensTotal: "Preis für Rahmen + Gläser",
        },
        startingAt: "Ab",
        free: "Kostenlos",
        included: "Inklusive",
        frameLensStartingAt: "Rahmen + Gläser bei",
        frameLens: "Rahmen + Gläser",
      },
      noTreatment: "No treatment",
      insuranceButtonLabelRemove: "Versicherungsleistungen entfernen",
      frameSize: "Frame size",
      exitTitle: "MÖCHTEN SIE WIRKLICH ABBRECHEN?",
      exitSubtitle: "Ihre Glasauswahl wird nicht gespeichert",
      exitYes: "Änderungen verwerfen",
      exitContinueEditing: "Bearbeitung fortsetzen",
      exitSave: "Speichern und Einkauf fortsetzen",
      applyingInsurance: "Versicherungsleistungen eingeben",
      learnMoreTreatment: "Behandlung ansehen",
      learnMoreMade: "So wird es gemacht",
      insuranceButtonLabel: "Versicherungsleistungen hinzufügen",
      insuranceSyncedLabel: "Insurance is synced",
      fallbackImageMessageTitle: "Farbvorschau für Gläser nicht verfügbar",
      fallbackImageMessageSubtitle:
        "Keine Sorge, Sie erhalten Ihre Fassung mit der von Ihnen gewählten Glasfarbe.",
      proceedAsIs: "Weiter (ohne weitere Auswahl)",
      yourSelections: "Ihre Auswahl",
      editButton: "Bearbeiten",
    },
    de_CH: {
      steps: {
        prescription: {
          title: "Korrektionsgläser ergänzen",
          description:
            "Oder wir empfehlen Ihnen das richtige Brillenglas für Ihre Sehanforderungen, so dass Sie sich ganz entspannt zurücklehnen können.",
        },
        advancedPrescription: {
          title: "Haben Sie einen Brillenpass?",
          manual: {
            title: "Daten aus dem Brillenpass eingeben",
            card: {
              title: "Manuell eingeben",
              description:
                "Dies ist unsere schnellste und beliebteste Methode. Wir empfehlen Ihnen dann den am besten geeigneten Brillenglastyp.",
            },
            subtitle:
              "Fügen Sie Ihre Korrektionswerte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre Sehanforderungen geeignet ist.",
            rightEye: {
              initials: "OD",
              name: "(Rechtes Augen)",
            },
            leftEye: {
              initials: "OS",
              name: "(Linkes Auge)",
            },
            sphere: "Sphäre (SPH)",
            cylinder: "Zylinder (ZYL)",
            axis: "Achse",
            add: "ADD",
            vertical: "Vertikal (Δ)",
            baseDirection: "Basisrichtung",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillendistanz)",
            pdLeft: "Links",
            pdRight: "Rechts",
            iHaveTwoPd: "Ich habe 2 PD-Nummern",
            applyButton: "Weiter",
            howToRead: "Brillenpass richtig lesen",
            whatIsIt: "Was ist das?",
            pupillaryDistanceWarningTooltip:
              "Text für diesen Kurzhinweis bereitstellen",
            moreOptions: "Weitere Optionen",
            alerts: {
              axisRangeError:
                "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt! Der Wert muss zwischen 1 und 180 liegen.",
              axisZeroError:
                "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt! Wenn der Wert für den Zylinder (CYL) größer oder kleiner als 0 ist, können die Werte für die ACHSE nicht 0 sein.",
              sphPositiveNegativeWarning:
                "Sie haben 1 positiven und 1 negativen Wert eingegeben, was sehr selten ist. Bitte überprüfen Sie Ihre Eingabe. Falls Ihre Eingabe richtig ist, fahren Sie fort.",
              missingValues: "Bitte geben Sie die noch fehlenden Daten ein",
              requiredToProceed:
                "Sie müssen dieses Kontrollkästchen aktivieren, um fortzufahren.",
              incompatibleWithPrescriptionTitle:
                "Es tut uns leid! Das von Ihnen gewählte Modell ist nicht für Ihrer Sehstärke erhältlich.",
              incompatibleWithPrescriptionBody:
                "Aber keine Sorge, wir haben auch passende Modelle für Sie.",
              shopCompatible: "Passende Modelle anzeigen",
              clearAll: "Alle löschen",
              agreementText:
                "Durch Anklicken dieses Kontrollkästchens bestätige ich, dass die oben eingegebenen Korrekturwerte einem aktuellen (nicht abgelaufenen) Brillenpass entnommen sind, der mir von einem Optiker oder Augenarzt ausgestellt wurde.",
            },
            continueModal: {
              title: "Brillenpass später senden",
              message:
                "Wenn Sie auf „Weiter“ klicken, überspringen Sie den Schritt „Eingabe der Korrekturwerte“. Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
              continue: "Ja, weiter",
              cancel: "Nein, zurück",
            },
          },
          account: {
            card: {
              title: "Über mein Konto abrufen",
              description:
                "Loggen Sie sich ein und wählen Sie Ihren Brillenpass aus.",
            },
          },
          upload: {
            title: "Ihre Brillenverordnung",
            subtitle:
              "Bevor Sie Ihre Brillenverordnung einreichen, überprüfen Sie bitte das Ablaufdatum, um sicherzustellen, dass sie noch gültig ist.",
            card: {
              title: "Jetzt hochladen",
              description:
                "Um den Rest kümmern wir uns. Es werden folgende Formate akzeptiert: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max. 10 MB) und iWork Pages",
            },
            prescriptionUploaded: "Brillenverodnung wurde hochgeladen",
            fileTooBigErrorTitle: "Datei ist zu groß",
            fileTooBigErrorDescription:
              "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
            changeMethod: "Übermittlungsart ändern",
            uploadDifferentFile: "Eine andere Datei hochladen",
            pdForm: {
              pupillaryDistance: "PD (Pupillendistanz)",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe 2 PD-Nummern",
            },
            confirmAndContinue: "Bestätigen & Fortfahren",
          },
          doctor: {
            title: "Daten zu Ihrem Augenarzt eingeben",
            subtitle:
              "Wir fordern Ihre Korrekturwerte direkt bei ihrem Augenarzt/Ihrer Augenärztin an",
            searchBy: "Augenarzt suchen nach",
            card: {
              title: "Meinen Arzt anrufen",
              description:
                "Wir fordern Ihre Korrekturwerte direkt bei ihm/ihr an",
            },
          },
          later: {
            card: {
              title: "Später senden",
              description:
                "Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
            },
          },
        },
        type: {
          title: "Sehanforderungen auswählen",
          confirmedTitle: "Anwenden",
          prescriptionTitle: "Oder",
          addToCart: "In den Einkaufswagen legen",
          moreInfo: "Mehr Info",
        },
        brand: {
          title: "Brillenglasmarke auswählen",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Brillenglastyp:",
          title: "Brillenglastyp auswählen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
          blueLight: {
            title: "Blaufilter",
            description:
              "Verringert die Blaulichtbelastung durch digitale Bildschirme, wodurch eine Ermüdung der Augen verringert werden kann.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Ein Modell für drinnen und draußen mit Transitions®-Gläsern: Sie verdunkeln sich schnell und werden wieder farblos, sodass Sie Ihre Brille nicht wechseln müssen.",
            imageFileName: "",
          },
          clear: {
            title: "Klar",
            description:
              "Klassische, transparente Gläser, perfekt für den täglichen Gebrauch",
            imageFileName: "",
          },
          sun: {
            title: "Sonne",
            description:
              "Wählen Sie aus verschiedenen Gläserfarben und -tönungen.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Plus-Paket ergänzen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von LensCrafters gefertigt.",
          confirmedTitle: "Gläser-Paket:",
        },
        thickness: {
          confirmedTitle: "Stärke",
          title: "Glasstärke auswählen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von LensCrafters veredelt und geliefert.",
          designTypeTitle: "Brillenglasdesign auswählen",
        },
        treatments: {
          addToBag: "In den Warenkorb legen",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandlungen:",
          extraTreatments: {
            antiScratch: {
              name: "Kratzschutz",
              title: "Kratzfest",
            },
            antiReflective: {
              value: "Entspiegelung",
            },
            uvProtection: {
              title: "UV-Filter",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "DRINNEN",
          outdoor: "DRAUSSEN",
        },
        lensColor: {
          confirmedTitle: "Farbe:",
          detailBox: {
            lightTransmission: "Lichtdurchlässigkeit",
            lightConditions: "Lichtverhältnisse",
            contrast: "Kontrast",
            baseLensColor: "Grundfarbe der Gläser",
          },
          categories: {
            all: {
              title: "Alle",
            },
            "Ugly category": {
              title: "Kategorie „Ugly“",
            },
            "Cool category": {
              title: "Kategorie „Cool“",
            },
          },
        },
        color: {
          confirmedTitle: "Farbe:",
          title: "Brillenglasfarbe auswählen",
          titleMobile: "Brillenglasfarbe auswählen",
          titleDesignTypeLayout: "Brillenglastechnologie auswählen",
          titleColorCategoryLayout: "Brillenglastyp auswählen",
          polarized: "Nur polarisiert",
          applyAndContinue: "Anwenden",
          subtitle:
            "Verbesserte Farb- und Kontrastsicht und eine bessere Detailerkennung",
          tooltip: {
            switch:
              "Verringert die Blendung bei besonders hellem Licht und sorgt so für bessere Klarsicht und Schutz der Augen.",
            polarized:
              "Verringert die Blendung bei besonders hellem Licht und sorgt so für bessere Klarsicht und Schutz der Augen.",
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen",
            blueLight:
              "Die Belastung durch blaues Licht von digitalen Bildschirmen und Sonnenstrahlen wird reduziert, was zur Verringerung von Augenermüdung beitragen kann.",
          },
        },
        addOns: {
          confirmedTitle: "Ergänzen Sie Ihre Gläser.",
          label: "Bei Ihren Gläsern bereits inklusive",
          title: "Ergänzen Sie Ihre Gläser.",
          reviewTitle: "Veredelungen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
        },
        review: {
          title: "Auswahl überprüfen",
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.",
          discountTitle:
            "- 40% auf Korrektionsgläser werden automatisch abgezogen.",
          firstLineReview: "Rahmen:",
          paymentInstallments: {
            installmentsLabel:
              "Ratenzahlung in zinslosen Raten mit ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Versicherungsleistungen entfernen",
          oneYearCoverageAdd: "Hinzufügen",
          tooltip:
            "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.",
        },
      },
      prescription: {
        toggleLabel:
          "Brillenpass-Werte eingeben. Wir helfen Ihnen dabei, die perfekten Gläser für Ihre Sehanforderungen zu finden",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Rechtes Augen)",
        rightEyeInitials: "OD",
        leftEye: "(Linkes Auge)",
        leftEyeInitials: "OS",
        readPrescription: "Brillenpass richtig lesen",
        addLabel: "Ich habe auch einen ADD-Wert",
        applyButton: "Übernehmen & Weiter",
        submitButton: "Senden",
        clearButton: "Alle löschen",
        skipButton: "Jetzt überspringen",
        compatibleFramesButton: "Passende Modelle anzeigen",
        doctorDetails:
          "Bei oder nach dem Bestellvorgang fragen wir Sie nach einer Kopie Ihres Brillenpasses oder den erforderlichen Werte der Sehstärke",
        missingValueError: "Werte eingeben und weiter",
        positiveNegativeError:
          "Sie haben einen positiven und einen negativen Wert eingeben. Das ist eher ungewöhnlich. Bitte überprüfen Sie Ihre Eingabe.",
        compatibleFrameError:
          "Es tut uns leid! Doch das von Ihnen ausgewählte Modell ist für Ihre Sehstärke nicht erhältlich.",
        compatibleFrameSubLabel:
          "Keine Sorge. Wir haben Modelle, die zu Ihrer Sehstärke passen.",
        axisError:
          "Der von Ihnen eingegebene Wert für die ACHSE ist nicht korrekt!",
        axisSubError: "Der Wert muss zwischen 0 und 180 liegen.",
        axisSubErrorZero:
          "Wenn der Wert für den Zylinder (CYL) größer oder kleiner als 0 ist, kann der Wert für die ACHSE nicht 0 sein.",
        editLabel: "Werte bearbeiten",
        pdHelp: "Was bedeutet das?",
        twoPDNumbers: "Ich habe zwei PD-Werte",
        missingInputMessage:
          "Fügen Sie die fehlenden Werte hinzu, um den Upload Ihrer Sehstärke abzuschließen. Wir empfehlen daraufhin das am besten geeignete Glas.",
        defaultInputMessage:
          "Fügen Sie Ihre SPH- und CYL-Werte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre",
        skipMessage:
          "Wir werden Sie später bitten, Ihre Werte aus dem Brillenpass einzutragen oder hochzuladen.",
        readyMessage:
          "Jetzt können wir Ihnen das richtige Glas für Sie vorschlagen!",
        bestPrescription: "Die beste Auswahl für Ihre Sehanforderungen",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Erforderlich, um fortzufahren",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Preis für Rahmen + Gläser",
          frame: "Preis für den Rahmen",
          total: "Total",
        },
        free: "Kostenlos",
        included: "Inklusive",
        frameLensStartingAt: "Rahmen + Gläser bei",
        frameLens: "Rahmen + Gläser",
        startingAt: "Ab",
      },
      noTreatment: "No treatment",
      learnMoreMade: "So wird es gemacht",
      learnMoreTreatment: "Behandlung ansehen",
      exitTitle: "MÖCHTEN SIE WIRKLICH ABBRECHEN?",
      exitSubtitle: "Ihre Glasauswahl wird nicht gespeichert",
      exitYes: "Änderungen verwerfen",
      exitContinueEditing: "Bearbeitung fortsetzen",
      exitSave: "Speichern und Einkauf fortsetzen",
      insuranceButtonLabel: "Versicherungsleistungen hinzufügen",
      insuranceButtonLabelRemove: "Versicherungsleistungen entfernen",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Versicherungsleistungen eingeben",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Farbvorschau für Gläser nicht verfügbar",
      fallbackImageMessageSubtitle:
        "Keine Sorge, Sie erhalten Ihre Fassung mit der von Ihnen gewählten Glasfarbe.",
      proceedAsIs: "Weiter (ohne weitere Auswahl)",
      yourSelections: "Ihre Auswahl",
      editButton: "Bearbeiten",
    },
    it_IT: {
      steps: {
        prescription: {
          title: "Completa la tua prescrizione",
          description:
            "Oppure ti suggeriremo le lenti più adatte alle tue necessità visive e non dovrai preoccuparti più di nulla",
        },
        advancedPrescription: {
          title: "Hai una prescrizione medica?",
          manual: {
            title: "Inserisci la tua prescrizione medica",
            card: {
              title: "Inserimento manuale",
              description:
                "È il modo più veloce e usato. Ti suggeriremo noi il tipo di lente più indicato.",
            },
            subtitle:
              "Aggiungi i valori della tua prescrizione medica e ti suggeriremo le lenti più adatte alle tue esigenze visive.",
            rightEye: {
              initials: "OD",
              name: "(Occhio destro)",
            },
            leftEye: {
              initials: "OS",
              name: "(Occhio sinistro)",
            },
            sphere: "Sfera (SF)",
            cylinder: "Cilindro (CIL)",
            axis: "Asse",
            add: "AGGIUNGI",
            vertical: "Verticale (Δ)",
            baseDirection: "Direzione base",
            horizontal: "Orizzontale (Δ)",
            pupillaryDistance: "Distanza interpupillare (PD)",
            pdLeft: "Sinistra",
            pdRight: "Destra",
            iHaveTwoPd: "Ho 2 numeri PD",
            applyButton: "Continua",
            howToRead: "Come leggere la tua prescrizione oculistica",
            whatIsIt: "Di cosa si tratta?",
            pupillaryDistanceWarningTooltip:
              "Inserire testo per questa descrizione comando",
            moreOptions: "Altre opzioni",
            alerts: {
              axisRangeError:
                "I valori ASSE che hai inserito non sono corretti. Questi valori devono essere compresi tra 1 e 180.",
              axisZeroError:
                "I valori ASSE che hai inserito non sono corretti. Se il valore CIL è maggiore o minore di 0, i valori ASSE non possono essere uguali a 0.",
              sphPositiveNegativeWarning:
                "Hai inserito un valore positivo e uno negativo, una combinazione molto rara. Per sicurezza, controlla la tua prescrizione. Se è tutto corretto, procedi pure.",
              missingValues: "Inserisci i valori mancanti",
              requiredToProceed: "È obbligatorio per procedere",
              incompatibleWithPrescriptionTitle:
                "Spiacenti! La montatura scelta non è compatibile con la tua prescrizione medica",
              incompatibleWithPrescriptionBody:
                "ma non preoccuparti! Abbiamo altre montature adatte",
              shopCompatible: "Acquista montature compatibili",
              clearAll: "Cancella tutto",
              agreementText:
                "Selezionando questa casella confermo che i valori della prescrizione medica inseriti qui sopra sono presi da una prescrizione medica valida (non scaduta) emessa per me e firmata da un oftalmologo o optometrista autorizzato.",
            },
            continueModal: {
              title: "Invia la prescrizione medica in un secondo momento",
              message:
                "Cliccando su “Continua” salterai il passaggio della prescrizione medica. Ti verrà richiesta più avanti nel corso dell’evasione del tuo ordine, richiedendoti di caricarla o di metterci in contatto con il tuo medico.",
              continue: "Sì, continua",
              cancel: "No, torna indietro",
            },
          },
          account: {
            card: {
              title: "Prendila dal mio account.",
              description: "Accedi e seleziona la tua prescrizione medica.",
            },
          },
          upload: {
            title: "La tua prescrizione",
            subtitle:
              "Prima di inviare la tua prescrizione medica, controlla la data di scadenza per assicurarti che sia ancora valida.",
            card: {
              title: "Caricala ora",
              description:
                "Al resto penseremo noi. Accettiamo i formati elencati di seguito: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10MB) e documenti iWork",
            },
          },
          doctor: {
            title: "Aggiungi i dati del tuo medico",
            subtitle:
              "Chiederemo la tua prescrizione medica direttamente a lei/lui",
            searchBy: "Cerca il medico per",
            card: {
              title: "Chiama il mio medico",
              description:
                "Chiederemo la tua prescrizione medica direttamente a lei/lui.",
            },
          },
          later: {
            card: {
              title: "Inviala in un secondo momento.",
              description:
                "Più avanti nel corso dell’evasione del tuo ordine ti verrà richiesto di caricarla o di metterci in contatto con il tuo medico.",
            },
          },
        },
        type: {
          title: "Seleziona le tue necessità visive",
          confirmedTitle: "Usa",
          prescriptionTitle: "Oppure",
          addToCart: "Aggiungi al carrello",
          moreInfo: "Maggiori informazioni",
        },
        brand: {
          title: "Scegli la marca delle lenti",
          confirmedTitle: "Marchio lenti",
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo di lente",
          title: "Scegli il tipo di lente",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi.",
          blueLight: {
            title: "Filtro anti luce blu",
            description:
              "Riduce l’esposizione alla luce blu degli schermi digitali, prevenendo l’affaticamento degli occhi.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Un solo paio sia all’aperto che al chiuso, grazie alle lenti Transitions®: le lenti variano velocemente da più scure a più chiare, e non dovrai mai cambiare gli occhiali.",
            imageFileName: "",
          },
          clear: {
            title: "Trasparenti",
            description:
              "Lenti trasparenti tradizionali, perfette per l’uso quotidiano",
            imageFileName: "",
          },
          sun: {
            title: "Sole",
            description:
              "Scegli tra una vasta gamma di lenti con colori e tonalità diverse.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Scegli il tuo Pacchetto Convenienza",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia LensCrafters.",
          confirmedTitle: "Confezione lenti:",
        },
        thickness: {
          confirmedTitle: "Spessore",
          title: "Scegli lo spessore delle lenti",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia LensCrafters.",
          designTypeTitle: "Scegli il design delle lenti",
        },
        treatments: {
          addToBag: "Aggiungi al carrello",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Trattamenti",
          extraTreatments: {
            antiScratch: {
              name: "Antigraffio",
              title: "Anti graffio",
            },
            antiReflective: {
              value: "Anti riflesso",
            },
            uvProtection: {
              title: "Protezione UV",
            },
          },
        },
        transitionColor: {
          title: "Colore Transitions",
          indoor: "AL CHIUSO",
          outdoor: "ALL'APERTO",
        },
        lensColor: {
          confirmedTitle: "Colore",
          detailBox: {
            lightTransmission: "Trasmissione della luce",
            lightConditions: "Condizioni di illuminazione",
            contrast: "Contrasto",
            baseLensColor: "Colore lente base",
          },
          categories: {
            "Ugly category": {
              title: "Categoria Ugly",
            },
            "Cool category": {
              title: "Categoria Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Colore",
          title: "Scegli il colore delle lenti",
          titleMobile: "Scegli il colore delle lenti",
          titleDesignTypeLayout: "Scegli la tecnologia delle lenti",
          titleColorCategoryLayout: "Scegli il tipo di lente",
          polarized: "Solo polarizzate",
          applyAndContinue: "Applica",
          subtitle:
            "Colore e contrasto migliorati per una maggiore visibilità dei dettagli",
          tooltip: {
            switch:
              "Riduzione del riverbero in condizioni di forte luminosità, per donare maggiore nitidezza proteggendo gli occhi.",
            polarized:
              "Riduzione del riverbero in condizioni di forte luminosità, per donare maggiore nitidezza proteggendo gli occhi.",
            antiScratch: "Protegge la superficie delle lenti dagli urti",
            blueLight:
              "Riduce l’esposizione ai raggi del sole e alla luce blu degli schermi dei dispositivi digitali, riducendo così l’affaticamento oculare.",
          },
        },
        addOns: {
          confirmedTitle: "Completa le lenti",
          label: "Già incluso nelle lenti",
          title: "Completa le lenti",
          reviewTitle: "Finitura",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi",
        },
        review: {
          title: "Controlla le tue scelte",
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban.",
          discountTitle:
            "Lo sconto del 40% sulle lenti graduate viene applicato automaticamente",
          firstLineReview: "Montatura:",
          paymentInstallments: {
            installmentsLabel:
              "Paga a rate a tasso zero con ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Aggiungi copertura assicurativa",
          insuranceButtonLabelRemove: "Elimina copertura assicurativa",
          oneYearCoverageAdd: "Aggiungi",
          tooltip:
            "Assicura i tuoi occhiali con una estensione di garanzia: comprenderà riparazioni senza alcun limite e sostituzione in qualunque negozio LensCrafters.",
        },
      },
      prescription: {
        toggleLabel:
          "Aggiungi i valori della tua prescrizione, ti aiuteremo a trovare la lente perfetta per le tue esigenze visive.",
        prescriptionTitle:
          "Aggiungi i valori della tua prescrizione, ti mostreremo la lente perfetta per le tue esigenze visive.",
        rightEye: "(Occhio destro)",
        rightEyeInitials: "OD",
        leftEye: "(Occhio sinistro)",
        leftEyeInitials: "OS",
        readPrescription: "Come leggere la tua prescrizione oculistica",
        addLabel: "Ho anche un valore ADD",
        applyButton: "Applica e continua",
        submitButton: "Invia",
        clearButton: "Cancella tutto",
        skipButton: "Salta",
        compatibleFramesButton: "Acquista montature compatibili",
        doctorDetails:
          "Ti chiederemo copia della tua prescrizione o delle informazioni mediche durante o dopo la procedura di pagamento",
        missingValueError: "Aggiungi i tuoi valori per continuare",
        positiveNegativeError:
          "Hai inserito un valore positivo e un valore negativo. Questo è piuttosto raro. Per sicurezza, controlla la tua prescrizione.",
        compatibleFrameError:
          "Spiacenti! La montatura che hai scelto non è disponibile per i tuoi valori.",
        compatibleFrameSubLabel:
          "Non preoccuparti, abbiamo altre montature che fanno per te.",
        axisError: "I valori ASSE che hai inserito non sono corretti.",
        axisSubError: "Questi valori devono essere compresi tra 0 e 180",
        axisSubErrorZero:
          "Se il valore CIL è maggiore o minore di 0, i valori ASSE non possono essere uguali a 0.",
        editLabel: "Modifica prescrizione",
        pdHelp: "Che succede?",
        twoPDNumbers: "Ho due numeri PD",
        missingInputMessage:
          "Inserisci i valori mancanti per completare il caricamento della tua prescrizione, e ti suggeriremo le lenti più adatte a te.",
        defaultInputMessage:
          "Aggiungi i tuoi valori SF e CIL e ti suggeriremo le lenti più adatte alle tue esigenze visive.",
        skipMessage:
          "Ti chiederemo di completare o caricare la tua prescrizione successivamente",
        readyMessage: "Ci siamo! Ecco le lenti perfette per te!",
        bestPrescription: "Le migliori per la tua prescrizione",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Campo obbligatorio per procedere",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Prezzo montatura + lenti",
          frame: "Prezzo montatura",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluso",
        frameLensStartingAt: "Montatura + Lenti a",
        frameLens: "Montatura + Lenti",
        startingAt: "A partire da",
      },
      noTreatment: "Nessun trattamento",
      learnMoreMade: "Scopri la creazione",
      learnMoreTreatment: "Scopri il trattamento",
      exitTitle: "Sicuro di voler uscire?",
      exitSubtitle: "La tua selezione di lenti non sarà salvata",
      exitYes: "Si, esci",
      exitContinueEditing: "No, continua",
      exitSave: "Salva e continua lo shopping",
      insuranceButtonLabel: "Aggiungi copertura assicurativa",
      insuranceButtonLabelRemove: "Elimina copertura assicurativa",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applica copertura assicurativa",
      frameSize: "Frame size",
      fallbackImageMessageTitle:
        "Anteprima del colore della lente non disponibile",
      fallbackImageMessageSubtitle:
        "Niente paura! Riceverai la montatura con la lente del colore che hai scelto.",
      proceedAsIs: "Procedi con le opzioni selezionate",
      yourSelections: "Scelti da te",
      editButton: "Modifica",
    },
    it_CH: {
      steps: {
        prescription: {
          title: "Completa la tua prescrizione",
          description:
            "Oppure ti suggeriremo le lenti più adatte alle tue necessità visive e non dovrai preoccuparti più di nulla",
        },
        advancedPrescription: {
          title: "Hai una prescrizione medica?",
          manual: {
            title: "Inserisci la tua prescrizione medica",
            card: {
              title: "Inserimento manuale",
              description:
                "È il modo più veloce e usato. Ti suggeriremo noi il tipo di lente più indicato.",
            },
            subtitle:
              "Aggiungi i valori della tua prescrizione medica e ti suggeriremo le lenti più adatte alle tue esigenze visive.",
            rightEye: {
              initials: "OD",
              name: "(Occhio destro)",
            },
            leftEye: {
              initials: "OS",
              name: "(Occhio sinistro)",
            },
            sphere: "Sfera (SF)",
            cylinder: "Cilindro (CIL)",
            axis: "Asse",
            add: "AGGIUNGI",
            vertical: "Verticale (Δ)",
            baseDirection: "Direzione base",
            horizontal: "Orizzontale (Δ)",
            pupillaryDistance: "Distanza interpupillare (PD)",
            pdLeft: "Sinistra",
            pdRight: "Destra",
            iHaveTwoPd: "Ho 2 numeri PD",
            applyButton: "Continua",
            howToRead: "Come leggere la tua prescrizione oculistica",
            whatIsIt: "Di cosa si tratta?",
            pupillaryDistanceWarningTooltip:
              "Inserire testo per questa descrizione comando",
            moreOptions: "Altre opzioni",
            alerts: {
              axisRangeError:
                "I valori ASSE che hai inserito non sono corretti. Questi valori devono essere compresi tra 1 e 180.",
              axisZeroError:
                "I valori ASSE che hai inserito non sono corretti. Se il valore CIL è maggiore o minore di 0, i valori ASSE non possono essere uguali a 0.",
              sphPositiveNegativeWarning:
                "Hai inserito un valore positivo e uno negativo, una combinazione molto rara. Per sicurezza, controlla la tua prescrizione. Se è tutto corretto, procedi pure.",
              missingValues: "Inserisci i valori mancanti",
              requiredToProceed: "È obbligatorio per procedere",
              incompatibleWithPrescriptionTitle:
                "Spiacenti! La montatura scelta non è compatibile con la tua prescrizione medica",
              incompatibleWithPrescriptionBody:
                "ma non preoccuparti! Abbiamo altre montature adatte",
              shopCompatible: "Acquista montature compatibili",
              clearAll: "Cancella tutto",
              agreementText:
                "Selezionando questa casella confermo che i valori della prescrizione medica inseriti qui sopra sono presi da una prescrizione medica valida (non scaduta) emessa per me e firmata da un oftalmologo o optometrista autorizzato.",
            },
            continueModal: {
              title: "Invia la prescrizione medica in un secondo momento",
              message:
                "Cliccando su “Continua” salterai il passaggio della prescrizione medica. Ti verrà richiesta più avanti nel corso dell’evasione del tuo ordine, richiedendoti di caricarla o di metterci in contatto con il tuo medico.",
              continue: "Sì, continua",
              cancel: "No, torna indietro",
            },
          },
          account: {
            card: {
              title: "Prendila dal mio account.",
              description: "Accedi e seleziona la tua prescrizione medica.",
            },
          },
          upload: {
            title: "La tua prescrizione",
            subtitle:
              "Prima di inviare la tua prescrizione medica, controlla la data di scadenza per assicurarti che sia ancora valida.",
            card: {
              title: "Caricala ora",
              description:
                "Al resto penseremo noi. Accettiamo i formati elencati di seguito: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10MB) e documenti iWork",
            },
          },
          doctor: {
            title: "Aggiungi i dati del tuo medico",
            subtitle:
              "Chiederemo la tua prescrizione medica direttamente a lei/lui",
            searchBy: "Cerca il medico per",
            card: {
              title: "Chiama il mio medico",
              description:
                "Chiederemo la tua prescrizione medica direttamente a lei/lui.",
            },
          },
          later: {
            card: {
              title: "Inviala in un secondo momento.",
              description:
                "Più avanti nel corso dell’evasione del tuo ordine ti verrà richiesto di caricarla o di metterci in contatto con il tuo medico.",
            },
          },
        },
        type: {
          title: "Seleziona le tue necessità visive",
          confirmedTitle: "Usa",
          prescriptionTitle: "Oppure",
          addToCart: "Aggiungi al carrello",
          moreInfo: "Maggiori informazioni",
        },
        brand: {
          title: "Scegli la marca delle lenti",
          confirmedTitle: "Marchio lenti",
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo di lente",
          title: "Scegli il tipo di lente",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi.",
          blueLight: {
            title: "Filtro anti luce blu",
            description:
              "Riduce l’esposizione alla luce blu degli schermi digitali, prevenendo l’affaticamento degli occhi.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Un solo paio sia all’aperto che al chiuso, grazie alle lenti Transitions®: le lenti variano velocemente da più scure a più chiare, e non dovrai mai cambiare gli occhiali.",
            imageFileName: "",
          },
          clear: {
            title: "Trasparenti",
            description:
              "Lenti trasparenti tradizionali, perfette per l’uso quotidiano",
            imageFileName: "",
          },
          sun: {
            title: "Sole",
            description:
              "Scegli tra una vasta gamma di lenti con colori e tonalità diverse.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Scegli il tuo Pacchetto Convenienza",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia LensCrafters.",
          confirmedTitle: "Confezione lenti:",
        },
        thickness: {
          confirmedTitle: "Spessore",
          title: "Scegli lo spessore delle lenti",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia LensCrafters.",
          designTypeTitle: "Scegli il design delle lenti",
        },
        treatments: {
          addToBag: "Aggiungi al carrello",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Trattamenti",
          extraTreatments: {
            antiScratch: {
              name: "Antigraffio",
              title: "Anti graffio",
            },
            antiReflective: {
              value: "Anti riflesso",
            },
            uvProtection: {
              title: "Protezione UV",
            },
          },
        },
        transitionColor: {
          title: "Colore Transitions",
          indoor: "AL CHIUSO",
          outdoor: "ALL'APERTO",
        },
        lensColor: {
          confirmedTitle: "Colore",
          detailBox: {
            lightTransmission: "Trasmissione della luce",
            lightConditions: "Condizioni di illuminazione",
            contrast: "Contrasto",
            baseLensColor: "Colore lente base",
          },
          categories: {
            "Ugly category": {
              title: "Categoria Ugly",
            },
            "Cool category": {
              title: "Categoria Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Colore",
          title: "Scegli il colore delle lenti",
          titleMobile: "Scegli il colore delle lenti",
          titleDesignTypeLayout: "Scegli la tecnologia delle lenti",
          titleColorCategoryLayout: "Scegli il tipo di lente",
          polarized: "Solo polarizzate",
          applyAndContinue: "Applica",
          subtitle:
            "Colore e contrasto migliorati per una maggiore visibilità dei dettagli",
          tooltip: {
            switch:
              "Riduzione del riverbero in condizioni di forte luminosità, per donare maggiore nitidezza proteggendo gli occhi.",
            polarized:
              "Riduzione del riverbero in condizioni di forte luminosità, per donare maggiore nitidezza proteggendo gli occhi.",
            antiScratch: "Protegge la superficie delle lenti dagli urti",
            blueLight:
              "Riduce l’esposizione ai raggi del sole e alla luce blu degli schermi dei dispositivi digitali, riducendo così l’affaticamento oculare.",
          },
        },
        addOns: {
          confirmedTitle: "Completa le lenti",
          label: "Già incluso nelle lenti",
          title: "Completa le lenti",
          reviewTitle: "Finitura",
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi",
        },
        review: {
          title: "Controlla le tue scelte",
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban.",
          discountTitle:
            "Lo sconto del 40% sulle lenti graduate viene applicato automaticamente",
          firstLineReview: "Montatura:",
          paymentInstallments: {
            installmentsLabel:
              "Paga a rate a tasso zero con ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Aggiungi copertura assicurativa",
          insuranceButtonLabelRemove: "Elimina copertura assicurativa",
          oneYearCoverageAdd: "Aggiungi",
          tooltip:
            "Assicura i tuoi occhiali con una estensione di garanzia: comprenderà riparazioni senza alcun limite e sostituzione in qualunque negozio LensCrafters.",
        },
      },
      prescription: {
        toggleLabel:
          "Aggiungi i valori della tua prescrizione, ti aiuteremo a trovare la lente perfetta per le tue esigenze visive.",
        prescriptionTitle:
          "Aggiungi i valori della tua prescrizione, ti mostreremo la lente perfetta per le tue esigenze visive.",
        rightEye: "(Occhio destro)",
        rightEyeInitials: "OD",
        leftEye: "(Occhio sinistro)",
        leftEyeInitials: "OS",
        readPrescription: "Come leggere la tua prescrizione oculistica",
        addLabel: "Ho anche un valore ADD",
        applyButton: "Applica e continua",
        submitButton: "Invia",
        clearButton: "Cancella tutto",
        skipButton: "Salta",
        compatibleFramesButton: "Acquista montature compatibili",
        doctorDetails:
          "Ti chiederemo copia della tua prescrizione o delle informazioni mediche durante o dopo la procedura di pagamento",
        missingValueError: "Aggiungi i tuoi valori per continuare",
        positiveNegativeError:
          "Hai inserito un valore positivo e un valore negativo. Questo è piuttosto raro. Per sicurezza, controlla la tua prescrizione.",
        compatibleFrameError:
          "Spiacenti! La montatura che hai scelto non è disponibile per i tuoi valori.",
        compatibleFrameSubLabel:
          "Non preoccuparti, abbiamo altre montature che fanno per te.",
        axisError: "I valori ASSE che hai inserito non sono corretti.",
        axisSubError: "Questi valori devono essere compresi tra 0 e 180",
        axisSubErrorZero:
          "Se il valore CIL è maggiore o minore di 0, i valori ASSE non possono essere uguali a 0.",
        editLabel: "Modifica prescrizione",
        pdHelp: "Che succede?",
        twoPDNumbers: "Ho due numeri PD",
        missingInputMessage:
          "Inserisci i valori mancanti per completare il caricamento della tua prescrizione, e ti suggeriremo le lenti più adatte a te.",
        defaultInputMessage:
          "Aggiungi i tuoi valori SF e CIL e ti suggeriremo le lenti più adatte alle tue esigenze visive.",
        skipMessage:
          "Ti chiederemo di completare o caricare la tua prescrizione successivamente",
        readyMessage: "Ci siamo! Ecco le lenti perfette per te!",
        bestPrescription: "Le migliori per la tua prescrizione",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Campo obbligatorio per procedere",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Prezzo montatura + lenti",
          frame: "Prezzo montatura",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluso",
        frameLensStartingAt: "Montatura + Lenti a",
        frameLens: "Montatura + Lenti",
        startingAt: "A partire da",
      },
      noTreatment: "Nessun trattamento",
      learnMoreMade: "Scopri la creazione",
      learnMoreTreatment: "Scopri il trattamento",
      exitTitle: "Sicuro di voler uscire?",
      exitSubtitle: "La tua selezione di lenti non sarà salvata",
      exitYes: "Si, esci",
      exitContinueEditing: "No, continua",
      exitSave: "Salva e continua lo shopping",
      insuranceButtonLabel: "Aggiungi copertura assicurativa",
      insuranceButtonLabelRemove: "Elimina copertura assicurativa",
      insuranceSyncedLabel: "Assicurazione applicata",
      applyingInsurance: "Applica copertura assicurativa",
      frameSize: "Frame size",
      fallbackImageMessageTitle:
        "Anteprima del colore della lente non disponibile",
      fallbackImageMessageSubtitle:
        "Niente paura! Riceverai la montatura con la lente del colore che hai scelto.",
      proceedAsIs: "Procedi con le opzioni selezionate",
      yourSelections: "Scelti da te",
      editButton: "Modifica",
    },
    fr_BE: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance",
          description:
            "Ou nous vous suggérerons les verres les mieux adaptés à votre vue, afin que vous puissiez vous libérer de vos soucis.",
        },
        advancedPrescription: {
          title: "Avez-vous une correction ?",
          manual: {
            title: "Saisissez votre correction",
            card: {
              title: "Saisissez-la manuellement",
              description:
                "Il s'agit de notre méthode la plus rapide et la plus populaire. Nous vous recommanderons ensuite le type de verres le plus adapté.",
            },
            subtitle:
              "Ajoutez les valeurs de votre ordonnance et nous vous recommanderons les verres les mieux adaptés à votre vue.",
            rightEye: {
              initials: "OD",
              name: "(Œil droit)",
            },
            leftEye: {
              initials: "OS",
              name: "(Œil gauche)",
            },
            sphere: "Sphère (SPH)",
            cylinder: "Cylindre (CYL)",
            axis: "Axe",
            add: "AJOUTER",
            vertical: "Vertical (Δ)",
            baseDirection: "Orientation du prisme",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Écart pupillaire/EP)",
            pdLeft: "Gauche",
            pdRight: "Droite",
            iHaveTwoPd: "J’ai 2 valeurs d’EP",
            applyButton: "Continuer",
            howToRead: "Comment lire votre ordonnance",
            whatIsIt: "Qu'est-ce c'est ?",
            pupillaryDistanceWarningTooltip:
              "Fournir un texte pour cette infobulle",
            moreOptions: "Plus d'options",
            alerts: {
              axisRangeError:
                "Les valeurs d’AXE que vous avez saisies sont incorrectes ! Ces valeurs doivent entre 1 et 180.",
              axisZeroError:
                "Les valeurs que vous avez saisies pour les AXES sont incorrectes ! Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
              sphPositiveNegativeWarning:
                "Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer.",
              missingValues: "Compléter les valeurs manquantes",
              requiredToProceed: "Cela est nécessaire pour pouvoir continuer",
              incompatibleWithPrescriptionTitle:
                "Désolés ! La monture que vous avez choisie n’est pas compatible avec votre ordonnance",
              incompatibleWithPrescriptionBody:
                "Mais ne vous inquiétez pas, des montures compatibles sont disponibles",
              shopCompatible: "Acheter des montures compatibles",
              clearAll: "Effacer tout",
              agreementText:
                "En cliquant sur cette case, je confirme que les valeurs de correction saisies ci-dessus proviennent d'une ordonnance valide (non expirée) qui m'a été délivrée et signée par un optométriste ou un ophtalmologiste agréé.",
            },
            continueModal: {
              title: "Envoyer l'ordonnance plus tard",
              message:
                "En cliquant sur continuer, vous sauterez l'étape de l'ordonnance. Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
              continue: "Oui, continuer",
              cancel: "Non, retourner en arrière",
            },
          },
          account: {
            card: {
              title: "La récupérer dans mon compte",
              description: "Connectez-vous et sélectionnez votre ordonnance.",
            },
          },
          upload: {
            title: "Votre ordonnance",
            subtitle:
              "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours valable.",
            card: {
              title: "Téléchargez-la maintenant",
              description:
                "Et nous nous chargeons du reste. Nous acceptons les formats de fichier suivants : .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) et pages iWork",
            },
          },
          doctor: {
            title: "Ajoutez les coordonnées de votre médecin",
            subtitle:
              "Nous obtiendrons les détails de votre ordonnance directement de lui/elle.",
            searchBy: "Recherchez votre ophtalmologiste par",
            card: {
              title: "Appelez mon médecin",
              description:
                "Nous obtiendrons votre ordonnance directement de lui/elle.",
            },
          },
          later: {
            card: {
              title: "Envoyez-la plus tard",
              description:
                "Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
            },
          },
        },
        type: {
          title: "Sélectionnez vos exigences de vue",
          confirmedTitle: "Utilisez",
          prescriptionTitle: "Ou",
          addToCart: "Ajouter au panier",
          moreInfo: "En savoir plus",
        },
        brand: {
          title: "Choisissez la marque de vos verres",
          confirmedTitle: "Marque",
        },
        treatmentsFamily: {
          confirmedTitle: "Type de verres",
          title: "Choisissez le type de verres",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse.",
          blueLight: {
            title: "Filtre lumière bleue",
            description:
              "Réduit l'exposition à la lumière bleue des écrans numériques, ce qui peut aider à prévenir la fatigue oculaire.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Une seule paire pour l'intérieur et l'extérieur avec les verres Transitions® : ils s'assombrissent et s’éclaircissent rapidement pour devenir clairs, pour que vous n'ayez jamais à changer de lunettes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparents",
            description:
              "Verres transparents conventionnels parfaits pour la vie de tous les jours",
            imageFileName: "",
          },
          sun: {
            title: "Solaires",
            description:
              "Faites votre choix parmi différentes couleurs et teintes de verres.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Complétez votre forfait au meilleur prix",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
          confirmedTitle: "Le pack verres :",
        },
        thickness: {
          confirmedTitle: "Épaisseur",
          title: "Sélectionnez l’épaisseur de vos verres",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
          designTypeTitle: "Choisissez le design de vos verres",
        },
        treatments: {
          addToBag: "Ajouter au panier",
          title: "Complétez vos verres",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Traitements",
          extraTreatments: {
            antiScratch: {
              name: "Anti-rayures",
              title: "Résistance aux rayures",
            },
            antiReflective: {
              value: "Traitement anti-reflets",
            },
            uvProtection: {
              title: "protection contre les UV et résistance aux rayures",
            },
          },
        },
        transitionColor: {
          title: "Transition teintés",
          indoor: "INTÉRIEUR",
          outdoor: "EXTÉRIEUR",
        },
        lensColor: {
          confirmedTitle: "Couleur ",
          detailBox: {
            lightTransmission: "Transmission de la lumière",
            lightConditions: "Conditions de luminosité",
            contrast: "Contraste",
            baseLensColor: "Couleur de base des verres",
          },
          categories: {
            "Ugly category": {
              title: "Catégorie Ugly",
            },
            "Cool category": {
              title: "Catégorie Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Couleur ",
          title: "Choisissez la couleur de vos verres",
          titleMobile: "Choisissez la couleur de vos verres",
          titleDesignTypeLayout: "Choisissez la technologie pour vos verres",
          titleColorCategoryLayout: "Choisissez le type de verres",
          polarized: "Uniquement polarisants",
          applyAndContinue: "Appliquer",
          subtitle:
            "Un meilleur rendu des couleurs et un contraste optimal afin de vous permettre de voir plus de détails",
          tooltip: {
            switch:
              "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
            polarized:
              "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
            antiScratch:
              "Protège la surface de vos verres contre les chocs violents",
            blueLight:
              "Réduit l'exposition à la lumière bleue des écrans numériques et aux rayons du soleil, ce qui peut aider à réduire la fatigue oculaire.",
          },
        },
        addOns: {
          confirmedTitle: "Complétez vos verres",
          label: "Déjà compris avec vos verres",
          title: "Complétez vos verres",
          reviewTitle: "Finitions",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse",
        },
        review: {
          title: "Vérifiez vos choix",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
          discountTitle:
            "Une remise de 40 % sur les verres correcteurs est appliquée automatiquement",
          firstLineReview: "Monture :",
          paymentInstallments: {
            installmentsLabel:
              "Payez en plusieurs versements sans intérêt avec ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Appliquer l’assurance",
          insuranceButtonLabelRemove: "Supprimer des prestations sociales",
          insuranceSyncedLabel: "Prestations sociales appliquées",
          oneYearCoverageAdd: "Ajouter",
          tooltip:
            "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters.",
        },
      },
      prescription: {
        toggleLabel:
          "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins",
        prescriptionTitle:
          "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.",
        rightEye: "(Œil droit)",
        rightEyeInitials: "OD",
        leftEye: "(Œil gauche)",
        leftEyeInitials: "OS",
        readPrescription: "Comment lire votre ordonnance",
        addLabel: "J’ai aussi une valeur d’addition",
        applyButton: "Appliquer et continuer",
        submitButton: "Envoyer",
        clearButton: "Effacer tout",
        skipButton: "Ignorer pour l’instant",
        compatibleFramesButton: "Acheter des montures compatibles",
        doctorDetails:
          "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement",
        missingValueError: "Ajoutez vos valeurs pour continuer",
        positiveNegativeError:
          "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.",
        compatibleFrameError:
          "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.",
        compatibleFrameSubLabel:
          '"Aucun problème, nous avons des montures s\'adaptant à votre correction."',
        axisError: "Les valeurs d’AXE que vous avez saisies sont incorrectes !",
        axisSubError: "Ces valeurs doivent entre 0 et 180.",
        axisSubErrorZero:
          "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
        editLabel: "Modifier la correction",
        pdHelp: "\"Qu'est-ce c'est ?\"",
        twoPDNumbers: "J’ai deux valeurs d'EP",
        missingInputMessage:
          "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.",
        defaultInputMessage:
          "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.",
        skipMessage:
          "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard",
        readyMessage:
          "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !",
        bestPrescription: "Le meilleur pour votre correction",
        proceedCheckbox: "Obligatoire pour continuer",
        proceedCheckboxError: "Ou {0} paiements de {1}{2} avec",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Prix monture + verres",
          frame: "Prix de la monture",
          total: "Total",
        },
        free: "Gratuit",
        included: "Inclus",
        frameLensStartingAt: "Monture+Verres à",
        frameLens: "Monture + Verres",
        startingAt: "Prix partant de",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Voir comment c’est fait",
      learnMoreTreatment: "Voir le traitement",
      exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?",
      exitSubtitle: "Votre choix de verres ne sera pas enregistré",
      exitYes: "Oui, sortie",
      exitContinueEditing: "Continuer la modification",
      exitSave: "Enregistrer et continuer le shopping",
      insuranceButtonLabel: "Ajouter des prestations sociales",
      insuranceButtonLabelRemove: "Supprimer des prestations sociales",
      insuranceSyncedLabel: "Prestations sociales appliquées",
      applyingInsurance: "Application des prestations sociales en cours",
      frameSize: "Frame size",
      fallbackImageMessageTitle:
        "Aperçu de la couleur des verres pas disponible",
      fallbackImageMessageSubtitle:
        "Ne vous inquiétez pas, vous recevrez votre monture avec la couleur de verre que vous avez choisie.",
      proceedAsIs: "Procéder comme décrit",
      yourSelections: "Vos sélections",
      editButton: "Modifier",
    },
    fr_FR: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance",
          description:
            "Ou nous vous suggérerons les verres les mieux adaptés à votre vue, afin que vous puissiez vous libérer de vos soucis.",
        },
        advancedPrescription: {
          title: "Avez-vous une correction ?",
          manual: {
            title: "Saisissez votre correction",
            card: {
              title: "Saisissez-la manuellement",
              description:
                "Il s'agit de notre méthode la plus rapide et la plus populaire. Nous vous recommanderons ensuite le type de verres le plus adapté.",
            },
            subtitle:
              "Ajoutez les valeurs de votre ordonnance et nous vous recommanderons les verres les mieux adaptés à votre vue.",
            rightEye: {
              initials: "OD",
              name: "(Œil droit)",
            },
            leftEye: {
              initials: "OS",
              name: "(Œil gauche)",
            },
            sphere: "Sphère (SPH)",
            cylinder: "Cylindre (CYL)",
            axis: "Axe",
            add: "AJOUTER",
            vertical: "Vertical (Δ)",
            baseDirection: "Orientation du prisme",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Écart pupillaire/EP)",
            pdLeft: "Gauche",
            pdRight: "Droite",
            iHaveTwoPd: "J’ai 2 valeurs d’EP",
            applyButton: "Continuer",
            howToRead: "Comment lire votre ordonnance",
            whatIsIt: "Qu'est-ce c'est ?",
            pupillaryDistanceWarningTooltip:
              "Fournir un texte pour cette infobulle",
            moreOptions: "Plus d'options",
            alerts: {
              axisRangeError:
                "Les valeurs d’AXE que vous avez saisies sont incorrectes ! Ces valeurs doivent entre 1 et 180.",
              axisZeroError:
                "Les valeurs que vous avez saisies pour les AXES sont incorrectes ! Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
              sphPositiveNegativeWarning:
                "Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer.",
              missingValues: "Compléter les valeurs manquantes",
              requiredToProceed: "Cela est nécessaire pour pouvoir continuer",
              incompatibleWithPrescriptionTitle:
                "Désolés ! La monture que vous avez choisie n’est pas compatible avec votre ordonnance",
              incompatibleWithPrescriptionBody:
                "Mais ne vous inquiétez pas, des montures compatibles sont disponibles",
              shopCompatible: "Acheter des montures compatibles",
              clearAll: "Effacer tout",
              agreementText:
                "En cliquant sur cette case, je confirme que les valeurs de correction saisies ci-dessus proviennent d'une ordonnance valide (non expirée) qui m'a été délivrée et signée par un optométriste ou un ophtalmologiste agréé.",
            },
            continueModal: {
              title: "Envoyer l'ordonnance plus tard",
              message:
                "En cliquant sur continuer, vous sauterez l'étape de l'ordonnance. Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
              continue: "Oui, continuer",
              cancel: "Non, retourner en arrière",
            },
          },
          account: {
            card: {
              title: "La récupérer dans mon compte",
              description: "Connectez-vous et sélectionnez votre ordonnance.",
            },
          },
          upload: {
            title: "Votre ordonnance",
            subtitle:
              "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours valable.",
            card: {
              title: "Téléchargez-la maintenant",
              description:
                "Et nous nous chargeons du reste. Nous acceptons les formats de fichier suivants : .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) et pages iWork",
            },
          },
          doctor: {
            title: "Ajoutez les coordonnées de votre médecin",
            subtitle:
              "Nous obtiendrons les détails de votre ordonnance directement de lui/elle.",
            searchBy: "Recherchez votre ophtalmologiste par",
            card: {
              title: "Appelez mon médecin",
              description:
                "Nous obtiendrons votre ordonnance directement de lui/elle.",
            },
          },
          later: {
            card: {
              title: "Envoyez-la plus tard",
              description:
                "Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
            },
          },
        },
        type: {
          title: "Sélectionnez vos exigences de vue",
          confirmedTitle: "Utilisez",
          prescriptionTitle: "Ou",
          addToCart: "Ajouter au panier",
          moreInfo: "En savoir plus",
        },
        brand: {
          title: "Choisissez la marque de vos verres",
          confirmedTitle: "Marque",
        },
        treatmentsFamily: {
          confirmedTitle: "Type de verres",
          title: "Choisissez le type de verres",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse.",
          blueLight: {
            title: "Filtre lumière bleue",
            description:
              "Réduit l'exposition à la lumière bleue des écrans numériques, ce qui peut aider à prévenir la fatigue oculaire.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Une seule paire pour l'intérieur et l'extérieur avec les verres Transitions® : ils s'assombrissent et s’éclaircissent rapidement pour devenir clairs, pour que vous n'ayez jamais à changer de lunettes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparents",
            description:
              "Verres transparents conventionnels parfaits pour la vie de tous les jours",
            imageFileName: "",
          },
          sun: {
            title: "Solaires",
            description:
              "Faites votre choix parmi différentes couleurs et teintes de verres.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Complétez votre forfait au meilleur prix",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
          confirmedTitle: "Le pack verres ",
        },
        thickness: {
          confirmedTitle: "Épaisseur",
          title: "Sélectionnez l’épaisseur de vos verres",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
          designTypeTitle: "Choisissez le design de vos verres",
        },
        treatments: {
          addToBag: "Ajouter au panier",
          title: "Complétez vos verres",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Traitements",
          extraTreatments: {
            antiScratch: {
              name: "Anti-rayures",
              title: "Résistance aux rayures",
            },
            antiReflective: {
              value: "Traitement anti-reflets",
            },
            uvProtection: {
              title: "protection contre les UV et résistance aux rayures",
            },
          },
        },
        transitionColor: {
          title: "Transition teintés",
          indoor: "INTÉRIEUR",
          outdoor: "EXTÉRIEUR",
        },
        lensColor: {
          confirmedTitle: "Couleur ",
          detailBox: {
            lightTransmission: "Transmission de la lumière",
            lightConditions: "Conditions de luminosité",
            contrast: "Contraste",
            baseLensColor: "Couleur de base des verres",
          },
          categories: {
            "Ugly category": {
              title: "Catégorie Ugly",
            },
            "Cool category": {
              title: "Catégorie Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Couleur ",
          title: "Choisissez la couleur de vos verres",
          titleMobile: "Choisissez la couleur de vos verres",
          titleDesignTypeLayout: "Choisissez la technologie pour vos verres",
          titleColorCategoryLayout: "Choisissez le type de verres",
          polarized: "Uniquement polarisants",
          applyAndContinue: "Appliquer",
          subtitle:
            "Un meilleur rendu des couleurs et un contraste optimal afin de vous permettre de voir plus de détails",
          tooltip: {
            switch:
              "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
            polarized:
              "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
            antiScratch:
              "Protège la surface de vos verres contre les chocs violents",
            blueLight:
              "Réduit l'exposition à la lumière bleue des écrans numériques et aux rayons du soleil, ce qui peut aider à réduire la fatigue oculaire.",
          },
        },
        addOns: {
          confirmedTitle: "Complétez vos verres",
          label: "Déjà compris avec vos verres",
          title: "Complétez vos verres",
          reviewTitle: "Finitions",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse",
        },
        review: {
          title: "Vérifiez vos choix",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
          discountTitle:
            "Une remise de 40 % sur les verres correcteurs est appliquée automatiquement",
          firstLineReview: "Monture :",
          paymentInstallments: {
            installmentsLabel:
              "Payez en plusieurs versements sans intérêt avec ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Appliquer l’assurance",
          insuranceButtonLabelRemove: "Supprimer des prestations sociales",
          insuranceSyncedLabel: "Prestations sociales appliquées",
          oneYearCoverageAdd: "Ajouter",
          tooltip:
            "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters.",
        },
      },
      prescription: {
        toggleLabel:
          "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins",
        prescriptionTitle:
          "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.",
        rightEye: "(Œil droit)",
        rightEyeInitials: "OD",
        leftEye: "(Œil gauche)",
        leftEyeInitials: "OS",
        readPrescription: "Comment lire votre ordonnance",
        addLabel: "J’ai aussi une valeur d’addition",
        applyButton: "Appliquer et continuer",
        submitButton: "Envoyer",
        clearButton: "Effacer tout",
        skipButton: "Ignorer pour l’instant",
        compatibleFramesButton: "Acheter des montures compatibles",
        doctorDetails:
          "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement",
        missingValueError: "Ajoutez vos valeurs pour continuer",
        positiveNegativeError:
          "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.",
        compatibleFrameError:
          "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.",
        compatibleFrameSubLabel:
          '"Aucun problème, nous avons des montures s\'adaptant à votre correction."',
        axisError: "Les valeurs d’AXE que vous avez saisies sont incorrectes !",
        axisSubError: "Ces valeurs doivent entre 0 et 180.",
        axisSubErrorZero:
          "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
        editLabel: "Modifier la correction",
        pdHelp: "\"Qu'est-ce c'est ?\"",
        twoPDNumbers: "J’ai deux valeurs d'EP",
        missingInputMessage:
          "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.",
        defaultInputMessage:
          "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.",
        skipMessage:
          "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard",
        readyMessage:
          "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !",
        bestPrescription: "Le meilleur pour votre correction",
        proceedCheckbox: "Obligatoire pour continuer",
        proceedCheckboxError: "Ou {0} paiements de {1}{2} avec",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Prix monture + verres",
          frame: "Prix de la monture",
          total: "Total",
        },
        free: "Gratuit",
        included: "Inclus",
        frameLensStartingAt: "Monture+Verres à",
        frameLens: "Monture + Verres",
        startingAt: "Prix partant de",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Voir comment c’est fait",
      learnMoreTreatment: "Voir le traitement",
      exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?",
      exitSubtitle: "Votre choix de verres ne sera pas enregistré",
      exitYes: "Oui, sortie",
      exitContinueEditing: "Continuer la modification",
      exitSave: "Enregistrer et continuer le shopping",
      insuranceButtonLabel: "Ajouter des prestations sociales",
      insuranceButtonLabelRemove: "Supprimer des prestations sociales",
      insuranceSyncedLabel: "Prestations sociales appliquées",
      applyingInsurance: "Application des prestations sociales en cours",
      frameSize: "Frame size",
      fallbackImageMessageTitle:
        "Aperçu de la couleur des verres pas disponible",
      fallbackImageMessageSubtitle:
        "Ne vous inquiétez pas, vous recevrez votre monture avec la couleur de verre que vous avez choisie.",
      proceedAsIs: "Procéder comme décrit",
      yourSelections: "Vos sélections",
      editButton: "Modifier",
    },
    fr_CH: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance",
          description:
            "Ou nous vous suggérerons les verres les mieux adaptés à votre vue, afin que vous puissiez vous libérer de vos soucis.",
        },
        advancedPrescription: {
          title: "Avez-vous une correction ?",
          manual: {
            title: "Saisissez votre correction",
            card: {
              title: "Saisissez-la manuellement",
              description:
                "Il s'agit de notre méthode la plus rapide et la plus populaire. Nous vous recommanderons ensuite le type de verres le plus adapté.",
            },
            subtitle:
              "Ajoutez les valeurs de votre ordonnance et nous vous recommanderons les verres les mieux adaptés à votre vue.",
            rightEye: {
              initials: "OD",
              name: "(Œil droit)",
            },
            leftEye: {
              initials: "OS",
              name: "(Œil gauche)",
            },
            sphere: "Sphère (SPH)",
            cylinder: "Cylindre (CYL)",
            axis: "Axe",
            add: "AJOUTER",
            vertical: "Vertical (Δ)",
            baseDirection: "Orientation du prisme",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Écart pupillaire/EP)",
            pdLeft: "Gauche",
            pdRight: "Droite",
            iHaveTwoPd: "J’ai 2 valeurs d’EP",
            applyButton: "Continuer",
            howToRead: "Comment lire votre ordonnance",
            whatIsIt: "Qu'est-ce c'est ?",
            pupillaryDistanceWarningTooltip:
              "Fournir un texte pour cette infobulle",
            moreOptions: "Plus d'options",
            alerts: {
              axisRangeError:
                "Les valeurs d’AXE que vous avez saisies sont incorrectes ! Ces valeurs doivent entre 1 et 180.",
              axisZeroError:
                "Les valeurs que vous avez saisies pour les AXES sont incorrectes ! Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
              sphPositiveNegativeWarning:
                "Vous avez saisi 1 valeur positive et 1 valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance. Si ces valeurs sont correctes, veuillez continuer.",
              missingValues: "Compléter les valeurs manquantes",
              requiredToProceed: "Cela est nécessaire pour pouvoir continuer",
              incompatibleWithPrescriptionTitle:
                "Désolés ! La monture que vous avez choisie n’est pas compatible avec votre ordonnance",
              incompatibleWithPrescriptionBody:
                "Mais ne vous inquiétez pas, des montures compatibles sont disponibles",
              shopCompatible: "Acheter des montures compatibles",
              clearAll: "Effacer tout",
              agreementText:
                "En cliquant sur cette case, je confirme que les valeurs de correction saisies ci-dessus proviennent d'une ordonnance valide (non expirée) qui m'a été délivrée et signée par un optométriste ou un ophtalmologiste agréé.",
            },
            continueModal: {
              title: "Envoyer l'ordonnance plus tard",
              message:
                "En cliquant sur continuer, vous sauterez l'étape de l'ordonnance. Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
              continue: "Oui, continuer",
              cancel: "Non, retourner en arrière",
            },
          },
          account: {
            card: {
              title: "La récupérer dans mon compte",
              description: "Connectez-vous et sélectionnez votre ordonnance.",
            },
          },
          upload: {
            title: "Votre ordonnance",
            subtitle:
              "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours valable.",
            card: {
              title: "Téléchargez-la maintenant",
              description:
                "Et nous nous chargeons du reste. Nous acceptons les formats de fichier suivants : .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) et pages iWork",
            },
          },
          doctor: {
            title: "Ajoutez les coordonnées de votre médecin",
            subtitle:
              "Nous obtiendrons les détails de votre ordonnance directement de lui/elle.",
            searchBy: "Recherchez votre ophtalmologiste par",
            card: {
              title: "Appelez mon médecin",
              description:
                "Nous obtiendrons votre ordonnance directement de lui/elle.",
            },
          },
          later: {
            card: {
              title: "Envoyez-la plus tard",
              description:
                "Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
            },
          },
        },
        type: {
          title: "Sélectionnez vos exigences de vue",
          confirmedTitle: "Utilisez",
          prescriptionTitle: "Ou",
          addToCart: "Ajouter au panier",
          moreInfo: "En savoir plus",
        },
        brand: {
          title: "Choisissez la marque de vos verres",
          confirmedTitle: "Marque",
        },
        treatmentsFamily: {
          confirmedTitle: "Type de verres",
          title: "Choisissez le type de verres",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse.",
          blueLight: {
            title: "Filtre lumière bleue",
            description:
              "Réduit l'exposition à la lumière bleue des écrans numériques, ce qui peut aider à prévenir la fatigue oculaire.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Une seule paire pour l'intérieur et l'extérieur avec les verres Transitions® : ils s'assombrissent et s’éclaircissent rapidement pour devenir clairs, pour que vous n'ayez jamais à changer de lunettes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparents",
            description:
              "Verres transparents conventionnels parfaits pour la vie de tous les jours",
            imageFileName: "",
          },
          sun: {
            title: "Solaires",
            description:
              "Faites votre choix parmi différentes couleurs et teintes de verres.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Complétez votre forfait au meilleur prix",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
          confirmedTitle: "Le pack verres ",
        },
        thickness: {
          confirmedTitle: "Épaisseur",
          title: "Sélectionnez l’épaisseur de vos verres",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie LensCrafters",
          designTypeTitle: "Choisissez le design de vos verres",
        },
        treatments: {
          addToBag: "Ajouter au panier",
          title: "Complétez vos verres",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Traitements",
          extraTreatments: {
            antiScratch: {
              name: "Anti-rayures",
              title: "Résistance aux rayures",
            },
            antiReflective: {
              value: "Traitement anti-reflets",
            },
            uvProtection: {
              title: "protection contre les UV et résistance aux rayures",
            },
          },
        },
        transitionColor: {
          title: "Transition teintés",
          indoor: "INTÉRIEUR",
          outdoor: "EXTÉRIEUR",
        },
        lensColor: {
          confirmedTitle: "Couleur ",
          detailBox: {
            lightTransmission: "Transmission de la lumière",
            lightConditions: "Conditions de luminosité",
            contrast: "Contraste",
            baseLensColor: "Couleur de base des verres",
          },
          categories: {
            "Ugly category": {
              title: "Catégorie Ugly",
            },
            "Cool category": {
              title: "Catégorie Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Couleur ",
          title: "Choisissez la couleur de vos verres",
          titleMobile: "Choisissez la couleur de vos verres",
          titleDesignTypeLayout: "Choisissez la technologie pour vos verres",
          titleColorCategoryLayout: "Choisissez le type de verres",
          polarized: "Uniquement polarisants",
          applyAndContinue: "Appliquer",
          subtitle:
            "Un meilleur rendu des couleurs et un contraste optimal afin de vous permettre de voir plus de détails",
          tooltip: {
            switch:
              "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
            polarized:
              "Réduit l'éblouissement en cas de lumière très vive, pour une clarté exceptionnelle et une protection de l'œil supérieure",
            antiScratch:
              "Protège la surface de vos verres contre les chocs violents",
            blueLight:
              "Réduit l'exposition à la lumière bleue des écrans numériques et aux rayons du soleil, ce qui peut aider à réduire la fatigue oculaire.",
          },
        },
        addOns: {
          confirmedTitle: "Complétez vos verres",
          label: "Déjà compris avec vos verres",
          title: "Complétez vos verres",
          reviewTitle: "Finitions",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse",
        },
        review: {
          title: "Vérifiez vos choix",
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
          discountTitle:
            "Une remise de 40 % sur les verres correcteurs est appliquée automatiquement",
          firstLineReview: "Monture :",
          paymentInstallments: {
            installmentsLabel:
              "Payez en plusieurs versements sans intérêt avec ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Appliquer l’assurance",
          insuranceButtonLabelRemove: "Supprimer des prestations sociales",
          insuranceSyncedLabel: "Prestations sociales appliquées",
          oneYearCoverageAdd: "Ajouter",
          tooltip:
            "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters.",
        },
      },
      prescription: {
        toggleLabel:
          "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins",
        prescriptionTitle:
          "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.",
        rightEye: "(Œil droit)",
        rightEyeInitials: "OD",
        leftEye: "(Œil gauche)",
        leftEyeInitials: "OS",
        readPrescription: "Comment lire votre ordonnance",
        addLabel: "J’ai aussi une valeur d’addition",
        applyButton: "Appliquer et continuer",
        submitButton: "Envoyer",
        clearButton: "Effacer tout",
        skipButton: "Ignorer pour l’instant",
        compatibleFramesButton: "Acheter des montures compatibles",
        doctorDetails:
          "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement",
        missingValueError: "Ajoutez vos valeurs pour continuer",
        positiveNegativeError:
          "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.",
        compatibleFrameError:
          "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.",
        compatibleFrameSubLabel:
          '"Aucun problème, nous avons des montures s\'adaptant à votre correction."',
        axisError: "Les valeurs d’AXE que vous avez saisies sont incorrectes !",
        axisSubError: "Ces valeurs doivent entre 0 et 180.",
        axisSubErrorZero:
          "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
        editLabel: "Modifier la correction",
        pdHelp: "\"Qu'est-ce c'est ?\"",
        twoPDNumbers: "J’ai deux valeurs d'EP",
        missingInputMessage:
          "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.",
        defaultInputMessage:
          "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.",
        skipMessage:
          "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard",
        readyMessage:
          "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !",
        bestPrescription: "Le meilleur pour votre correction",
        proceedCheckbox: "Obligatoire pour continuer",
        proceedCheckboxError: "Ou {0} paiements de {1}{2} avec",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Prix monture + verres",
          frame: "Prix de la monture",
          total: "Total",
        },
        free: "Gratuit",
        included: "Inclus",
        frameLensStartingAt: "Monture+Verres à",
        frameLens: "Monture + Verres",
        startingAt: "Prix partant de",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Voir comment c’est fait",
      learnMoreTreatment: "Voir le traitement",
      exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?",
      exitSubtitle: "Votre choix de verres ne sera pas enregistré",
      exitYes: "Oui, sortie",
      exitContinueEditing: "Continuer la modification",
      exitSave: "Enregistrer et continuer le shopping",
      insuranceButtonLabel: "Ajouter des prestations sociales",
      insuranceButtonLabelRemove: "Supprimer des prestations sociales",
      insuranceSyncedLabel: "Prestations sociales appliquées",
      applyingInsurance: "Application des prestations sociales en cours",
      frameSize: "Frame size",
      fallbackImageMessageTitle:
        "Aperçu de la couleur des verres pas disponible",
      fallbackImageMessageSubtitle:
        "Ne vous inquiétez pas, vous recevrez votre monture avec la couleur de verre que vous avez choisie.",
      proceedAsIs: "Procéder comme décrit",
      yourSelections: "Vos sélections",
      editButton: "Modifier",
    },
    nl_NL: {
      steps: {
        prescription: {
          title: "Rond jouw brilrecept af",
          description:
            "Of we adviseren je het brillenglas dat het beste aan jouw eisen voldoet, zodat je je geen zorgen hoeft te maken",
        },
        advancedPrescription: {
          title: "Heb je een brilrecept?",
          manual: {
            title: "Voer je brilrecept in",
            card: {
              title: "Voer het met de hand in",
              description:
                "Dit is de snelste en meest gebruikte methode. Daarna adviseren we je het meest geschikte type brillenglas.",
            },
            subtitle:
              "Voeg je brilrecept toe en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",
            rightEye: {
              initials: "OD",
              name: "(Rechteroog)",
            },
            leftEye: {
              initials: "OS",
              name: "(Linkeroog)",
            },
            sphere: "Sfeer (SPH)",
            cylinder: "Cilinder (CYL)",
            axis: "As",
            add: "ADD",
            vertical: "Verticaal (Δ)",
            baseDirection: "Basiscurve",
            horizontal: "Horizontaal (Δ)",
            pupillaryDistance: "PD (Pupilafstand)",
            pdLeft: "Links",
            pdRight: "Rechts",
            iHaveTwoPd: "Ik heb 2 PD-nummers",
            applyButton: "Doorgaan",
            howToRead: "Hoe lees je het brilrecept",
            whatIsIt: "Wat is dit?",
            pupillaryDistanceWarningTooltip: "Geef tekst voor deze tooltip",
            moreOptions: "Meer opties",
            alerts: {
              axisRangeError:
                "De AS-waarden die je hebt ingevoerd zijn niet juist! Deze moeten tussen 1 en 180 liggen.",
              axisZeroError:
                "De AS-waarden die je hebt ingevoerd zijn niet juist! De AS-waarden kunnen niet 0 zijn, als de CYL-waarde hoger of lager is dan 0.",
              sphPositiveNegativeWarning:
                "Je hebt 1 positieve en 1 negatieve waarde ingevoerd, dat is erg bijzonder. Ik adviseer je om het brilrecept te controleren. Als dit juist is, kun je doorgaan.",
              missingValues: "Vul de ontbrekende waarden in",
              requiredToProceed: "Dit is vereist om door te gaan",
              incompatibleWithPrescriptionTitle:
                "Helaas! Het montuur dat je hebt gekozen, is niet compatibel met je brilrecept",
              incompatibleWithPrescriptionBody:
                "maar maak je niet druk, we kunnen je compatibele monturen aanbieden",
              shopCompatible: "Koop compatibele monturen",
              clearAll: "Alle wissen",
              agreementText:
                "Door dit hokje aan te vinken, bevestig ik dat de bovenstaande waarden afkomstig zijn van een geldig (niet vervallen) brilrecept dat voor mij is afgegeven en door een erkende optometrist of oogarts is ondertekend.",
            },
            continueModal: {
              title: "Stuur het brilrecept later op",
              message:
                "Door op verder te klikken, sla je de stap betreffende het brilrecept over. We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
              continue: "Ja, doorgaan",
              cancel: "Nee, terug",
            },
          },
          account: {
            card: {
              title: "Haal het op uit mijn account",
              description: "Log in en selecteer jouw brilrecept.",
            },
          },
          upload: {
            title: "Jouw brilrecept",
            subtitle:
              "Controleer de geldigheidsdatum om na te gaan of je brilrecept nog altijd geldig is, voordat je het indient.",
            card: {
              title: "Upload het nu",
              description:
                "Wij denken aan de rest. We accepteren de volgende bestandsformaten': .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) en IWork pages",
            },
          },
          doctor: {
            title: "Voeg de gegevens van jouw arts toe",
            subtitle:
              "We zullen direct met hem/haar contact opnemen en jouw brilrecept opvragen",
            searchBy: "Zoek je oogarts op",
            card: {
              title: "Bel mijn arts",
              description:
                "We zullen direct met hem/haar contact opnemen en jouw brilrecept opvragen.",
            },
          },
          later: {
            card: {
              title: "Stuur het later op",
              description:
                "We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
            },
          },
        },
        type: {
          title: "Selecteer de benodigde sterkte",
          confirmedTitle: "Gebruik",
          prescriptionTitle: "Of",
          addToCart: "Aan winkelwagen toevoegen",
          moreInfo: "Meer informatie",
        },
        brand: {
          title: "Kies het merk brillenglazen",
          confirmedTitle: "Merk",
        },
        treatmentsFamily: {
          confirmedTitle: "Type brillenglas:",
          title: "Kies het type brillenglazen",
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming.",
          blueLight: {
            title: "Blauwlichtfilter",
            description:
              "Reduceert de blootstelling aan blauw licht afkomstig van digitale schermen, waardoor je oogvermoeidheid kunt beperken.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Eén bril voor binnen en buiten met Transitions®-brillenglazen die snel overschakelen van licht naar donker en omgekeerd, zodat je nooit van bril hoeft te wisselen.",
            imageFileName: "",
          },
          clear: {
            title: "Helder",
            description:
              "Traditionele, transparante brillenglazen voor alledaags gebruik",
            imageFileName: "",
          },
          sun: {
            title: "Zon",
            description:
              "Maak een keuze uit brillenglazen in diverse kleuren en nuances.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Rond jouw voordeelpakket af",
          subtitle:
            "Jouw bril wordt geleverd met de beste LensCrafters-technologie.",
          confirmedTitle: "Lenzenpakket:",
        },
        thickness: {
          confirmedTitle: "Dikte",
          title: "Selecteer de dikte van de brillenglazen",
          subtitle:
            "Jouw bril wordt geleverd met de beste LensCrafters-technologie.",
          designTypeTitle: "Kies het design van de brillenglazen",
        },
        treatments: {
          addToBag: "Aan boodschappenmand toevoegen",
          title: "Rond jouw brillenglas af",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandelingen",
          extraTreatments: {
            antiScratch: {
              name: "Krasbestendig",
              title: "Krasbestendig",
            },
            antiReflective: {
              value: "Antireflexbehandeling",
            },
            uvProtection: {
              title: "UV-bescherming en krasbestendig",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "BINNEN",
          outdoor: "BUITEN",
        },
        lensColor: {
          confirmedTitle: "Kleur:",
          detailBox: {
            lightTransmission: "Lichttransmissie",
            lightConditions: "Lichtomstandigheden",
            contrast: "Contrast",
            baseLensColor: "Basiskleur brillenglazen",
          },
          categories: {
            "Ugly category": {
              title: "Ugly categorie",
            },
            "Cool category": {
              title: "Cool categorie",
            },
          },
        },
        color: {
          confirmedTitle: "Kleur:",
          title: "Kies de kleur van de brillenglazen",
          titleMobile: "Kies de kleur van de brillenglazen",
          titleDesignTypeLayout: "Kies de technologie van de brillenglazen",
          titleColorCategoryLayout: "Kies het type brillenglazen",
          polarized: "Uitsluitend gepolariseerd",
          applyAndContinue: "Toepassen",
          subtitle:
            "Verbeterde kleur en contrast, zodat je meer details kunt waarnemen",
          tooltip: {
            switch:
              "Minder verblinding bij extra helder licht, voor helderder zicht en bescherming van de ogen.",
            polarized:
              "Minder verblinding bij extra helder licht, voor helderder zicht en bescherming van de ogen.",
            antiScratch: "Beschermt je brillenglazen tegen harde schokken",
            blueLight:
              "Reduceert de blootstelling aan blauw licht afkomstig van digitale schermen, waardoor oogvermoeidheid wordt beperkt.",
          },
        },
        addOns: {
          confirmedTitle: "Maak jouw brillenglazen af",
          label: "Reeds inbegrepen met jouw brillenglas",
          title: "Maak jouw brillenglazen af",
          reviewTitle: "Afwerking",
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming",
        },
        review: {
          title: "Herzie jouw keuzes",
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban.",
          discountTitle:
            "Over brillenglazen op sterkte wordt automatisch -40% toegepast",
          firstLineReview: "Montuur:",
          paymentInstallments: {
            installmentsLabel:
              "Gespreide betaling met rentevrije afbetalingstermijnen met ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Verzekeringsuitkeringen verwijderen",
          oneYearCoverageAdd: "Toevoegen",
          tooltip:
            "Verzeker je bril met een verlengde garantie voor onbeperkte reparatie en vervanging in elke LensCrafters-winkel.",
        },
      },
      prescription: {
        toggleLabel:
          "Voeg de gegevens van jouw brilrecept in, we helpen je het juiste brillenglas voor jouw sterkte te vinden.",
        prescriptionTitle:
          "Voeg de gegevens van jouw brilrecept in, we tonen je het juiste brillenglas voor jouw sterkte.",
        rightEye: "(Rechteroog)",
        rightEyeInitials: "OD",
        leftEye: "(Linkeroog)",
        leftEyeInitials: "OS",
        readPrescription: "Hoe lees je het brilrecept",
        addLabel: "Ik heb ook een ADD-waarde",
        applyButton: "Toepassen en doorgaan",
        submitButton: "Verzenden",
        clearButton: "Alle wissen",
        skipButton: "Tijdelijk overslaan",
        compatibleFramesButton: "Koop compatibele monturen",
        doctorDetails:
          "Na het afrekenen vragen we je om een kopie van jouw brilrecept of de gegevens van jouw oogarts",
        missingValueError: "Voeg jouw gegevens toe om door te gaan",
        positiveNegativeError:
          "Je hebt één positieve waarde en één negatieve waarde ingevoerd. Dit is bijzonder zeldzaam. Ik adviseer je om het brilrecept te controleren.",
        compatibleFrameError:
          "Helaas! Het montuur dat je hebt gekozen is niet geschikt voor jouw brilrecept.",
        compatibleFrameSubLabel:
          "Geen probleem. We hebben monturen die voor jouw brilrecept geschikt zijn.",
        axisError: "De AS-waarden die je hebt ingevoerd zijn niet juist!",
        axisSubError: "Deze moeten tussen 0 en 180 liggen.",
        axisSubErrorZero:
          "Als de CYL-waarde hoger of lager is dan 0, dan kunnen de AS-waarden niet 0 zijn.",
        editLabel: "Brilrecept bewerken",
        pdHelp: "Wat is dit?",
        twoPDNumbers: "Ik heb twee PD-nummers",
        missingInputMessage:
          "Voeg de ontbrekende gegevens in zodat je het uploaden van het brilrecept kunt afronden en we zullen je het meest geschikte brillenglas aanbevelen.",
        defaultInputMessage:
          "Voeg jouw SPH- en CYL-waarden in en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",
        skipMessage:
          "We vragen later om het brilrecept af te ronden of te uploaden",
        readyMessage: "Nu kunnen we je het beste brillenglas aanbevelen!",
        bestPrescription: "Het beste voor jouw brilrecept",
        proceedCheckbox: "Vereist om door te gaan",
        proceedCheckboxError: "Of {0} betalingen van {1}{2} met",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Bedrag montuur + brillenglazen",
          frame: "Bedrag montuur",
          total: "Total",
        },
        free: "Gratis",
        included: "Inclusief",
        frameLensStartingAt: "Montuur + brillenglazen bij",
        frameLens: "Montuur + brillenglazen",
        startingAt: "Vanaf",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Zie hoe hij wordt gemaakt",
      learnMoreTreatment: "Bekijk de behandeling",
      exitTitle: "WEET JE HET ZEKER DAT JE WILT AFSLUITEN?",
      exitSubtitle: "De gekozen brillenglazen worden niet opgeslagen",
      exitYes: "Yes, exit",
      exitContinueEditing: "Doorgaan met bewerken",
      exitSave: "Opslaan en verder winkelen",
      insuranceButtonLabel: "Verzekeringsuitkeringen toevoegen",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Verzekeringsuitkeringen toegepast",
      applyingInsurance: "Verzekeringsuitkeringen worden toegepast",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Weergave lenskleur niet beschikbaar",
      fallbackImageMessageSubtitle:
        "Geen probleem, je ontvangt het montuur met de gekozen lenskleur.",
      proceedAsIs: "Doorgaan zonder wijzigingen",
      yourSelections: "Jouw keuzes",
      editButton: "Bewerken",
    },
    nl_BE: {
      steps: {
        prescription: {
          title: "Rond jouw brilrecept af",
          description:
            "Of we adviseren je het brillenglas dat het beste aan jouw eisen voldoet, zodat je je geen zorgen hoeft te maken",
        },
        advancedPrescription: {
          title: "Heb je een brilrecept?",
          manual: {
            title: "Voer je brilrecept in",
            card: {
              title: "Voer het met de hand in",
              description:
                "Dit is de snelste en meest gebruikte methode. Daarna adviseren we je het meest geschikte type brillenglas.",
            },
            subtitle:
              "Voeg je brilrecept toe en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",
            rightEye: {
              initials: "OD",
              name: "(Rechteroog)",
            },
            leftEye: {
              initials: "OS",
              name: "(Linkeroog)",
            },
            sphere: "Sfeer (SPH)",
            cylinder: "Cilinder (CYL)",
            axis: "As",
            add: "ADD",
            vertical: "Verticaal (Δ)",
            baseDirection: "Basiscurve",
            horizontal: "Horizontaal (Δ)",
            pupillaryDistance: "PD (Pupilafstand)",
            pdLeft: "Links",
            pdRight: "Rechts",
            iHaveTwoPd: "Ik heb 2 PD-nummers",
            applyButton: "Doorgaan",
            howToRead: "Hoe lees je het brilrecept",
            whatIsIt: "Wat is dit?",
            pupillaryDistanceWarningTooltip: "Geef tekst voor deze tooltip",
            moreOptions: "Meer opties",
            alerts: {
              axisRangeError:
                "De AS-waarden die je hebt ingevoerd zijn niet juist! Deze moeten tussen 1 en 180 liggen.",
              axisZeroError:
                "De AS-waarden die je hebt ingevoerd zijn niet juist! De AS-waarden kunnen niet 0 zijn, als de CYL-waarde hoger of lager is dan 0.",
              sphPositiveNegativeWarning:
                "Je hebt 1 positieve en 1 negatieve waarde ingevoerd, dat is erg bijzonder. Ik adviseer je om het brilrecept te controleren. Als dit juist is, kun je doorgaan.",
              missingValues: "Vul de ontbrekende waarden in",
              requiredToProceed: "Dit is vereist om door te gaan",
              incompatibleWithPrescriptionTitle:
                "Helaas! Het montuur dat je hebt gekozen, is niet compatibel met je brilrecept",
              incompatibleWithPrescriptionBody:
                "maar maak je niet druk, we kunnen je compatibele monturen aanbieden",
              shopCompatible: "Koop compatibele monturen",
              clearAll: "Alle wissen",
              agreementText:
                "Door dit hokje aan te vinken, bevestig ik dat de bovenstaande waarden afkomstig zijn van een geldig (niet vervallen) brilrecept dat voor mij is afgegeven en door een erkende optometrist of oogarts is ondertekend.",
            },
            continueModal: {
              title: "Stuur het brilrecept later op",
              message:
                "Door op verder te klikken, sla je de stap betreffende het brilrecept over. We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
              continue: "Ja, doorgaan",
              cancel: "Nee, terug",
            },
          },
          account: {
            card: {
              title: "Haal het op uit mijn account",
              description: "Log in en selecteer jouw brilrecept.",
            },
          },
          upload: {
            title: "Jouw brilrecept",
            subtitle:
              "Controleer de geldigheidsdatum om na te gaan of je brilrecept nog altijd geldig is, voordat je het indient.",
            card: {
              title: "Upload het nu",
              description:
                "Wij denken aan de rest. We accepteren de volgende bestandsformaten': .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) en IWork pages",
            },
          },
          doctor: {
            title: "Voeg de gegevens van jouw arts toe",
            subtitle:
              "We zullen direct met hem/haar contact opnemen en jouw brilrecept opvragen",
            searchBy: "Zoek je oogarts op",
            card: {
              title: "Bel mijn arts",
              description:
                "We zullen direct met hem/haar contact opnemen en jouw brilrecept opvragen.",
            },
          },
          later: {
            card: {
              title: "Stuur het later op",
              description:
                "We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
            },
          },
        },
        type: {
          title: "Selecteer de benodigde sterkte",
          confirmedTitle: "Gebruik",
          prescriptionTitle: "Of",
          addToCart: "Aan winkelwagen toevoegen",
          moreInfo: "Meer informatie",
        },
        brand: {
          title: "Kies het merk brillenglazen",
          confirmedTitle: "Merk",
        },
        treatmentsFamily: {
          confirmedTitle: "Type brillenglas:",
          title: "Kies het type brillenglazen",
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming.",
          blueLight: {
            title: "Blauwlichtfilter",
            description:
              "Reduceert de blootstelling aan blauw licht afkomstig van digitale schermen, waardoor je oogvermoeidheid kunt beperken.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Eén bril voor binnen en buiten met Transitions®-brillenglazen die snel overschakelen van licht naar donker en omgekeerd, zodat je nooit van bril hoeft te wisselen.",
            imageFileName: "",
          },
          clear: {
            title: "Helder",
            description:
              "Traditionele, transparante brillenglazen voor alledaags gebruik",
            imageFileName: "",
          },
          sun: {
            title: "Zon",
            description:
              "Maak een keuze uit brillenglazen in diverse kleuren en nuances.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Rond jouw voordeelpakket af",
          subtitle:
            "Jouw bril wordt geleverd met de beste LensCrafters-technologie.",
          confirmedTitle: "Lenzenpakket:",
        },
        thickness: {
          confirmedTitle: "Dikte",
          title: "Selecteer de dikte van de brillenglazen",
          subtitle:
            "Jouw bril wordt geleverd met de beste LensCrafters-technologie.",
          designTypeTitle: "Kies het design van de brillenglazen",
        },
        treatments: {
          addToBag: "Aan boodschappenmand toevoegen",
          title: "Rond jouw brillenglas af",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandelingen",
          extraTreatments: {
            antiScratch: {
              name: "Krasbestendig",
              title: "Krasbestendig",
            },
            antiReflective: {
              value: "Antireflexbehandeling",
            },
            uvProtection: {
              title: "UV-bescherming en krasbestendig",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "BINNEN",
          outdoor: "BUITEN",
        },
        lensColor: {
          confirmedTitle: "Kleur:",
          detailBox: {
            lightTransmission: "Lichttransmissie",
            lightConditions: "Lichtomstandigheden",
            contrast: "Contrast",
            baseLensColor: "Basiskleur brillenglazen",
          },
          categories: {
            "Ugly category": {
              title: "Ugly categorie",
            },
            "Cool category": {
              title: "Cool categorie",
            },
          },
        },
        color: {
          confirmedTitle: "Kleur:",
          title: "Kies de kleur van de brillenglazen",
          titleMobile: "Kies de kleur van de brillenglazen",
          titleDesignTypeLayout: "Kies de technologie van de brillenglazen",
          titleColorCategoryLayout: "Kies het type brillenglazen",
          polarized: "Uitsluitend gepolariseerd",
          applyAndContinue: "Toepassen",
          subtitle:
            "Verbeterde kleur en contrast, zodat je meer details kunt waarnemen",
          tooltip: {
            switch:
              "Minder verblinding bij extra helder licht, voor helderder zicht en bescherming van de ogen.",
            polarized:
              "Minder verblinding bij extra helder licht, voor helderder zicht en bescherming van de ogen.",
            antiScratch: "Beschermt je brillenglazen tegen harde schokken",
            blueLight:
              "Reduceert de blootstelling aan blauw licht afkomstig van digitale schermen, waardoor oogvermoeidheid wordt beperkt.",
          },
        },
        addOns: {
          confirmedTitle: "Maak jouw brillenglazen af",
          label: "Reeds inbegrepen met jouw brillenglas",
          title: "Maak jouw brillenglazen af",
          reviewTitle: "Afwerking",
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming",
        },
        review: {
          title: "Herzie jouw keuzes",
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban.",
          discountTitle:
            "Over brillenglazen op sterkte wordt automatisch -40% toegepast",
          firstLineReview: "Montuur:",
          paymentInstallments: {
            installmentsLabel:
              "Gespreide betaling met rentevrije afbetalingstermijnen met ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Verzekeringsuitkeringen verwijderen",
          oneYearCoverageAdd: "Toevoegen",
          tooltip:
            "Verzeker je bril met een verlengde garantie voor onbeperkte reparatie en vervanging in elke LensCrafters-winkel.",
        },
      },
      prescription: {
        toggleLabel:
          "Voeg de gegevens van jouw brilrecept in, we helpen je het juiste brillenglas voor jouw sterkte te vinden.",
        prescriptionTitle:
          "Voeg de gegevens van jouw brilrecept in, we tonen je het juiste brillenglas voor jouw sterkte.",
        rightEye: "(Rechteroog)",
        rightEyeInitials: "OD",
        leftEye: "(Linkeroog)",
        leftEyeInitials: "OS",
        readPrescription: "Hoe lees je het brilrecept",
        addLabel: "Ik heb ook een ADD-waarde",
        applyButton: "Toepassen en doorgaan",
        submitButton: "Verzenden",
        clearButton: "Alle wissen",
        skipButton: "Tijdelijk overslaan",
        compatibleFramesButton: "Koop compatibele monturen",
        doctorDetails:
          "Na het afrekenen vragen we je om een kopie van jouw brilrecept of de gegevens van jouw oogarts",
        missingValueError: "Voeg jouw gegevens toe om door te gaan",
        positiveNegativeError:
          "Je hebt één positieve waarde en één negatieve waarde ingevoerd. Dit is bijzonder zeldzaam. Ik adviseer je om het brilrecept te controleren.",
        compatibleFrameError:
          "Helaas! Het montuur dat je hebt gekozen is niet geschikt voor jouw brilrecept.",
        compatibleFrameSubLabel:
          "Geen probleem. We hebben monturen die voor jouw brilrecept geschikt zijn.",
        axisError: "De AS-waarden die je hebt ingevoerd zijn niet juist!",
        axisSubError: "Deze moeten tussen 0 en 180 liggen.",
        axisSubErrorZero:
          "Als de CYL-waarde hoger of lager is dan 0, dan kunnen de AS-waarden niet 0 zijn.",
        editLabel: "Brilrecept bewerken",
        pdHelp: "Wat is dit?",
        twoPDNumbers: "Ik heb twee PD-nummers",
        missingInputMessage:
          "Voeg de ontbrekende gegevens in zodat je het uploaden van het brilrecept kunt afronden en we zullen je het meest geschikte brillenglas aanbevelen.",
        defaultInputMessage:
          "Voeg jouw SPH- en CYL-waarden in en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",
        skipMessage:
          "We vragen later om het brilrecept af te ronden of te uploaden",
        readyMessage: "Nu kunnen we je het beste brillenglas aanbevelen!",
        bestPrescription: "Het beste voor jouw brilrecept",
        proceedCheckbox: "Vereist om door te gaan",
        proceedCheckboxError: "Of {0} betalingen van {1}{2} met",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Bedrag montuur + brillenglazen",
          frame: "Bedrag montuur",
          total: "Total",
        },
        free: "Gratis",
        included: "Inclusief",
        frameLensStartingAt: "Montuur + brillenglazen bij",
        frameLens: "Montuur + brillenglazen",
        startingAt: "Vanaf",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Zie hoe hij wordt gemaakt",
      learnMoreTreatment: "Bekijk de behandeling",
      exitTitle: "WEET JE HET ZEKER DAT JE WILT AFSLUITEN?",
      exitSubtitle: "De gekozen brillenglazen worden niet opgeslagen",
      exitYes: "Yes, exit",
      exitContinueEditing: "Doorgaan met bewerken",
      exitSave: "Opslaan en verder winkelen",
      insuranceButtonLabel: "Verzekeringsuitkeringen toevoegen",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Verzekeringsuitkeringen toegepast",
      applyingInsurance: "Verzekeringsuitkeringen worden toegepast",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Weergave lenskleur niet beschikbaar",
      fallbackImageMessageSubtitle:
        "Geen probleem, je ontvangt het montuur met de gekozen lenskleur.",
      proceedAsIs: "Doorgaan zonder wijzigingen",
      yourSelections: "Jouw keuzes",
      editButton: "Bewerken",
    },
    pt_BR: {
      steps: {
        prescription: {
          title: "Adicione os valores de sua receita",
          description:
            "Ou nós sugeriremos as lentes certas para sua necessidade de visão, assim você não tem de se preocupar com nada",
        },
        advancedPrescription: {
          title: "Você tem uma receita?",
          manual: {
            addPrismValues: "Adicione valores de prisma",
            title: "Digite sua receita",
            card: {
              title: "Digite-a manualmente",
              description:
                "Esse é nosso método mais rápido e mais popular. Nós iremos recomendar o tipo de lentes mais adequado.",
            },
            subtitle:
              "Adicione os valores de sua receita e nós iremos recomendar as melhores lentes para suas necessidades de visão.",
            rightEye: {
              initials: "OD",
              name: "(Olho direito)",
            },
            leftEye: {
              initials: "OS",
              name: "(Olho esquerdo)",
            },
            sphere: "Esférico",
            cylinder: "Cilíndrico",
            axis: "Eixo",
            add: "ADICIONAR",
            vertical: "Vertical (Δ)",
            baseDirection: "Direção base",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "DP (Distância pupilar)",
            pdLeft: "Esquerda",
            pdRight: "Direita",
            iHaveTwoPd: "Eu tenho 2 números de DP",
            applyButton: "Continuar",
            howToRead: "Como ler sua receita",
            whatIsIt: "O que é isso?",
            pupillaryDistanceWarningTooltip:
              "Forneça texto para essa dica de ferramenta",
            moreOptions: "Mais opções",
            alerts: {
              axisRangeError:
                "Os valores de EIXO que você inseriu não estão corretos! Estes valores devem estar entre 1 e 180.",
              axisZeroError:
                "Os valores de EIXO que você inseriu não estão corretos! Se o valor de cilíndrico for maior ou menor de 0, os valores de EIXO não podem ser 0.",
              sphPositiveNegativeWarning:
                "Você inseriu 1 valor positivo e 1 valor negativo, o que é muito raro. Sugerimos que verifique novamente sua receita. Se estiver correto, pode continuar.",
              pdValueWarning:
                "A DP selecionada está fora do intervalo normal, sugerimos que verifique novamente sua receita. Pode deixar a predefinição de 63, que é a medição média para adultos.\n Se precisarmos de mais informações sobre sua receita, um de nossos especialistas estará em contato consigo. ",
              missingValues: "Preencha os valores ausentes",
              requiredToProceed: "Isto é necessário para continuar",
              incompatibleWithPrescriptionTitle:
                "Ops! A armação que escolheu não é compatível com sua receita",
              incompatibleWithPrescriptionBody:
                "mas não se preocupe, estão disponíveis armações compatíveis",
              incompatibleWithPackagesTitle:
                "Ops! A receita que escolheu não é compatível com nenhuma de nossas lentes disponíveis para essa armação",
              shopCompatible: "Compre armações compatíveis",
              clearAll: "Limpar tudo",
              agreementText:
                "Clicando nessa caixa, confirmo que os valores de receita digitados acima são retirados de uma receita válida (não expirada) emitida para mim, assinada por um optometrista ou oftalmologista licenciado.",
            },
            continueModal: {
              title: "Enviar receita mais tarde",
              message:
                "Clicando em continuar, irá ignorar o passo da receita. Pediremos para carregar a receita após sua encomenda ou chamaremos seu médico.",
              continue: "Continuar",
              cancel: "Voltar",
            },
            manualPreviewTitle: "Detalhes de sua receita",
            seeDetails: {
              sphere: "Esférico",
              cylinder: "Cilíndrico",
              axis: "Eixo",
              add: "ADICIONAR",
              pd: "DP",
              od: "OD",
              os: "OE",
              vertical: "Prisma Vertical (Δ)",
              baseDirection: "Direção base",
              horizontal: "Prisma Horizontal (Δ)",
            },
          },
          account: {
            card: {
              title: "Obter de minha conta",
              description: "Faça login e selecione sua receita.",
            },
          },
          upload: {
            title: "Sua receita",
            subtitle:
              "Antes de submeter sua receita, verifique a data de vencimento para verificar se ainda é válida.",
            card: {
              title: "Carregue agora",
              description:
                "E nós cuidaremos do resto. Aceitamos os seguintes formatos de arquivo: ###FILE_FORMATS### (máx. ###FILE_SIZE### MB) e páginas IWork",
            },
            uploadDifferentFile: "Carregar arquivo diferente",
            changeMethod: "Alterar método de envio",
            prescriptionUploaded: "Receita Carregada",
            somethingWentWrong: "Desculpe, algo deu errado.",
            fileTooBigErrorTitle: "O arquivo é muito grande",
            fileTooBigErrorDescription:
              "O tamanho de seu arquivo é muito grande. Carregamento máximo ###FILE_SIZE### MB",
            tryAgain: "Por favor, tente novamente",
            upload: "Carregar",
            pdForm: {
              pupillaryDistance: "DP (Distância pupilar)",
              whatIsIt: "O que é isso?",
              pdValueWarning:
                "A DP selecionada está fora do intervalo normal, sugerimos que verifique novamente sua receita. Pode deixar a predefinição de 63, que é a medição média para adultos.\nSe precisarmos de mais informações sobre sua receita, um de nossos especialistas estará em contato consigo. ",
              pdLeft: "Esquerda",
              pdRight: "Direita",
              iHaveTwoPd: "Eu tenho 2 números de DP",
            },
            confirmAndContinue: "Confirmar e continuar",
            filePreviewTitle: "Sua receita",
          },
          doctor: {
            title: "Adicione os dados de seu médico",
            subtitle:
              "Tem de ser o mesmo médico que emitiu e assinou sua receita. Vamos chamar para verificar seus detalhes.",
            searchBy: "Procure seu oftalmologista por",
            card: {
              title: "Chamar meu médico",
              description: "Vamos obter sua receita diretamente do médico/a.",
            },
            addNewDoctorModal: {
              title: "Adicionar médico novo",
              clinicOrDoctorName: "Nome do médico ou da clínica",
              phoneNumber: "Número de telefone",
              addDoctor: "Adicionar médico",
            },
            slider: {
              phone: "Número de telefone",
              clinicOrDoctorName: "Nome do médico ou da clínica",
            },
            table: {
              doctorName: "Nome do médico",
              clinicName: "Nome da clínica",
              address: "Endereço",
              chooseButton: "Selecionar",
              phone: "Telefone",
              fax: "Fax",
              phoneNumber: "Número de telefone",
              changeButton: "Mudar",
              wrongDoctor: "Médico errado?",
            },
            pdForm: {
              pupillaryDistance: "DP (Distância pupilar)",
              whatIsIt: "O que é isso?",
              pdValueWarning:
                "A DP selecionada está fora do intervalo normal, sugerimos que verifique novamente sua receita. Pode deixar a predefinição de 63, que é a medição média para adultos.\n Se precisarmos de mais informações sobre sua receita, um de nossos especialistas estará em contato consigo.",
              pdLeft: "Esquerda",
              pdRight: "Direita",
              iHaveTwoPd: "Eu tenho 2 números de DP",
            },
            addManuallyOption: {
              didntFind: "Não encontrou seu médico?",
              addManually: "Adicione manualmente",
            },
            missingInfoError: "Preencha as informações ausentes",
            doctorNotFoundBoxMessage: "Não encontramos seu médico",
            doctorNotFoundBoxMessageAdd: "Adicione mais detalhes ou ",
            doctorNotFoundBoxMessageLink: "adicione um médico novo.",
            phoneNumber: "Número de telefone do médico",
            search: "Buscar",
            clinicOrDoctorName: "Nome do médico ou da clínica",
            city: "Cidade",
            state: "Estado",
            changeSubmissionMethod: "Alterar método de envio",
            confirmAndContinue: "Confirmar e continuar",
            selectedDoctorPageTitle: "Os dados de seu médico",
            doctorPreviewTitle: "Chamaremos seu médico",
          },
          later: {
            card: {
              title: "Enviar mais tarde",
              description:
                "Pediremos para carregar após sua encomenda ou chamaremos seu médico.",
            },
            review: {
              title: "Pediremos para carregar após o check-out",
            },
          },
          californian: {
            toggle: "É residente na Califórnia?",
            info: "Observe que, para além de adicionar manualmente os valores da receita, os residentes na Califórnia também são obrigados a transmitir eletronicamente sua receita válida. Escolha uma opção para continuar.",
            chooseTitle: "ESCOLHA COMO ENVIAR SUA RECEITA",
            uploadTitle: "Carregar",
            uploadDesc:
              "Aceitamos os seguintes formatos de arquivo: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (máx. 10 MB) e páginas IWork.",
            uploadButton: "Navegar",
            callMyDoc: "Chamar meu médico",
            callMyDocButton: "ENCONTRE SEU MÉDICO",
            yes: "SIM",
            no: "NÃO",
            modalMissingPrescription: {
              title: "Valores de receita ausentes",
              subtitle:
                "Se não preencher os valores, não poderemos sugerir as lentes certas para você",
              bottonYes: "CONTINUAR",
              bottonNo: "VOLTAR",
            },
          },
        },
        type: {
          title: "Selecione sua necessidade de visão",
          confirmedTitle: "Necessidade de visão:",
          prescriptionTitle: "Ou",
          addToCart: "Adicione ao carrinho",
          moreInfo: "Mais informações",
        },
        brand: {
          title: "Escolha a marca de sua lente",
          confirmedTitle: "Marca da lente:",
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo de lente:",
          title: "Escolha seu tipo de lente",
          subtitle:
            "Seus óculos vêm com a melhor tecnologia Ray-Ban. Resistentes a riscos e proteção UV incluídos.",
          blueLight: {
            title: "Filtro de luz azul",
            description:
              "Reduz a exposição à luz azul emitida pelas telas digitais, assim ajuda a conter a fadiga ocular.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Uns óculos para ambientes internos e o ar livre com lentes Transitions®: escurecem e clareiam rapidamente, assim não tem de mudar de óculos.",
            imageFileName: "",
          },
          clear: {
            title: "Transparentes",
            description:
              "Lentes tradicionais, transparentes perfeitas para uso diário",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description: "Escolha entre cores e nuances de lentes diferentes.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Escolha seu Pacote de Grande Valor",
          subtitle: "Seus óculos vêm com a melhor tecnologia LensCrafters.",
          confirmedTitle: "Embalagem de Lentes:",
        },
        thickness: {
          confirmedTitle: "Espessura:",
          title: "Escolha a espessura de suas lentes",
          subtitle: "Seus óculos vêm com a melhor tecnologia LensCrafters.",
          designTypeTitle: "Escolha o design de sua lente",
        },
        treatments: {
          addToBag: "Adicione ao carrinho",
          title: "Escolha o tratamento de ajuste à luz",
          subtitle:
            "Seus óculos vêm com a melhor tecnologia Ray-Ban. Tratamento resistente a riscos e proteção UV incluídos.",
          confirmedTitle: "Tratamento:",
          extraTreatments: {
            antiScratch: {
              name: "Antirrisco",
              title: "Resistente a riscos",
            },
            antiReflective: {
              value: "Antirreflexo",
            },
            uvProtection: {
              title: "Proteção UV",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "Interno",
          outdoor: "Ar livre",
        },
        lensColor: {
          confirmedTitle: "Cor:",
          detailBox: {
            lightTransmission: "Transmissão da luz",
            lightConditions: "Condições de iluminação",
            contrast: "Contraste",
            baseLensColor: "Cor da lente base",
          },
          categories: {
            "Ugly category": {
              title: "Categoria feio",
            },
            "Cool category": {
              title: "Categoria legal",
            },
          },
        },
        color: {
          confirmedTitle: "Cor:",
          title: "Escolha a cor de suas lentes",
          titleMobile: "Escolha a cor de suas lentes",
          titleDesignTypeLayout: "Escolha a tecnologia de suas lentes",
          titleColorCategoryLayout: "Escolha seu tipo de lente",
          polarized: "Apenas as polarizadas",
          applyAndContinue: "Aplicar",
          subtitle:
            "Cores e contraste incrementados, para que você possa ver com maiores detalhes",
          tooltip: {
            switch:
              "Reduz o reflexo com luz muito intensa, para uma excelente nitidez e proteção dos olhos.",
            polarized:
              "Reduz o reflexo com luz muito intensa, para uma excelente nitidez e proteção dos olhos.",
            antiReflective:
              "Reduz o reflexo e fornece um escudo invisível. É resistente a reflexo, poeira e manchas para uma visão panorâmica mais clara.",
            uvProtection:
              "Camadas de proteção rígidas à frente e atrás das lentes para máxima proteção contra raios UV e riscos.",
            antiScratch:
              "Protege a superfície de suas lentes de fortes impactos",
            blueLight:
              "Reduz a exposição à luz azul emitida pelas telas digitais e raios do sol, e assim ajuda a conter a fadiga ocular",
          },
        },
        addOns: {
          confirmedTitle: "Acabamentos:",
          label: "Já incluídos em suas lentes",
          title: "Complete suas lentes",
          reviewTitle: "Acabamentos:",
          subtitle:
            "Seus óculos vêm com a melhor tecnologia Ray-Ban. Resistentes a riscos e proteção UV incluídos.",
        },
        review: {
          title: "Confira sua seleção",
          subtitle: "Seus óculos vêm com a melhor tecnologia Ray-Ban.",
          discountTitle:
            "40% de desconto em lentes de grau aplicado automaticamente",
          firstLineReview: "Armação:",
          paymentInstallments: {
            installmentsLabel:
              "Pagar ao longo do tempo em parcelas sem juros com ###PAYMENT_TYPE###",
            modal: {
              title: "Compre agora. Pague mais tarde.",
              subtitle:
                "Selecione ###PAYMENT_TYPE### como seu meio de pagamento no check-out para pagar em parcelas sem juros, sem taxas ocultas.",
              affirm:
                "Com ###PAYMENT_TYPE###, pague em ###INSTALLMENTS### parcelas sem juros de ###MONTHLY_PRICE###",
              afterpay:
                "Com ###PAYMENT_TYPE###, pague em ###INSTALLMENTS### parcelas sem juros de ###MONTHLY_PRICE###",
              klarna:
                "Com ###PAYMENT_TYPE###, pague em ###INSTALLMENTS### parcelas sem juros de ###MONTHLY_PRICE###",
              footer:
                "Você deve ser maior de 18 anos, residente nos Estados Unidos, e satisfazer critérios de elegibilidade adicionais para estar qualificado. Podem ser aplicadas taxas posteriores. Os empréstimos concedidos ou acordados aos residentes da Califórnia estão de acordo com uma licença da Lei de Empréstimos Financeiros da Califórnia.",
              exitButton: "Entendi!",
            },
          },
          insuranceButtonLabel: "Adicionar benefícios de seguro",
          insuranceButtonLabelRemove: "Adicionar benefícios de seguro",
          oneYearCoverageAdd: "Adicionar",
          tooltip:
            "Assegure seus óculos com uma garantia estendida para conserto e substituição ilimitados em qualquer loja LensCrafters.",
          seeDetails: "Ver os detalhes",
        },
      },
      prescription: {
        toggleLabel:
          "Adicione os valores de sua receita e nós o ajudaremos a encontrar a lente perfeita para suas necessidades de visão.",
        prescriptionTitle:
          "Adicione os valores de sua receita e nós lhe mostraremos a lente perfeita para suas necessidades de visão.",
        rightEye: "(Olho direito)",
        rightEyeInitials: "OD",
        leftEye: "(Olho esquerdo)",
        leftEyeInitials: "OE",
        readPrescription: "Como ler sua receita",
        addLabel: "Também tenho um valor de adição",
        applyButton: "Aplicar e continuar",
        submitButton: "Submeter",
        clearButton: "Limpar tudo",
        skipButton: "Pular por enquanto",
        compatibleFramesButton: "Compre armações compatíveis",
        doctorDetails:
          "Pediremos uma cópia de sua receita ou os dados do médico durante ou após o check-out",
        missingValueError: "Adicione seus valores para continuar",
        positiveNegativeError:
          "Você inseriu um valor positivo e um valor negativo, o que é bastante raro. Sugerimos que verifique novamente sua receita.",
        compatibleFrameError:
          "Ops! A armação que escolheu não é compatível com sua receita.",
        compatibleFrameSubLabel:
          "Mas não se preocupe, estão disponíveis armações compatíveis.",
        axisError: "Lembre-se de adicionar seus valores de EIXO",
        axisSubError: "Estes valores devem estar entre 0 e 180.",
        axisSubErrorZero:
          "Se o valor de cilíndrico for maior ou menor de 0, os valores de EIXO não podem ser 0.",
        editLabel: "Editar receita",
        pdHelp: "O que é isso?",
        twoPDNumbers: "Eu tenho dois valores de DP",
        missingInputMessage:
          "Adicione os valores ausentes para completar o carregamento de sua receita e recomendaremos a lente mais adequada.",
        defaultInputMessage:
          "Escolha para adicionar sua receita agora ou carregar mais tarde.",
        skipMessage: "",
        readyMessage: "Agora podemos sugerir a lente certa para você!",
        bestPrescription: "A melhor para sua receita",
        proceedCheckbox:
          "Clicando nessa caixa, confirmo que os valores de receita digitados acima são retirados de uma receita escrita não expirada emitida para mim, assinada por um optometrista ou oftalmologista licenciado.",
        proceedCheckboxError: "Necessário para continuar",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Preço da armação + lentes",
          frame: "Preço da armação",
          total: "Total",
        },
        free: "Gratuito",
        included: "Incluído",
        frameLensStartingAt: "Armação + Lentes por",
        frameLens: "Armação + Lentes",
        startingAt: "A partir de",
        vatIncluded: "Imposto incluído",
        discountBadgePercentage: "###VALUE######SYMBOL### OFF",
        discountBadgeAmount: "###VALUE######SYMBOL### OFF",
      },
      noTreatment: "Nenhum tratamento",
      learnMoreMade: "Veja como é feito",
      learnMoreTreatment: "Veja o tratamento",
      exitTitle: "Tem certeza que deseja sair?",
      exitSubtitle: "Sua seleção de lentes não será salva",
      exitYes: "Sim, sair",
      exitContinueEditing: "Não, continuar",
      exitSave: "Salve e continue comprando",
      insuranceButtonLabel: "Adicionar benefícios de seguro",
      insuranceLabelRemove: "Remover benefícios",
      insuranceButtonLabelRemove: "Remover benefícios de seguro",
      insuranceSyncedLabel: "O seguro está sincronizado",
      applyingInsurance: "Aplicando benefícios de seguro",
      frameSize: "Tamanho armação",
      fallbackImageMessageTitle:
        "A pré-visualização da cor da lente não está disponível",
      fallbackImageMessageSubtitle:
        "Não se preocupe, receberá sua armação com a cor da lente que escolheu.",
      insuranceErrorModal: {
        title: "Algo deu errado...",
        content:
          "Não foi possível aplicar seus benefícios de seguro. Seu plano de seguro será automaticamente não sincronizado a fim de continuar.",
        confirm: "Confirmar",
      },
      grayOutBannerMessage:
        "Algumas opções podem ser desativadas por não serem compatíveis com seus valores de receita.",
      proceedAsIs: "Continuar assim como está",
      yourSelections: "Suas seleções",
      editButton: "Modificar",
      error: {
        title: "Algo deu errado...",
        description:
          "Desculpe, estamos com algumas dificuldades técnicas. Enquanto isso, se você tiver alguma dúvida ou precisar de assistência, entre em <a href='#'>contato com nosso serviço de atendimento.</a>",
        buttonLabel: "Voltar",
      },
    },
    no_NO: {
      steps: {
        prescription: {
          title: "Legg til brilleseddelverdiene dine",
          description:
            "Ellers forslår vi riktig brilleglas etter ditt synsbehov, slik at du kan fortsette uten problemer",
        },
        advancedPrescription: {
          confirmedTitle: "Prescription",
          title: "Har du brilleseddel?",
          manual: {
            addPrismValues: "Legg til prismeverdier",
            review: {
              title: "Angitt manuelt",
            },
            title: "Angi brilleseddel",
            card: {
              title: "Angi manuelt",
              description:
                "Dette er vår raskeste og mest populære metode. Vi vil da anbefale det brilleglasset som passer best.",
            },
            subtitle:
              "Legg til brilleseddelverdiene dine, så anbefaler vi det beste brilleglasset for dine synsbehov.",
            rightEye: {
              initials: "OD",
              name: "(Høyre øye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Venstre øye)",
            },
            sphere: "Kule (SPH)",
            cylinder: "Sylinder (CYL)",
            axis: "Akse",
            add: "ADD",
            vertical: "Loddrett (Δ)",
            baseDirection: "Baseretning",
            horizontal: "Vannrett (Δ)",
            pupillaryDistance: "PD (Pupillavstand)",
            pupillaryDistanceSubtitle: `Du kan velge standardinnstillingene <b>61 for kvinner</b> og <b>64 for menn</b> hvis du bruker en gjennomsnittlig eller liten styrke. Hvis du bruker stor styrke (eller vil vite nøyaktig pupillavstand), må du <a  data-element-id=\"X_X_LensPanel_AdvancedPrescription-MeasurePD\">måle pupillavstanden din</a>`,
            pdLeft: "Venstre",
            pdRight: "Høyre",
            iHaveTwoPd: "Jeg har to PD-numre",
            applyButton: "Fortsett",
            howToRead: "Slik leser du brilleseddelen din",
            whatIsIt: "Hva er det?",
            pupillaryDistanceWarningValue:
              "Skriv inn pupillavstanden din. Hvis du ikke kjenner til denne, kan du bruke standarden ovenfor eller ",
            pupillaryDistanceMisurePDAction: "måle pupillavstanden din.",
            pupillaryDistanceWarningTooltip:
              "Angi tekst for dette verktøytipset",
            moreOptions: "Flere alternativer",
            commentsTitle: "Kommentarer",
            alerts: {
              axisRangeError:
                "AKSE-verdiene du har angitt er ikke riktige! Disse verdiene må være mellom 1 og 180.",
              axisZeroError:
                "AKSE-verdiene du har angitt er ikke riktige! Hvis CYL-verdien er høyere eller lavere enn 0, kan ikke AKSE-verdiene være 0.",
              sphPositiveNegativeWarning:
                "Du har satt inn én positiv og én negativ verdi, noe som er svært sjeldent. Vi foreslår at du dobbeltsjekker brilleseddelen din. Hvis dette er riktig, kan du fortsette.",
              pdValueWarning:
                "Valgt PD er utenfor det normale området. Vi foreslår at du dobbeltsjekker brilleseddelen din. Du kan la standardinnstillingen være 63, som er den gjennomsnittlige målingen for voksne. Hvis vi trenger mer informasjon om brilleseddelen din, vil en av våre eksperter ta kontakt.",
              pdValueWarning2:
                "Valgt pupillavstand er mindre enn gjennomsnittet, så vi anbefaler at du dobbeltsjekker styrken din. Hvis du ikke kjenner til denne, kan du legge til verdiene ovenfor, eller ",
              missingValues: "Fullfør de manglende verdiene",
              requiredToProceed: "Dette er nødvendig for å fortsette",
              incompatibleWithPrescriptionTitle:
                "Beklager! Innfatningen du har valgt er ikke kompatibel med brilleseddelen din",
              incompatibleWithPrescriptionBody:
                "men slapp av, det finnes kompatible innfatninger",
              incompatibleWithPackagesTitle:
                "Beklager! Brilleseddelen du har valgt er ikke kompatibel med noen av de tilgjengelige brilleglassene våre for denne innfatningen",
              shopCompatible: "Kjøp kompatible innfatninger",
              clearAll: "Tøm alle",
              agreementText:
                "Ved å klikke på denne boksen, bekrefter jeg at brilleseddelverdiene som er angitt ovenfor er hentet fra en gyldig (ikke utløpt) brilleseddel utstedt til meg, signert av en lisensiert optiker eller øyelege.",
            },
            continueModal: {
              title: "Send brilleseddel senere",
              message:
                "Ved å klikke på fortsett, hopper du over brilleseddeltrinnet. Vi vil be om det etter bestillingen din, enten ved å laste den opp eller ved at vi ringer legen din.",
              continue: "Ja, fortsett",
              cancel: "Nei, gå tilbake",
            },
            digitalOptometry: {
              title: "Slik måler du pupillavstand",
              appSubtitle:
                "Pupillavstanden er avstanden i millimeter (mm) fra midten av en pupill til midten av den andre. Den angir nøyaktig hvilken del av brilleglasset du ser gjennom, og sikrer optimal komfort og klarhet. Du kan måle med appen eller manuelt.",
              manuallySubtitle:
                "Pupillavstanden er avstanden i millimeter (mm) fra midten av en pupill til midten av den andre. Du kan måle med appen eller manuelt.",
              toggle: {
                app: "med appen",
                manually: "manuelt",
              },
              opthyDesc:
                "<b>Få helt riktige briller med Opthy.</b><br/><p>Opthy måler pupillavstanden din, slik at du kan finne den mest komfortable og personlige passformen.</p><p>Appen er tilgjengelig på iPhone X og nyere.</p>",
              manuallyContent:
                "<div>Hvis du vil, kan du selv måle pupillavstanden på følgende måte:</div><ul><li>Ta en liten linjal i den ene hånden og ha papir og blyant klart.</li><li>Stå ca. 20 cm fra et speil.</li><li>Lukk venstre øye og legg 0 mm på linjalen over midten til høyre pupill.</li><li>Mål avstanden fra høyre til venstre pupill.</li><li>Tallet som er like over venstre pupill er pupillavstanden din (gjennomsnittlig pupillavstand for voksne er mellom 58 og 65 mm).</li></ul>",
            },
            manualPreviewTitle: "Angi brilleseddelinformasjon",
            seeDetails: {
              sphere: "SPH",
              cylinder: "CYL",
              axis: "Akse",
              add: "ADD",
              pd: "PD",
              od: "OD",
              os: "OS",
              vertical: "Loddrett prisme (Δ)",
              baseDirection: "Baseretning",
              horizontal: "Vannrett prisme (Δ)",
            },
          },
          account: {
            card: {
              title: "Hent det fra kontoen min",
              description: "Logg inn og velg brilleseddel.",
            },
            review: {
              title: "Lastet fra kontoen",
            },
          },
          upload: {
            title: "Din brilleseddel",
            subtitle:
              "Før du sender inn brilleseddelen, må du sjekke utløpsdatoen for å sikre at den fortsatt er gyldig.",
            card: {
              title: "Last den opp nå",
              description:
                "Så tar vi oss av resten. Vi godtar følgende filformater: ###FILE_FORMATS### (maks ###FILE_SIZE### MB) og IWork-sider",
            },
            review: {
              title: "Lastet opp",
            },
            uploadDifferentFile: "Last opp en annen fil",
            changeMethod: "Endre sendemetode",
            prescriptionUploaded: "Brilleseddel lastet opp",
            somethingWentWrong: "Beklager, noe gikk galt.",
            fileTooBigErrorTitle: "Filen er for stor",
            fileTooBigErrorDescription:
              "Filstørrelsen er for stor. Maksimal opplasting er ###FILE_SIZE### MB",
            tryAgain: "Prøv på nytt",
            upload: "Last opp",
            pdForm: {
              pupillaryDistance: "PD (Pupillavstand)",
              whatIsIt: "Hva er det?",
              pdValueWarning:
                "Valgt PD er utenfor det normale området. Vi foreslår at du dobbeltsjekker brilleseddelen din. Du kan la standardinnstillingen være 63, som er den gjennomsnittlige målingen for voksne. Hvis vi trenger mer informasjon om brilleseddelen din, vil en av våre eksperter ta kontakt.",
              pdLeft: "Venstre",
              pdRight: "Høyre",
              iHaveTwoPd: "Jeg har to PD-numre",
            },
            confirmAndContinue: "Bekreft og fortsett",
            filePreviewTitle: "Din brilleseddel",
          },
          doctor: {
            title: "Legg til legedetaljene dine",
            subtitle:
              "Det må være den samme legen som utstedte og signerte brilleseddelen din. Vi ringer legen for å bekrefte opplysningene dine.",
            searchBy: "Søk etter øyelegen din etter",
            card: {
              title: "Ring legen min",
              description:
                "Vi henter brilleseddelen din direkte fra ham/henne.",
            },
            review: {
              title: "Vi ringer legen din",
            },
            addNewDoctorModal: {
              title: "Legg til ny lege",
              clinicOrDoctorName: "Klinikk- eller legenavn",
              phoneNumber: "Telefonnummer",
              addDoctor: "Legg til lege",
            },
            slider: {
              phone: "Telefonnummer",
              clinicOrDoctorName: "Klinikk- eller legenavn",
            },
            table: {
              doctorName: "Navn på lege",
              clinicName: "Navn på klinikk",
              address: "Adresse",
              chooseButton: "Velg",
              phone: "Telefon",
              fax: "Faks",
              phoneNumber: "Telefonnummer",
              changeButton: "Endre",
              wrongDoctor: "Feil lege?",
            },
            pdForm: {
              pupillaryDistance: "PD (Pupillavstand)",
              whatIsIt: "Hva er det?",
              pdValueWarning:
                "Valgt PD er utenfor det normale området. Vi foreslår at du dobbeltsjekker brilleseddelen din. Du kan la standardinnstillingen være 63, som er den gjennomsnittlige målingen for voksne. Hvis vi trenger mer informasjon om brilleseddelen din, vil en av våre eksperter ta kontakt.",
              pdLeft: "Venstre",
              pdRight: "Høyre",
              iHaveTwoPd: "Jeg har to PD-numre",
            },
            addManuallyOption: {
              didntFind: "Fant du ikke legen din?",
              addManually: "Legg til manuelt",
            },
            missingInfoError: "Fyll ut den manglende informasjonen",
            doctorNotFoundBoxMessage: "Vi fant ikke legen din.",
            doctorNotFoundBoxMessageAdd: "Legg til flere detaljer eller",
            doctorNotFoundBoxMessageLink: "legg til en ny lege.",
            phoneNumber: "Telefonnummer til lege",
            search: "Søk",
            clinicOrDoctorName: "Klinikk- eller legenavn",
            city: "By",
            state: "Stat",
            changeSubmissionMethod: "Endre innsendingsmetode",
            confirmAndContinue: "Bekreft og fortsett",
            selectedDoctorPageTitle: "Dine legedetaljer",
            doctorPreviewTitle: "Vi ringer legen din",
          },
          later: {
            card: {
              title: "Send den senere",
              description:
                "Vi vil be om det etter bestillingen din, enten ved å laste den opp eller ved at vi ringer legen din.",
            },
            review: {
              title: "Vi ber om det etter betaling",
            },
          },
          californian: {
            toggle: "Er du bosatt i California?",
            info: "I tillegg til å legge til styrkeverdier manuelt, må innbyggere i California også overføre gyldig styrke elektronisk. Velg et alternativ for å fortsette.",
            chooseTitle: "VELG HVORDAN DU SKAL SENDE STYRKEN",
            uploadTitle: "Last opp",
            uploadDesc:
              "Vi godtar følgende filformater: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (maks. 10 MB) og IWork-sider.",
            uploadButton: "Bla gjennom",
            callMyDoc: "Ring legen min",
            callMyDocButton: "FINN LEGEN DIN",
            yes: "JA",
            no: "NEI",
            modalMissingPrescription: {
              title: "Manglende styrkeverdier",
              subtitle:
                "Hvis du ikke legger inn verdiene, kan vi ikke foreslå riktige brilleglass for deg",
              bottonYes: "JA, FORTSETT",
              bottonNo: "NEI, GÅ TILBAKE",
            },
          },
        },
        type: {
          title: "Velg ditt synsbehov",
          confirmedTitle: "Synsbehov:",
          prescriptionTitle: "Eller",
          addToCart: "Legg til i handlekurv",
          moreInfo: "Mer informasjon",
        },
        brand: {
          title: "Velg brilleglassmerke",
          confirmedTitle: "Brilleglassmerke:",
        },
        treatmentsFamily: {
          confirmedTitle: "Type brilleglass:",
          title: "Velg brilleglasstype",
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert.",
          blueLight: {
            title: "Blålysfiltrering",
            description:
              "Reduserer eksponering mot blått lys fra digitale skjermer, noe som kan bidra til å forhindre tretthet i øynene.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Transitions®-brilleglass blir raskt mørkere når de utsettes for sollys og går tilbake til klare når du er innendørs. Velg mellom en rekke farger. Blålysfilter inkludert.",
            imageFileName: "",
          },
          clear: {
            title: "Klart",
            description:
              "Tradisjonelle, gjennomsiktige brilleglass som er perfekte til hverdagsbruk",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description:
              "Velg mellom forskjellige brilleglassfarger og -nyanser.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Velg en prisgunstig pakke",
          subtitle:
            "Brillene dine vil komme med førsteklasses Oakley-teknologi.",
          confirmedTitle: "Brilleglasspakke:",
        },
        thickness: {
          confirmedTitle: "Tykkelse::",
          title: "Velg brilleglasstykkelse",
          subtitle:
            "Brillene dine vil komme med førsteklasses LensCrafters-teknologi.",
          designTypeTitle: "Velg brilleglassdesign",
        },
        treatments: {
          addToBag: "LEGG I HANDLEKURV",
          title: "Velg en lysresponsiv behandling",
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig behandling og UV-beskyttelse er inkludert.",
          confirmedTitle: "Behandling:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-riper",
              title: "Ripebestandig",
            },
            antiReflective: {
              value: "Anti-reflekterende",
            },
            uvProtection: {
              title: "UV-beskyttelse",
            },
          },
        },
        transitionColor: {
          title: "Overgangsfarge",
          indoor: "Innendørs",
          outdoor: "Utendørs",
        },
        lensColor: {
          confirmedTitle: "Farge:",
          detailBox: {
            lightTransmission: "Lysoverføring",
            lightConditions: "Lysforhold",
            contrast: "Kontrast",
            baseLensColor: "Grunnfarge på brilleglassene",
          },
          categories: {
            all: {
              title: "Alle",
            },
            "Ugly category": {
              title: "Stygg kategori",
            },
            "Cool category": {
              title: "Kul kategori",
            },
          },
        },
        color: {
          confirmedTitle: "Farge:",
          title: "Velg brilleglassfarge",
          titleMobile: "Velg brilleglassfarge",
          titleDesignTypeLayout: "Velg brilleglassteknologi",
          titleColorCategoryLayout: "Velg brilleglasstype",
          polarized: "Kun polarisert",
          applyAndContinue: "Bruk",
          subtitle:
            "Forsterkede farger og kontrast slik at du kan se flere detaljer",
          tooltip: {
            switch:
              "Reduserer gjenskinn i ekstra sterkt lys, for overlegen klarhet og beskyttelse av øyet.",
            polarized:
              "Reduserer gjenskinn i ekstra sterkt lys, for overlegen klarhet og beskyttelse av øyet.",
            antiReflective:
              "Reduserer refleksjoner og gir et usynlig skjold. Det motstår gjenskinn, støv og flekker for generelt klarere syn.",
            uvProtection:
              "Harde beskyttende lag foran og bak på brilleglassene for maksimal UV- og ripebeskyttelse.",
            antiScratch: "Beskytter brilleglassoverflaten mot harde støt",
            blueLight:
              "Reduserer eksponeringen av blått lys fra digitale skjermer og solstråler som kan bidra til å redusere trøtte øyne",
          },
        },
        addOns: {
          confirmedTitle: "Sluttbehandling:",
          label: "Allerede inkludert i brilleglassene",
          title: "Fullfør brilleglassene",
          reviewTitle: "Sluttbehandling:",
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert.",
        },
        review: {
          title: "Se gjennom valgene dine",
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi.",
          discountTitle:
            "40 % avslag på reseptbelagte brilleglass påføres automatisk",
          firstLineReview: "Innfatning:",
          paymentInstallments: {
            installmentsLabel:
              "Betal over tid i rentefrie avdrag med ###PAYMENT_TYPE###",
            modal: {
              title: "Betaling i avdrag",
              subtitle:
                "Vil du spre ut kostnadene for bestillingen din med ###BRAND###? Velg ###PAYMENT_TYPE### som betalingsmetode i kassen for å betale i rentefrie avdrag, uten skjulte gebyrer.",
              affirm:
                "Med Affirm foretar du tre rentefrie betalinger annenhver uke med null skjulte gebyrer. Betalingsalternativer tilbys av Affirm, er underlagt en kvalifiseringskontroll og er kanskje ikke tilgjengelig i alle stater. CA-innbyggere: Lån av Affirm Loan Services, LLC inngås eller arrangeres i henhold til en California Finance Lender-lisens.",
              klarna:
                "Med ###PAYMENT_TYPE###, gjør ###INSTALLMENTS### rentefrie avdrag på ###MONTHLY_PRICE###",
              afterpay:
                "Med Afterpay foretar du fire rentefrie betalinger annenhver uke. Du må være over 18 år, bosatt i USA og oppfylle ytterligere kvalifikasjonskriterier for å være kvalifisert. Gebyrer for sen betaling kan påløpe. Klikk her for fullstendige vilkår. Lån til innbyggere i California som inngås eller arrangeres gjøres i henhold til en California Finance Lenders Law-lisens.",
            },
          },
          insuranceButtonLabel: "Legg til forsikringsfordeler",
          insuranceButtonLabelRemove: "Fjern forsikringsfordeler",
          oneYearCoverageAdd: "Legg til",
          tooltip:
            "Sikre brillene med utvidet garanti for ubegrenset reparasjon og utskifting i enhver LensCrafters-butikk.",
          seeDetails: "Se detaljer",
        },
      },
      prescription: {
        toggleLabel:
          "Legg til brilleseddelverdiene dine, så hjelper vi deg med å finne de perfekte brilleglassene for dine synsbehov.",
        prescriptionTitle:
          "Legg til brilleseddelverdiene dine, så viser vi deg de perfekte brilleglassene for dine synsbehov.",
        rightEye: "(Høyre øye)",
        rightEyeInitials: "OD",
        leftEye: "(Venstre øye)",
        leftEyeInitials: "OS",
        readPrescription: "Slik leser du brilleseddelen din",
        addLabel: "Jeg har også en ADD-verdi",
        applyButton: "Bruk og fortsett",
        submitButton: "Send inn",
        clearButton: "Tøm alle",
        skipButton: "Hopp over foreløpig",
        compatibleFramesButton: "Kjøp kompatible innfatninger",
        doctorDetails:
          "Vi ber om en kopi av brilleseddelen eller legens detaljer under eller etter utsjekking",
        missingValueError: "Legg til verdiene dine for å fortsette",
        positiveNegativeError:
          "Du har angitt én positiv verdi og én negativ verdi, noe som er ganske sjeldent. Vi foreslår at du dobbeltsjekker brilleseddelen din.",
        compatibleFrameError:
          "Beklager! Innfatningen du har valgt er ikke kompatibel med brilleseddelen din.",
        compatibleFrameSubLabel:
          "Men slapp av, det finnes kompatible innfatninger.",
        axisError: "Husk å legge til AKSE-verdiene",
        axisSubError: "Disse verdiene må være mellom 0 og 180.",
        axisSubErrorZero:
          "Hvis CYL-verdien er høyere eller lavere enn 0, kan ikke AKSE-verdiene være 0.",
        editLabel: "Rediger brilleseddel",
        pdHelp: "Hva er dette?",
        twoPDNumbers: "Jeg har to PD-verdier",
        missingInputMessage:
          "Legg til dine manglende verdier for å fullføre opplastingen av brilleseddelen din, så anbefaler vi brilleglassene som passer best.",
        defaultInputMessage:
          "Velg å legge til brilleseddelen din nå, eller last den opp senere.",
        skipMessage: "",
        readyMessage: "Nå kan vi foreslå riktig brilleglass til deg!",
        bestPrescription: "Best til brilleseddelen din",
        proceedCheckbox:
          "Ved å klikke på denne boksen, bekrefter jeg at brilleseddelverdiene angitt ovenfor er hentet fra en gyldig (ikke utløpt) brilleseddel utstedt til meg, signert av en lisensiert optiker eller øyelege.",
        proceedCheckboxError: "Nødvendig for å fortsette",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Pris på innfatning + brilleglass",
          frame: "Pris på innfatning",
          total: "Totalt",
        },
        free: "Gratis",
        included: "Inkludert",
        frameLensStartingAt: "Innfatning + brilleglass til",
        frameLens: "Innfatning + brilleglass",
        startingAt: "Fra",
        vatIncluded: "Inkludert mva",
        discountBadgePercentage: "###VALUE######SYMBOL",
        discountBadgeAmount: "###VALUE######SYMBOL### AV",
      },
      noTreatment: "Ingen behandling",
      learnMoreMade: "Se hvordan det lages",
      learnMoreTreatment: "Se behandlingen",
      exitTitle: "Er du sikker på at du vil avslutte?",
      exitSubtitle: "Valget av brilleglass blir ikke lagret",
      exitYes: "Ja, avslutt",
      exitContinueEditing: "Nei, fortsett",
      exitSave: "Lagre og fortsett å handle",
      insuranceButtonLabel: "Legg til forsikringsfordeler",
      insuranceLabelRemove: "Fjern fordeler",
      insuranceButtonLabelRemove: "Fjern forsikringsfordeler",
      insuranceSyncedLabel: "Forsikringen er synkronisert",
      applyingInsurance: "Bruke forsikringsfordeler",
      frameSize: "Innfatningsstørelse",
      fallbackImageMessageTitle:
        "Forhåndsvisning av brilleglassfarge er ikke tilgjengelig",
      fallbackImageMessageSubtitle:
        "Ingen problem, du vil motta innfatningen med brilleglassfargen du valgte.",
      insuranceErrorModal: {
        title: "Noe gikk galt…",
        content:
          "Vi kunne ikke bruke forsikringsfordelene dine. Synkronisering av forsikringsplanen din vil automatisk bli opphevet for å kunne fortsette.",
        confirm: "Bekreft",
      },
      proceedAsIs: "Fortsett som det er",
      grayOutBannerMessage:
        "Noen alternativer kan være deaktivert, da de ikke er kompatible med brilleseddelverdiene dine.",
      yourSelections: "Dine valg",
      editButton: "Rediger",
      error: {
        title: "Noe gikk galt …",
        description:
          "Beklager, vi har tekniske problemer. Hvis du i mellomtiden har spørsmål eller trenger hjelp, ta gjerne <a href='#'>kontakt med vår kundeservice.</a>",
        buttonLabel: "Tilbake",
      },
      generic: {
        modal: {
          close: "",
        },
      },
    },
    sv_SE: {
      steps: {
        prescription: {
          title: "Slutför ditt glasögonrecept",
          description:
            "Eller så föreslår vi den rätta linsen för dina synbehov så att du slipper alla bekymmer",
        },
        advancedPrescription: {
          title: "Har du ett glasögonrecept?",
          manual: {
            title: "Mata in ditt glasögonrecept",
            card: {
              title: "Mata in det manuellt",
              description:
                "Detta är vår snabbaste och mest populära metod. Vi rekommenderar sedan den lämpligaste linstypen.",
            },
            subtitle:
              "Lägg till dina glasögonreceptvärden så kommer vi att rekommendera den lins som är mest lämplig för dina synbehov.",
            rightEye: {
              initials: "OD",
              name: "(Höger öga)",
            },
            leftEye: {
              initials: "OS",
              name: "(Vänster öga)",
            },
            sphere: "Sfär (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axel (AXIS)",
            add: "ADD",
            vertical: "Vertikal (Δ)",
            baseDirection: "Basriktning",
            horizontal: "Horisontell (Δ)",
            pupillaryDistance: "PD (Pupillavstånd)",
            pdLeft: "Vänster",
            pdRight: "Höger",
            iHaveTwoPd: "Jag har två PD-värden",
            applyButton: "Fortsätt",
            howToRead: "Hur du läser ditt glasögonrecept",
            whatIsIt: "Vad är det?",
            pupillaryDistanceWarningTooltip:
              "Tillhandahåll text för detta verktygstips",
            moreOptions: "Fler alternativ",
            alerts: {
              axisRangeError:
                "AXIS-värdena som du har angett är inte korrekta! Dessa värden måste vara mellan 1 och 180.",
              axisZeroError:
                "AXIS-värdena som du har angett är inte korrekta! Om CYL-värdet är högre eller lägre än 0 kan AXIS-värdena inte vara 0.",
              sphPositiveNegativeWarning:
                "Du angav ett positivt och ett negativ värde vilket är mycket sällsynt. Vi rekommenderar att du dubbelkollar ditt glasögonrecept. Om det är rätt kan du fortsätta.",
              missingValues: "Fyll i de värden som saknas",
              requiredToProceed: "Det krävs för att få fortsätta",
              incompatibleWithPrescriptionTitle:
                "Tyvärr! Bågen som du har valt är inte kompatibel med ditt glasögonrecept.",
              incompatibleWithPrescriptionBody:
                "Inga problem, det finns kompatibla bågar.",
              shopCompatible: "Köp kompatibla bågar",
              clearAll: "Rensa alla",
              agreementText:
                "Genom att kryssa i denna ruta bekräftar jag att de glasögonreceptvärden som har fyllts i ovan är tagna från ett giltigt (inte utgånget) glasögonrecept som har utfärdats åt mig och undertecknats av en licensierad optiker eller ögonläkare.",
            },
            continueModal: {
              title: "Skicka glasögonrecept senare",
              message:
                "Genom att klicka på Fortsätt hoppar du över glasögonreceptsteget. Vi kommer att be om det efter din beställning, antingen genom att det laddas upp eller att vi ringer din optiker/ögonläkare.",
              continue: "Ja, fortsätt",
              cancel: "Nej, gå tillbaka",
            },
          },
          account: {
            card: {
              title: "Få det från mitt konto",
              description: "Logga in och välj ditt glasögonrecept.",
            },
          },
          upload: {
            title: "Ditt glasögonrecept",
            subtitle:
              "Kontrollera utgångsdatumet innan du skickar ditt glasögonrecept så att du vet att det fortfarande är giltigt.",
            card: {
              title: "Ladda upp det nu",
              description:
                "Vi tar hand om resten. Vi accepterar följande filformat: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max. 10 MB) och IWork-sidor",
            },
          },
          doctor: {
            title: "Lägg till din optikers/ögonläkares kontaktuppgifter",
            subtitle: "Vi hämtar dina receptuppgifter direkt från honom/henne",
            searchBy: "Sök efter din optiker/ögonläkare på",
            card: {
              title: "Ring min optiker/ögonläkare",
              description:
                "Vi hämtar ditt glasögonrecept direkt från honom/henne",
            },
          },
          later: {
            card: {
              title: "Skicka det senare",
              description:
                "Vi kommer att be om det efter din beställning, antingen genom att det laddas upp eller att vi ringer din optiker/ögonläkare.",
            },
          },
        },
        type: {
          title: "Välj dina synbehov",
          confirmedTitle: "Använd",
          prescriptionTitle: "Eller",
          addToCart: "Lägg till i kundvagn",
          moreInfo: "Mer info",
        },
        brand: {
          title: "Välj ditt linsmärke",
          confirmedTitle: "Varumärke",
        },
        treatmentsFamily: {
          confirmedTitle: "Linstyp:",
          title: "Välj din linstyp",
          subtitle:
            "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår.",
          blueLight: {
            title: "Blåljusfilter",
            description:
              "Minskar exponering för blått ljus från digitala skärmar vilket kan hjälpa till att förhindra ögontrötthet.",
            imageFileName: "",
          },
          transition: {
            title: "Transitions ® Signature ® GEN 8™",
            description:
              "Ett enda par för inomhus- och utomhusbruk med Transitions®-linser: Blir både mörka och klara snabbt så att du aldrig behöver byta glasögon.",
            imageFileName: "",
          },
          clear: {
            title: "Klara",
            description: "Traditionella genomskinliga linser för dagligt bruk",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description: "Välj mellan olika linsfärger och färgtoner.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Slutför ditt Prisvärda paket",
          subtitle: "Dina glasögon har förstklassig LensCrafters-teknik.",
          confirmedTitle: "Linspaket:",
        },
        thickness: {
          confirmedTitle: "Tjocklek",
          title: "Välj din linstjocklek",
          subtitle: "Dina glasögon har förstklassig LensCrafters-teknik.",
          designTypeTitle: "Välj din linsdesign",
        },
        treatments: {
          addToBag: "Lägg till i varukorg",
          title: "Slutför din lins",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandlingar",
          extraTreatments: {
            antiScratch: {
              name: "Reptålighet",
              title: "Reptålighet",
            },
            antiReflective: {
              value: "Antireflektionsbehandling",
            },
            uvProtection: {
              title: "UV-skydd och reptålighet",
            },
          },
        },
        transitionColor: {
          title: "Övergångsfärg",
          indoor: "INOMHUS",
          outdoor: "UTOMHUS",
        },
        lensColor: {
          confirmedTitle: "Färg:",
          detailBox: {
            lightTransmission: "Ljusgenomsläpplighet",
            lightConditions: "Ljusförhållanden",
            contrast: "Kontrast",
            baseLensColor: "Baslinsfärg",
          },
          categories: {
            "Ugly category": {
              title: "Kategori Ugly",
            },
            "Cool category": {
              title: "Kategori Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Färg:",
          title: "Välj din linsfärg",
          titleMobile: "Välj din linsfärg",
          titleDesignTypeLayout: "Välj din linsteknik",
          titleColorCategoryLayout: "Välj din linstyp",
          polarized: "Endast polariserande",
          applyAndContinue: "Applicera",
          subtitle: "Förbättrad färg och kontrast så att du kan se tydligare",
          tooltip: {
            switch:
              "Minskar bländning i extra starkt ljus för ökad klarhet och skydd av ögat.",
            polarized:
              "Minskar bländning i extra starkt ljus för ökad klarhet och skydd av ögat.",
            antiScratch: "Skyddar ytan på din lins mot kraftig yttre påverkan.",
            blueLight:
              "Minskar exponering för blått ljus från digitala skärmar och solens strålar vilket kan hjälpa till att förhindra ögontrötthet.",
          },
        },
        addOns: {
          confirmedTitle: "Slutför dina linser",
          label: "Ingår redan i din lins",
          title: "Slutför dina linser",
          reviewTitle: "Ytfinishar",
          subtitle:
            "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår",
        },
        review: {
          title: "Granska dina val",
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik.",
          discountTitle: "40 % rabatt dras automatiskt av på slipade linser",
          firstLineReview: "Båge:",
          paymentInstallments: {
            installmentsLabel:
              "Betala över tid genom räntefria avbetalningar med ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Applicera försäkring",
          insuranceButtonLabelRemove: "Ta bort försäkringsförmåner",
          oneYearCoverageAdd: "Lägg till",
          tooltip:
            "Försäkra dina glasögon med en utökad garanti för obegränsad reparation och utbyte hos valfri LensCrafters-butik.",
        },
      },
      prescription: {
        toggleLabel:
          "Lägg till dina glasögonreceptvärden, så hjälper vi dig att hitta den perfekta linsen för dina synbehov.",
        prescriptionTitle:
          "Lägg till dina glasögonreceptvärden, så visar vi dig den perfekta linsen för dina synbehov.",
        rightEye: "(Höger öga)",
        rightEyeInitials: "OD",
        leftEye: "(Vänster öga)",
        leftEyeInitials: "OS",
        readPrescription: "Hur du läser ditt glasögonrecept",
        addLabel: "Jag har även ett ADD-värde",
        applyButton: "Applicera och fortsätt",
        submitButton: "Skicka",
        clearButton: "Rensa alla",
        skipButton: "Hoppa över tills vidare",
        compatibleFramesButton: "Köp kompatibla bågar",
        doctorDetails:
          "Vi kommer att be om en kopia på ditt glasögonrecept eller kontaktuppgifterna till din optiker under eller efter utcheckningen.",
        missingValueError: "Lägg till dina värden för att fortsätta",
        positiveNegativeError:
          "Du skrev in ett positivt värde och ett negativt värde, vilket är ganska sällsynt. Vi rekommenderar att du dubbelkollar ditt glasögonrecept.",
        compatibleFrameError:
          "Tyvärr! Bågen som du har valt fungerar inte med dina glasögonreceptvärden.",
        compatibleFrameSubLabel:
          "Inga problem, vi har bågar som passar ditt glasögonrecept.",
        axisError: "AXIS-värdena som du har angett är inte korrekta!",
        axisSubError: "Dessa värden måste vara mellan 0 och 180.",
        axisSubErrorZero:
          "Om CYL-värdet är högre eller lägre än 0, kan AXIS-värdena inte vara 0.",
        editLabel: "Redigera glasögonrecept",
        pdHelp: "Vad är detta?",
        twoPDNumbers: "Jag har två PD-nummer.",
        missingInputMessage:
          "Lägg till de av dina värden som saknas för att slutföra uppladdningen av ditt glasögonrecept så kommer vi att rekommendera den lins som är mest lämplig.",
        defaultInputMessage:
          "Lägg till dina SPH- och CYL-värden så kommer vi att rekommendera den lins som är mest lämplig för dina synbehov.",
        skipMessage:
          "Vi kommer att be dig slutföra eller ladda upp ditt glasögonrecept senare.",
        readyMessage: "Vi kan inte föreslå den rätta linsen för dig!",
        bestPrescription: "Bäst för ditt glasögonrecept",
        proceedCheckbox: "Du måste fortsätta",
        proceedCheckboxError: "Eller {0} betalningar av {1}{2} med",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Pris för båge + linser",
          frame: "Pris för båge",
          total: "Total",
        },
        free: "Gratis",
        included: "Inklusive",
        frameLensStartingAt: "Båge + Linser hos",
        frameLens: "Båge + Linser",
        startingAt: "Lägsta pris",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Se hur den görs",
      learnMoreTreatment: "Se behandlingen",
      exitTitle: "ÄR DET SÄKERT ATT DU VILL AVSLUTA?",
      exitSubtitle: "Ditt linsval kommer inte att sparas",
      exitYes: "Yes, exit",
      exitContinueEditing: "Fortsätt redigering",
      exitSave: "Spara och fortsätt handla",
      insuranceButtonLabel: "Lägg till försäkringsförmåner",
      insuranceButtonLabelRemove: "Ta bort försäkringsförmåner",
      insuranceSyncedLabel: "Försäkringsförmåner applicerade",
      applyingInsurance: "Applicera försäkringsförmåner",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Förhandsvisning av linsfärg finns inte",
      fallbackImageMessageSubtitle:
        "Inga problem, du kommer att få din båge med den linsfärg du har valt.",
      proceedAsIs: "Fortsätt härifrån",
      yourSelections: "Dina Val",
      editButton: "Redigera",
    },
    ja_JP: {
      steps: {
        prescription: {
          title: "処方箋を確定",
          description:
            "またはお客様が必要とする視界に適したレンズをお勧めし、迷わず選べます",
        },
        advancedPrescription: {
          title: "処方せんをお持ちですか？",
          manual: {
            title: "処方せんを送信",
            card: {
              title: "マニュアル入力",
              description:
                "最も早く最も人気の方法です。これによって最適なレンズタイプをお勧めできます。",
            },
            subtitle:
              "処方せん数値を追加し、お客様が必要とする最適なレンズをお勧めします。",
            rightEye: {
              initials: "OD",
              name: "（右眼）",
            },
            leftEye: {
              initials: "OS",
              name: "（左眼）",
            },
            sphere: "Sphere（スフィア） (SPH)",
            cylinder: "Cylinder（シリンダー） (CYL)",
            axis: "乱視軸",
            add: "加入度数",
            vertical: "垂直 (Δ)",
            baseDirection: "基底方向",
            horizontal: "水平 (Δ)",
            pupillaryDistance: "PD （瞳孔間距離）",
            pdLeft: "左",
            pdRight: "右",
            iHaveTwoPd: "PD値が2種あります",
            applyButton: "続ける",
            howToRead: "処方せんの読み方",
            whatIsIt: "これは何でしょう？",
            pupillaryDistanceWarningTooltip: "このツールチップに文章を入れる",
            moreOptions: "他のオプション",
            alerts: {
              axisRangeError:
                "入力した乱視軸の値は不正確です。この値は1から180の間になるはずです。",
              axisZeroError:
                "入力した乱視軸の値は不正確です。CYL値は0より上か下かで、乱視軸の値は0にはなりません。",
              sphPositiveNegativeWarning:
                "プラス値1つとマイナス値1つを入力されましたが、これは非常に稀なケースです。処方せんをもう一度確認するようにお勧めいたします。もしこれが正確な値の場合、次に進んでください。",
              missingValues: "未入力の値を入力してください",
              requiredToProceed: "先に進むにはこれが必須です",
              incompatibleWithPrescriptionTitle:
                "申し訳ありませんお選びのフレームはお客様の処方せん内容に適しません。",
              incompatibleWithPrescriptionBody:
                "ご心配には及びません、他にも適するフレームが揃っています。",
              shopCompatible: "適するフレームを購入",
              clearAll: "全て削除",
              agreementText:
                "このボックスをクリックすることで、上に入力した処方せん値は、有資格の検眼士か眼科医が記した私に適正な（失効したものではない）処方せんに基づくものだと認めます。",
            },
            continueModal: {
              title: "処方せんを後ほど送信する",
              message:
                "先に進むをクリックすることで、処方せんのステップを飛ばすことができます。ご注文後に書類をアップロードするか、主治医からお電話していただくようにお願いします。",
              continue: "了解して、先に進みます",
              cancel: "元に戻ります",
            },
          },
          account: {
            card: {
              title: "マイアカウントから入手する",
              description: "ログインして処方せんを選択してください。",
            },
          },
          upload: {
            title: "お客様の処方せん",
            subtitle:
              "処方せんを送信する前に、有効期限内かをもう一度確認してください。",
            card: {
              title: "今すぐアップロードする",
              description:
                "残りは全て当社にお任せください。ご利用いただけるファイル形式は次の通りです：.pdf、 .png、 .jpeg、 .gif、 .tiff、 .bmp、 .docx （最大10MB）、IWork pages",
            },
          },
          doctor: {
            title: "主治医の情報を入力してください",
            subtitle:
              "お医者様から直接処方せんを送信していただけるように手配します",
            searchBy: "眼科医の検索は",
            card: {
              title: "医師に電話する",
              description:
                "お医者様から直接処方せんをいただけるように手配します。",
            },
          },
          later: {
            card: {
              title: "後ほど送信",
              description:
                "ご注文後に書類をアップロードするか、主治医からお電話していただくようにお願いします。",
            },
          },
        },
        type: {
          title: "レンズの補正機能",
          confirmedTitle: "レンズの補正機能s",
          prescriptionTitle: "または",
          addToCart: "カートに追加",
          moreInfo: "詳細情報",
        },
        brand: {
          title: "レンズのブランドを選択",
          confirmedTitle: "ブランド",
        },
        treatmentsFamily: {
          confirmedTitle: "レンズタイプ:",
          title: "ご自分のレンズタイプを選択",
          subtitle:
            "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています。",
          blueLight: {
            title: "ブルーライト・フィルター",
            description:
              "電子スクリーンが発するブルーライトの照射を抑え、眼精疲労を予防します。",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "屋内でも屋外でもTransitions-トランジション® レンズ装着なら1点で兼用： ダークからクリアに素早く変化し、アイグラスをかけ替える必要がありません。",
            imageFileName: "",
          },
          clear: {
            title: "クリア",
            description: "伝統的なトランスペアレントレンズで日常使いに最適",
            imageFileName: "",
          },
          sun: {
            title: "サン",
            description: "豊富なレンズカラーや色調から選択。",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "グレートバリューパッケージを確定",
          subtitle:
            "お客様のアイグラスは最高ランクのLensCrafters技術を使っています。",
          confirmedTitle: "レンズパッケージ：",
        },
        thickness: {
          confirmedTitle:
            "レンズの厚さ（組み合わせによっては、自動的に決まる場合もあります）",
          title: "レンズの厚さを選択",
          subtitle:
            "お客様のアイグラスは最高ランクのLensCrafters技術を使っています。",
          designTypeTitle: "ご自分のレンズデザインを選択",
        },
        treatments: {
          addToBag: "カートに追加",
          title: "レンズを確定",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "加工",
          extraTreatments: {
            antiScratch: {
              name: "アンチスクラッチ",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "インドア",
          outdoor: "アウトドア",
        },
        lensColor: {
          confirmedTitle: "レンズテクノロジー＆カラー",
          detailBox: {
            lightTransmission: "可視光線透過率",
            lightConditions: "日照状況",
            contrast: "コントラスト",
            baseLensColor: "ベースレンズカラー",
          },
          categories: {
            "Ugly category": {
              title: "アグリーカテゴリー",
            },
            "Cool category": {
              title: "クールカテゴリー",
            },
          },
        },
        color: {
          confirmedTitle: "レンズテクノロジー＆カラー",
          title: "レンズカラーを選択",
          titleMobile: "レンズカラーを選択",
          titleDesignTypeLayout: "レンズテクノロジーを選択",
          titleColorCategoryLayout: "ご自分のレンズタイプを選択",
          polarized: "偏光レンズのみ",
          applyAndContinue: "確定",
          subtitle: "色調やコントラストを強調し細部までくっきり見せる",
          tooltip: {
            switch:
              "光量が特に多い条件で眩光を抑え、一際くっきり見せ眼を守ります。",
            polarized:
              "光量が特に多い条件で眩光を抑え、一際くっきり見せ眼を守ります。",
            antiScratch: "レンズ表面を強い衝撃から守ります ",
            blueLight:
              "電子スクリーンが発するブルーライト照射の影響を抑えることで、眼精疲労を和らげます。",
          },
        },
        addOns: {
          confirmedTitle: "付属オプション",
          label: "レンズに元々付属しているのは",
          title: "レンズに追加するには",
          reviewTitle: "フィニッシュ",
          subtitle:
            "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています",
        },
        review: {
          title: "選択中の内容",
          subtitle:
            "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。",
          discountTitle: "度付レンズの40%オフは自動的に適応されます。",
          firstLineReview: "フレーム:",
          paymentInstallments: {
            installmentsLabel: "###PAYMENT_TYPE###で無利子の分割払い",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "保険を適用する",
          insuranceButtonLabelRemove: "保険の恩恵を受けない",
          oneYearCoverageAdd: "追加する",
          tooltip:
            "アイウェアの延長保証は、全てのLensCrafters ストアで無制限の修理や交換を受けられます。",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "（右眼）",
        rightEyeInitials: "OD",
        leftEye: "（左眼）",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "フレーム価格",
          total: "Total",
        },
        free: "Free",
        included: "付",
        frameLensStartingAt: "フレーム + レンズを",
        frameLens: "フレーム + レンズ",
        startingAt: "Starting at",
      },
      noTreatment: "No treatment",
      learnMoreMade: "製造方法を見る",
      learnMoreTreatment: "加工を見る",
      exitTitle: "本当に終了しますか？",
      exitSubtitle: "お客様が選択したレンズは保存されません",
      exitYes: "Yes, exit",
      exitContinueEditing: "入力を続ける",
      exitSave: "保存してショッピングを続ける",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceButtonLabelRemove: "保険の恩恵を受けない",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "保険給付を受ける",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "レンズカラーのプレビュー表示非対応",
      fallbackImageMessageSubtitle:
        "ご心配なく。選択したカラーのレンズを装着したフレームをお届けします。",
      proceedAsIs: "このままで続ける",
      yourSelections: "あなたの選択",
      editButton: "編集",
    },
  },
};
