import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { prescriptionActions } from "../../redux/slices/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { usePupillaryDistance } from "@hooks";
import "./default.module.scss";

export interface PrescriptionUploadFormProps {
  fileInput: any;
  closePrescriptionForm: () => {};
  onSuccessPrescription: (prescription: any) => {};
  prescriptionObject: any;
}

const Subtitle = () => {
  const uploadSubtitle = useTranslate(
    "steps.advancedPrescription.upload.subtitle"
  );
  return (
    <>
      <div className={"PrescriptionUploadForm__subtitle"}>{uploadSubtitle}</div>
    </>
  );
};

const UploadResult = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadResult__container">
      {props.isSuccessful && (
        <>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99992 13.6666C11.1295 13.6666 13.6666 11.1295 13.6666 7.99992C13.6666 4.87031 11.1295 2.33325 7.99992 2.33325C4.87031 2.33325 2.33325 4.87031 2.33325 7.99992C2.33325 11.1295 4.87031 13.6666 7.99992 13.6666ZM7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z"
              fill="#247534"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.4451 6.15454C10.7062 6.39024 10.7415 6.81062 10.5239 7.09347L7.95984 10.4268C7.84916 10.5707 7.6877 10.6575 7.51498 10.666C7.34226 10.6745 7.17421 10.6038 7.05195 10.4714L5.51349 8.80474C5.27317 8.5444 5.27317 8.12229 5.51349 7.86194C5.75382 7.60159 6.14345 7.60159 6.38378 7.86194L7.44574 9.0124L9.57844 6.23989C9.79601 5.95704 10.1841 5.91883 10.4451 6.15454Z"
              fill="#247534"
            />
          </svg>

          <div className="PrescriptionUploadForm__UploadResult__successText">
            {props.message}
          </div>
        </>
      )}
      {!props.isSuccessful && (
        <>
          <div className="PrescriptionUploadForm__UploadResult__unsuccessfulIconContainer">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-9 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm0-2h2V8h-2v6z"
                fill="#9B0800"
              />
            </svg>
          </div>
          <div
            className={
              "PrescriptionUploadForm__UploadResult__errorText" +
              (props.uppercase ? " uppercase" : "")
            }
          >
            {props.message}
          </div>
        </>
      )}
    </div>
  );
};

const FilePreview = (props) => {
  const reduxDispatch = useDispatch();
  const [fileData, setFileData] = useState(null);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);

  const fallBackImageUrl =
    "https://assets.lenscrafters.com/extra/image/LensCrafters/projects/202005-rxreview/LC_UploadOK_Icon.png";

  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );

  useEffect(() => {
    if (props.forceFileInfo) {
      setFileData({
        url: props.forceFilePath,
        type: props.forceFileInfo.type,
        name: props.forceFileInfo.name,
        size: props.forceFileInfo.size,
      });
      if (props.forceFilePath) {
        setIsPreviewEnabled(true);
      }
    }
  }, [props.forceFileInfo, props.forceFilePath]);

  const handleFileChange = (file: any) => {
    const fileReader = new window.FileReader();
    fileReader.onload = (fileLoad) => {
      const { result } = fileLoad.target;
      setFileData({
        url: result,
        type: file.type.split("/")[1],
        name: file.name,
        size: file.size,
      });
      setIsPreviewEnabled(true);
    };
    fileReader.readAsDataURL(file);
  };

  const getSizeInMB = (size) => {
    return "[" + (parseInt(size) / 1024 / 1024).toFixed(2) + "MB]";
  };

  const setErrorImage = (e: any) => {
    setIsPreviewEnabled(false);
    e.target.src = fallBackImageUrl;
  };

  return (
    fileData && (
      <div className="PrescriptionUploadForm__FilePreview__container">
        <div
          className={
            "PrescriptionUploadForm__FilePreview__container__filePreview" +
            (isPreviewEnabled ? "" : " noPreview")
          }
          onClick={() => {
            if (isPreviewEnabled) {
              reduxDispatch(
                modalsActions.setShowPrescriptionUploadFilePreviewModal(true)
              );
              reduxDispatch(
                prescriptionActions.setUploadFileDataPreview(
                  props.forceFilePath ? props.forceFilePath : fileData.url
                )
              );
            }
          }}
        >
          {props.forceFilePath && (
            <img
              src={props.forceFilePath}
              alt="Document"
              onError={setErrorImage}
            />
          )}
          {!props.forceFilePath && (
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 30, height: 30 },
              }}
            />
          )}
        </div>
        <div className="PrescriptionUploadForm__FilePreview__container__fileInfo">
          <div className="PrescriptionUploadForm__FilePreview__container__fileInfo__nameAndSize">
            {fileData.name + " - " + getSizeInMB(fileData.size)}
          </div>
          <div
            className="PrescriptionUploadForm__FilePreview__container__fileInfo__uploadDifferent"
            onClick={() => {
              props.handleUploadAgain();
            }}
          >
            {uploadDifferentFile}
          </div>
        </div>
      </div>
    )
  );
};

const UploadError = (props) => {
  return (
    <div className="PrescriptionUploadForm__UploadError__container">
      <div className="PrescriptionUploadForm__UploadError__container__message">
        {props.message}
      </div>
      <button
        className="PrescriptionUploadForm__button"
        onClick={() => props.onButtonClick()}
      >
        {props.buttonText}
      </button>
    </div>
  );
};

const UploadBox = (props) => {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);

  const changeMethod = useTranslate(
    "steps.advancedPrescription.upload.changeMethod"
  );
  const prescriptionUploaded = useTranslate(
    "steps.advancedPrescription.upload.prescriptionUploaded"
  );
  const somethingWentWrong = useTranslate(
    "steps.advancedPrescription.upload.somethingWentWrong"
  );
  const fileTooBigErrorTitle = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorTitle"
  );
  const fileTooBigErrorDescription = useTranslate(
    "steps.advancedPrescription.upload.fileTooBigErrorDescription"
  );
  const tryAgain = useTranslate("steps.advancedPrescription.upload.tryAgain");
  const uploadDifferentFile = useTranslate(
    "steps.advancedPrescription.upload.uploadDifferentFile"
  );
  const upload = useTranslate("steps.advancedPrescription.upload.upload");

  const fileInputRef = useRef(null);

  useEffect(() => {
    //TODO chiamata
    uploadFile(props.file);
  }, [props.file]);

  const getFileTypeFromFullName = (fileName: string) => {
    let split = fileName.split(".");
    let lastElem = split[split.length - 1];
    return "." + lastElem;
  };

  useEffect(() => {
    if (props.preloadedPrescription) {
      setIsUploading(false);
      setIsSuccessful(true);
      setPreloadedFileInfo({
        name: props.preloadedPrescription.fileName,
        size: parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(props.preloadedPrescription.fileName),
      });
      props.onFileChange({
        name: props.preloadedPrescription.fileName,
        filePath: props.preloadedPrescription.filePath,
        fileSize:
          parseFloat(props.preloadedPrescription.fileSize) * 1024 * 1024,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: props.preloadedPrescription.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
  }, [props.preloadedPrescription]);

  useEffect(() => {
    let update = isSuccessful && !isUploading;
    props.onUploadSuccessStateChange(update);
  }, [isSuccessful, isUploading]);

  const uploadFile = (file) => {
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
        const fileReader = new window.FileReader();
        fileReader.onload = (fileLoad) => {
          const { result } = fileLoad.target;
          let requestObj = {
            fileName: file.name,
            fileData: result,
          };
          prescriptionModule
            .uploadExtendedPrescription(requestObj)
            .then((res) => {
              props.onFileChange({
                name: file.name,
                savedFileName: res.savedFileName,
                fileSize: file.size,
              });
              props.onPrescriptionIdReceived(res.prescriptionId);
              setIsFileTooBigError(false);
              setIsSuccessful(true);

              setPreloadedFileInfo({
                name: file.name,
                size: file.size,
                type: getFileTypeFromFullName(file.name),
              });

              setIsUploading(false);

              if (prescriptionModule.downloadExtendedPrescription) {
                prescriptionModule
                  .downloadExtendedPrescription({
                    savedFileName: res.savedFileName,
                  })
                  .then((res) => {
                    setFilePath(res.fileData);
                  })
                  .catch((err) => {
                    setFilePath("");
                  });
              } else {
                console.error("Missing downloadExtendedPrescriptionFunction");
                setFilePath("");
              }
            })
            .catch((err) => {
              setIsSuccessful(false);
              setIsUploading(false);
              setIsFileTooBigError(false);
            });
        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      } else {
        if (file.name.indexOf(".") > 0) {
          let ext = file.name.split(".")[file.name.split(".").length - 1];
          if (prescriptionModule && prescriptionModule.fileExtensions) {
            if (!prescriptionModule.fileExtensions.includes(ext)) {
              try {
                //@ts-ignore
                window.tealium_data2track.push({
                  id: "Error",
                  Error_Source: "User",
                  Error_Code: "RX Configurator: prescription upload",
                  Error_Message: tryAgain,
                });
              } catch (error) {
                console.error(
                  "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
                );
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      return parsedExt.join();
    }
  };

  const handleFileChange = (file: any) => {
    setFilePath(null);
    uploadFile(file);
  };

  const handleUploadErrorButtonClick = () => {
    let inputFile = document.getElementById("PrescriptionUploadInputId");
    if (inputFile) {
      inputFile.click();
    }
  };

  return (
    <>
      <div
        className={
          "PrescriptionUploadForm__UploadBox__container" +
          (!isSuccessful && !isUploading ? " error" : "")
        }
      >
        {isUploading && (
          <div className="PrescriptionUploadForm__UploadBox__container__spinnerContainer">
            <BrandComponent
              componentName="Loader"
              parameter={{
                greyLoader: true,
                style: { width: 40, height: 40 },
              }}
            />
          </div>
        )}
        {!isUploading && (
          <>
            <div className="PrescriptionUploadForm__UploadBox__container__topContainer">
              <UploadResult
                isSuccessful={isSuccessful}
                uppercase={isSuccessful || isFileTooBigError ? true : false}
                message={
                  isSuccessful
                    ? prescriptionUploaded
                    : isFileTooBigError
                    ? fileTooBigErrorTitle
                    : somethingWentWrong
                }
              />
              <div
                className="PrescriptionUploadForm__UploadBox__container__changeMethod"
                onClick={() => props.closePrescriptionForm()}
              >
                {changeMethod}
              </div>
            </div>
            <div className="PrescriptionUploadForm__UploadBox__container__bottomContainer">
              {isSuccessful && (
                <FilePreview
                  handleUploadAgain={() => {
                    if (fileInputRef && fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                  forceFilePath={filePath}
                  forceFileInfo={preloadedFileInfo}
                />
              )}
              {!isSuccessful && (
                <UploadError
                  message={
                    isFileTooBigError
                      ? fileTooBigErrorDescription.replace(
                          "###FILE_SIZE###",
                          prescriptionModule?.maxFileSize
                            ? prescriptionModule?.maxFileSize
                            : 10
                        )
                      : tryAgain
                  }
                  buttonText={isFileTooBigError ? uploadDifferentFile : upload}
                  onButtonClick={handleUploadErrorButtonClick}
                />
              )}
            </div>
            <div
              className="PrescriptionUploadForm__UploadBox__container__changeMethodMobile"
              onClick={() => props.closePrescriptionForm()}
            >
              {changeMethod}
            </div>
          </>
        )}
      </div>
      <input
        id="PrescriptionUploadInputId"
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept={getSupportedFileExtensions()}
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export function PrescriptionUploadForm({
  fileInput,
  closePrescriptionForm,
  onSuccessPrescription,
  prescriptionObject,
}: PrescriptionUploadFormProps) {
  const saveExtendedPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule?.saveExtendedPrescription
  );
  const previousPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const [fileInfoForSave, setFileInfoForSave] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);

  const [showContinueButton, setShowContinueButton] = useState(false);

  const confirmAndContinue = useTranslate(
    "steps.advancedPrescription.upload.confirmAndContinue"
  );
  const PD = usePupillaryDistance({ prescription: prescriptionObject });

  const getDataElementIdForConfirm = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-ValuesCompatibles";
  };

  const CustomCheckbox = (props) => {
    const isChecked = props.value
      ? typeof props.value === "boolean"
        ? props.value
        : props.value.value
      : false;

    return (
      <div
        tabIndex={0}
        className={
          "CustomCheckbox__checkbox" +
          (isChecked ? " checked" : "") +
          (props.formErrorName ? " error" : "") +
          (props.marginTop ? " marginTop" : "")
        }
        onClick={() => {
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: false,
            });
          }
        }}
      ></div>
    );
  };

  const handleSubmitPrescription = () => {
    if (!PD.state.checkPDSelected()) return;
    if (
      saveExtendedPrescription &&
      typeof saveExtendedPrescription === "function"
    ) {
      let fileSize = fileInfoForSave?.fileSize
        ? (parseInt(fileInfoForSave.fileSize) / 1024 / 1024).toFixed(2)
        : 0;
      let submitObj = {
        prescriptionFlow: "UPLOAD",
        prescriptionId: prescriptionId
          ? prescriptionId
          : previousPrescription
          ? previousPrescription.prescriptionId
          : null,
        fileName: fileInfoForSave?.name,
        savedFileName:
          fileInfoForSave && fileInfoForSave.savedFileName
            ? fileInfoForSave.savedFileName
            : previousPrescription.savedFileName,
        fileSize: fileSize,
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      saveExtendedPrescription(submitObj)
        .then((res) => {
          onSuccessPrescription(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Subtitle />
      <UploadBox
        preloadedPrescription={prescriptionObject}
        onUploadSuccessStateChange={(isSuccessful) =>
          setShowContinueButton(isSuccessful)
        }
        onFileChange={(fileInfo) => setFileInfoForSave(fileInfo)}
        onPrescriptionIdReceived={(id) => setPrescriptionId(id)}
        closePrescriptionForm={closePrescriptionForm}
        file={fileInput}
      />
      <div className="PrescriptionUploadForm__Separator"></div>
      <BrandComponent
        componentName="PupillaryDistance"
        parameter={{
          PD1: PD.state.PD1,
          PD2: PD.state.PD2,
          showBothPdOptions: PD.state.showBothPdOptions,
          showPDWarning: PD.state.showPDWarning,
          onPD1Change: PD.state.setPD1,
          onPD2Change: PD.state.setPD2,
          setShowBothPdOptions: PD.state.setShowBothPdOptions,
          pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
          CustomCheckbox: CustomCheckbox,
        }}
      />

      {showContinueButton && (
        <div className="PrescriptionUploadForm__submitContainer">
          <button
            className="PrescriptionUploadForm__submitButton"
            onClick={handleSubmitPrescription}
            data-element-id={getDataElementIdForConfirm()}
          >
            {confirmAndContinue}
          </button>
        </div>
      )}
    </>
  );
}
