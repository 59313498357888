//@ts-ignore
export default {
  costa: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision need, so you can go hussle free",
        },
        advancedPrescription: {
          confirmedTitle: "Prescription",
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            review: {
              title: "Entered manually",
            },
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            addPlaceholder: "None",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pupillaryDistanceSubtitle:
              "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please ",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningValue:
              "Please input your pupillary distance, if you don’t have it you can use the default from the above or you can ",
            pupillaryDistanceMisurePDAction: "measure your PD.",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarning2:
                "The selected PD is smaller than average, we suggest double checking your prescription. If you don’t have it, you can add the values written above or you can ",
              missingBaseDir: "Please choose the base direction for your prism",
              missingPrismValues: "Please choose the prism value",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              incompatibleWithPackagesTitle:
                "Sorry! The prescription you've chosen isn't compatible with any of our available lenses for this frame",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
            digitalOptometry: {
              title: "How to measure your PD",
              appSubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
              manuallySubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. You can measure with the app or manually.",
              toggle: {
                app: "with the app",
                manually: "manually",
              },
              opthyDesc:
                "<b>Get your glasses just right with Opthy.</b><br/><p>Opthy measures your Pupillary Distance, allowing you to find the most comfortable, personalized fit.</p><p>App available on iPhone X and above.</p>",
              manuallyContent:
                "<div>If you want, you can measure your PD yourself by following these steps:</div><ul><li>Grab a small ruler in one hand, and have your pencil and paper handy.</li><li>Now position yourself approximately 8 inches (20 cm) away from a mirror.</li><li>Close your left eye and align the 0 over the center of your right pupil.</li><li>Measure the distance from your right to your left pupil.</li><li>The number that lines up directly over your left pupil is your PD (an average PD for an adult ranges between 58 and 65).</li></ul>",
            },
            manualPreviewTitle: "Your prescription details",
            seeDetails: {
              sphere: "SPH",
              cylinder: "CYL",
              axis: "Axis",
              add: "ADD",
              pd: "PD",
              od: "OD",
              os: "OS",
              vertical: "Vertical Prism (Δ)",
              baseDirection: "Base Direction",
              horizontal: "Horizontal Prism (Δ)",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload it now",
              description:
                "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages",
            },
            review: {
              title: "Uploaded",
            },
            uploadDifferentFile: "Upload different file",
            changeMethod: "Change sending method",
            prescriptionUploaded: "Prescription Uploaded",
            somethingWentWrong: "Sorry, something went wrong.",
            fileTooBigErrorTitle: "File is too big",
            fileTooBigErrorDescription:
              "Your file size is too big. Maximum upload ###FILE_SIZE### MB",
            tryAgain: "Please try again",
            upload: "Upload",
            pdForm: {
              pupillaryDistance: "PD (Pupillary distance)",
              whatIsIt: "What is it?",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 PD numbers",
            },
            confirmAndContinue: "Confirm & continue",
            filePreviewTitle: "Your prescription",
          },
          doctor: {
            title: "Add your doctor details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your eye doctor by",
            card: {
              title: "Call my doctor",
              description: "We'll get your prescription directly from him/her.",
            },
            addNewDoctorModal: {
              title: "Add new doctor",
              clinicOrDoctorName: "Clinic or doctor name",
              phoneNumber: "Phone number",
              addDoctor: "Add doctor",
            },
            slider: {
              phone: "Phone number",
              clinicOrDoctorName: "Clinic or doctor name",
            },
            table: {
              doctorName: "Doctor name",
              clinicName: "Clinic name",
              address: "Address",
              chooseButton: "Choose",
              phone: "Phone",
              fax: "Fax",
              phoneNumber: "Phone number",
              changeButton: "Change",
              wrongDoctor: "Wrong doctor?",
            },
            pdForm: {
              pupillaryDistance: "PD (Pupillary distance)",
              whatIsIt: "What is it?",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 PD numbers",
            },
            addManuallyOption: {
              didntFind: "Didn't find your doctor?",
              addManually: "Add manually",
            },
            missingInfoError: "Complete the missing informations",
            doctorNotFoundBoxMessage: "We couldn't find your doctor.",
            doctorNotFoundBoxMessageAdd: "Please add more details or ",
            doctorNotFoundBoxMessageLink: "add a new doctor.",
            phoneNumber: "Doctor phone number",
            search: "Search",
            clinicOrDoctorName: "Clinic or doctor name",
            city: "City",
            state: "State",
            changeSubmissionMethod: "Change submission method",
            confirmAndContinue: "Confirm & continue",
            selectedDoctorPageTitle: "Your doctor details",
            doctorPreviewTitle: "We'll call your doctor",
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
            review: {
              title: "We'll ask for it after the checkout",
            },
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, <b>California residents are also required to electronically transmit their valid prescription.</b> Choose an option to proceed.",
            chooseTitle: "Choose how to send your prescription",
            uploadTitle: "Upload",
            uploadDesc:
              "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Browse",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle:
                "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colors and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          designTypeTitle: "Choose your lens design",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Color",
          },
          categories: {
            all: {
              title: "All",
            },
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Color:",
          title: "Choose your lens color",
          titleMobile: "Choose your lens color",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarized only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced color and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishings:",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentLabel:
              "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE### with ",
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in installments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments every two weeks with zero hidden fees. Payment options are offered by ###PAYMENT_TYPE###, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by ###PAYMENT_TYPE### Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
          seeDetails: "See details",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
        vatIncluded: "Tax included",
        discountBadgePercentage: "###VALUE######SYMBOL### OFF",
        discountBadgeAmount: "###VALUE######SYMBOL### OFF",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceLabelRemove: "Remove benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      insuranceErrorModal: {
        title: "Something went wrong...",
        content:
          "We weren't able to apply your insurance benefits. Your insurance plan will be automatically unsynced in order to continue.",
        confirm: "Confirm",
      },
      proceedAsIs: "Proceed as is",
      grayOutBannerMessage:
        "Some options may be disabled as they are not compatible with your prescription values.",
      yourSelections: "Your selections",
      editButton: "Edit",
      error: {
        title: "Something went wrong...",
        description:
          "We’re experiencing some technical difficulties, we apologize. In the meantime, if you have any questions or need assistance, feel free to <a href='#'>contact our customer service.</a>",
        buttonLabel: "Back",
      },
      generic: {
        modal: {
          close: "",
        },
      },
    },
    es_MX: {
      steps: {
        prescription: {
          title: "Agrega tus valores de graduación.",
          description:
            "O te sugeriremos la mica adecuada para tus necesidades de vista y puedas ir sin problemas",
        },
        type: {
          title: "Selecciona su necesidad de visión",
          prescriptionTitle: "o",
          addToCart: "Agrega al carrito",
          moreInfo: "Más información",
          confirmedTitle: "Uso",
        },
        brand: {
          title: "Selecciona la marca de tus cristales",
          confirmedTitle: "Marca de la mica:",
        },
        thickness: {
          title: "Selecciona el grosor de tus cristales",
          confirmedTitle: "Grosor",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          designTypeTitle: "SELECCIONE EL DISEÑO DE LAS LENTES",
        },
        gvpTreatment: {
          title: "Elige tu paquete de buen precio",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          confirmedTitle: "Paquete de mica",
        },
        treatments: {
          addToBag: "Agregar a la cesta",
          title: "Completa tus cristales",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluye tratamiento de resistencia a los rayones y protección UV.",
          confirmedTitle: "Tratamiento",
          extraTreatments: {
            antiScratch: {
              name: "Antirayones",
              title: "Resistente a rayones",
            },
            antiReflective: {
              value: "Tratamiento antirreflejante",
            },
            uvProtection: {
              title: "Protección UV",
            },
          },
        },
        transitionColor: {
          title: "Color de transición",
          indoor: "INTERIORES",
          outdoor: "EXTERIORES",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Transmisión de luz",
            lightConditions: "Condiciones de luz",
            contrast: "Contraste",
            baseLensColor: "Color de mica de base",
          },
          categories: {
            "Ugly category": {
              title: "Categoría Ugly",
            },
            "Cool category": {
              title: "Categoría Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Color:",
          title: "Selecciona el color de la mica",
          titleMobile: "Selecciona el color de la mica",
          titleDesignTypeLayout: "Selecciona la tecnología de la mica",
          titleColorCategoryLayout: "Selecciona el tipo de la mica",
          polarized: "Solo polarizado",
          applyAndContinue: "Aplicar",
          subtitle:
            "Color y contraste mejorados para que puedas ver más detalles.",
          tooltip: {
            polarized:
              "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            switch:
              "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            antiScratch:
              "Protege la superficie de las lentes de los impactos fuertes ",
            antiReflective:
              "Reduce los reflejos y ofrece protección invisible. Resistente al resplandor, el polvo y las manchas para ofrecer una visión más clara y homogénea ",
            blueLight:
              "Reduce la exposición a la luz azul de las pantallas digitales y los rayos del sol, lo que puede ayudar a reducir la fatiga ocular.",
            uvProtection:
              "Capas protectoras resistentes en la parte delantera y trasera de las micas para ofrecer una máxima protección contra rayos UV y los rayones.",
          },
        },
        review: {
          title: "REVISE SU CONFIGURACIÓN",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          discountTitle:
            "Se aplica automáticamente un 40 % de descuento en micas graduadas",
          firstLineReview: "Armazón:",
          paymentInstallments: {
            installmentLabel:
              "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE### with ",
            installmentsLabel:
              "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
            modal: {
              title: "Pago a plazos",
              subtitle:
                "¿Quieres separar el costo de tu pedido con ###BRAND###? \n Selecciona ###PAYMENT_TYPE### como tu método de pago al finalizar la compra \n para pagar en cuotas sin intereses, sin cargos ocultos.",
              affirm:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. ###PAYMENT_TYPE### ofrece las opciones de pago, están sujetas a una verificación de elegibilidad y es posible que no esté disponible en todos los estados. Residentes de California: Los préstamos de ###PAYMENT_TYPE### Loan Services, LLC se otorgan de conformidad con una licencia de prestamista de California.",
              afterpay:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. Debes ser mayor de 18 años, residente de los EE. UU. y cumplir con los criterios de elegibilidad adicionales para calificar. Se pueden aplicar cargos por pagos atrasados. Haz clic aquí para conocer los términos completos. Los préstamos otorgados a los residentes de California se rigen por una licencia de la Ley de prestamistas de California.",
              klarna:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses de ###MONTHLY_PRICE###",
            },
            seeDetails: "Ve los detalles",
          },
          insuranceButtonLabel: "Aplicar seguro",
          insuranceButtonLabelRemove: "Elimina beneficios del seguro",
          oneYearCoverageAdd: "Agregar",
          tooltip:
            "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
        },
        advancedPrescription: {
          confirmedTitle: "Receta",
          title: "¿Tienes una receta?",
          manual: {
            title: "Ingresa tu receta",
            addPrismValues: "Agregar valores de prisma",
            review: {
              title: "Ingresado de manera manual",
            },
            card: {
              title: "Ingrésala de manera manual",
              description:
                "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado.",
            },
            subtitle:
              "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
            rightEye: {
              initials: "OD",
              name: "(Ojo derecho)",
            },
            leftEye: {
              initials: "OI",
              name: "(Ojo izquierdo)",
            },
            sphere: "Esfera (ESF)",
            cylinder: "Cilindro (CIL)",
            axis: "Eje",
            add: "Adición",
            vertical: "Vertical (Δ)",
            baseDirection: "Dirección base",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "DP (Distancia pupilar)",
            pupillaryDistanceSubtitle:
              "Puedes seleccionar la configuración predeterminada de <b>61 para mujeres</b> y <b>64 hombres</b> si tienes una receta de valores promedio o menores. Si tienes una receta detallada (o si deseas conocer tu distancia pupilar exacta),",
            pupillaryDistanceWarningValue:
              "Ingresa tu distancia pupilar; si no la conoces, puedes utilizar los valores predeterminados que aparecen arriba o puedes",
            pupillaryDistanceMisurePDAction: "medir tu distancia pupilar",
            pdLeft: "Izquierdo",
            pdRight: "Derecho",
            iHaveTwoPd: "Tengo dos números de DP",
            applyButton: "Continuar",
            howToRead: "Cómo leer tu receta",
            whatIsIt: "¿Qué es?",
            pupillaryDistanceWarningTooltip:
              "Proporcionar texto para esta descripción emergente",
            moreOptions: "Más opciones",
            alerts: {
              axisRangeError:
                "Los valores de EJE que introdujiste son incorrectos. Estos deben ser entre 1 y 180.",
              axisZeroError:
                "Los valores de EJE que introdujiste son incorrectos. Si el valor de CIL. es más alto o bajo que 0, el valor de EJE no puede ser 0.",
              sphPositiveNegativeWarning:
                "Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común. Te sugerimos revisar tu receta. Si es correcto, puedes continuar.",
              missingValues: "Completa los valores que hacen falta",
              requiredToProceed: "Es necesario para continuar",
              incompatibleWithPrescriptionTitle:
                "¡Lo sentimos! El armazón que elegiste no es compatible con tu receta",
              incompatibleWithPrescriptionBody:
                "pero no te preocupes, hay armazones compatibles",
              shopCompatible: "Compra los armazones compatibles",
              clearAll: "Borra todo",
              pdValueWarning:
                "La distancia pupilar seleccionada no está dentro del rango normal, te sugerimos que revises la receta. Puedes dejar el valor por defecto de 63, que es la medida promedio para adultos. \nIf necesitamos más información sobre tu receta, uno de nuestros expertos se pondrá en contacto.",
              pdValueWarning2:
                "La distancia pupilar seleccionada es menor al promedio, te sugerimos que revises la receta. Si no la tienes, puedes agregar los valores que aparecen arriba o puedes",
              incompatibleWithPackagesTitle:
                "¡Lo sentimos! La receta que elegiste no es compatible con ninguna de nuestras micas para este armazón",
              agreementText:
                "Al hacer clic en esta casilla, confirmo que los valores de la receta ingresados anteriormente se toman de una receta válida (no vencida) que me fue emitida, firmada por un optometrista u oftalmólogo con licencia.",
            },
            continueModal: {
              title: "Envía la receta más tarde",
              message:
                "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              continue: "Sí, continuar",
              cancel: "No, regresar",
            },
            digitalOptometry: {
              title: "Cómo medir tu distancia pupilar",
              appSubtitle:
                "Tu distancia pupilar o PD es la distancia en milímetros (mm) desde el centro de una pupila hasta el centro de la otra. Indica exactamente por cuál parte de la mica verás, asegurándote una comodidad y claridad óptimas. Puedes medirla con la aplicación o de manera manual.",
              manuallySubtitle:
                "Tu distancia pupilar o PD es la distancia en milímetros (mm) desde el centro de una pupila hasta el centro de la otra. Puedes medirla con la aplicación o de manera manual.",
              toggle: {
                manually: "de manera manual",
                app: "con la aplicación",
              },
              opthyDesc:
                "<b>Haz que tus lentes sean perfectos con Opthy.</b><br/><p>Opthy mide la distancia pupilar para que puedas encontrar un ajuste personalizado y más cómodo.</p><p>Aplicación disponible en iPhone X y versiones superiores.</p>",
              manuallyContent:
                "<div>Si lo deseas, puedes medir tu distancia pupilar por ti mismo siguiendo estos pasos:</div><ul><li>Toma una regla pequeña y ten a la mano lápiz y papel.</li><li>Ahora colócate aproximadamente a 20 cm (8 pulgadas) de distancia de un espejo.</li><li>Cierra tu ojo izquierdo y alinea el 0 sobre el centro de tu pupila derecha.</li><li>Mide la distancia de tu pupila derecha a tu pupila izquierda.</li><li>El número que se alinea directamente sobre tu pupila izquierda es tu distancia pupilar (la distancia promedio para un adulto oscila entre 58 y 65).</li></ul>",
            },
            manualPreviewTitle: "Los detalles de tu receta",
            seeDetails: {
              sphere: "SPH (esfera)",
              cylinder: "CYL (cilindro)",
              axis: "Eje",
              add: "ADD (adición)",
              pd: "DP (distancia pupilar)",
              od: "OD (ojo derecho)",
              os: "OI (ojo izquierdo)",
              vertical: "Prisma vertical (Δ)",
              baseDirection: "Dirección base",
              horizontal: "Prisma horizontal (Δ)",
            },
          },
          account: {
            card: {
              title: "Obtenerlo de mi cuenta",
              description: "Inicia sesión y selecciona tu receta",
            },
          },
          upload: {
            title: "Tu receta",
            subtitle:
              "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
            card: {
              title: "Cárgala ahora",
              description:
                "Y nosotros nos encargaremos del resto Aceptamos los siguientes formatos: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (10 MB máx.) y iWork Pages",
            },
            uploadDifferentFile: "Subir un archivo diferente",
            changeMethod: "Cambia el método de envío",
            prescriptionUploaded: "Receta subida",
            somethingWentWrong: "Lo sentimos, ocurrió un error.",
            fileTooBigErrorTitle: "El archivo es demasiado grande",
            fileTooBigErrorDescription:
              "El tamaño del archivo es demasiado grande. Tamaño máximo ###FILE_SIZE### MB",
            tryAgain: "Inténtalo de nuevo",
            upload: "Sube",
            pdForm: {
              pupillaryDistance: "DP (distancia pupilar)",
              whatIsIt: "¿Qué es?",
              pdValueWarning:
                "La distancia pupilar seleccionada no está dentro del rango normal, te sugerimos que revises la receta. Puedes dejar el valor por defecto de 63, que es la medida promedio para adultos. \nIf necesitamos más información sobre tu receta, uno de nuestros expertos se pondrá en contacto.",
              pdLeft: "Izquierdo",
              pdRight: "Derecho",
              iHaveTwoPd: "Tengo dos números de DP",
              confirmAndContinue: "Confirma y continúa",
              filePreviewTitle: "Tu receta",
            },
          },
          doctor: {
            title: "Agrega la información de tu doctor",
            subtitle: "Obtendremos los detalles de tu receta con él/ella",
            searchBy: "Busca a tu oculista por",
            card: {
              title: "Llama a mi doctor",
              description: "Obtendremos tu receta con él/ella",
            },
            addNewDoctorModal: {
              title: "Agrega un nuevo doctor",
              clinicOrDoctorName: "Clínica o nombre del doctor",
              phoneNumber: "Número telefónico",
              addDoctor: "Agrega un doctor",
            },
            slider: {
              phone: "Número telefónico",
              clinicOrDoctorName: "Clínica o nombre del doctor",
            },
            table: {
              doctorName: "Nombre del doctor",
              clinicName: "Nombre de la clínica",
              address: "Domicilio",
              chooseButton: "Elige",
              phone: "Teléfono",
              fax: "Fax",
              phoneNumber: "Número telefónico",
              changeButton: "Cambia",
              wrongDoctor: "Doctor incorrecto",
            },
            pdForm: {
              pupillaryDistance: "DP (distancia pupilar)",
              whatIsIt: "¿Qué es?",
              pdValueWarning:
                "La distancia pupilar seleccionada no está dentro del rango normal, te sugerimos que revises la receta. Puedes dejar el valor por defecto de 63, que es la medida promedio para adultos. \nIf necesitamos más información sobre tu receta, uno de nuestros expertos se pondrá en contacto.",
              pdLeft: "Izquierdo",
              pdRight: "Derecho",
              iHaveTwoPd: "Tengo dos números de DP",
            },
            addManuallyOption: {
              didntFind: "¿No encontraste a tu doctor?",
              addManually: "Agregar de manera manual",
            },
            missingInfoError: "Completa la información faltante",
            doctorNotFoundBoxMessage:
              "No nos fue posible encontrar a tu doctor.",
            doctorNotFoundBoxMessageAdd: "Agrega más detalles o ",
            doctorNotFoundBoxMessageLink: "agrega un nuevo doctor.",
            phoneNumber: "Número telefónico del doctor",
            search: "Búsqueda",
            clinicOrDoctorName: "Clínica o nombre del doctor",
            city: "Ciudad",
            state: "Estado",
            changeSubmissionMethod: "Cambia el método de envío",
            confirmAndContinue: "Confirma y continúa",
            selectedDoctorPageTitle: "La información de tu doctor",
            doctorPreviewTitle: "Llamaremos a tu doctor",
          },
          later: {
            card: {
              title: "Envíala más tarde",
              description:
                "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
            },
            review: {
              title:
                "Lo solicitaremos después de finalizar el proceso de compra",
            },
          },
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo de mica",
          title: "Selecciona el tipo de mica",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          blueLight: {
            title: "Filtro de luz azul",
            description:
              "Reduce la exposición a la luz azul de las pantallas digitales, lo que puede ayudar a prevenir la fatiga ocular.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Un par para interiores y exteriores con micas Transitions®: se oscurecen rápidamente y se vuelven transparentes, para que nunca tengas que cambiar de lentes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparente",
            description:
              "Lentes tradicionales y translúcidas, perfectas para el uso diario.",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description: "Elige entre diferentes colores y tonos de micas.",
            imageFileName: "",
          },
        },
        addOns: {
          confirmedTitle: "COMPLETE SU CONFIGURACIÓN DE LENTES",
          label: "Ya está incluido con tu mica",
          title: "COMPLETE SU CONFIGURACIÓN DE LENTES",
          reviewTitle: "Acabados",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
        },
      },
      prescription: {
        toggleLabel:
          "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
        prescriptionTitle:
          "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
        rightEye: "(Ojo derecho)",
        rightEyeInitials: "OD",
        leftEye: "(Ojo izquierdo)",
        leftEyeInitials: "OS",
        readPrescription: "Cómo leer tu receta",
        addLabel: "También tengo un valor de adición",
        applyButton: "Aplicar y continuar",
        submitButton: "Enviar",
        clearButton: "Borrar todo",
        skipButton: "Saltar por ahora",
        compatibleFramesButton: "Comprar armazones compatibles",
        doctorDetails:
          "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
        missingValueError: "Agrega tus valores para continuar",
        positiveNegativeError:
          "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
        compatibleFrameError:
          "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
        compatibleFrameSubLabel:
          "No te preocupes, tenemos armazones que se adaptan a tu receta.",
        axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
        axisSubError: "Estos deben ser entre 0 y 180.",
        axisSubErrorZero:
          "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
        editLabel: "Editar receta",
        pdHelp: "¿Qué es esto?",
        twoPDNumbers: "Tengo dos números de DIP",
        missingInputMessage:
          "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
        defaultInputMessage:
          "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
        skipMessage: "Más adelante, te pediremos completar o cargar tu receta",
        readyMessage: "¡Ya te podemos sugerir los cristales adecuados para ti!",
        bestPrescription: "Los mejores para tu receta",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Necesario para continuar",
      },
      price: {
        vatIncluded: "Impuestos incluidos",
        discountBadgePercentage: "###VALUE######SYMBOL### DE DESCUENTO",
        discountBadgeAmount: "###VALUE######SYMBOL### DE DESCUENTO",
        totalFrameLens: {
          frameLensTotal: "Precio del armazón + cristales",
          frame: "Precio del armazón",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluido",
        frameLensStartingAt: "Armazón + micas en",
        frameLens: "Armazón + micas",
        startingAt: "A partir de",
      },
      noTreatment: "Sin tratamiento",
      insuranceLabelRemove: "Quita el seguro de visión",
      insuranceErrorModal: {
        title: "Ocurrió un error…",
        content:
          "No nos fue posible aplicar los beneficios de tu seguro de visión. Tu plan de seguro dejará de sincronizarse automáticamente para poder continuar.",
        confirm: "Confirmar",
      },
      grayOutBannerMessage:
        "Algunas opciones pueden estar deshabilitadas ya que no son compatibles con los valores de tu receta.",
      error: {
        title: "Ocurrió un error…",
        description:
          "Estamos experimentando algunas dificultades técnicas, lo lamentamos. Mientras tanto, si tienes alguna duda o necesitas ayuda, siéntete en libertad de <a href='#'>ponerte en contacto con nuestro servicio de asistencia al cliente.</a>",
        buttonLabel: "Regresar",
      },
      learnMoreMade: "Ver cómo se hace",
      learnMoreTreatment: "Ver el tratamiento",
      exitTitle: "'¿SEGURO QUE QUIERES SALIR?'",
      exitSubtitle: "Tu selección de cristales no se guardará",
      exitYes: "Sí, salir",
      exitContinueEditing: "Seguir editando",
      exitSave: "Guardar y seguir comprando",
      insuranceButtonLabel: "Agregar beneficios del seguro",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
      applyingInsurance: "Aplicar beneficios del seguro",
      frameSize: "Precio del armazón",
      fallbackImageMessageTitle:
        "La vista previa del color de micas no está disponible",
      fallbackImageMessageSubtitle:
        "No te preocupes, recibirás tu armazón con el color de micas que seleccionaste.",
      proceedAsIs: "Proceder tal como están",
      yourSelections: "Tus selecciones",
      editButton: "Editar",
    },
    es_US: {
      steps: {
        prescription: {
          description:
            "O te sugeriremos la mica adecuada para tus necesidades de vista y puedas ir sin problemas",
        },
        type: {
          title: "Selecciona tus necesidades de visión",
          prescriptionTitle: "o",
          addToCart: "Agrega al carrito",
          moreInfo: "Más información",
          confirmedTitle: "Uso",
        },
        brand: {
          title: "Selecciona la marca de tus cristales",
          confirmedTitle: "Lens brand:",
        },
        thickness: {
          title: "Selecciona el grosor de tus cristales",
          confirmedTitle: "Grosor",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          designTypeTitle: "SELECCIONE EL DISEÑO DE LAS LENTES",
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Tus lentes tendrán la mejor tecnología LensCrafters de su clase.",
          confirmedTitle: "Paquete de mica",
        },
        treatments: {
          addToBag: "Agregar a la cesta",
          title: "Completa tus cristales",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Tratamiento",
          extraTreatments: {
            antiScratch: {
              name: "Antirayones",
              title: "Resistente a rayones",
            },
            antiReflective: {
              value: "Tratamiento antirreflejante",
            },
            uvProtection: {
              title: "Protección UV",
            },
          },
        },
        transitionColor: {
          title: "Color de transición",
          indoor: "INTERIORES",
          outdoor: "EXTERIORES",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Transmisión de luz",
            lightConditions: "Condiciones de luz",
            contrast: "Contraste",
            baseLensColor: "Color de mica de base",
          },
          categories: {
            "Ugly category": {
              title: "Categoría Ugly",
            },
            "Cool category": {
              title: "Categoría Cool",
            },
          },
        },
        color: {
          confirmedTitle: "Color:",
          title: "Selecciona el color de la mica",
          titleMobile: "Selecciona el color de la mica",
          titleDesignTypeLayout: "Selecciona la tecnología de la mica",
          titleColorCategoryLayout: "Selecciona el tipo de la mica",
          polarized: "Solo polarizado",
          applyAndContinue: "Aplicar",
          subtitle:
            "Color y contraste mejorados para que puedas ver más detalles.",
          tooltip: {
            polarized:
              "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            switch:
              "Reduce el resplandor en la luz extra brillante, para una claridad y protección superior de tus ojos.",
            antiScratch:
              "Protege la superficie de las lentes de los impactos fuertes ",
            blueLight:
              "Reduce la exposición a la luz azul de las pantallas digitales y los rayos del sol, lo que puede ayudar a reducir la fatiga ocular.",
          },
        },
        review: {
          title: "REVISE SU CONFIGURACIÓN",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          discountTitle:
            "Se aplica automáticamente un 40 % de descuento en micas graduadas",
          firstLineReview: "Armazón:",
          paymentInstallments: {
            installmentLabel:
              "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE### with ",
            installmentsLabel:
              "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
            modal: {
              title: "Pago a plazos",
              subtitle:
                "¿Quieres separar el costo de tu pedido con ###BRAND###? \n Selecciona ###PAYMENT_TYPE### como tu método de pago al finalizar la compra \n para pagar en cuotas sin intereses, sin cargos ocultos.",
              affirm:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. ###PAYMENT_TYPE### ofrece las opciones de pago, están sujetas a una verificación de elegibilidad y es posible que no esté disponible en todos los estados. Residentes de California: Los préstamos de ###PAYMENT_TYPE### Loan Services, LLC se otorgan de conformidad con una licencia de prestamista de California.",
              afterpay:
                "Con ###PAYMENT_TYPE###, realiza ###INSTALLMENTS### pagos sin intereses cada dos semanas sin cargos ocultos. Debes ser mayor de 18 años, residente de los EE. UU. y cumplir con los criterios de elegibilidad adicionales para calificar. Se pueden aplicar cargos por pagos atrasados. Haz clic aquí para conocer los términos completos. Los préstamos otorgados a los residentes de California se rigen por una licencia de la Ley de prestamistas de California.",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
            },
          },
          insuranceButtonLabel: "Aplicar seguro",
          insuranceButtonLabelRemove: "Elimina beneficios del seguro",
          oneYearCoverageAdd: "Agregar",
          tooltip:
            "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
        },
        advancedPrescription: {
          title: "¿Tienes una receta?",
          manual: {
            title: "Ingresa tu receta",
            card: {
              title: "Ingrésala de manera manual",
              description:
                "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado.",
            },
            subtitle:
              "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
            rightEye: {
              initials: "OD",
              name: "(Ojo derecho)",
            },
            leftEye: {
              initials: "OI",
              name: "(Ojo izquierdo)",
            },
            sphere: "Esfera (ESF)",
            cylinder: "Cilindro (CIL)",
            axis: "Eje",
            add: "Adición",
            vertical: "Vertical (Δ)",
            baseDirection: "Dirección base",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "DP (Distancia pupilar)",
            pdLeft: "Izquierdo",
            pdRight: "Derecho",
            iHaveTwoPd: "Tengo dos números de DP",
            applyButton: "Continuar",
            howToRead: "Cómo leer tu receta",
            whatIsIt: "¿Qué es?",
            pupillaryDistanceWarningTooltip:
              "Proporcionar texto para esta descripción emergente",
            moreOptions: "Más opciones",
            alerts: {
              axisRangeError:
                "Los valores de EJE que introdujiste son incorrectos. Estos deben ser entre 1 y 180.",
              axisZeroError:
                "Los valores de EJE que introdujiste son incorrectos. Si el valor de CIL. es más alto o bajo que 0, el valor de EJE no puede ser 0.",
              sphPositiveNegativeWarning:
                "Insertaste 1 valor positivo y 1 negativo, lo cual es muy poco común. Te sugerimos revisar tu receta. Si es correcto, puedes continuar.",
              missingValues: "Completa los valores que hacen falta",
              requiredToProceed: "Es necesario para continuar",
              incompatibleWithPrescriptionTitle:
                "¡Lo sentimos! El armazón que elegiste no es compatible con tu receta",
              incompatibleWithPrescriptionBody:
                "pero no te preocupes, hay armazones compatibles",
              shopCompatible: "Compra los armazones compatibles",
              clearAll: "Borra todo",
              agreementText:
                "Al hacer clic en esta casilla, confirmo que los valores de la receta ingresados anteriormente se toman de una receta válida (no vencida) que me fue emitida, firmada por un optometrista u oftalmólogo con licencia.",
            },
            continueModal: {
              title: "Envía la receta más tarde",
              message:
                "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              continue: "Sí, continuar",
              cancel: "No, regresar",
            },
          },
          account: {
            card: {
              title: "Obtenerlo de mi cuenta",
              description: "Inicia sesión y selecciona tu receta",
            },
          },
          upload: {
            title: "Tu receta",
            subtitle:
              "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
            card: {
              title: "Cárgala ahora",
              description:
                "Y nosotros nos encargaremos del resto Aceptamos los siguientes formatos: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (10 MB máx.) y iWork Pages",
            },
          },
          doctor: {
            title: "Agrega la información de tu doctor",
            subtitle: "Obtendremos los detalles de tu receta con él/ella",
            searchBy: "Busca a tu oculista por",
            card: {
              title: "Llama a mi doctor",
              description: "Obtendremos tu receta con él/ella",
            },
          },
          later: {
            card: {
              title: "Envíala más tarde",
              description:
                "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
            },
          },
        },
        treatmentsFamily: {
          confirmedTitle: "Tipo de mica",
          title: "Seleccione el tipo de lentes",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          blueLight: {
            title: "Filtro de luz azul",
            description:
              "Reduce la exposición a la luz azul de las pantallas digitales, lo que puede ayudar a prevenir la fatiga ocular.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "Un par para interiores y exteriores con micas Transitions®: se oscurecen rápidamente y se vuelven transparentes, para que nunca tengas que cambiar de lentes.",
            imageFileName: "",
          },
          clear: {
            title: "Transparente",
            description:
              "Micas tradicionales y translúcidas, perfectas para el uso diario",
            imageFileName: "",
          },
          sun: {
            title: "Sol",
            description: "Elige entre diferentes colores y tonos de micas.",
            imageFileName: "",
          },
        },
        addOns: {
          confirmedTitle: "Completa tus micas",
          label: "Ya está incluido con tu mica",
          title: "Completa tus micas",
          reviewTitle: "Acabados",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
        },
      },
      prescription: {
        toggleLabel:
          "Agrega tus valores de graduación, te ayudaremos a encontrar los cristales perfectos para satisfacer tus necesidades de visión.",
        prescriptionTitle:
          "Agrega tus valores de graduación, te mostraremos los cristales perfectos para satisfacer tus necesidades de visión.",
        rightEye: "(Ojo derecho)",
        rightEyeInitials: "OD",
        leftEye: "(Ojo izquierdo)",
        leftEyeInitials: "OS",
        readPrescription: "Cómo leer tu receta",
        addLabel: "También tengo un valor de adición",
        applyButton: "Aplicar y continuar",
        submitButton: "Enviar",
        clearButton: "Borrar todo",
        skipButton: "Saltar por ahora",
        compatibleFramesButton: "Comprar armazones compatibles",
        doctorDetails:
          "Te pediremos una copia de tu receta o los detalles de tu doctor durante o después de confirmar la compra",
        missingValueError: "Agrega tus valores para continuar",
        positiveNegativeError:
          "Introdujiste un valor positivo y un valor negativo, lo cual no es muy común. Te sugerimos revisar tu receta.",
        compatibleFrameError:
          "¡Lo sentimos! El armazón que seleccionaste no es compatible con los valores de tu receta.",
        compatibleFrameSubLabel:
          "No te preocupes, tenemos armazones que se adaptan a tu receta.",
        axisError: "¡Los valores de EJE que introdujiste son incorrectos!",
        axisSubError: "Estos deben ser entre 0 y 180.",
        axisSubErrorZero:
          "Si el valor de CIL. es más alto o bajo que 0, los valores de EJE no pueden ser 0.",
        editLabel: "Editar receta",
        pdHelp: "¿Qué es esto?",
        twoPDNumbers: "Tengo dos números de DIP",
        missingInputMessage:
          "Agrega los valores faltantes para completar la carga de tu receta y te recomendaremos los cristales más adecuados.",
        defaultInputMessage:
          "Agrega tus valores de ESF. y CIL. y te recomendaremos los cristales más adecuados para tus necesidades de visión.",
        skipMessage: "Más adelante, te pediremos completar o cargar tu receta",
        readyMessage: "¡Ya te podemos sugerir los cristales adecuados para ti!",
        bestPrescription: "Los mejores para tu receta",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Necesario para continuar",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Precio del armazón + cristales",
          frame: "Precio del armazón",
          total: "Total",
        },
        free: "Gratis",
        included: "Incluido",
        frameLensStartingAt: "Armazón + micas en",
        frameLens: "Armazón + micas",
        startingAt: "A partir de",
      },
      noTreatment: "No treatment",
      learnMoreMade: "Ver cómo se hace",
      learnMoreTreatment: "Ver el tratamiento",
      exitTitle: "'¿SEGURO QUE QUIERES SALIR?'",
      exitSubtitle: "Tu selección de cristales no se guardará",
      exitYes: "Sí, salir",
      exitContinueEditing: "Seguir editando",
      exitSave: "Guardar y seguir comprando",
      insuranceButtonLabel: "Agregar beneficios del seguro",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Se aplicaron los beneficios del seguro",
      applyingInsurance: "Aplicar beneficios del seguro",
      frameSize: "Precio del armazón",
      fallbackImageMessageTitle:
        "La vista previa del color de micas no está disponible",
      fallbackImageMessageSubtitle:
        "No te preocupes, recibirás tu armazón con el color de micas que seleccionaste.",
      proceedAsIs: "Proceder tal como están",
      yourSelections: "Tus selecciones",
      editButton: "Editar",
    },
  },
};
