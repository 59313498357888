import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { BrandComponent } from "../common/BrandComponent";

export type SelectFromMyAccountProps = {
  isLoggedIn: () => boolean;
  prescriptions: any[];
  login: (c) => Promise<void>;
  onSelect: (p) => void;
  onChangeMethod: () => void;
};

export function SelectFromMyAccount(props: SelectFromMyAccountProps) {
  useEffect(() => {
    !props.isLoggedIn() && props.login(props.onChangeMethod);
  }, []);

  return (
    <>
      {!props.isLoggedIn() && null}
      {props.isLoggedIn() &&
        (props.prescriptions.length > 0
          ? props.prescriptions.map((prescription, i) => (
              <BrandComponent
                componentName="PrescriptionFromMyAccountCard"
                parameter={{
                  id: prescription.id,
                  name: prescription.name,
                  lastUpdate: prescription.lastUpdate,
                  onSelect: () => props.onSelect(prescription),
                }}
                key={i}
              />
            ))
          : createPortal(
              <BrandComponent
                componentName="NoPrescriptionFromMyAccountModal"
                parameter={{
                  onClick: props.onChangeMethod,
                }}
              />,
              //@ts-ignore
              document.getElementById("RxcModals")
            ))}
    </>
  );
}
