import axios from "axios";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkGVP, getFrameOnlyKey } from "../ConfigLoader";
import { workflow } from "../models/Workflow";
import { useCurrentBrand } from "./useCurrentBrand";
import { useCurrentPackages } from "./useCurrentPackages";
import { useCurrentType } from "./useCurrentType";

export function useContextualMessage() {
  const ctxMsgBaseUrl = useSelector(
    (state: any) => state.config?.baseURLs?.contextualMessages
  );
  const language = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const currentBrand = useCurrentBrand();
  const currentType = useCurrentType();
  const currentPackages = useCurrentPackages();
  const [subtitle, setSubtitle] = useState(null);
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configData = useSelector((state: any) => state.config?.data);
  const isFrameOnlySelected = () => {
    let configFrameOnly = getFrameOnlyKey(configPackages, configData);
    let typeSelection = workflow
      .getAllCurrentParameterStep()
      .find((par) => par.key === "type")?.value;
    return typeSelection === configFrameOnly;
  };
  const getCorrectKey = (key: string, isEyeglasses: boolean) => {
    let ret = null;
    switch (key) {
      case "Type":
        ret = "type";
        break;
      case "Brand":
        ret = "brand";
        break;
      case "Thickness":
        ret = "thickness";
        break;
      case "DesignType":
        ret = "design-type";
        break;
      case "LensColor":
        ret = isEyeglasses ? "sun-step" : "lens-color";
        break;
      case "TreatmentsFamily":
        ret = "treatment-need";
        break;
      case "GVPTreatment":
        ret = "gvp-selection";
        break;
      case "AddOns":
        ret = "treatment-addon";
        break;
      case "Review":
        ret = isFrameOnlySelected() ? "review-frameOnly" : "review";
        break;
    }
    return ret;
  };
  const callFallBack = (currentMode: any, brand: any) => {
    let url =
      ctxMsgBaseUrl +
      "/" +
      currentMode +
      "/" +
      brand +
      "/rxc-ctx-msg_en_US.json";
    axios.get(url).then((res) => {
      let data = null;
      let isEyeglasses = currentType === "EYEGLASSES";
      let isGVP = checkGVP(currentPackages);
      if (isEyeglasses && !isGVP) {
        data = res.data.eye;
      } else if (isEyeglasses && isGVP) {
        data = res.data["gvp-eye"];
      } else if (!isEyeglasses && !isGVP) {
        data = res.data.sun;
      } else {
        data = res.data["gvp-sun"];
      }
      let correctKey = getCorrectKey(currentStep.key, isEyeglasses);
      if (correctKey) {
        let msg = data[correctKey];
        if (msg.trim() !== "") {
          setSubtitle(HTMLReactParser(msg));
        }
      }
    });
  };
  useEffect(() => {
    if (
      currentStep &&
      currentBrand &&
      currentType &&
      currentPackages &&
      ctxMsgBaseUrl &&
      language
    ) {
      if (process.env.DEV) return;
      let currentMode = process.env.CURRENT_MODE;
      if (currentMode === "development") currentMode = "dev";
      else if (currentMode === "standalone" || currentMode === "uat")
        currentMode = "test";
      else if (currentMode === "production") currentMode = "prod";
      let brand = currentBrand === "default" ? "lenscrafters" : currentBrand;
      let url =
        ctxMsgBaseUrl +
        "/" +
        currentMode +
        "/" +
        brand +
        "/rxc-ctx-msg_" +
        language +
        ".json";
      axios
        .get(url)
        .then((res) => {
          let data = null;
          let isEyeglasses = currentType === "EYEGLASSES";
          let isGVP = checkGVP(currentPackages);
          if (isEyeglasses && !isGVP) {
            data = res.data.eye;
          } else if (isEyeglasses && isGVP) {
            data = res.data["gvp-eye"];
          } else if (!isEyeglasses && !isGVP) {
            data = res.data.sun;
          } else {
            data = res.data["gvp-sun"];
          }
          let correctKey = getCorrectKey(currentStep.key, isEyeglasses);
          if (correctKey) {
            let msg = data[correctKey] || "";
            if (msg.trim() !== "" || isFrameOnlySelected()) {
              setSubtitle(HTMLReactParser(msg));
            } else {
              callFallBack(currentMode, brand);
            }
          }
        })
        .catch((err) => {
          console.error("Error while retreiving contextual message: " + err);
          callFallBack(currentMode, brand);
        });
    }
  }, [
    currentStep,
    currentBrand,
    currentType,
    currentPackages,
    ctxMsgBaseUrl,
    language,
  ]);
  return subtitle;
}
