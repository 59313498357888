import React from "react";
import { useSelector } from "react-redux";
import { useCurrentBrand } from "@hooks";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import "./default.module.scss";

interface ExitModalOpt {
  onContinueEditing: () => void;
  onExit: () => void;
  onSave: () => void;
}

export function ExitModal({ onContinueEditing, onExit, onSave }: ExitModalOpt) {
  const exitModalTitle = useTranslate("exitTitle");
  const exitModalSubtitle = useTranslate("exitSubtitle");
  const exitModalExitButton = useTranslate("exitYes");
  const exitModalContinueButton = useTranslate("exitContinueEditing");
  const exitModalSave = useTranslate("exitSave");

  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const isSaveAndContinueDisabled = useSelector(
    (state: any) => state.config.layoutSettings?.disableSaveAndContinue
  );
  const isCostaBrand = useCurrentBrand() === "costa";

  const getDataElementIdForBack = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-BackToProdConfirm";
  };

  const handleContinueEditing = () => {
    if (onContinueEditing) {
      onContinueEditing();
    }
  };

  const handleExit = () => {
    if (onExit) {
      onExit();
    }
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
    }
  };

  return (
    <div className={"ExitModal__container"}>
      <div className={"ExitModal__container__overlay"}></div>
      <div className={"ExitModal__container__popup"}>
        <div className={"ExitModal__container__title"}>{exitModalTitle}</div>
        <div className={"ExitModal__container__subtitle"}>
          {exitModalSubtitle}
        </div>
        <div className={"ExitModal__container__buttonsContainer"}>
          <GenericButton
            noArrow={true}
            className={"ExitModal__container__buttonsContainer__buttonExit"}
            handleClick={handleExit}
            dataElementId={getDataElementIdForBack()}
          >
            {exitModalExitButton}
          </GenericButton>
          <GenericButton
            noArrow={true}
            className={"ExitModal__container__buttonsContainer__buttonContinue"}
            handleClick={handleContinueEditing}
          >
            {exitModalContinueButton}
          </GenericButton>
        </div>
        {!isCostaBrand &&
          currentStep &&
          currentStep === workflow.getMaxStep(workflow.stepChain).progressive &&
          onSave &&
          (!isSaveAndContinueDisabled ||
            isSaveAndContinueDisabled !== true) && (
            <a className={"ExitModal__container__save"} onClick={handleSave}>
              {exitModalSave}
            </a>
          )}
      </div>
    </div>
  );
}
