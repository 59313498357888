import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCurrentBrand } from "./useCurrentBrand";

type IPDOutput = {
  state: {
    PD1: number | null | undefined;
    PD2: number | null | undefined;
    showBothPdOptions: {
      value: boolean;
      ignoreSplitLogic: boolean;
    };
    showPDWarning: boolean;
    pdValueNotSelectedError: boolean;

    setPD1: (val: number) => void;
    setPD2: (val: number) => void;
    initializeFromPrescriptionObject: (obj) => void;
    setPDvalueNotSelectedError: (b: boolean) => void;
    resetPDState: Function;
    setShowBothPdOptions: Function;
    checkPDSelected: Function;
  };
};

type IPDInput = {
  prescription: any;
};

export function usePupillaryDistance(args: IPDInput): IPDOutput {
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const brand = useCurrentBrand();
  const [showBothPdOptions, setShowBothPdOptions] = useState({
    value: false,
    ignoreSplitLogic: false,
  });
  const [PD1, setPD1] = useState<number | null>(
    enableDigitalOptometry ? null : 63
  );

  const [PD2, setPD2] = useState<number | null>(null);
  const [showPDWarning, setShowPDWarning] = useState(false);
  const [pdValueNotSelectedError, setPDvalueNotSelectedError] = useState(false);
  useEffect(() => {
    if (showBothPdOptions && !showBothPdOptions.ignoreSplitLogic) {
      if (showBothPdOptions.value && PD1) {
        let valueToSet = Math.floor(PD1 / 2);
        setPD1(valueToSet);
        setPD2(valueToSet);
      } else {
        if (
          (enableDigitalOptometry && PD1) ||
          (!enableDigitalOptometry && PD1 !== 63)
        ) {
          setPD1((prev) => {
            return prev && (prev % 2 === 0 ? prev * 2 : prev * 2 + 1);
          });
        }
        setPD2(null);
      }
    }
  }, [showBothPdOptions]);

  useEffect(() => {
    if (brand === "opsm") {
      setPD1(null);
    }
  }, [brand]);

  useEffect(() => {
    if (
      (!showBothPdOptions.value && PD1 && PD1 < 55) ||
      (!showBothPdOptions.value && PD1 && PD1 >= 76) ||
      (showBothPdOptions.value && PD1 && PD1 <= 27 && PD2 && PD2 <= 27) ||
      (showBothPdOptions.value && PD1 && PD1 >= 38 && PD2 && PD2 >= 38)
    ) {
      setShowPDWarning(true);
    } else if (showPDWarning) {
      setShowPDWarning(false);
    }
  }, [PD1, PD2]);

  useEffect(() => {
    /* if (brand === "opsm") {
      setPD1(null);
    } */
    initializeFromPrescriptionObject(args.prescription);
  }, [args.prescription]);

  const initializeFromPrescriptionObject = (prescObj) => {
    if (prescObj?.PD) {
      if (prescObj.PD.OD && prescObj.PD.OS) {
        setPD1(parseFloat(prescObj.PD.OD));
        setPD2(parseFloat(prescObj.PD.OS));
        setShowBothPdOptions({
          value: true,
          ignoreSplitLogic: true,
        });
      } else if (prescObj.PD.OD && !prescObj.PD.OS) {
        setPD1(parseFloat(prescObj.PD.OD));
      }
    }
  };

  const setPD1Fn = (val) => {
    setPD1(parseFloat(val));
    setPDvalueNotSelectedError(false);
  };

  const setPD2Fn = (val) => {
    setPD2(parseFloat(val));
    setPDvalueNotSelectedError(false);
  };

  const resetPDState = () => {
    setPD1(enableDigitalOptometry || brand === "opsm" ? null : 63);
    setPD2(null);
  };

  const checkPDSelected = () => {
    if (showBothPdOptions.value) {
      if (PD1 && PD2) {
        setPDvalueNotSelectedError(false);
        return true;
      } else {
        setPDvalueNotSelectedError(true);
        return false;
      }
    } else {
      if (PD1) {
        setPDvalueNotSelectedError(false);
        return true;
      } else {
        setPDvalueNotSelectedError(true);
        return false;
      }
    }
  };

  return {
    state: {
      PD1,
      PD2,
      setPD1: setPD1Fn,
      setPD2: setPD2Fn,
      initializeFromPrescriptionObject,
      setShowBothPdOptions,
      resetPDState,
      setPDvalueNotSelectedError,
      pdValueNotSelectedError,
      showBothPdOptions,
      showPDWarning,
      checkPDSelected,
    },
  };
}
