import { sortBy } from "fp-ts/lib/Array";
import { Either, fold, left, right } from "fp-ts/lib/Either";
import { fromCompare, Ord } from "fp-ts/lib/Ord";
import { workflowActions } from "../../redux/slices/workflow";
import { store } from "../../redux/Store";
import { checkGVP } from "../ConfigLoader";
import { IWorkflow } from "../meta/IWorkflow";
import { Parameter } from "./Parameter";
import { Step } from "./Step";

export class Workflow implements IWorkflow<number, Step, Parameter> {
  stepChain: Step[];
  stepCounter: number;
  private _currentStep: Step;
  firstStepWithManualSelection: Step = null;
  private _ignoreFirstManualStepOverride = false;

  get currentStep(): Step {
    return this._currentStep;
  }
  set currentStep(currentStep: Step) {
    this._currentStep = currentStep;
    this.notifyCurrentStepChanged();
  }

  public initByKey = (key: string): Step => {
    const finded = this.findByStepKey(key);
    if (finded) {
      this.currentStep = finded;
      this.stepCounter = finded.progressive;
    }
    return finded;
  };

  public cleanStepChain = () => {
    this.currentStep = null;
  };

  public hasManualSelectionBeenDone = () => {
    return (
      this.firstStepWithManualSelection?.progressive <
      this.currentStep?.progressive
    );
  };

  public willManualSelectionBeDone = () => {
    return (
      !this.firstStepWithManualSelection ||
      this.firstStepWithManualSelection?.progressive ===
        this.currentStep?.progressive
    );
  };

  public isCurrentStepGVP = (currentPackages: any[]) => {
    return checkGVP(currentPackages) && this.willManualSelectionBeDone();
  };

  protected goNext = (
    params: Parameter[],
    skipped?: boolean
  ): Either<Error, Step> => {
    const nextChain = this.getNextChain();
    if (nextChain && nextChain.length) {
      if (skipped) {
        this.setSkippedCurrent();
      }

      const nextStep = this.getMinStep(nextChain);
      nextStep.params = params;
      nextStep.navigated = true;
      this.setParam(nextStep.progressive, params);

      if (
        !this.stepIsFirst(nextStep) &&
        (this.currentStep.key !== "AdvancedPrescription" ||
          (this.currentStep.key === "AdvancedPrescription" &&
            !this.currentStep.navigated)) &&
        !this.stepIsLast(nextStep) &&
        skipped &&
        !this._ignoreFirstManualStepOverride
      ) {
        this.firstStepWithManualSelection = nextStep;
      }

      if (
        !skipped &&
        this.currentStep &&
        !this.stepIsFirst(this.currentStep) &&
        this.currentStep.key !== "AdvancedPrescription"
      ) {
        this._ignoreFirstManualStepOverride = true;
        if (!this.firstStepWithManualSelection) {
          this.firstStepWithManualSelection = this.currentStep;
        }
      }

      return right(nextStep);
    }
    return left(new Error("Next step not found"));
  };

  protected goStep = (
    key: string,
    params: Parameter[],
    skipped?: boolean
  ): Either<Error, Step> => {
    const nextChain = this.getNextChain();
    if (nextChain && nextChain.length) {
      if (skipped) {
        this.setSkippedCurrent();
      }

      const findedStep = nextChain.find((s) => s.key === key);
      findedStep.params = params;
      findedStep.navigated = true;
      this.setParam(findedStep.progressive, params);

      if (
        !this.stepIsFirst(findedStep) &&
        !this.stepIsLast(findedStep) &&
        (this.currentStep.key !== "AdvancedPrescription" ||
          (this.currentStep.key === "AdvancedPrescription" &&
            !this.currentStep.navigated)) &&
        skipped &&
        !this._ignoreFirstManualStepOverride
      ) {
        this.firstStepWithManualSelection = findedStep;
      }

      if (
        !skipped &&
        this.currentStep &&
        !this.stepIsFirst(this.currentStep) &&
        this.currentStep.key !== "AdvancedPrescription"
      ) {
        this._ignoreFirstManualStepOverride = true;
        if (!this.firstStepWithManualSelection) {
          this.firstStepWithManualSelection = this.currentStep;
        }
      }

      return right(findedStep);
    }
    return left(new Error("Next step not found"));
  };

  public goNextStep = (params: Parameter[], skipped?: boolean) =>
    fold(
      (ex: Error) => {
        console.log(ex.message);
      },
      (nextStep: Step) => {
        this.currentStep = nextStep;
      }
    )(this.goNext(params, skipped));
  public goToStep = (key: string, params: Parameter[], skipped?: boolean) =>
    fold(
      (ex: Error) => {
        console.log(ex.message);
      },
      (nextStep: Step) => {
        this.currentStep = nextStep;
      }
    )(this.goStep(key, params, skipped));

  public setParam = (stepProgressive: number, params: Parameter[]) => {
    this.stepChain = this.stepChain.map((s) => {
      if (s.progressive === stepProgressive) s.params = params;

      return s;
    });
    if (this.currentStep && this.currentStep.progressive === stepProgressive) {
      this.currentStep.params = params;
    }
  };

  public addParams = (stepProgressive: number, params: Parameter[]) => {
    this.stepChain = this.stepChain.map((s) => {
      if (s.progressive === stepProgressive) {
        s.params = [...s.params, ...params];
      }

      return s;
    });
    if (this.currentStep && this.currentStep.progressive === stepProgressive) {
      this.currentStep.params = [...this.currentStep.params, ...params];
    }
  };

  public getLadindingStepFromBack = () => {
    const prevChain = this.getPrevChain().filter((s) => s.navigated);
    if (prevChain && prevChain.length) {
      return this.getMaxStep(prevChain);
    }
  };

  public backToStep: (keyStep: number) => Either<Error, Step> = (
    keyStep: number
  ) => {
    {
      const prevChain = this.getPrevChain();
      if (prevChain && prevChain.length) {
        const findedStep = prevChain.find(
          (s) => s.progressive === keyStep && s.navigated
        );
        if (findedStep) {
          for (let i = findedStep.progressive; i < this.stepChain.length; i++) {
            this.stepChain[i].navigated = false;
          }
          this.clearParameterForStepPrev(findedStep);

          if (
            this.firstStepWithManualSelection &&
            findedStep.progressive <
              this.firstStepWithManualSelection.progressive
          ) {
            this.firstStepWithManualSelection = null;
          }

          right(findedStep);
        }
      }
    }
    return left(new Error("Prev step not found"));
  };

  public backToStepByKey: (keyStep: string) => Either<Error, Step> = (
    keyStep: string
  ) => {
    {
      const prevChain = this.getPrevChain();
      if (prevChain && prevChain.length) {
        const findedStep = prevChain.find(
          (s) => s.key.toLowerCase() === keyStep.toLowerCase()
        );
        if (findedStep) {
          for (let i = findedStep.progressive; i < this.stepChain.length; i++) {
            this.stepChain[i].navigated = false;
          }
          this.clearParameterForStepPrev(findedStep);

          if (
            this.firstStepWithManualSelection &&
            findedStep.progressive <
              this.firstStepWithManualSelection.progressive
          ) {
            this.firstStepWithManualSelection = null;
          }

          return right(findedStep);
        }
      }
    }
    return left(new Error("Prev step not found"));
  };

  public goPrev: () => Either<Error, Step> = () => {
    {
      const prevChain = this.getPrevChain().filter((s) => s.navigated);
      if (prevChain && prevChain.length) {
        const prevStep = this.getMaxStep(prevChain);

        for (let i = prevStep.progressive; i < this.stepChain.length; i++) {
          this.stepChain[i].navigated = false;
        }
        this.clearParameterForStepPrev(prevStep);

        if (
          this.firstStepWithManualSelection &&
          prevStep.progressive < this.firstStepWithManualSelection.progressive
        ) {
          this.firstStepWithManualSelection = null;
        }

        return right(prevStep);
      }
      return left(new Error("Prev step not found"));
    }
  };

  protected clearParameterForStepPrev = (arrivalStep: Step) => {
    const nextNavigatedStep = this.stepChain.filter(
      (p) =>
        p.progressive > arrivalStep.progressive && p.params && p.params.length
    )[0];
    if (nextNavigatedStep) {
      this.stepChain = this.stepChain.map((p) => {
        if (
          p.progressive >= nextNavigatedStep.progressive ||
          this.stepIsFirst(arrivalStep)
        )
          p.params = null;
        return p;
      });
    }
  };

  public goPrevStep = () =>
    fold(
      (ex: Error) => {
        console.log("Workflow goPrevStep() error: ", ex.message);
      },
      (prevStep: Step) => {
        this.currentStep = prevStep;
      }
    )(this.goPrev());

  protected ordByProgressiveAsc: Ord<Step> = fromCompare((x, y) =>
    x.progressive < y.progressive ? -1 : x.progressive > y.progressive ? 1 : 0
  );
  protected ordByProgressiveDesc: Ord<Step> = fromCompare((x, y) =>
    x.progressive > y.progressive ? -1 : x.progressive < y.progressive ? 1 : 0
  );
  protected findByStepProgressive = (key: number) =>
    this.stepChain?.find((s: Step) => s.progressive === key);
  public findByStepKey = (key: string) =>
    this.stepChain?.find(
      (s: Step) => s.key.toLowerCase() === key?.toLowerCase()
    );

  protected setSkippedCurrent = () => {
    this.stepChain = this.stepChain.map((s) => {
      if (s.key === this.currentStep.key) {
        s.navigated = false;
      }
      return s;
    });
  };

  protected minStepByProgressive(): (x: Step[]) => Step {
    return (x: Step[]) => sortBy([this.ordByProgressiveAsc])(x)[0];
  }
  protected maxStepByProgressive(): (x: Step[]) => Step {
    return (x: Step[]) => sortBy([this.ordByProgressiveDesc])(x)[0];
  }

  public getMinStep = this.minStepByProgressive();
  public getMaxStep = this.maxStepByProgressive();

  public getNextChain: () => Step[] = () => {
    if (!this.stepChain) return [];
    return this.stepChain.filter(
      (x) => !this.currentStep || x.progressive > this.currentStep.progressive
    );
  };

  public getPrevChain: () => Step[] = () => {
    if (!this.stepChain) return [];
    return this.stepChain.filter(
      (x) => x.progressive < this.currentStep?.progressive && x.navigated
    );
  };

  public getPrevAndCurrentChain: () => Step[] = () => {
    if (!this.stepChain) return [];
    return this.stepChain.filter(
      (x) => x.progressive <= this.currentStep?.progressive && x.navigated
    );
  };

  public getPrevAndCurrentChainIgnoreSkip: () => Step[] = () => {
    if (!this.stepChain) return [];
    return this.stepChain.filter(
      (x) => x.progressive <= this.currentStep?.progressive
    );
  };

  protected notifyCurrentStepChanged: () => void = () => {
    store.dispatch(workflowActions.setCurrentStep(this.currentStep));
  };

  public getAllCurrentParameterStep: (limitStep?: string) => Parameter[] = (
    limitStep?: string
  ) => {
    let ret: Parameter[] = [];
    if (this.stepChain) {
      this.stepChain.forEach((s) => {
        if (s.params) ret = [...ret, ...s.params];
      });
    }
    return ret;
  };

  public getPrevCurrentParameterStep: (limitStep?: string) => Parameter[] = (
    limitStep?: string
  ) => {
    let ret: Parameter[] = [];
    const pcc = this.getPrevAndCurrentChainIgnoreSkip();
    if (pcc) {
      pcc.forEach((s) => {
        if (s.params) ret = [...ret, ...s.params];
      });
    }

    return ret;
  };

  public isFirstStep = (): boolean => {
    return this.currentStep
      ? this.currentStep.progressive ===
          this.getMinStep(this.stepChain).progressive
      : false;
  };

  public isLastStep = (): boolean => {
    return this.currentStep
      ? this.currentStep.progressive ===
          this.getMaxStep(this.stepChain).progressive
      : false;
  };

  public stepIsLast = (step: Step): boolean => {
    return step
      ? step.progressive === this.getMaxStep(this.stepChain).progressive
      : false;
  };

  public stepIsFirst = (step: Step): boolean => {
    return step
      ? step.progressive === this.getMinStep(this.stepChain).progressive
      : false;
  };
}

export const workflow = new Workflow();
