import { useCurrentBrand } from "@hooks";
import React, { useMemo } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { CustomCheckbox } from "./CustomCheckbox";
import { SimpleTextError } from "./SimpleTextError";
import { isMobile } from "react-device-detect";

export const AgreementCheckbox = (props) => {
  const agreementText = useTranslate(
    "steps.advancedPrescription.manual.alerts.agreementText"
  );
  const agreementTextMobile = useTranslate(
    "steps.advancedPrescription.manual.alerts.agreementTextMobile"
  );
  const requiredToProceed = useTranslate(
    "steps.advancedPrescription.manual.alerts.requiredToProceed"
  );
  const currentBrand = useCurrentBrand();

  return (
    <>
      <div className={"AgreementCheckbox__container"}>
        <CustomCheckbox
          value={props.agreement}
          setter={props.setAgreement}
          formErrorName={"agreement"}
          formErrors={props.formErrors}
          setFormErrors={props.setFormErrors}
          disabled={props.disabled}
          marginTop={true}
        />
        <label
          className={`AgreementCheckbox__text ${
            props.disabled ? "disabled" : ""
          }`}
          onClick={() =>
            !props.disabled && props.setAgreement(!props.agreement)
          }
        >
          {!isMobile ? (
            agreementText
          ) : currentBrand === "opsm" ? (
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{ __html: agreementTextMobile }}
            />
          ) : (
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{ __html: agreementText }}
            />
          )}
          {currentBrand === "opsm" && isMobile && (
            <div className="AgreementCheckbox__tooltip">
              <div className="AgreementCheckbox__tooltip__body">
                {props.tooltipText}
              </div>
            </div>
          )}
        </label>
        {currentBrand === "opsm" && !isMobile && (
          <div className="AgreementCheckbox__tooltip">
            <div className="AgreementCheckbox__tooltip__body">
              {props.tooltipText}
            </div>
          </div>
        )}
      </div>
      {props.formErrors["agreement"] ? (
        <SimpleTextError message={requiredToProceed} />
      ) : null}
    </>
  );
};
