import React from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../../redux/slices/modals";

export const Subtitle = () => {
  const subtitle = useTranslate("steps.advancedPrescription.manual.subtitle");
  const howToRead = useTranslate("steps.advancedPrescription.manual.howToRead");
  const reduxDispatch = useDispatch();

  return (
    <>
      <div className={"FullPrescriptionForm__subtitle"}>{subtitle}</div>
      <a
        tabIndex={0}
        className={"FullPrescriptionForm__readInfo"}
        data-element-id="X_X_LensPanel_Type-ValuesHowToRead"
        onClick={() =>
          reduxDispatch(modalsActions.setShowPrescriptionModalInfo(true))
        }
      >
        {howToRead}
      </a>
    </>
  );
};
