import { flatten, groupBy, sortedIndexBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  blueLightRule,
  cleanRule,
  getPriceFromPackageAndCustomFiltered,
  getStepBadgeType,
  sunRule,
  transitionRule,
} from "../ConfigLoader";
import { useTranslation } from "../translations/TranslationHooks";
import { useTreatmentsMapper } from "./useTreatmentsMapper";

export function useTreatmentsFamilyMapper() {
  //const options = useSelector((state: any) => state.config.treatmentsOptions);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  /*   const lensPackage = useSelector((state: any) => state.config?.lensesData?.packages);
  
      const lensPackageMap = lensPackage.map(x => x.lensPackage.brand)
      var attributesArray = lensPackageMap.filter(function(elem, pos) {
          return lensPackageMap.indexOf(elem) == pos;});
      attributesArray = attributesArray.filter(function (el) {
          return el != null;});
      const lensPackageFinder = (prop) => {
          var matchingAttributes=""
          for (var i = 0; i < attributesArray.length; i++) { 
               matchingAttributes =  configContent[prop][attributesArray[i]].title;
          }
          return matchingAttributes
      }
      const Pippo = lensPackageFinder("blueLight")
      console.log("lensFunction" , Pippo) */

  const translate = useTranslation();
  const treatmentsMapper = useTreatmentsMapper();

  const optionsToCheck = [
    {
      key: "clear",
      rule: cleanRule,
    },
    {
      key: "blueLight",
      rule: blueLightRule,
    },
    {
      key: "transition",
      rule: transitionRule,
    },
    {
      key: "sun",
      rule: sunRule,
    },
  ];

  const [ret, setRet] = useState(null);

  const getOptP = (
    opt: string,
    treatmentName: string,
    currentPackages,
    filteredPackages
  ) => {
    let ret: any = {
      id: opt,
      title: treatmentName,
      included: false,
    };
    let priceObj = null;
    let tfOpt = null;

    switch (opt) {
      case "clear":
        priceObj = getPriceFromPackageAndCustomFiltered(
          currentPackages,
          filteredPackages
        );
        ret.title =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].title
            ? configContent.treatmentFamily[opt].title
            : translate(`steps.treatmentsFamily.clear.title`);
        ret.description =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].description
            ? configContent.treatmentFamily[opt].description
            : translate(`steps.treatmentsFamily.clear.description`);
        ret.imageUrl =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].imageFileName
            ? configContent.treatmentFamily[opt].imageFileName
            : "clear-icon";
        break;
      case "sun":
        priceObj = getPriceFromPackageAndCustomFiltered(
          currentPackages,
          filteredPackages
        );
        ret.title =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].title
            ? configContent.treatmentFamily[opt].title
            : translate(`steps.treatmentsFamily.sun.title`);
        ret.description =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].description
            ? configContent.treatmentFamily[opt].description
            : translate(`steps.treatmentsFamily.sun.description`);
        ret.imageUrl =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].imageFileName
            ? configContent.treatmentFamily[opt].imageFileName
            : "sun-icon";
        break;
      case "blueLight":
        priceObj = getPriceFromPackageAndCustomFiltered(
          currentPackages,
          filteredPackages
        );
        tfOpt = filteredPackages[0].lensPackage.treatmentFamily;
        ret.title =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[tfOpt] &&
          configContent.treatmentFamily[tfOpt].title
            ? configContent.treatmentFamily[tfOpt].title
            : configContent.blueLight &&
              Object.keys(configContent.blueLight).length !== 0
            ? configContent.blueLight[
                Object.keys(configContent.blueLight).filter(
                  (key) => key === filteredPackages[0].lensPackage.blueLight
                )[0]
              ].title
            : translate(`steps.treatmentsFamily.blueLight.title`);
        ret.description =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[tfOpt] &&
          configContent.treatmentFamily[tfOpt].description
            ? configContent.treatmentFamily[tfOpt].description
            : translate(`steps.treatmentsFamily.blueLight.description`);
        ret.imageUrl =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[tfOpt] &&
          configContent.treatmentFamily[tfOpt].imageFileName
            ? configContent.treatmentFamily[tfOpt].imageFileName
            : configContent.blueLight &&
              Object.keys(configContent.blueLight).length !== 0
            ? configContent.blueLight[
                Object.keys(configContent.blueLight).filter(
                  (key) => key === filteredPackages[0].lensPackage.blueLight
                )[0]
              ].imageFileName
            : null;
        ret.textBadge =
          configContent.treatmentFamily && configContent.treatmentFamily[tfOpt]
            ? configContent.treatmentFamily[tfOpt].badge
            : configContent.blueLight &&
              Object.keys(configContent.blueLight).length !== 0
            ? configContent.blueLight[
                Object.keys(configContent.blueLight).filter(
                  (key) => key === filteredPackages[0].lensPackage.blueLight
                )[0]
              ].badge
            : null;
        ret.badge =
          configContent.treatmentFamily && configContent.treatmentFamily[tfOpt]
            ? getStepBadgeType(configContent.treatmentFamily[tfOpt].badge)
            : configContent.blueLight &&
              Object.keys(configContent.blueLight).length !== 0
            ? getStepBadgeType(
                configContent.blueLight[
                  Object.keys(configContent.blueLight).filter(
                    (key) => key === filteredPackages[0].lensPackage.blueLight
                  )[0]
                ].badge
              )
            : null;
        break;
      case "transition":
        priceObj = getPriceFromPackageAndCustomFiltered(
          currentPackages,
          filteredPackages
        );
        tfOpt = filteredPackages[0].lensPackage.treatmentFamily;
        ret.title =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[tfOpt] &&
          configContent.treatmentFamily[tfOpt].title
            ? configContent.treatmentFamily[tfOpt].title
            : configContent.transition &&
              Object.keys(configContent.transition).length !== 0
            ? configContent.transition[
                Object.keys(configContent.transition).filter(
                  (key) => key === filteredPackages[0].lensPackage.transition
                )[0]
              ].title
            : translate(`steps.treatmentsFamily.transition.title`);
        ret.description =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[tfOpt] &&
          configContent.treatmentFamily[tfOpt].description
            ? configContent.treatmentFamily[tfOpt].description
            : translate(`steps.treatmentsFamily.transition.description`);
        ret.imageUrl =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[tfOpt] &&
          configContent.treatmentFamily[tfOpt].imageFileName
            ? configContent.treatmentFamily[tfOpt].imageFileName
            : configContent.transition &&
              Object.keys(configContent.transition).length !== 0
            ? configContent.transition[
                Object.keys(configContent.transition).filter(
                  (key) => key === filteredPackages[0].lensPackage.transition
                )[0]
              ].imageFileName
            : null;
        ret.textBadge =
          configContent.treatmentFamily && configContent.treatmentFamily[tfOpt]
            ? configContent.treatmentFamily[tfOpt].badge
            : configContent.transition &&
              Object.keys(configContent.transition).length !== 0
            ? configContent.transition[
                Object.keys(configContent.transition).filter(
                  (key) => key === filteredPackages[0].lensPackage.transition
                )[0]
              ].badge
            : null;
        ret.badge =
          configContent.treatmentFamily && configContent.treatmentFamily[tfOpt]
            ? getStepBadgeType(configContent.treatmentFamily[tfOpt].badge)
            : configContent.transition &&
              Object.keys(configContent.transition).length !== 0
            ? getStepBadgeType(
                configContent.transition[
                  Object.keys(configContent.transition).filter(
                    (key) => key === filteredPackages[0].lensPackage.transition
                  )[0]
                ].badge
              )
            : null;
        break;
    }
    if (priceObj) {
      ret.price = priceObj.price;
      ret.discount = priceObj.discount;
      ret.insPrice = priceObj.insPrice;
    }
    return ret;
  };

  const getTreatments = (
    currentPackages: { validRXPackages: any[]; invalidRXPackages: any[] },
    currentPackage: any
  ) => {
    let ret = [];
    let sortedOptions = [];

    if (!currentPackages) {
      return ret;
    }

    optionsToCheck.forEach((option) => {
      const found = currentPackages.validRXPackages.filter((cp) =>
        option.rule(cp.lensPackage)
      );
      const greyOutFound = currentPackages.invalidRXPackages.filter((cp) =>
        option.rule(cp.lensPackage)
      );
      if ((found && found.length) || (greyOutFound && greyOutFound.length)) {
        let thereAreNonIncludedOptions =
          Object.keys(groupBy(found, "lensPackage." + option.key)).length > 1;
        //get all unique treatment for packages that have a family
        let treatment = [];
        found.map((el) => {
          if (
            el.lensPackage.treatmentFamily &&
            !treatment.includes(el.lensPackage[option.key])
          ) {
            treatment.push(el.lensPackage[option.key]);
          }
        });
        //if are more than one (therefore the pachages are more then one) then set areNonIncludedOptions = false
        if (
          thereAreNonIncludedOptions &&
          configContent.treatmentFamily &&
          treatment.length > 1
        ) {
          thereAreNonIncludedOptions = false;
        }
        //
        let tFamilyOption =
          found && found.length
            ? getOptP(
                option.key,
                option.key,
                currentPackages.validRXPackages,
                found
              )
            : null;
        let greyOutTfamilyOption =
          greyOutFound && greyOutFound.length
            ? getOptP(
                option.key,
                option.key,
                currentPackages.invalidRXPackages,
                greyOutFound
              )
            : null;

        if (greyOutTfamilyOption) {
          greyOutTfamilyOption["disabled"] = true;
        }

        if (thereAreNonIncludedOptions && tFamilyOption) {
          tFamilyOption.thereAreNonIncludedOptions = thereAreNonIncludedOptions;
        }
        if (tFamilyOption) {
          ret.push(tFamilyOption);
        }

        if (
          (greyOutTfamilyOption && !tFamilyOption) ||
          (greyOutTfamilyOption && greyOutTfamilyOption.id !== tFamilyOption.id)
        ) {
          ret.push(greyOutTfamilyOption);
        }
      }
    });

    //RXC shows "Sun" tile always when sun lenses are available in lensesData
    //despite config.treatmentFamily value
    let treatmentFamily = ["sun"];
    let isBlue = currentPackages.validRXPackages.filter((el) =>
      blueLightRule(el.lensPackage)
    );
    let isTrans = currentPackages.validRXPackages.filter((el) =>
      transitionRule(el.lensPackage)
    );
    if (
      configContent.treatmentFamily &&
      Object.keys(configContent.treatmentFamily).length > 0
    ) {
      treatmentFamily = [
        isBlue.length > 0 && isBlue[0].lensPackage.treatmentFamily
          ? "blueLight"
          : null,
        isTrans.length > 0 && isTrans[0].lensPackage.treatmentFamily
          ? "transition"
          : null,
      ].filter((el) => el !== null);
    }

    let avaiabletreatments = treatmentsMapper(
      currentPackages,
      true,
      null,
      true,
      treatmentFamily
    );

    let newTreatments = avaiabletreatments.treatments;

    newTreatments = [
      ...newTreatments,
      ...ret
        .map((r) => {
          if (treatmentFamily.includes(r.id)) {
            let value = r.id == "transition" ? isTrans : isBlue;
            return {
              ...r,
              transitionId: r.id + "$" + value[0].lensPackage[r.id],
            };
          }
        })
        .filter((el) => el !== undefined),
    ];

    avaiabletreatments = {
      ...avaiabletreatments,
      treatments: newTreatments,
    };
    const options = optionsToCheck.reduce(
      (keys, option) => ({
        ...keys,
        [option.key]: [],
      }),
      {}
    );

    if (avaiabletreatments && avaiabletreatments.treatments) {
      avaiabletreatments.treatments.forEach((t) => {
        //RXPANEL-1817
        const tkey = t.id.split("$")[0];
        // const tValue = t.id.split("$")[1];

        options[tkey].splice(sortedIndexBy(options[tkey], t, "sequence"), 0, t);

        // if (
        //     tValue &&
        //     tValue != "null" &&
        //     tValue != "true" &&
        //     tValue != "false"
        // ) {
        //     ret = ret.filter((familyOpt) => familyOpt.id != tkey);

        //     ret.push(t);
        // }
      });
      sortedOptions = flatten(Object.values(options));
      return sortedOptions;
    }
  };
  useEffect(() => {
    if (treatmentsMapper && configContent && translate)
      setRet(
        () =>
          (
            currenPackages: {
              validRXPackages: any[];
              invalidRXPackages: any[];
            },
            currentPackage: any
          ) => {
            return getTreatments(currenPackages, currentPackage);
          }
      );
  }, [configContent, translate, treatmentsMapper]);

  return ret;
}
