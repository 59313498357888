import { isFunction } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../components/common/BrandComponent";
import GenericButton from "../../../components/common/GenericButton";
import { TranslateText } from "../../../components/common/TranslateText";
import {
  callInsuranceService,
  checkGVP,
  getCheapPackageFromList,
  getFrameOnlyKey,
} from "../../ConfigLoader";
import {
  useContextualMessage,
  useCurrentPackages,
  useCurrentPrices,
  useProductImage,
  useReviewIncludedTreatmentsMapper,
  useReviewSteps,
} from "@hooks";
import {
  useTranslate,
  useTranslation,
} from "../../translations/TranslationHooks";
import { useEditStep } from "../../WorkflowUtils";
import { StepComponentOptions } from "../StepComponent";
import HtmlParser from "html-react-parser";
import "./default.module.scss";
import { modalsActions } from "../../../redux/slices/modals";
import { workflow } from "../../models/Workflow";
import Image from "../../../components/common/Image";
import { useInstallmentsLabel } from "../../hooks";
import { workflowActions } from "../../../redux/slices/workflow";

/**
 * This component displays the 'review' step. It uses the
 * ```StepComponentOptions``` to work in his flow.
 * this component get the history of selection by method ```loadHistorySteps``` and treatmnets by method ```loadReviewTreatments```;
 * this component also call toogle insurance for package and addtocart to complete order.
 */

export function Review(props: StepComponentOptions) {
  const translation = useTranslation();
  const currentPrices = useCurrentPrices();
  const vatIncluded = useSelector(
    (state: any) => state.config?.data?.vatIncluded
  );
  const [pricesToDisplay, setPricesToDisplay] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [promoInPackage, setPromoInPackage] = useState("");

  const frameConfigData = useSelector(
    (state: any) => state.config?.data?.frame
  );

  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );

  const configData = useSelector((state: any) => state.config?.data);

  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  //FALLBACK
  const swatchImage = useSelector((state: any) => state.color.swatchImage);

  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const [historyStep, setHistoryStep] = useState(null);
  const [treatmentsStep, setTreatmentsStep] = useState(null);
  const currentTreatments = useSelector(
    (state: any) => state.workflow?.currentTreatments
  );
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const configPaymentInstallments = useSelector(
    (state: any) => state.config?.paymentInstallment
  );
  const promoType = useSelector((state: any) => state.workflow?.promoType);
  const { type, multiplePaymentInstallment: isMultiplePayments } =
    configPaymentInstallments;

  const onEdit = useEditStep();
  const { urlImage: productImagePath } = useProductImage();
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const addToBagLabel = useTranslate("steps.treatments.addToBag");

  const configActionLoadContent = useSelector(
    (state: any) => state.config?.actionsModule?.loadContent
  );

  //product container mobile
  const showFallbackImageMessage = useSelector(
    (state: any) => state.color?.showFallbackImageMessage
  );
  const showNotFoundImageMessage = useSelector(
    (state: any) => state.color?.showNotFoundImageMessage
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  //product container mobile

  const [promoBanners, setPromoBanners] = useState({
    desktop: null,
    mobile: null,
  });

  const ctxMessage = useContextualMessage();

  const [alreadyIncluded, setalreadyIncluded] = useState(null);

  const loadTreatments = useReviewIncludedTreatmentsMapper();

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);

  const [isIncludedOpen, setIsIncludedOpen] = useState(false);

  const currentPackages = useCurrentPackages();
  const paymentInstallmentsTypes = useSelector(
    (state: any) => state.config?.paymentInstallmentsTypes
  );

  const fallbackImageMessageTitle = useTranslate("fallbackImageMessageTitle");
  const fallbackImageMessageSubTitle = useTranslate(
    "fallbackImageMessageSubtitle"
  );

  const confirmedTitlesMap = {
    type: useTranslate("steps.type.confirmedTitle"),
    brand: useTranslate("steps.brand.confirmedTitle"),
    advancedPrescription: useTranslate(
      "steps.advancedPrescription.confirmedTitle"
    ),
    treatmentsFamily: useTranslate("steps.treatmentsFamily.confirmedTitle"),
    gvpTreatment: useTranslate("steps.gvpTreatment.confirmedTitle"),
    thickness: useTranslate("steps.thickness.confirmedTitle"),
    treatments: useTranslate("steps.treatments.confirmedTitle"),
    transitionColor: useTranslate("steps.transitionColor.confirmedTitle"),
    lensColor: useTranslate("steps.lensColor.confirmedTitle"),
    color: useTranslate("steps.color.confirmedTitle"),
    addOns: useTranslate("steps.addOns.confirmedTitle"),
  };

  const reduxDispatch = useDispatch();

  const getInsPriceForFrame = () => {
    return currentPrices.frame.insPrice || currentPrices.frame.insPrice === 0
      ? currentPrices.frame.insPrice
      : currentPrices.frame.price;
  };

  const getOfferPriceForFrame = () => {
    return isInsuranceEnabled
      ? insPriceForFrame
      : currentPrices.frame.price - currentPrices.frame.discount;
  };

  const { priceForFrame, insPriceForFrame, discountForFrame } = useMemo(() => {
    const priceForFrame = currentPrices?.frame.price;
    const offerForFrame = getOfferPriceForFrame();
    return {
      priceForFrame,
      insPriceForFrame: getInsPriceForFrame(),
      offerForFrame,
      discountForFrame: priceForFrame - offerForFrame,
    };
  }, [currentPrices, getInsPriceForFrame, getOfferPriceForFrame]);

  const installmentsLink = (multiplePayment: boolean) => {
    let translationPath = "";
    if (multiplePayment) {
      translationPath = "installmentsLabel";
    } else {
      translationPath = "installmentLabel";
    }
    return translation(`steps.review.paymentInstallments.${translationPath}`);
  };

  const isInsurable = () => {
    return !!configInsurance;
  };

  const reviewSteps = useReviewSteps();

  useEffect(() => {
    if (!alreadyIncluded && loadTreatments) {
      const { included, treatments } = loadTreatments(currentPackages);

      setalreadyIncluded({
        included: included.reverse(),
        treatments: treatments,
      });
    }
  }, [currentPackages, loadTreatments, alreadyIncluded]);

  const getReviewObjectForCart = (history: any) => {
    let ret = {};
    history.forEach((step, index) => {
      let stepObj = { sequence: index + 1 };
      stepObj["name"] = confirmedTitlesMap[step.id];

      if (step.id !== "addOns" || typeof step.title !== typeof []) {
        stepObj["description"] = step.title;
        if (
          step.strikePrice !== undefined &&
          step.strikePrice !== null &&
          !isNaN(step.strikePrice)
        ) {
          stepObj["listPrice"] = step.strikePrice;
        }
        if (
          step.offerPrice !== undefined &&
          step.offerPrice !== null &&
          !isNaN(step.offerPrice)
        ) {
          stepObj["offerPrice"] = step.offerPrice;
        }
      } else {
        let childrenArray = step.title?.map((selection, index) => {
          let retObj = { sequence: index + 1, name: selection };
          if (index === 0) {
            retObj["listPrice"] = step.strikePrice;
            retObj["offerPrice"] = step.offerPrice;
            if (
              isInsuranceEnabled &&
              currentPrices.lens.insPrice !== undefined &&
              currentPrices.lens.insPrice !== null &&
              !isNaN(currentPrices.lens.insPrice)
            ) {
              retObj["insPrice"] = currentPrices.lens.insPrice;
            }
          }
          return retObj;
        });
        stepObj["children"] = childrenArray;
      }
      ret[step.originalStep] = stepObj;
    });
    return ret;
  };

  useEffect(() => {
    if (
      reviewSteps &&
      alreadyIncluded &&
      (!isInsuranceEnabled || (isInsuranceEnabled && !isInsuranceLoading))
    ) {
      let history = reviewSteps.map((p) => ({
        id: p.key,
        title: p.title,
        originalStep: p.originalStep,
        onClick: () => {},
        strikePrice: p.price,
        offerPrice: p.price - p.discount,
        insPrice: p.insPrice,
        attributes: p.attributes,
      }));
      if (alreadyIncluded.included.length) {
        let addOnsStep = history.find(
          (step) => step.id.toLowerCase() === "addons"
        );
        if (addOnsStep) {
          history.pop();
          let toPush = [];
          alreadyIncluded.treatments.map((x) => {
            toPush.push(translation(x.name));
          });
          alreadyIncluded.included.map((x) => {
            if (alreadyIncluded.treatments.length) {
              alreadyIncluded.treatments.map((y) => {
                if (y.id.indexOf(x.id)) {
                  toPush.push(translation(x.name));
                }
              });
            } else {
              toPush.push(translation(x.name));
            }
          });
          addOnsStep.title = toPush;
          if (
            isNaN(addOnsStep.offerPrice) &&
            (addOnsStep.insPrice == null || addOnsStep.insPrice == undefined)
          ) {
            addOnsStep.offerPrice = 0;
            addOnsStep.insPrice = 0;
          }
          history.push(addOnsStep);
        } else {
          let toPush = [];
          alreadyIncluded.included.map((x) => {
            toPush.push(translation(x.name));
          });
          history.push({
            attributes: undefined,
            id: "addOns",
            offerPrice: 0,
            insPrice: 0,
            originalStep: "AddOns",
            strikePrice: undefined,
            title: toPush,
            hideEdit: true,
          });
        }
      }
      setHistoryStep(history);
      reduxDispatch(
        workflowActions.setReviewObjectForCart(getReviewObjectForCart(history))
      );
    }
  }, [
    reviewSteps,
    alreadyIncluded,
    isInsuranceEnabled,
    isInsuranceLoading,
    currentPrices,
  ]);

  useEffect(() => {
    if (currentTreatments && !checkGVP(currentPackages)) loadReviewTreatments();
  }, [currentTreatments, currentPackages]);

  useEffect(() => {
    if (currentPrices) {
      setPricesToDisplay({
        frame: currentPrices.frame,
        lens: currentPrices.lens,
        total: currentPrices.total,
      });
    }
  }, [currentPrices]);

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  useEffect(() => {
    if (
      configActionLoadContent &&
      historyStep &&
      configContent &&
      currentPackages
    ) {
      let frameOnlyTitle = getFrameOnlyKey(configPackages, configData);
      if (
        frameOnlyTitle &&
        currentPackages.length &&
        currentPackages[0].lensPackage.type === frameOnlyTitle
      ) {
        return;
      } else {
        loadPromoBanner();
      }
    }
  }, [configActionLoadContent, historyStep, configContent, currentPackages]);

  const loadPromoBanner = () => {
    const packageWithPromo = currentPackages?.find((item) => {
      return item.lensPackage.promo !== undefined;
    });
    let promoKey = null;
    let promoInConfig = null;
    if (packageWithPromo) {
      if (packageWithPromo?.lensPackage?.promo) {
        promoKey = Object.keys(packageWithPromo?.lensPackage.promo)[0];
        if (promoKey) {
          setPromoInPackage(promoKey);
        }
      }
    }
    if (configContent && configContent.promo) {
      if (packageWithPromo?.lensPackage?.promo) {
        promoInConfig = Object.keys(configContent?.promo)[0];
      }
    }
    const promoContent =
      promoInConfig && configContent?.promo
        ? configContent?.promo[promoInConfig].title
        : "";

    if (promoKey && promoInConfig && promoKey === promoInConfig) {
      setPromoBanners({
        desktop: promoContent,
        mobile: promoContent,
      });
    } else if (isFunction(configActionLoadContent)) {
      const contentIds = ["DESK_PromoBanner", "MOB_PromoBanner"];
      let promises: Promise<string>[] = contentIds.map((ids) =>
        configActionLoadContent(ids)
      );
      Promise.all(promises).then((htmls: string[]) => {
        setPromoBanners({
          desktop: htmls[0],
          mobile: htmls[1],
        });
      });
    }
  };

  const loadReviewTreatments = () => {
    let priceSum = 0;
    let discountSum = 0;
    let insPriceSum = 0;
    let titles = [];

    let filteredTreatments = currentTreatments.filter((treatment) => {
      return treatment.key !== "photocromic";
    });

    filteredTreatments.forEach((ct) => {
      if (!ct.skipped) {
        titles.push(ct.title);
        priceSum += parseFloat(ct.price);
        discountSum += parseFloat(ct.discount);
        insPriceSum += ct.insPrice ? parseFloat(ct.insPrice) : 0;
      }
    });
    if (insPriceSum === 0) {
      insPriceSum = undefined;
    }
    if (titles.length) {
      setTreatmentsStep({
        title: "treatments",
        selection: titles,
        strikePrice: priceSum,
        offerPrice: priceSum - discountSum,
        insPrice: insPriceSum,
      });
    }
  };

  const handleStepEditClick = (stepName: string) => {
    onEdit({ id: stepName });
  };

  const handleInsuranceClick = () => {
    if (configInsurance) {
      if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
        configInsurance.openInsurancePanel();
      } else if (
        isInsuranceEnabled &&
        configInsurance.removeInsuranceBenefits
      ) {
        configInsurance.removeInsuranceBenefits();
      }
    }
  };

  //Yeah, this is a rushed ugly fix. We gotta think this through
  const shouldShowTreatments = (
    historyStep: any[],
    currentHistoryStep: any
  ) => {
    let ret = true;
    const found = historyStep.find(
      (hs) =>
        hs.id === "treatmentsFamily" &&
        hs.attributes &&
        hs.attributes.hideTreatmentsInReview
    );
    ret = !found;
    let displayTreatmentsNow = currentHistoryStep.id === "treatmentsFamily";
    if (ret) {
      let isThereTreatmentsFamily = historyStep.find(
        (hs) => hs.id === "treatmentsFamily"
      );
      if (!isThereTreatmentsFamily) {
        let isThereBrand = historyStep.find((hs) => hs.id === "brand");
        let isThereType = historyStep.find((hs) => hs.id === "type");
        if (isThereBrand) {
          displayTreatmentsNow = currentHistoryStep.id === "brand";
        } else if (isThereType) {
          displayTreatmentsNow = currentHistoryStep.id === "type";
        }
      }
    }
    return treatmentsStep && displayTreatmentsNow && ret;
  };

  // -------- TEMPORARY INSURANCE CODE -------- //

  const handleAffirmPaymentInstallmentsClick = () => {
    if (
      //@ts-ignore
      typeof window.affirm !== "undefined" &&
      //@ts-ignore
      window.affirm.ui.refresh !== undefined
    ) {
      //@ts-ignore
      window.affirm.ui.refresh();
    }
  };

  useEffect(() => {
    if (type) {
      affirmSpanCheck();
    }
  }, [type]);

  const affirmSpanCheck = () => {
    let affirmSpan = document.getElementById("affirm-product-rxc");
    if (!affirmSpan) setTimeout(affirmSpanCheck, 200);
    else setTimeout(handleAffirmPaymentInstallmentsClick, 500);
  };

  const frame = useSelector((state: any) => state.config?.data?.frame);

  const [insuranceCalled, setInsuranceCalled] = useState(false);

  useEffect(() => {
    if (
      isInsuranceEnabled &&
      currentPackages &&
      currentPackages.length &&
      configInsurance &&
      frame &&
      !insuranceCalled
    ) {
      setInsuranceCalled(true);
      callInsuranceService(
        [getCheapPackageFromList(currentPackages)],
        configInsurance,
        frame,
        reduxDispatch
      );
    }
  }, [isInsuranceEnabled, currentPackages, configInsurance, frame]);

  // -------- TEMPORARY INSURANCE CODE -------- //

  const handleInstallmentsClick = () => {
    reduxDispatch(modalsActions.setShowPaymentInstallmentsModal(true));
  };

  const isFrameOnlySelected = () => {
    let configFrameOnly = getFrameOnlyKey(configPackages, configData);
    let typeSelection = workflow
      .getAllCurrentParameterStep()
      .find((par) => par.key === "type")?.value;
    return typeSelection === configFrameOnly;
  };

  const getImagery = (pdImageUrl, swatchImageObj) => {
    let url = null;
    if (swatchImage && showFallbackImageMessage) {
      url = swatchImageObj.url.split(",")[0].split(" ")[0];
    }
    return {
      productImage: pdImageUrl,
      fallbackImage: url,
    };
  };

  const onAddToBag = () => {
    window.dispatchEvent(
      new CustomEvent("AddToCartEvent", {
        detail: {
          selectedWarranty: sessionWarranty,
          reviewObjectForCart: getReviewObjectForCart(historyStep),
          imagery: getImagery(productImagePath, swatchImage),
        },
      })
    );
    setButtonLoading(true);
  };
  const onAddToBagMobile = () => {
    document.documentElement.classList.remove("RXC__is-locked");
    document.body.classList.remove("RXC__is-locked");
    onAddToBag();
  };

  const installmentsLabel = useInstallmentsLabel({
    initialString: installmentsLink(isMultiplePayments),
  });

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translation("steps.review.title"),
        subtitle: ctxMessage,
        subtitleStyle: { marginBottom: 0 },
      }}
    >
      {productImagePath && (
        <div
          className={"Review__productImageContainer"}
          style={{
            marginBottom:
              showNotFoundImageMessage || showFallbackImageMessage
                ? "48px"
                : "unset",
          }}
        >
          <img
            className={"Review__productImageContainer__image"}
            alt="Image"
            src={productImagePath}
            style={{
              opacity: showNotFoundImageMessage ? "50%" : "unset",
            }}
          />
          {showFallbackImageMessage && (
            <div className={"ProductContainer__fallbackContainer"}>
              {swatchImage && (
                <Image
                  imageClass={"ProductContainer__fallbackImage"}
                  url={configProjectImage}
                  fileName={swatchImage.value}
                  alt={swatchImage.name}
                  id={swatchImage.value}
                />
              )}
              <div
                className={
                  "ProductContainer__productImageContainer__productImageContent__productImage__fallbackMessage"
                }
              >
                <div
                  className={
                    "ProductContainer__productImageContainer__productImageContent__productImage__fallbackMessageTitle"
                  }
                >
                  {HtmlParser(fallbackImageMessageTitle.replace(/\n/g, "<br>"))}
                </div>
                <div
                  className={
                    "ProductContainer__productImageContainer__productImageContent__productImage__fallbackMessageSubtitle"
                  }
                >
                  {HtmlParser(
                    fallbackImageMessageSubTitle.replace(/\n/g, "<br>")
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {promoBanners?.desktop &&
        (promoInPackage ||
          (promoType &&
            currentPackages[0] &&
            (promoType === currentPackages[0].frame.promoTypeDetail ||
              !currentPackages[0].frame.promoTypeDetail))) && (
          <div className={"Review__topBadge__desktop"}>
            {HtmlParser(promoBanners.desktop)}
          </div>
        )}

      {promoBanners?.mobile &&
        (promoInPackage ||
          (promoType &&
            currentPackages[0] &&
            (promoType === currentPackages[0].frame.promoTypeDetail ||
              !currentPackages[0].frame.promoTypeDetail))) && (
          <div className={"Review__topBadge__mobile"}>
            {HtmlParser(promoBanners.mobile)}
          </div>
        )}

      <div className={"Review__frameContainer"}>
        <div className="Review__frameContainer__contentContainer">
          <div className={"Review__frameContainer__contentContainer__title"}>
            {translation("steps.review.firstLineReview")}
          </div>
          <div className={"Review__frameContainer__contentContainer__name"}>
            {frameConfigData
              ? frameConfigData.isStellaProduct
                ? frameConfigData.name
                : frameConfigData.brand + " " + frameConfigData.name
              : null}
          </div>
        </div>
        <div className={"Review__frameContainer__price"}>
          <BrandComponent
            componentName="StepPrice"
            parameter={{
              price: priceForFrame,
              discount: discountForFrame,
              insPrice: insPriceForFrame,
              ignorePriceLabels: true,
              hideSign: true,
            }}
          />
        </div>
      </div>

      <div className={"Review__stepsSeparator"} />

      {historyStep &&
        historyStep.map((hs, index) => {
          if (!isInsuranceEnabled || isNonPrescriptionAvailable()) {
            //if it's a skipped step, hide the price and edit button
            let hideEditAndPrice = false;
            if (hs.attributes && hs.attributes.skipped) {
              hideEditAndPrice = true;
            }
            return (
              <React.Fragment key={hs.id + "_review_step"}>
                <BrandComponent
                  componentName="StepInReview"
                  parameter={{
                    title: hs.id,
                    selection: [hs.title],
                    strikePrice: hideEditAndPrice ? null : hs.strikePrice,
                    offerPrice: hideEditAndPrice ? null : hs.offerPrice,
                    onEditClick: hideEditAndPrice
                      ? null
                      : (title: string) => handleStepEditClick(hs.originalStep),
                    isGVP: checkGVP(currentPackages),
                    showSeeDetails:
                      hs.id === "advancedPrescription" &&
                      hs.attributes?.showSeeDetails,
                    hideEdit: hs.hideEdit ? hs.hideEdit : false,
                    hideMainPrice: hs.id.toLowerCase() === "addons",
                  }}
                />
                <div className={"Review__stepsSeparator"} />
              </React.Fragment>
            );
          } else {
            let showInsPrice = !checkGVP(currentPackages);
            //if it's a skipped step, hide the price and edit button
            let hideEditAndPrice = false;
            if (hs.attributes && hs.attributes.skipped) {
              hideEditAndPrice = true;
            }
            return (
              <React.Fragment key={hs.id + "_review_step"}>
                <BrandComponent
                  componentName="StepInReview"
                  parameter={{
                    title: hs.id,
                    selection: [hs.title],
                    insPrice:
                      hideEditAndPrice || !showInsPrice
                        ? undefined
                        : hs.insPrice,
                    strikePrice: hs.strikePrice,
                    onEditClick: hideEditAndPrice
                      ? null
                      : (title: string) => handleStepEditClick(hs.originalStep),
                    showSeeDetails:
                      hs.id === "advancedPrescription" &&
                      hs.attributes?.showSeeDetails,
                    hideEdit: hs.hideEdit ? hs.hideEdit : false,
                    hideMainPrice: hs.id.toLowerCase() === "addons",
                  }}
                />
                <div className={"Review__stepsSeparator"} />
              </React.Fragment>
            );
          }
        })}

      <BrandComponent componentName="WarrantyOptions" />

      <div className={"Review__priceSummary"}>
        <div className={"Review__priceSummary__row"}>
          <span className={"Review__priceSummary__row__labelTotal"}>
            {!isFrameOnlySelected() && (
              <TranslateText label={"price.totalFrameLens.frameLensTotal"} />
            )}
            {isFrameOnlySelected() && (
              <TranslateText label={"price.totalFrameLens.frame"} />
            )}
          </span>
          <span className={"Review__priceSummary__row__labelTotalMobile"}>
            <TranslateText label={"price.totalFrameLens.total"} />
          </span>
          <BrandComponent componentName="EarlyAccessTooltip">
            <BrandComponent
              componentName="StepPrice"
              parameter={{
                price: pricesToDisplay?.total.price,
                discount: pricesToDisplay?.total.discount,
                insPrice: pricesToDisplay?.total.insPrice,
                hideSign: true,
                priceStyle: { fontSize: 20 },
                strikePriceStyle: { fontSize: 14 },
                ignorePriceLabels: true,
                vatIncluded: vatIncluded,
              }}
            />
          </BrandComponent>
        </div>

        <>
          {type?.toLowerCase() === paymentInstallmentsTypes.AFFIRM &&
          !isMultiplePayments ? (
            <div
              className={
                `Review__priceSummary__${
                  isMultiplePayments
                    ? "multipleInstallments"
                    : "paymentInstallment"
                }` + (vatIncluded ? " marginTop" : "")
              }
            >
              <span
                id="affirm-product-rxc"
                className="affirm-as-low-as product"
                data-page-type="product"
                data-amount={
                  pricesToDisplay
                    ? (pricesToDisplay.total.price -
                        pricesToDisplay.total.discount) *
                      100
                    : 0
                }
                data-learnmore-show="false"
              ></span>
            </div>
          ) : (
            (type || isMultiplePayments) && (
              <div
                className={`Review__priceSummary__${
                  isMultiplePayments
                    ? "multipleInstallments"
                    : "paymentInstallment"
                }`}
                data-element-id="X_X_StickyBar_PayOverTime"
                onClick={handleInstallmentsClick}
              >
                {installmentsLabel}
                {!isMultiplePayments ? (
                  <Image
                    imageClass="Review__priceSummary__paymentInstallment__badge"
                    url={
                      "https://assets.lenscrafters.com/extra/image/rxc/images/"
                    }
                    fileName={`${type}_badge`}
                    alt={`${type}`}
                  />
                ) : null}
              </div>
            )
          )}
        </>
      </div>

      <div className={"Review__buttonsMobileContainer"}>
        <div className={"Review__stepsSeparator"} />
        {isInsurable() && (
          <GenericButton
            className={"BottomBar__container__left__addInsuranceButton"}
            title={addToBagLabel}
            id="insuranceReviewButton"
            type="button"
            noArrow={true}
            handleClick={handleInsuranceClick}
          >
            {!isInsuranceEnabled
              ? translation("insuranceButtonLabel")
              : translation("insuranceButtonLabelRemove")}
          </GenericButton>
        )}
        <GenericButton
          className={`BottomBar__container__right__addToBagButton ${
            buttonLoading ? "loading" : ""
          }`}
          title={addToBagLabel}
          id="goToCartButton"
          type="button"
          noArrow={true}
          disabled={buttonLoading}
          handleClick={onAddToBagMobile}
          dataElementId="X_X_Prod_AddCart"
        >
          {addToBagLabel}
        </GenericButton>
      </div>
    </BrandComponent>
  );
}
