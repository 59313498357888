import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { prescriptionActions } from "../../redux/slices/prescription";
import { getFrameOnlyKey, getStepBadgeType } from "../ConfigLoader";
import { Parameter } from "../models/Parameter";
import { workflow } from "../models/Workflow";
import { useConfigContent } from "./useConfigContent";
import { useCurrentPackages } from "./useCurrentPackages";
import { useDistanceAndReading } from "./useDistanceAndReading";
import { useCurrentType } from "@hooks";

type Type = {
  id;
  title;
  description;
  imageFileName;
  sequence;
  badge;
};

export function useTypes() {
  const typeContent = useConfigContent("Type");
  const currentPackages = useCurrentPackages();
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configData = useSelector((state: any) => state.config?.data);
  const currentType = useCurrentType();
  const configPrescription = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const prescriptionTypes = useSelector(
    (state: any) => state.config?.prescriptionTypes
  );
  const { isDistanceAndReadingActive } = useDistanceAndReading();
  const reduxDispatch = useDispatch();

  const getSingleVisionId = useCallback(
    () =>
      typeContent.find((type) => type.id.toLowerCase().includes("single"))?.id,
    [typeContent]
  );

  const onTypeSelected = useCallback(
    (id: any, skipped?: boolean, preSelected?: boolean) => {
      if (["Non_prescription", "Frame Only"].includes(id))
        reduxDispatch(prescriptionActions.reset());
      // reset promo - done to fix case in which Single Vision is a GVP promo while
      // Progressive and Frame Only aren't, so clicking on Frame Only here will go
      // to review and perform some promo logic.
      reduxDispatch(workflowActions.setPromoType(null));

      if (id === getFrameOnlyKey(configPackages, configData)) {
        //TODO verificare che serva per i sun, altri sun.something non ce l'hanno
        /* const frameOnlyConfig = configs.find((c) => c.id.indexOf("FRAME") >= 0);
        if (frameOnlyConfig) {
          id = frameOnlyConfig.id;
        } */
        workflow.goToStep(
          "Review",
          [
            {
              key: "type",
              value: id,
            },
          ],
          skipped
        );
      } else {
        //TODO use constants
        const isDistanceAndReading = ["reading", "distance"].includes(
          id?.toLowerCase()
        );
        const parameters: Parameter[] = [
          {
            key: "type",
            value: isDistanceAndReading ? getSingleVisionId() : id,
          },
        ];
        if (isDistanceAndReading)
          parameters[0].optionalAttributes = {
            key: "reading&distance",
            value: id,
          };

        if (
          (configPrescription?.prescriptionType === prescriptionTypes.FULL ||
            configPrescription?.prescriptionType ===
              prescriptionTypes.NESTED_CA) &&
          id !== "Non_prescription"
        ) {
          workflow.goNextStep(parameters, skipped);
        } else if (currentType === "SUNGLASSES") {
          workflow.goNextStep(parameters, skipped);
        } else {
          workflow.goToStep("Brand", parameters, skipped);
        }
      }
    },
    [getSingleVisionId]
  );

  const types = useMemo(() => {
    if (currentPackages && typeContent && configPackages && configData) {
      //remap typeContent
      const extendedTypeContent = typeContent.map((content) => ({
        ...content,
        imageUrl: content.imageFileName,
        noMarginTitle: true,
        badge: content.badge && getStepBadgeType(content.badge as string),
        disabled: content.badge == "coming soon" ? true : false,
        onClick: () =>
          content.badge !== "coming soon" &&
          !content.disabled &&
          onTypeSelected(content.id),
      }));
      //filter the type section in lensesData.content looking in currentPackages
      let types = extendedTypeContent
        .filter((content) => {
          return currentPackages.some(
            (cp) => cp.lensPackage.type === content.id
          );
        })
        //filter frameOnly
        .filter((content) => {
          const showFrameOnly =
            configData.showFrameOnly == false ? false : true;

          const configFrameOnly = getFrameOnlyKey(configPackages, configData);

          if (content.id !== configFrameOnly) return true;
          return showFrameOnly;
        });

      if (!isDistanceAndReadingActive) return types;

      const reading = extendedTypeContent.find(
        (content) => content.id === "Reading"
      );
      const distance = extendedTypeContent.find(
        (content) => content.id === "Distance"
      );
      //looking for reading and distance in content
      if (!reading || !distance) {
        return types;
      }

      return (
        ([distance, reading] as { [key: string]: any }[])
          .concat(...types)
          //remove single vision
          .filter((type) => type.id != getSingleVisionId())
          .sort((a, b) =>
            a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
          )
      );
    }
  }, [
    typeContent,
    currentPackages,
    configPackages,
    configData,
    getSingleVisionId,
    isDistanceAndReadingActive,
  ]);

  return { types: types, onSelect: onTypeSelected };
}
