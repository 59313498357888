import React, { FC, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { getSimplePrescriptionValuesArray } from "../../utils/prescription";
import { BrandComponent } from "../common/BrandComponent";

import "./default.module.scss";

type IShowBothPdOptions = {
  value: boolean;
  ignoreSplitLogic: boolean;
};

type Props = {
  PD1: number | null | undefined;
  PD2: number | null | undefined;
  showBothPdOptions: IShowBothPdOptions;
  showPDWarning: boolean;
  onPD1Change: (v) => void;
  onPD2Change: (v) => void;
  setShowBothPdOptions: Function;
  pdValueNotSelectedError: boolean;
  CustomCheckbox: FC<{ value: IShowBothPdOptions; setter: Function }>;
};

export const PupillaryDistance = ({
  showBothPdOptions,
  CustomCheckbox,
  pdValueNotSelectedError,
  PD1,
  PD2,
  onPD1Change,
  onPD2Change,
  setShowBothPdOptions,
  showPDWarning,
}: Props) => {
  const pupillaryDistanceTitle = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistance"
  );
  const whatIsIt = useTranslate("steps.advancedPrescription.manual.whatIsIt");
  const pupillaryDistanceMisurePDAction = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceMisurePDAction"
  );
  const pdSubtitle = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceSubtitle"
  );
  const iHaveTwoPd = useTranslate(
    "steps.advancedPrescription.manual.iHaveTwoPd"
  );

  const pupillaryDistanceWarningValue = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceWarningValue"
  );
  const pdRight = useTranslate("steps.advancedPrescription.manual.pdRight");
  const pdLeft = useTranslate("steps.advancedPrescription.manual.pdLeft");

  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const pdValueWarning = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarning"
  );
  const pdValueWarning2 = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarning2"
  );

  const reduxDispatch = useDispatch();

  const showPrescriptionModalPD = useCallback(
    (show: boolean) =>
      reduxDispatch(
        modalsActions.setShowPrescriptionModalPD({
          show,
          modal: enableDigitalOptometry,
        })
      ),
    []
  );
  const closeDoublePD = () => {
    setShowBothPdOptions({
      ...showBothPdOptions,
      value: !showBothPdOptions.value,
    });
    onPD1Change(null);
    onPD2Change(null);
  };

  return (
    (showBothPdOptions.value || !showBothPdOptions.value) && (
      <>
        <div className={"PupillaryDistance__titleContainer"}>
          <div className={"PupillaryDistance__titleContainer__title"}>
            {pupillaryDistanceTitle}
          </div>
          {!enableDigitalOptometry && (
            <div
              tabIndex={0}
              className={"PupillaryDistance__titleContainer__link"}
              onClick={() => showPrescriptionModalPD(true)}
            >
              {whatIsIt}
            </div>
          )}
        </div>
        {enableDigitalOptometry && (
          <div
            className="PupillaryDistance__subtitleContainer"
            onClick={() => showPrescriptionModalPD(true)}
            dangerouslySetInnerHTML={{ __html: pdSubtitle }}
          />
        )}
        <div
          className={`PupillaryDistance__optionsContainer ${
            showBothPdOptions.value ? "both_values" : ""
          }`}
        >
          {showBothPdOptions.value && (
            <div className="PupillaryDistance__checkboxLabel">{pdRight}</div>
          )}
          <div
            className={`PupillaryDistance__optionsContainer__select_container ${
              showBothPdOptions.value ? "margin" : ""
            }`}
          >
            <select
              className={
                /* formErrors["PD_1"] || */ pdValueNotSelectedError && !PD1
                  ? "error"
                  : showBothPdOptions.value
                  ? "__DoubleSelect"
                  : ""
              }
              id="PD_1"
              name="PD_1"
              title="PD_1"
              value={PD1}
              onChange={(e) => {
                onPD1Change(e.target.value);
              }}
            >
              {enableDigitalOptometry && (
                <option /* disabled={!!PD1} */ value={undefined}>-</option>
              )}
              {(!showBothPdOptions.value
                ? getSimplePrescriptionValuesArray(
                    prescriptionDefaults.pdMin,
                    prescriptionDefaults.pdMax,
                    prescriptionDefaults.pdRes
                  )
                : getSimplePrescriptionValuesArray(
                    Math.floor(prescriptionDefaults.pdMin / 2),
                    Math.floor(prescriptionDefaults.pdMax / 2),
                    prescriptionDefaults.pdRes
                  )
              ).map((e, key) => {
                return (
                  <Fragment key={key}>
                    <option value={e.value}>{e.name}</option>
                    {enableDigitalOptometry && (
                      <option value={(e.value as number) + 0.5}>
                        {(e.name as number) + 0.5}
                      </option>
                    )}
                  </Fragment>
                );
              })}
            </select>
          </div>

          {showBothPdOptions.value && (
            <>
              <div className="PupillaryDistance__checkboxLabel">{pdLeft}</div>
              <div
                className={`PupillaryDistance__optionsContainer__select_container ${
                  showBothPdOptions.value ? "marginRight" : ""
                }`}
              >
                <select
                  className={`${
                    showBothPdOptions.value ? "__DoubleSelect" : ""
                  }${pdValueNotSelectedError && !PD2 ? " error" : ""}`}
                  id="PD_2"
                  name="PD_2"
                  title="PD_2"
                  value={PD2}
                  onChange={(e) => onPD2Change(parseFloat(e.target.value))}
                >
                  {enableDigitalOptometry && (
                    <option /* disabled={!!PD2} */ value={undefined}>-</option>
                  )}
                  {getSimplePrescriptionValuesArray(
                    Math.floor(prescriptionDefaults.pdMin / 2),
                    Math.floor(prescriptionDefaults.pdMax / 2),
                    prescriptionDefaults.pdRes
                  ).map((e, key) => {
                    return (
                      <Fragment key={key}>
                        <option value={e.value}>{e.name}</option>
                        {enableDigitalOptometry && (
                          <option value={(e.value as number) + 0.5}>
                            {(e.name as number) + 0.5}
                          </option>
                        )}
                      </Fragment>
                    );
                  })}
                </select>
              </div>
            </>
          )}
          <div
            className={
              "PupillaryDistance__checkboxContainer" +
              (showBothPdOptions.value ? "__noMargin" : "__margin")
            }
          >
            <CustomCheckbox value={showBothPdOptions} setter={closeDoublePD} />

            <div className="PupillaryDistance__checkBoxText">{iHaveTwoPd}</div>
          </div>
        </div>
        {showPDWarning && (
          <BrandComponent
            componentName="WarningBanner"
            parameter={{
              text: enableDigitalOptometry ? pdValueWarning2 : pdValueWarning,
              dataContent: pupillaryDistanceMisurePDAction,
              onClick: () =>
                reduxDispatch(
                  modalsActions.setShowPrescriptionModalPD({
                    show: true,
                    modal: enableDigitalOptometry,
                  })
                ),
            }}
          />
        )}
        {pdValueNotSelectedError && (
          <div
            className="PupillaryDistance__pdValueNotSelectedWarning"
            data-content={pupillaryDistanceMisurePDAction}
            onClick={() => showPrescriptionModalPD(true)}
          >
            {pupillaryDistanceWarningValue}
          </div>
        )}
      </>
    )
  );
};
