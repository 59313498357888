import React from "react";
import "./default.module.scss";
import Image from "../common/Image";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { StepBadgeType } from "../StepBadge/types/StepBadgeType";
import { workflowActions } from "../../redux/slices/workflow";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import useDelegatingLearnMore from "../../configurator/hooks/useDelegatingLearnMore";
import { isMobile } from "react-device-detect";
import { useCurrentPackages, usePriceDiscountBadge } from "@hooks";

export interface StepCardProps {
  title: string;
  description?: string;
  price?: number;
  discount?: number;
  insPrice?: number;
  onClick?: (p: any) => void;
  badge?: StepBadgeType;
  textBadge?: string;
  imageUrl?: string;
  id: string;
  noMarginTitle?: boolean;
  included?: boolean;
  noImageCard?: boolean;
  moreInfo?: Function;
  sequence?: number;
  disabled?: boolean;
  largeIcon?: boolean;
  discountLoading?: boolean;
  contentIdentifier?: string;
}

export function StepCard({
  title,
  description,
  price,
  discount,
  insPrice,
  badge,
  textBadge,
  onClick,
  imageUrl,
  id,
  included,
  noImageCard,
  moreInfo,
  disabled,
  largeIcon,
  discountLoading,
  contentIdentifier,
}: StepCardProps) {
  const currentPackages = useCurrentPackages();
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  let promoBadgeID = id;
  let splittedID = promoBadgeID.split("$");
  if (splittedID.length > 1) {
    promoBadgeID = splittedID[1];
  } else {
    promoBadgeID = splittedID[0];
  }

  const promoBadge = usePriceDiscountBadge(promoBadgeID, discount, price);
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );
  const translation = useTranslation();
  const dispatch = useDispatch();

  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const onCardClick = () => {
    let isClickable =
      (insuranceEnabled &&
        (price === undefined || price === null || isNaN(price))) ||
      (insPrice !== undefined && insPrice !== null && !isNaN(insPrice));

    if (!insuranceEnabled || isNonPrescriptionAvailable() || isClickable) {
      if (onClick) {
        if (promoBadge) {
          const currentStep = workflow.currentStep;
          const param = new AutomaticDiscountParameter(
            promoBadge.key,
            promoBadge.value,
            {
              key: currentStep.key,
              progressive: currentStep.progressive,
            }
          );
          dispatch(
            workflowActions.setAutomaticDiscountBadgeStepSelection(param)
          );
        }
        onClick(id);
      }
    }
  };

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Choose";
  };

  const getDataElementIdForLearnMore = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreOpen";
  };

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const isBadgeVisible = () => {
    return (
      badge &&
      (!promoBadge ||
        (promoBadge &&
          price !== undefined &&
          price !== null &&
          discount !== undefined &&
          discount !== null))
    );
  };

  const isTopPromoBadgeVisible = () => {
    return (
      promoBadge &&
      (price === undefined || price === null) &&
      (discount === undefined || discount === null)
    );
  };

  return (
    <div
      className={"StepCard__container"}
      onClick={onCardClick}
      data-element-id={getDataElementIdForCard()}
      data-description={title}
    >
      <button className={"StepCard__card" + (disabled ? "_disabled" : "")}>
        <div className={"StepCard__layout"}>
          {disabled && <div className="StepCard__layout__disabled" />}
          {imageUrl && !isMobile && (
            <div
              className={
                largeIcon
                  ? "StepCard__layout__largeimgcontainer"
                  : "StepCard__layout__imgcontainer" +
                    " " +
                    (noImageCard ? "noImage" : "")
              }
            >
              <Image
                imageClass={
                  largeIcon
                    ? "StepCard__layout__largeimgcontainer__largeimg"
                    : "StepCard__layout__imgcontainer__img"
                }
                url={configProjectImage}
                fileName={imageUrl}
                alt={imageUrl}
              />
            </div>
          )}

          <div
            className={
              !imageUrl
                ? "StepCard__layout__content spacer"
                : "StepCard__layout__content"
            }
          >
            <div
              className={
                (badge
                  ? "StepCard__layout__content__title__badgespacer"
                  : "StepCard__layout__content__title") +
                (disabled ? "_disabled" : "")
              }
            >
              {title}

              {isBadgeVisible() && (
                <BrandComponent
                  componentName="StepBadge"
                  parameter={{ type: badge, text: textBadge }}
                />
              )}

              {isTopPromoBadgeVisible() && (
                <div className="StepCard__layout__topBadgeWrapper">
                  <BrandComponent
                    componentName="StepPriceBadge"
                    parameter={{ badge: promoBadge?.badge }}
                  />
                </div>
              )}
            </div>
            <div
              className={
                "StepCard__layout__content__description" +
                (disabled ? "_disabled" : "")
              }
            >
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            {!disabled && delegatingLearnMore && contentIdentifier ? (
              <>
                <div className={"StepCard__layout__content__spacer"} />
                <div
                  className={"StepCard__layout__content__learnMore"}
                  onClick={(e) => {
                    e.stopPropagation();
                    delegateLearnMoreContent(contentIdentifier);
                  }}
                >
                  {translation("steps.type.moreInfo")}
                </div>
                {price != null ? (
                  <div className={"StepCard__layout__content__price"}>
                    {!included ? (
                      <BrandComponent
                        componentName="StepPrice"
                        parameter={{
                          price,
                          discount,
                          insPrice,
                          promo: promoBadge?.badge,
                          ignoreInsurance: isNonPrescriptionAvailable(),
                          discountLoading,
                        }}
                      />
                    ) : (
                      translation("price.included")
                    )}
                  </div>
                ) : null}
              </>
            ) : (disabled || !moreInfo) &&
              (price === undefined || price === null) ? null : (
              <>
                <div className={"StepCard__layout__content__spacer"} />
                <div
                  className={
                    "StepCard__layout__content__learnMorePriceContainer" +
                    (!moreInfo ? " onlyPrice" : "")
                  }
                >
                  {moreInfo && (
                    <a
                      className={"StepCard__layout__content__learnMore"}
                      tabIndex={0}
                      onClick={(e) => {
                        e.stopPropagation();
                        moreInfo();
                      }}
                      data-element-id={getDataElementIdForLearnMore()}
                    >
                      {translation("steps.type.moreInfo")}
                    </a>
                  )}

                  {price != null ? (
                    <div className={"StepCard__layout__content__price"}>
                      {!included ? (
                        <BrandComponent
                          componentName="StepPrice"
                          parameter={{
                            price,
                            discount,
                            insPrice,
                            promo: promoBadge?.badge,
                            ignoreInsurance: isNonPrescriptionAvailable(),
                            discountLoading,
                          }}
                        />
                      ) : (
                        translation("price.included")
                      )}
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}
