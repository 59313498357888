import React, { useState, useEffect, useCallback } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./default.module.scss";

/**
 * @param props.id
 * @param props.name
 * @param props.defaultValue
 * @param props.options
 * @param props.onChange
 * @param props.hasError
 * @param props.inputRef
 */
export function PrescriptionSelect(props) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = React.useRef(null);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key == "Enter") {
      toggleSelect();
    }

    if (event.key == "Escape") {
      setIsOpen(false);
    }
  };

  const handleExitClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleExitClick, true);

    return () => {
      document.removeEventListener("click", handleExitClick, true);
    };
  }, []);

  useEffect(() => {
    if (props.reset) {
      props.onChange(props.defaultValue);
    }
  }, [props.reset]);

  const onChange = useCallback((value) => {
    setIsOpen(false);
    props.onChange(value);
  }, []);

  const onKeyPress = useCallback((e, value) => {
    if (e === "Enter") {
      setIsOpen(false);
      props.onChange(value);
    }
  }, []);

  const renderOptions = (options) => {
    const renderOption = (option, className) => {
      const optionValue =
        option.props.value > 0
          ? `+${option.props.children}`
          : option.props.children;
      return (
        <div
          key={option.props.value}
          className={className}
          onClick={() => onChange(option.props.value)}
          onKeyDown={(e) => onKeyPress(e.key, option.props.value)}
          tabIndex={0}
        >
          {optionValue}
        </div>
      );
    };

    const zeroOption = options.filter((option) => {
      return option.props.value === 0 ? option : null;
    });
    const negativeOptions = options
      .filter((option) => {
        return option.props.value < 0 ? option : null;
      })
      .sort((a, b) => {
        return a.props.value > b.props.value ? -1 : 1;
      });
    const positiveOptions = options
      .filter((option) => {
        return option.props.value > 0 ? option : null;
      })
      .sort((a, b) => {
        return a.props.value < b.props.value ? -1 : 1;
      });

    return (
      <Scrollbars
        onClick={(e) => e.stopPropagation()}
        renderThumbVertical={(props) => (
          <div {...props} className="rxc-prescription-select-thumb"></div>
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="rxc-prescription-select-track"></div>
        )}
      >
        {zeroOption.length > 0
          ? renderOption(
              zeroOption[0],
              "rxc-prescription-select-table-row rxc-prescription-select-table-option"
            )
          : null}
        {negativeOptions.length > 0 && positiveOptions.length > 0 ? (
          <div
            className="rxc-prescription-select-table-row scrollable-menu"
            style={{ overflow: "visible" }}
          >
            <div className="rxc-prescription-select-table-col">
              {negativeOptions.map((negativeOption) => {
                return renderOption(
                  negativeOption,
                  "rxc-prescription-select-table-row rxc-prescription-select-table-option"
                );
              })}
            </div>
            <div className="rxc-prescription-select-table-col">
              {positiveOptions.map((positiveOption) => {
                return renderOption(
                  positiveOption,
                  "rxc-prescription-select-table-row rxc-prescription-select-table-option"
                );
              })}
            </div>
          </div>
        ) : null}
      </Scrollbars>
    );
  };

  const fieldValue = () => {
    let value = "";
    const isIntegerValue = Number.isInteger(props.value);

    if (
      props.value === undefined ||
      props.value === null ||
      props.value === ""
    ) {
      value = "--";
    } else {
      if (props.value !== 0) {
        if (props.value > 0) {
          value = isIntegerValue
            ? `+${props.value}.00`
            : `+${props.value.toFixed(2)}`;
        } else {
          value = isIntegerValue
            ? `${props.value}.00`
            : `${props.value.toFixed(2)}`;
        }
      } else {
        value = `${props.value}.00`;
      }
    }
    return value;
  };

  return (
    <div
      className={
        "PrescriptionSelect rxc-prescription-select rxc-prescription-select-arrow" +
        (props.hasError ? " rxc-prescription-select-error" : "") +
        (props.hasWarning ? " rxc-prescription-select-warning" : "")
      }
      onClick={toggleSelect}
      onKeyDown={handleKeyDown}
      ref={selectRef}
      tabIndex={0}
    >
      <input
        id={props.id}
        className="rxc-prescription-select-value"
        value={fieldValue()}
        name={props.name}
        tabIndex={-1}
        readOnly
        ref={props.inputRef}
      />
      {isOpen ? (
        <div className="rxc-prescription-select-table">
          {renderOptions(props.options)}
        </div>
      ) : null}
    </div>
  );
}
