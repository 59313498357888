import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { isMobile } from "react-device-detect";
import "./default.module.scss";

type Props = {
  text: string;
  textMobile: string;
  dataContent: string;
  onClick?: Function;
};

export const WarningBanner = (props: Props) => {
  const tooltip = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceWarningTooltip"
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );

  return (
    <div className="WarningBanner__container">
      <div
        className="WarningBanner__container__icon"
        data-tip={tooltip}
        data-class={"Tooltip__tooltip"}
      />
      <div
        className={
          enableDigitalOptometry
            ? "WarningBanner__container__text__withAfter"
            : "WarningBanner__container__text"
        }
        onClick={() => props.onClick && props.onClick()}
        dangerouslySetInnerHTML={
          isMobile ? { __html: props.textMobile } : { __html: props.text }
        }
        data-content={enableDigitalOptometry ? props.dataContent : ""}
      />
    </div>
  );
};
