import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { useCurrentBrand } from "@hooks";

export const loadTranslationConfig = (brand?: string, type?: string) => {
  if (!type || type === "EYEGLASSES") {
    return import("./languages." + brand)
      .then((comp) => ({ ...comp.default }))
      .catch(() =>
        import("./languages.default").then((comp) => ({ ...comp.default }))
      );
  } else {
    return import("./languages.sun." + brand)
      .then((comp) => ({ ...comp.default }))
      .catch(() =>
        import("./languages." + brand)
          .then((comp) => ({ ...comp.default }))
          .catch(() =>
            import("./languages.sun.default")
              .then((comp) => ({ ...comp.default }))
              .catch(() =>
                import("./languages.default").then((comp) => ({
                  ...comp.default,
                }))
              )
          )
      );
  }
};

function translate(
  label: string,
  lang: string,
  brandT: string,
  transationFallback?: any
) {
  let ret = null;
  //@ts-ignore
  if (
    window.rxcTranslations &&
    window.rxcTranslations[brandT] &&
    window.rxcTranslations[brandT][lang]
  ) {
    //@ts-ignore
    ret = get(window.rxcTranslations[brandT][lang], label);
  } else {
    //@ts-ignore
    if (window.rxcTranslations && window.rxcTranslations[lang]) {
      //@ts-ignore
      ret = get(window.rxcTranslations[lang], label);
    }
  }
  if (!ret && transationFallback) {
    if (transationFallback[brandT] && transationFallback[brandT][lang]) {
      ret = get(transationFallback[brandT][lang], label);
      if (!ret) {
        //missing that label for that language, go to default en_US
        ret = get(transationFallback[brandT]["en_US"], label);
      }
    } else if (
      transationFallback[brandT] &&
      transationFallback[brandT]["en_US"]
    ) {
      ret = get(transationFallback[brandT]["en_US"], label);
    } else {
      ret = get(transationFallback[lang], label);
      if (!ret) {
        //missing that label for that language, go to default en_US
        ret = get(transationFallback[brandT]["en_US"], label);
      }
    }
  }
  if (ret?.length === 0) return "";

  return ret || label;
}

export function useTranslate(label: string, lang?: string) {
  const defaultLanguage = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const defaultBrand = useSelector((state: any) => state.config?.brand);
  const translationFallback = useSelector(
    (state: any) => state.translation?.labels
  );
  const [textToShow, setTextToShow] = useState("");
  const currentBrand = useCurrentBrand();

  useEffect(() => {
    getText();
  }, [
    lang,
    label,
    defaultLanguage,
    defaultBrand,
    currentBrand,
    translationFallback,
  ]);

  const getText = () => {
    let langT = lang || defaultLanguage;
    let brandT = defaultBrand || currentBrand;
    const t = translate(label, langT, brandT, translationFallback);
    if (t?.length === 0) return "";

    setTextToShow(t || label);
  };

  return textToShow;
}

export function useTranslation() {
  const defTranslation = (lbl: string): string => {
    return lbl;
  };

  const defaultLanguage = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const defaultBrand = useSelector((state: any) => state.config?.brand);
  const [translatefn, setTranslate] = useState(() => defTranslation);
  const translationFallback = useSelector(
    (state: any) => state.translation?.labels
  );
  const currentBrand = useCurrentBrand();

  useEffect(() => {
    getTranslation();
  }, [defaultLanguage, defaultBrand, currentBrand, translationFallback]);

  const getTranslation = () => {
    let langT = defaultLanguage;
    let brandT = defaultBrand || currentBrand;
    //const t =translate(label,langT,brandT);
    const hh = () => (label: string) =>
      translate(label, langT, brandT, translationFallback);
    setTranslate(hh);
  };

  return translatefn;
}
