import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrandComponent } from "../../../components/common/BrandComponent";
import { workflowActions } from "../../../redux/slices/workflow";
import { configObjectToSliderObject } from "../../adapters/ConfigObjectToSliderObject";
import { getStepBadgeType } from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useContextualMessage,
  useGVPOptions,
  useMoreInfo,
} from "@hooks";
import { ParameterWithTitle } from "../../models/Parameter";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import "./default.module.scss";
import ReferencedSection from "../../../components/common/ReferencedSection";

export function GVPTreatment() {
  const currentPackages = useAllCurrentPackages();
  const gvpoptions = useGVPOptions();
  const mapperMoreInfo = useMoreInfo("treatment");
  const [treatmentList, setTreatmentList] = useState(null);

  const ctxMessage = useContextualMessage();

  const [selectedGroup, setSelectedGroup] = useState(null);

  const [pills, setPills] = useState(null);

  const translate = useTranslation();

  const dispatch = useDispatch();

  const onGVPSelected = (obj: {
    id: string;
    title: string;
    price: number;
    discount: number;
  }) => {
    const idT = obj.id.split("$")[0];
    const valueT = obj.id.split("$")[1];
    const mapped = treatmentList.map((ta: any) => {
      const idTa = ta.id.split("$")[0];
      const valueTa = ta.id.split("$")[1];
      if (idTa === idT) {
        if (valueTa === valueT) {
          ta.isSelected = !ta.isSelected;
        } else {
          ta.isSelected = false;
        }
      }
      return ta;
    });
    const onlySelected = mapped
      .filter((ta: any) => ta.isSelected)
      .map((ta: any) => {
        let ret = new ParameterWithTitle();
        const idTa = ta.id.split("$")[0];
        const valueTa = ta.id.split("$")[1];
        ret.key = idTa;
        ret.value = valueTa;
        ret.title = obj.title;
        ret.price = obj.price;
        ret.discount = obj.discount;
        return ret;
      });
    dispatch(workflowActions.setParameterPrice(onlySelected));
    if (onlySelected.key === "transition") {
      workflow.goToStep("TransitionColor", [
        {
          key: "gvpTreatment",
          value: onlySelected[0].title,
          price: onlySelected[0].price,
          discount: onlySelected[0].discount,
          insPrice: onlySelected[0].insPrice,
        },
        {
          key: "treatment",
          value: onlySelected[0].value,
          price: onlySelected[0].price,
          discount: onlySelected[0].discount,
          insPrice: onlySelected[0].insPrice,
        },
      ]);
    } else {
      if (
        currentPackages.validRXPackages.some(
          (cp) =>
            cp.lensPackage[onlySelected[0].key] === onlySelected[0].value &&
            !cp.lensPackages.transition &&
            cp.lensPackages.color
        )
      ) {
        workflow.goToStep("LensColor", [
          {
            key: "gvpTreatment",
            value: onlySelected[0].title,
            price: onlySelected[0].price,
            discount: onlySelected[0].discount,
            insPrice: onlySelected[0].insPrice,
          },
          {
            key: "treatment",
            value: onlySelected[0].value,
            price: onlySelected[0].price,
            discount: onlySelected[0].discount,
            insPrice: onlySelected[0].insPrice,
          },
          {
            key: "transition",
            value: null,
            price: onlySelected[0].price,
            discount: onlySelected[0].discount,
            insPrice: onlySelected[0].insPrice,
          },
        ]);
      } else {
        workflow.goToStep("Thickness", [
          {
            key: "treatment",
            value: onlySelected[0].value,
            price: onlySelected[0].price,
            discount: onlySelected[0].discount,
            insPrice: onlySelected[0].insPrice,
          },
          {
            key: "gvpTreatment",
            value: onlySelected[0].title,
            price: onlySelected[0].price,
            discount: onlySelected[0].discount,
            insPrice: onlySelected[0].insPrice,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (currentPackages && gvpoptions && mapperMoreInfo) {
      const { groups, disabledGroups, options } = gvpoptions(currentPackages);

      const validOpts = options.filter((opt) => !opt.disabled);
      const greyOutOpts = options.filter((opt) => opt.disabled);

      const treatmentListWithMoreInfo = mapperMoreInfo(validOpts);
      //sort treatmemts keys by sequence in content
      const treatmentsSortedBySequence = sortBy(treatmentListWithMoreInfo, [
        "sequence",
      ]);
      const greyOutTreatmentsSortedBySequence = sortBy(greyOutOpts, [
        "sequence",
      ]);

      setPills(getAllGVPGroups(groups, disabledGroups));
      setTreatmentList(
        treatmentsSortedBySequence.concat(greyOutTreatmentsSortedBySequence)
      );

      if (!validOpts || !validOpts.length) {
        workflow.goToStep("Thickness", [], true);
      }
    }
  }, [currentPackages, gvpoptions]);

  const getAllGVPGroups = (groups, disabledGroups) => {
    if (groups.length + disabledGroups.length > 1) {
      let categories = [
        {
          id: "All",
          title: "All",
        },
        ...groups.map((key) => ({
          id: key,
          title: key,
        })),
        ...disabledGroups.map((key) => ({
          id: key,
          title: key,
        })),
      ];
      return configObjectToSliderObject.adaptList(categories);
    }
    return null;
  };

  const filterGroup = (groupName: string) => {
    if (groupName === "All") {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(groupName);
    }
  };

  const getKey = (title: string) =>
    "GVPTreatment_sc_" + (!title ? "" : title.split(" ").join("_"));

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.gvpTreatment.title"),
        subtitle: ctxMessage,
        showGrayOutBanner:
          treatmentList && treatmentList.find((t) => t.disabled),
      }}
    >
      <div className={"GVPTreatment__container"}>
        {treatmentList &&
          treatmentList
            .filter((c) => {
              if (!selectedGroup) return true;

              const key = c.id.split("$")[0];
              return key === selectedGroup;
            })
            .map((c: any) => (
              <BrandComponent
                key={getKey(c.id)}
                componentName="StepCard"
                parameter={{
                  ...c,
                  badge: c.badge ? getStepBadgeType(c.badge) : null,
                  disabled: c.badge == "coming soon" ? true : false,
                  onClick:
                    c.badge == "coming soon" || c.disabled
                      ? null
                      : () => onGVPSelected(c),
                }}
              />
            ))}
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
