import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IncludedTreatment } from "../../components/IncludedBox/component";
import { StepCardProps } from "../../components/StepCard/component";
import {
  blueLightRule,
  cleanRule,
  getStepBadgeType,
  transitionRule,
} from "../ConfigLoader";
import { useTranslation } from "../translations/TranslationHooks";
import { usePriceStepItem } from "./usePriceStepItem";

export function useTreatmentsMapper() {
  const options = useSelector((state: any) => state.config.treatmentsOptions);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  //const configPackages = useSelector((state: any) => state.config?.lensesData?.packages);
  const getPriceStep = usePriceStepItem();
  const translate = useTranslation();

  const [ret, setRet] = useState(null);
  const getOptP = (
    opt: string,
    treatmentName: string,
    currentPackages,
    disabled?: boolean
  ) => {
    //'antiReflective','blueLight','antiScratch','uvProtection'
    let ret: StepCardProps & { filter? } = {
      id: opt + "$" + treatmentName,
      title: treatmentName,
      filter: null,
    };
    switch (opt) {
      case "clear":
        ret.title =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].title
            ? configContent.treatmentFamily[opt].title
            : translate(`steps.treatmentsFamily.clear.title`);
        ret.description =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].description
            ? configContent.treatmentFamily[opt].description
            : translate(`steps.treatmentsFamily.clear.description`);
        ret.imageUrl =
          configContent.treatmentFamily &&
          configContent.treatmentFamily[opt] &&
          configContent.treatmentFamily[opt].imageFileName
            ? configContent.treatmentFamily[opt].imageFileName
            : "clear-icon";
        ret.filter = null;
        break;
      case "antiScratch":
        ret.title = translate(
          "steps.treatments.extraTreatments." + opt + ".title"
        );
        ret.imageUrl = translate(
          "steps.treatments.extraTreatments." + opt + ".imageFileName"
        );
        ret.filter = { antiScratch: true };
        break;
      case "uvProtection":
        ret.title = translate(
          "steps.treatments.extraTreatments." + opt + ".title"
        );
        ret.imageUrl = translate(
          "steps.treatments.extraTreatments." + opt + ".imageFileName"
        );
        ret.filter = { uvProtection: true };
        break;
      case "blueLight":
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        ret.textBadge =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].badge
            : null;
        ret.badge =
          configContent[opt] && configContent[opt][treatmentName]
            ? getStepBadgeType(configContent[opt][treatmentName].badge)
            : null;

        ret.filter = { blueLight: treatmentName };
        break;
      case "antiReflective":
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        ret.textBadge =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].badge
            : null;
        ret.badge =
          configContent[opt] && configContent[opt][treatmentName]
            ? getStepBadgeType(configContent[opt][treatmentName].badge)
            : null;
        ret.filter = { antiReflective: treatmentName };
        break;
      case "transition":
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        ret.textBadge =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].badge
            : null;
        ret.badge =
          configContent[opt] && configContent[opt][treatmentName]
            ? getStepBadgeType(configContent[opt][treatmentName].badge)
            : null;
        ret.filter = { transition: treatmentName };
        break;
    }

    if (!disabled) {
      const priceObj = getPriceStep(ret.filter, currentPackages);
      if (priceObj) {
        ret.price = priceObj.price;
        ret.discount = priceObj.discount;
        ret.insPrice = priceObj.insPrice;
      }
    }

    if (disabled) {
      ret.disabled = true;
    }
    const tkey = ret.id.split("$")[0];

    const treatmentsOptions: any = configContent[tkey]
      ? Object.values(configContent[tkey])
      : Object.values({ ret });

    if (treatmentsOptions.length > 1) {
      treatmentsOptions.map((option) => {
        if (option.title === ret.title) {
          ret.sequence = option.sequence;
        }
      });
    } else {
      ret.sequence = 0;
    }
    return ret;
  };

  const rules = {
    clear: cleanRule,
    blueLight: blueLightRule,
    transition: transitionRule,
  };

  const getTreatments = (
    currentPackages: { validRXPackages: any[]; invalidRXPackages: any[] },
    empty?: boolean,
    forceTreatments?: string[],
    ignoreIncluded?: boolean,
    treatmentFamily?: string[]
  ) => {
    let treatments: StepCardProps[] = [];
    let included: IncludedTreatment[] = [];
    let localOption = options;
    if (!currentPackages) {
      return { included, treatments };
    }
    if (treatmentFamily && treatmentFamily.length > 0) {
      localOption = localOption.filter((el) => !treatmentFamily.includes(el));
    }

    localOption.forEach((opt: string) => {
      let packageWithTreatments = !empty
        ? currentPackages.validRXPackages.filter((c) => c.lensPackage[opt])
        : currentPackages.validRXPackages;

      let greyOutPackageWithTreatments = !empty
        ? currentPackages.invalidRXPackages.filter((c) => c.lensPackage[opt])
        : currentPackages.invalidRXPackages;

      packageWithTreatments = packageWithTreatments.filter(
        (pkg) => !rules[opt] || rules[opt](pkg.lensPackage)
      );

      greyOutPackageWithTreatments = greyOutPackageWithTreatments.filter(
        (pkg) => !rules[opt] || rules[opt](pkg.lensPackage)
      );

      const treatmentsOfPackets = groupBy(
        packageWithTreatments,
        "lensPackage." + opt
      );
      const greyOutTreatmentsOfPackets = groupBy(
        greyOutPackageWithTreatments,
        "lensPackage." + opt
      );
      const isforced = forceTreatments && forceTreatments.indexOf(opt) >= 0;
      if (treatmentsOfPackets) {
        const availableValue = Object.keys(treatmentsOfPackets);
        const greyOutAvailableValue = greyOutTreatmentsOfPackets
          ? Object.keys(greyOutTreatmentsOfPackets).filter(
              (top) => !availableValue.find((v) => v === top)
            )
          : [];

        const allAvailableValues = [
          ...availableValue,
          ...greyOutAvailableValue,
        ];

        if (allAvailableValues.length <= 0 && !isforced) {
          //not found
        } else if (
          allAvailableValues.length === 1 &&
          !isforced &&
          !ignoreIncluded
        ) {
          //included
          if (availableValue.length) {
            const mapped = getOptP(
              opt,
              availableValue[0],
              currentPackages.validRXPackages
            );
            included.push({
              name: mapped.title,
              id: opt,
              key: mapped.id.split("$")[1],
            });
          }
        } else {
          //avaiable
          treatments = [
            ...treatments,
            ...availableValue
              .filter(
                (treatmentName) =>
                  empty ||
                  (configContent &&
                    configContent[opt] &&
                    configContent[opt][treatmentName])
              )
              .map((treatmentName) =>
                getOptP(opt, treatmentName, currentPackages.validRXPackages)
              ),
            ...greyOutAvailableValue
              .filter(
                (treatmentName) =>
                  empty ||
                  (configContent &&
                    configContent[opt] &&
                    configContent[opt][treatmentName])
              )
              .map((treatmentName) =>
                getOptP(
                  opt,
                  treatmentName,
                  currentPackages.invalidRXPackages,
                  true
                )
              ),
          ];
        }
      }
    });

    /* treatments = treatments.map(treat => ({
            ...treat,
            discountLoading: isLoading(treat.id)
        })) */

    return { included, treatments };
  };
  useEffect(() => {
    setRet(
      () =>
        (
          currenPackages: {
            validRXPackages: any[];
            invalidRXPackages: any[];
          },
          empty?: boolean,
          forceTreatments?: string[],
          ignoreIncluded?: boolean,
          treatmentFamily?: string[]
        ) =>
          getTreatments(
            currenPackages,
            empty,
            forceTreatments,
            ignoreIncluded,
            treatmentFamily
          )
    );
  }, [options, configContent, translate]);

  return ret;
}
