import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { convertISODate } from "../../utils/date";
import "./default.module.scss";
type Props = {
  id: string;
  name: string;
  lastUpdate: string;
  onSelect: () => void;
};

export function PrescriptionFromMyAccountCard(props: Props) {
  const prescriptionName = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionName"
  );
  const lastModified = useTranslate(
    "steps.advancedPrescription.fromMyAccount.lastModified"
  );
  const selectButton = useTranslate(
    "steps.advancedPrescription.fromMyAccount.selectButton"
  );
  return (
    <div className="PrescriptionFromMyAccountCard">
      <div className="PrescriptionFromMyAccountCard__infoContainer">
        <p className="PrescriptionFromMyAccountCard__infoContainer__name">
          {prescriptionName}: {props.name}
        </p>
        <p className="PrescriptionFromMyAccountCard__infoContainer__date">
          {lastModified + convertISODate({ isoDate: props.lastUpdate })}
        </p>
      </div>
      <div
        className="PrescriptionFromMyAccountCard__button"
        onClick={() => props.onSelect()}
      >
        {selectButton}
      </div>
    </div>
  );
}
