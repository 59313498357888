import React from "react";
import "./default.module.scss";
import { ReactNode } from "react";
import { BrandComponent } from "../common/BrandComponent";
import { StepTitleProps } from "../StepTitle/component";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";

interface StepContainerProps extends StepTitleProps {
  children?: ReactNode;
  subtitleStyle?: any;
}

const getDataElementId = () => {
  let currentStepName = workflow.currentStep?.key;
  let analyticsStepName = getCorrectAnalyticsStepContainerName(currentStepName);
  return "X_X_LensPanel_" + analyticsStepName;
};

/**
 * Wrapper component that contains a step. It accepts an input of type ```StepContainerProps```
 * extending the interface ```StepTitleProps``` with the following parameter:
 *  - ```children``` (_optional_): the step you want to display
 */
export function StepContainer({
  children,
  title,
  titleMobile,
  subtitle,
  subtitleStyle,
  slider,
  pills,
  pillsSubtitle,
  brandCallback,
  pillsCallback,
  pillSelected,
  showGrayOutBanner,
}: StepContainerProps) {
  return (
    <div
      className={"StepContainer__container"}
      id="step-container"
      data-element-id={getDataElementId()}
    >
      <BrandComponent
        componentName="StepTitle"
        parameter={{
          title,
          titleMobile,
          subtitle,
          subtitleStyle,
          slider,
          pills,
          pillsSubtitle,
          brandCallback,
          pillsCallback,
          pillSelected,
          showGrayOutBanner,
        }}
      />
      {children}
    </div>
  );
}
