import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../translations/TranslationHooks";
import { useCurrentPackages } from "./useCurrentPackages";

export function usePriceDiscountBadge(
  optKey: string,
  discount: number,
  listPrice: number
) {
  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const content = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const autoDiscountBadge = useSelector(
    (state: any) => state.config?.data?.autoDiscountBadge
  );
  const automaticBadgeStepSelection = useSelector(
    (state: any) => state.workflow?.automaticDiscountBadgeStepSelection
  );
  const currentPackages = useCurrentPackages();

  const [badge, setBadge] = useState<{
    key: string;
    value: string;
    badge: string;
  }>(null);

  const percentageLabel = useTranslate("price.discountBadgePercentage");
  const amountLabel = useTranslate("price.discountBadgeAmount");

  useEffect(() => {
    if (
      content &&
      currentPackages &&
      currentPackages.length &&
      !insuranceEnabled
    ) {
      let contentEntry = null;
      let contentKey = null;

      const contentKeys = Object.keys(content);
      if (contentKeys && contentKeys.length) {
        contentKeys.forEach((key) => {
          if (content[key] && typeof content[key] === "object") {
            const found = Object.keys(content[key]).find(
              (inKey) => inKey === optKey
            );
            if (found) {
              contentEntry = content[key][found];
              contentKey = key;
            }
          }
        });
      }
      if (!autoDiscountBadge) {
        //fallback behavior, get the badges from the content
        if (contentEntry?.priceBadge) {
          setBadge({
            key: contentKey,
            value: optKey,
            badge: contentEntry?.priceBadge,
          });
        }
      } else {
        if (contentEntry && !automaticBadgeStepSelection) {
          let exclude = [];
          if (contentKey === "blueLight") {
            //bluelight does not have transition but the opposite can be true
            exclude.push("transition");
          } else if (!contentKey) {
            //clear
            exclude.push("blueLight");
            exclude.push("transition");
            exclude.push("color");
          }
          const nextPackages = currentPackages.filter((pkg) => {
            const doesntHaveExcluded = exclude.every(
              (val) =>
                !pkg.lensPackage[val] ||
                pkg.lensPackage[val].toString().length === 0
            );
            return pkg.lensPackage[contentKey] === optKey && doesntHaveExcluded;
          });

          if (
            nextPackages.length &&
            isUndefined(discount) &&
            isUndefined(listPrice)
          ) {
            listPrice = parseFloat(nextPackages[0].lensPackage.listPrice);
            discount =
              listPrice - parseFloat(nextPackages[0].lensPackage.offerPrice);
          }

          if (autoDiscountBadge === "PERCENTAGE") {
            const referencePkg =
              nextPackages.length > 1 ? nextPackages[0] : null;
            let percentage = null;
            if (referencePkg) {
              const referenceListPrice = parseFloat(
                parseFloat(referencePkg.lensPackage.listPrice).toFixed(2)
              );
              const referenceDoscount =
                referenceListPrice -
                parseFloat(
                  parseFloat(referencePkg.lensPackage.offerPrice).toFixed(2)
                );
              percentage =
                parseFloat(
                  (referenceDoscount / referenceListPrice).toPrecision(2)
                ) * 100;
            }
            const showBadge =
              nextPackages.length > 1 &&
              nextPackages.every((pkg) => {
                const pkgListPrice = parseFloat(
                  parseFloat(pkg.lensPackage.listPrice).toFixed(2)
                );
                const pkgDiscount =
                  pkgListPrice -
                  parseFloat(parseFloat(pkg.lensPackage.offerPrice).toFixed(2));
                const pkgPercentage =
                  parseFloat((pkgDiscount / pkgListPrice).toPrecision(2)) * 100;
                return percentage === pkgPercentage;
              });
            if (showBadge && percentage !== 0) {
              setBadge({
                key: contentKey,
                value: optKey,
                badge: percentageLabel
                  .replace("###VALUE###", percentage.toString())
                  .replace("###SYMBOL###", "%"),
              });
            }
          } else if (autoDiscountBadge === "AMOUNT") {
            const amount =
              nextPackages.length > 1
                ? parseFloat(nextPackages[0].lensPackage.listPrice) -
                  parseFloat(nextPackages[0].lensPackage.offerPrice)
                : null;
            const showBadge =
              nextPackages.length > 1 &&
              nextPackages.every((pkg) => {
                return (
                  parseFloat(pkg.lensPackage.listPrice) -
                    parseFloat(pkg.lensPackage.offerPrice) ===
                  amount
                );
              });
            if (showBadge && amount !== 0) {
              setBadge({
                key: contentKey,
                value: optKey,
                badge: amountLabel
                  .replace("###VALUE###", discount.toString())
                  .replace(
                    "###SYMBOL###",
                    currencyFormat?.prefix
                      ? currencyFormat.prefix
                      : currencyFormat?.suffix
                  ),
              });
            }
          }
        }
      }
    }
  }, [content, currentPackages, automaticBadgeStepSelection]);

  return badge;
}
