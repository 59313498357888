import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflowActions } from "../../../redux/slices/workflow";
import { checkGVP, filterExistingProperties } from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useContextualMessage,
  useGetDiscount,
  usePriceStepItem,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import { useBrands } from "@hooks";

export function Brand(props: StepComponentOptions) {
  const translate = useTranslation();
  const currentPackages = useAllCurrentPackages();
  const { brands } = useBrands();
  const ctxMessage = useContextualMessage();
  const dispatch = useDispatch();
  const { getDiscount, isLoading } = useGetDiscount();
  const getPriceStep = usePriceStepItem();

  const onBrandSelectedFromStep = (id, skipped) => {
    onBrandSelected({ id, ...getPriceStep({ brand: id }) }, skipped);
  };

  const onBrandSelected = (
    brand: { id; price; discount; insPrice },
    skipped: boolean
  ) => {
    let nextStep;
    if (
      checkGVP(
        currentPackages.validRXPackages.filter((p) =>
          filterExistingProperties(p, { brand: brand.id })
        )
      )
    ) {
      dispatch(workflowActions.setPromoType("GVP"));
      const isIncorrectData = currentPackages.validRXPackages
        .concat(currentPackages.invalidRXPackages)
        .every((current) => {
          return current.lensPackage.treatmentGroup === null;
        });
      if (isIncorrectData) {
        console.error(
          `ATTENTION! For GVP packages the identifier of lens treatment group (treatmentGroup parameter in lensPackage) can't be null because is mandatory. \n The current filtered packages are:`,
          currentPackages
        );
      }
      nextStep = "GVPTreatment";
    } else {
      dispatch(workflowActions.setPromoType(null));
      nextStep = "TreatmentsFamily";
    }
    workflow.goToStep(
      nextStep,
      [
        ...(skipped ? workflow.currentStep.params : []),
        {
          key: "brand",
          value: brand.id,
          price: brand.price,
          discount: brand.discount,
          insPrice: skipped && brand.price == 0 ? 0 : brand.insPrice,
        },
      ],
      skipped
    );
  };

  useEffect(() => {
    for (const brand of brands) {
      const { id } = brand;
      getDiscount(id, currentPackages.validRXPackages, { brand: id });
    }
  }, [brands]);

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.brand.title"),
        subtitle: ctxMessage,
        showGrayOutBanner: brands && brands.find((b) => b.disabled),
      }}
    >
      <div className={"Brand__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "Brand",
            onNext: (selected: any, skipped?: boolean) => {
              onBrandSelectedFromStep(selected?.id, skipped);
            },
            parameterPreselection: ["brand"],
            onPreselect: (selected, skipped: boolean) => {
              onBrandSelectedFromStep(selected.brand, skipped);
            },
          }}
        >
          {brands.map((brand, i) => (
            <BrandComponent
              key={i}
              componentName="StepCard"
              parameter={{
                ...brand,
                onClick:
                  brand.badge == "coming soon" || brand.disabled
                    ? null
                    : () => onBrandSelected(brand, false),
                discountLoading: isLoading(brand.id),
              }}
            />
          ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
