import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export function useCurrentType(defaultBrand?: string) {
  const [currentType, setCurrentType] = useState(defaultBrand);
  const typeConfig = useSelector(
    (state: any) => state.config?.data?.frame?.category
  );

  useEffect(() => {
    let type = defaultBrand;
    if (process.env.CURRENT_MODE === "standalone") {
      const urlType = window?.localStorage?.getItem("type");
      if (urlType) {
        type = urlType;
      }
    } else {
      type = typeConfig || defaultBrand;
    }

    if (!type) {
      type = "EYEGLASSES";
    }

    setCurrentType(type);
  }, [typeConfig]);

  return currentType;
}
