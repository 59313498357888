//@ts-ignore
export default {
  glasses: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision need, so you can go hussle free",
        },
        advancedPrescription: {
          confirmedTitle: "Prescription",
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            review: {
              title: "Entered manually",
            },
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            addPlaceholder: "None",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pupillaryDistanceSubtitle:
              "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please ",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningValue:
              "Please input your pupillary distance, if you don’t have it you can use the default from the above or you can ",
            pupillaryDistanceMisurePDAction: "measure your PD.",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            commentsTitle: "Comments",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "Complete the missing values. Please note, if CYL value is higher or lower than 0, AXIS values can’t be 0",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarning2:
                "The selected PD is smaller than average, we suggest double checking your prescription. If you don’t have it, you can add the values written above or you can ",
              missingBaseDir: "Please choose the base direction for your prism",
              missingPrismValues: "Please choose the prism value",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              incompatibleWithPackagesTitle:
                "Sorry! The prescription you've chosen isn't compatible with any of our available lenses for this frame",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
            digitalOptometry: {
              title: "How to measure your PD",
              appSubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. It indicates exactly which part of the lens you look through, ensuring optimal comfort and clarity. You can measure with the app or manually.",
              manuallySubtitle:
                "Your Pupillary Distance, or PD, is the distance in millimeters (mm) from the center of one pupil to the center of the other. You can measure with the app or manually.",
              toggle: {
                app: "with the app",
                manually: "manually",
              },
              opthyDesc:
                "<b>Get your glasses just right with Opthy.</b><br/><p>Opthy measures your Pupillary Distance, allowing you to find the most comfortable, personalized fit.</p><p>App available on iPhone X and above.</p>",
              manuallyContent:
                "<div>If you want, you can measure your PD yourself by following these steps:</div><ul><li>Grab a small ruler in one hand, and have your pencil and paper handy.</li><li>Now position yourself approximately 8 inches (20 cm) away from a mirror.</li><li>Close your left eye and align the 0 over the center of your right pupil.</li><li>Measure the distance from your right to your left pupil.</li><li>The number that lines up directly over your left pupil is your PD (an average PD for an adult ranges between 58 and 65).</li></ul>",
            },
            manualPreviewTitle: "Your prescription details",
            seeDetails: {
              sphere: "SPH",
              cylinder: "CYL",
              axis: "Axis",
              add: "ADD",
              pd: "PD",
              od: "OD",
              os: "OS",
              vertical: "Vertical Prism (Δ)",
              baseDirection: "Base Direction",
              horizontal: "Horizontal Prism (Δ)",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
            review: {
              title: "Loaded from the account",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload it now",
              description:
                "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages",
            },
            review: {
              title: "Uploaded",
            },
            uploadDifferentFile: "Upload different file",
            changeMethod: "Change sending method",
            prescriptionUploaded: "Prescription Uploaded",
            somethingWentWrong: "Sorry, something went wrong.",
            fileTooBigErrorTitle: "File is too big",
            fileTooBigErrorDescription:
              "Your file size is too big. Maximum upload ###FILE_SIZE### MB",
            tryAgain: "Please try again",
            upload: "Upload",
            pdForm: {
              pupillaryDistance: "PD (Pupillary distance)",
              whatIsIt: "What is it?",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 PD numbers",
            },
            confirmAndContinue: "Confirm & continue",
            filePreviewTitle: "Your prescription",
          },
          doctor: {
            title: "Add your doctor details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your eye doctor by",
            card: {
              title: "Call my doctor",
              description: "We'll get your prescription directly from him/her.",
            },
            review: {
              title: "We'll call your doctor",
            },
            addNewDoctorModal: {
              title: "Add new doctor",
              clinicOrDoctorName: "Clinic or doctor name",
              phoneNumber: "Phone number",
              addDoctor: "Add doctor",
            },
            slider: {
              phone: "Phone number",
              clinicOrDoctorName: "Clinic or doctor name",
            },
            table: {
              doctorName: "Doctor name",
              clinicName: "Clinic name",
              address: "Address",
              chooseButton: "Choose",
              phone: "Phone",
              fax: "Fax",
              phoneNumber: "Phone number",
              changeButton: "Change",
              wrongDoctor: "Wrong doctor?",
            },
            pdForm: {
              pupillaryDistance: "PD (Pupillary distance)",
              whatIsIt: "What is it?",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              pdLeft: "Left",
              pdRight: "Right",
              iHaveTwoPd: "I have 2 PD numbers",
            },
            addManuallyOption: {
              didntFind: "Didn't find your doctor?",
              addManually: "Add manually",
            },
            missingInfoError: "Complete the missing informations",
            doctorNotFoundBoxMessage: "We couldn't find your doctor.",
            doctorNotFoundBoxMessageAdd: "Please add more details or ",
            doctorNotFoundBoxMessageLink: "add a new doctor.",
            phoneNumber: "Doctor phone number",
            search: "Search",
            clinicOrDoctorName: "Clinic or doctor name",
            city: "City",
            state: "State",
            changeSubmissionMethod: "Change submission method",
            confirmAndContinue: "Confirm & continue",
            selectedDoctorPageTitle: "Your doctor details",
            doctorPreviewTitle: "We'll call your doctor",
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
            review: {
              title: "We'll ask for it after the checkout",
            },
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, <b>California residents are also required to electronically transmit their valid prescription</b>. Please, upload it to proceed.",
            infoCMD:
              "Please note that besides adding prescription values manually, <b>California residents are also required to electronically transmit their valid prescription</b>. Choose an option to proceed.",
            chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
            uploadTitle: "Upload",
            uploadDesc:
              "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Browse",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle:
                "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colors and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best in class LensCrafters technology.",
          designTypeTitle: "Choose your lens design",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColor",
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        lensColor: {
          confirmedTitle: "Color:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Color",
          },
          categories: {
            all: {
              title: "All",
            },
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Color:",
          title: "Choose your lens color",
          titleMobile: "Choose your lens color",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarized only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced color and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishings:",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in installments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
          seeDetails: "See details",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
        vatIncluded: "Tax included",
        discountBadgePercentage: "###VALUE######SYMBOL### OFF",
        discountBadgeAmount: "###VALUE######SYMBOL### OFF",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceLabelRemove: "Remove benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      insuranceErrorModal: {
        title: "Something went wrong...",
        content:
          "We weren't able to apply your insurance benefits. Your insurance plan will be automatically unsynced in order to continue.",
        confirm: "Confirm",
      },
      grayOutBannerMessage:
        "Some options may be disabled as they are not compatible with your prescription values.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
      error: {
        title: "Something went wrong...",
        description:
          "We’re experiencing some technical difficulties, we apologize. In the meantime, if you have any questions or need assistance, feel free to <a href='#'>contact our customer service.</a>",
        buttonLabel: "Back",
      },
      generic: {
        modal: {
          close: "",
        },
      },
    },
    en_GB: {
      steps: {
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision needs, so you can go hassle free",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest you double check your prescription. If this is correct, you can proceed.",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          doctor: {
            title: "Add your optician details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your optician by",
            card: {
              title: "Call my optician",
              description: "We'll get your prescription directly from him/her.",
            },
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colours and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColour",
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        lensColor: {
          confirmedTitle: "Colour:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Colour",
          },
          categories: {
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Colour:",
          title: "Choose your lens colour",
          titleMobile: "Choose your lens colour",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarised only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced colour and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishes",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in instalments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest you double check your prescription.",
        compatibleFrameError:
          "SSorry! The frame you've chosen doesn't support your prescription values.",
        compatibleFrameSubLabel:
          "No worries, we've got frames to suit your prescription.",
        axisError: "The AXIS values you've inserted are not correct!",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD numbers",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      proceedAsIs: "Proceed as is",
      yourSelections: "Your selections",
      editButton: "Edit",
    },
    en_CH: {
      steps: {
        prescription: {
          title: "Add your prescription values",
          description:
            "Or we will suggest the right lens for your vision needs, so you can go hassle free",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            title: "Enter your prescription",
            card: {
              title: "Enter it manually",
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type.",
            },
            subtitle:
              "Add your prescription values and we'll recommend the best lens for your vision needs.",
            rightEye: {
              initials: "OD",
              name: "(Right eye)",
            },
            leftEye: {
              initials: "OS",
              name: "(Left eye)",
            },
            sphere: "Sphere (SPH)",
            cylinder: "Cylinder (CYL)",
            axis: "Axis",
            add: "ADD",
            vertical: "Vertical (Δ)",
            baseDirection: "Base Direction",
            horizontal: "Horizontal (Δ)",
            pupillaryDistance: "PD (Pupillary distance)",
            pdLeft: "Left",
            pdRight: "Right",
            iHaveTwoPd: "I have 2 PD numbers",
            applyButton: "Continue",
            howToRead: "How to read your prescription",
            whatIsIt: "What is it?",
            pupillaryDistanceWarningTooltip: "Provide text for this tooltip",
            moreOptions: "More options",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "You’ve inserted 1 positive and 1 negative value, which is very rare. We suggest you double check your prescription. If this is correct, you can proceed.",
              missingValues: "Complete the missing values",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionTitle:
                "Sorry! The frame you’ve chosen isn’t compatible with your prescription",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              shopCompatible: "Shop compatible frames",
              clearAll: "Clear all",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
          },
          account: {
            card: {
              title: "Get it from my account",
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            title: "Your prescription",
            subtitle:
              "Before submitting your prescription, please check the expiration date to make sure it's still valid.",
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          doctor: {
            title: "Add your optician details",
            subtitle:
              "It must be the same doctor that issued and signed your prescription. We'll call them to verify your details.",
            searchBy: "Search your optician by",
            card: {
              title: "Call my optician",
              description: "We'll get your prescription directly from him/her.",
            },
          },
          later: {
            card: {
              title: "Send it later",
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        type: {
          title: "Choose your vision need",
          confirmedTitle: "Vision need:",
          prescriptionTitle: "Or",
          addToCart: "Add to cart",
          moreInfo: "More info",
        },
        brand: {
          title: "Choose your lens brand",
          confirmedTitle: "Lens brand:",
        },
        treatmentsFamily: {
          confirmedTitle: "Lens type:",
          title: "Choose your lens type",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
          blueLight: {
            title: "Blue-light filtering",
            description:
              "Reduces exposure to blue light from digital screens, which can help prevent eye-fatigue.",
            imageFileName: "",
          },
          transition: {
            title: "Transition ® Signature ® GEN 8™",
            description:
              "One pair for indoors and outdoors with Transitions® lenses: quickly darken and fade to clear, so you never have to change glasses.",
            imageFileName: "",
          },
          clear: {
            title: "Clear",
            description:
              "Traditional, transparent lenses perfect for everyday use",
            imageFileName: "",
          },
          sun: {
            title: "Sun",
            description: "Choose from different lens colours and tints.",
            imageFileName: "",
          },
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
          confirmedTitle: "Lens Package:",
        },
        thickness: {
          confirmedTitle: "Thickness:",
          title: "Choose your lens thickness",
          subtitle:
            "Your glasses will come with best-in-class LensCrafters technology.",
        },
        treatments: {
          addToBag: "Add to bag",
          title: "Choose your light responsive treatment",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Treatment:",
          extraTreatments: {
            antiScratch: {
              name: "Anti-scratch",
              title: "Scratch resistant",
            },
            antiReflective: {
              value: "Anti-reflective",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        transitionColor: {
          title: "TransitionColour",
          indoor: "Indoor",
          outdoor: "Outdoor",
        },
        lensColor: {
          confirmedTitle: "Colour:",
          detailBox: {
            lightTransmission: "Light Transmission",
            lightConditions: "Light conditions",
            contrast: "Contrast",
            baseLensColor: "Base Lens Colour",
          },
          categories: {
            "Ugly category": {
              title: "Ugly Category",
            },
            "Cool category": {
              title: "Cool Category",
            },
          },
        },
        color: {
          confirmedTitle: "Colour:",
          title: "Choose your lens colour",
          titleMobile: "Choose your lens colour",
          titleDesignTypeLayout: "Choose your lens technology",
          titleColorCategoryLayout: "Choose your lens type",
          polarized: "Polarised only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced colour and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            antiReflective:
              "Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.",
            uvProtection:
              "Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.",
            antiScratch: "Protects your lens surface from harsh impacts",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          confirmedTitle: "Finishings:",
          label: "Already included in your lens",
          title: "Complete your lenses",
          reviewTitle: "Finishes",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          title: "Review your selections",
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          discountTitle: "40% off prescription lenses is automatically applied",
          firstLineReview: "Frame:",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              title: "Paying in instalments",
              subtitle:
                "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free instalments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna:
                "With ###PAYMENT_TYPE###, pay in ###INSTALLMENTS### interest-free payments of ###MONTHLY_PRICE###",
            },
          },
          insuranceButtonLabel: "Add insurance benefits",
          insuranceButtonLabelRemove: "Remove insurance benefits",
          oneYearCoverageAdd: "Add",
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      prescription: {
        toggleLabel:
          "Add your prescription values, we’ll help you find the perfect lens for your vision needs.",
        prescriptionTitle:
          "Add your prescription values, we’ll show you the perfect lens for your vision needs.",
        rightEye: "(Right eye)",
        rightEyeInitials: "OD",
        leftEye: "(Left eye)",
        leftEyeInitials: "OS",
        readPrescription: "How to read your prescription",
        addLabel: "I also have an ADD value",
        applyButton: "Apply & continue",
        submitButton: "Submit",
        clearButton: "Clear all",
        skipButton: "Skip for now",
        compatibleFramesButton: "Shop compatible frames",
        doctorDetails:
          "We'll ask for a copy of your prescription or doctor's details during or after checkout",
        missingValueError: "Add your values to continue",
        positiveNegativeError:
          "You've entered one positive value and one negative value, which is quite rare. We suggest double checking your prescription.",
        compatibleFrameError:
          "Sorry! The frame you've chosen isn't compatible with your prescription.",
        compatibleFrameSubLabel:
          "But don't worry, compatible frames are available.",
        axisError: "Remember to add your AXIS values",
        axisSubError: "These values must be between 0 and 180.",
        axisSubErrorZero:
          "If CYL value is higher or lower than 0, AXIS values can’t be 0.",
        editLabel: "Edit prescription",
        pdHelp: "What is this?",
        twoPDNumbers: "I have two PD values",
        missingInputMessage:
          "Add your missing values to complete the upload of your prescription and we'll recommend the most suitable lens.",
        defaultInputMessage:
          "Choose to add your prescription now or upload it later.",
        skipMessage: "",
        readyMessage: "We are now able to suggest the right lens for you!",
        bestPrescription: "Best for your prescription",
        proceedCheckbox:
          "By clicking this box, I confirm that the prescription values entered above are taken from a unexpired written prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
        proceedCheckboxError: "Required to proceed",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
          frame: "Frame price",
          total: "Total",
        },
        free: "Free",
        included: "Included",
        frameLensStartingAt: "Frame + Lenses at",
        frameLens: "Frame + Lenses",
        startingAt: "Starting at",
      },
      noTreatment: "No treatment",
      learnMoreMade: "See how it's made",
      learnMoreTreatment: "See the treatment",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      exitYes: "Yes, exit",
      exitContinueEditing: "No, continue",
      exitSave: "Save and continue shopping",
      insuranceButtonLabel: "Add insurance benefits",
      insuranceButtonLabelRemove: "Remove insurance benefits",
      insuranceSyncedLabel: "Insurance is synced",
      applyingInsurance: "Applying insurance benefits",
      frameSize: "Frame size",
      proceedAsIs: "Proceed as is",
      fallbackImageMessageTitle: "Lens color preview not available",
      fallbackImageMessageSubtitle:
        "No worries, you will receive your frame with the lens color you selected.",
      yourSelections: "Your selections",
      editButton: "Edit",
    },
  },
};
