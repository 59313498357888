import React, { useCallback, useEffect, useState } from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";
import { modalsActions } from "..//..//redux/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { configObjectToSliderObject } from "../../configurator/adapters/ConfigObjectToSliderObject";
import PhoneInput from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { prescriptionActions } from "../../redux/slices/prescription";
import HtmlParser from "html-react-parser";
import getStateOptionsByCountry from "../../configurator/utils/getStateOptionsByCountry";
import Input from "../common/Input";
import CallMyDoctorStateInput from "../CallMyDoctorStateInput/component";
import { usePupillaryDistance } from "@hooks";

const Subtitle = () => {
  const callMyDoctorSubtitle = useTranslate(
    "steps.advancedPrescription.doctor.subtitle"
  );
  return (
    <>
      <div className={"CallMyDoctorPrescriptionForm__subtitle"}>
        {callMyDoctorSubtitle}
      </div>
    </>
  );
};

export const SearchBy = (props) => {
  const searchByTitle = useTranslate(
    "steps.advancedPrescription.doctor.searchBy"
  );
  const [tel, setTel] = useState(null);
  const [isClinic, setIsClinic] = useState(false);
  const [name, setName] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);

  const [errors, setErrors] = useState({
    tel: false,
    name: false,
    city: false,
    state: false,
  });
  const [validTick, setValidTick] = useState({
    tel: false,
    name: false,
    city: false,
    state: false,
  });
  const [showMissingValueError, setShowMissingValueError] = useState(false);

  const phoneNumberSliderLabel = useTranslate(
    "steps.advancedPrescription.doctor.slider.phone"
  );
  const clinicOrDoctorNameSliderLabel = useTranslate(
    "steps.advancedPrescription.doctor.slider.clinicOrDoctorName"
  );
  const missingInfoError = useTranslate(
    "steps.advancedPrescription.doctor.missingInfoError"
  );
  const phoneNumberInputLabel = useTranslate(
    "steps.advancedPrescription.doctor.phoneNumber"
  );
  const searchLabel = useTranslate("steps.advancedPrescription.doctor.search");
  const clinicOrDoctorNameInputLabel = useTranslate(
    "steps.advancedPrescription.doctor.clinicOrDoctorName"
  );
  const cityInputLabel = useTranslate("steps.advancedPrescription.doctor.city");
  const stateInputLabel = useTranslate(
    "steps.advancedPrescription.doctor.state"
  );

  useEffect(() => {
    let errorsToSet = {};
    if (tel && errors.tel) {
      errorsToSet["tel"] = false;
    }
    if (
      (name && errors.name) ||
      (city && errors.city) ||
      (state && errors.state)
    ) {
      errorsToSet["name"] = false;
      errorsToSet["city"] = false;
      errorsToSet["state"] = false;
    }

    if (Object.keys(errorsToSet).length > 0) {
      setErrors((prevState) => ({
        ...prevState,
        ...errorsToSet,
      }));

      if (!isClinic) {
        setShowMissingValueError(false);
      } else {
        if (
          (!errors.name || errorsToSet["name"] === false) &&
          (!errors.city || errorsToSet["city"] === false) &&
          (!errors.state || errorsToSet["state"] === false)
        ) {
          setShowMissingValueError(false);
        }
      }
    }
  }, [tel, name, city, state]);

  const resetErrors = () => {
    setErrors({
      tel: false,
      name: false,
      city: false,
      state: false,
    });
    setShowMissingValueError(false);
  };

  const resetValues = () => {
    setTel(null);
    setName(null);
    setCity(null);
    setState(null);
    setIsFocus(false);
  };

  const resetTicks = () => {
    setValidTick({ tel: false, name: false, city: false, state: false });
  };

  const validateSearchObj = (obj) => {
    let isValidSearch = false;

    let validPhone = obj.tel; /* && isPossiblePhoneNumber(obj.tel); */
    let validClinic =
      (obj.name && obj.name.toString().trim() !== "") ||
      (obj.city && obj.city.toString().trim() !== "") ||
      (obj.state && obj.state.toString().trim() !== "");

    if (!validPhone && !isClinic) {
      setErrors((prevState) => ({
        ...prevState,
        tel: true,
      }));
      isValidSearch = false;
    } else if (!validClinic && isClinic) {
      let errorsToSet = { name: false, city: false, state: false };
      if (!obj.name || (obj.name && obj.name.toString().trim() === "")) {
        errorsToSet.name = true;
      }
      if (!obj.city || (obj.city && obj.city.toString().trim() === "")) {
        errorsToSet.city = true;
      }
      if (!obj.state || (obj.state && obj.state.toString().trim() === "")) {
        errorsToSet.state = true;
      }

      setErrors((prevState) => ({
        ...prevState,
        ...errorsToSet,
      }));
      isValidSearch = false;
    } else {
      resetErrors();
      isValidSearch = true;
    }

    if (isValidSearch) {
      let searchObj = {
        searchType: isClinic ? "NAME" : "PHONE",
        phone: obj.tel,
        name: obj.name,
        city: obj.city,
        state: obj.state,
      };

      props.handleSearch(searchObj);
      setShowMissingValueError(false);
    } else {
      setShowMissingValueError(true);
    }
  };

  const sliderClickCallback = (value) => {
    if (value === 1) {
      setIsClinic(false);
    } else if (value === 2) {
      setIsClinic(true);
    }
    resetErrors();
    resetValues();
    resetTicks();
  };
  const getDoctorSlider = () => {
    let temp = [
      {
        id: 1,
        title: phoneNumberSliderLabel,
      },
      {
        id: 2,
        title: clinicOrDoctorNameSliderLabel,
      },
    ];

    return configObjectToSliderObject.adaptList(temp);
  };

  const [isFocus, setIsFocus] = useState(false);

  const getSearchObjectForCallback = () => {
    let formattedTel = formatPhoneNumberIntl(tel).split(" ").slice(1).join("");

    return {
      tel: formattedTel,
      name: name,
      city: city,
      state: state,
    };
  };

  const checkIfValidAndShowTick = useCallback(
    (fieldName) => {
      let map = {
        tel: {
          val: tel,
          setter: setTel,
        },
        name: {
          val: name,
          setter: setName,
        },
        city: {
          val: city,
          setter: setCity,
        },
        state: {
          val: state,
          setter: setState,
        },
      };
      if (map[fieldName].val && map[fieldName].val.toString().trim() !== "") {
        setValidTick((prevState) => ({
          ...prevState,
          [fieldName]: !!map[fieldName].val,
        }));
      } else {
        setValidTick((prevState) => ({
          ...prevState,
          [fieldName]: false,
        }));
      }
    },
    [tel, name, city, state]
  );

  const currentLanguage = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const isStateSelectInput =
    currentLanguage === "en_US" ||
    currentLanguage === "es_US" ||
    currentLanguage === "en_CA" ||
    currentLanguage === "fr_CA";
  const stateSelectOtions = getStateOptionsByCountry({
    country: currentLanguage,
  });

  const onChangeStateValue = (value) => {
    const stateValue =
      value === "State*" || value === "Province*" ? null : value;
    setState(stateValue);
  };

  useEffect(() => {
    if (isStateSelectInput) {
      checkIfValidAndShowTick("state");
    }
  }, [checkIfValidAndShowTick]);

  return (
    <div
      className={"SearchBy__container" + (props.class ? " " + props.class : "")}
    >
      <div className={"SearchBy__title"}>{searchByTitle}</div>
      <BrandComponent
        componentName="optionsSlider"
        parameter={{
          slider: getDoctorSlider(),
          callback: sliderClickCallback,
        }}
      />
      {!isClinic && (
        <>
          <div className={"SearchBy__searchContainer"}>
            <div
              className={
                validTick.tel
                  ? "SearchBy_numberContainerGreen"
                  : !errors.tel
                  ? "SearchBy_numberContainer"
                  : "SearchBy_numberContainerError"
              }
            >
              <PhoneInput
                value={tel}
                defaultCountry="US"
                type="tel"
                placeholder=" "
                autocomplete={true}
                onChange={setTel}
                onFocus={(e) => {
                  setIsFocus(true);
                  setValidTick((prevState) => ({
                    ...prevState,
                    tel: false,
                  }));
                }}
                onBlur={(e) => {
                  if (!tel) {
                    setIsFocus(false);
                  } else {
                    checkIfValidAndShowTick("tel");
                  }
                }}
                numberInputProps={{
                  maxLength: 14,
                }}
              />
              <span
                className={
                  isFocus
                    ? "PhoneInputInput__labelFloat"
                    : "PhoneInputInput__label"
                }
              >
                {phoneNumberInputLabel}*
              </span>
              {showMissingValueError && (
                <div className="SearchBy__missingValueErrorMobile">
                  {missingInfoError}
                </div>
              )}
            </div>
            <button
              type="button"
              className="CallMyDoctorButton"
              onClick={() => validateSearchObj(getSearchObjectForCallback())}
              data-analytics_available_call="0"
            >
              {searchLabel}
            </button>
            {props.children && props.children}
          </div>
          {showMissingValueError && (
            <div className="SearchBy__missingValueError">
              {missingInfoError}
            </div>
          )}
        </>
      )}
      {isClinic && (
        <React.Fragment>
          <div className="input__container">
            {/* RXPANEL-1501 */}
            <Input
              isValidTick={validTick.name}
              isError={errors.name}
              type="text"
              required={true}
              placeholder=" "
              label={clinicOrDoctorNameInputLabel + "*"}
              onChange={(e) => setName(e.target.value)}
              onFocus={() =>
                setValidTick((prevState) => ({
                  ...prevState,
                  name: false,
                }))
              }
              onBlur={() => checkIfValidAndShowTick("name")}
            />
            <Input
              isValidTick={validTick.city}
              isError={errors.city}
              type="text"
              placeholder=" "
              label={cityInputLabel + "*"}
              onChange={(e) => setCity(e.target.value)}
              onFocus={() =>
                setValidTick((prevState) => ({
                  ...prevState,
                  city: false,
                }))
              }
              onBlur={() => checkIfValidAndShowTick("city")}
            />
            <CallMyDoctorStateInput
              isSelect={isStateSelectInput}
              currentLanguage={currentLanguage}
              options={stateSelectOtions}
              isValidTick={validTick.state}
              isError={errors.state}
              label={
                isStateSelectInput
                  ? stateSelectOtions[0]
                  : stateInputLabel + "*"
              }
              country={state}
              labelClassName={`stateInputMobileMaxWidth ${
                isStateSelectInput ? "select" : ""
              }`}
              onChange={(e) => onChangeStateValue(e.target.value)}
              onFocus={() =>
                setValidTick((prevState) => ({
                  ...prevState,
                  state: false,
                }))
              }
              onBlur={() => checkIfValidAndShowTick("state")}
            />
          </div>
          {showMissingValueError && (
            <div className="SearchBy__missingValueError">
              {missingInfoError}
            </div>
          )}
          {showMissingValueError && (
            <div className="SearchBy__missingValueErrorMobile">
              {missingInfoError}
            </div>
          )}
          {props.children ? (
            <div className={"SearchBy__withChildren"}>
              <button
                type="button"
                className="SearchBy__clinicContainer__button"
                onClick={() => validateSearchObj(getSearchObjectForCallback())}
                data-analytics_available_call="0"
              >
                {searchLabel}
              </button>
              {props.children}
            </div>
          ) : (
            <button
              type="button"
              className="SearchBy__clinicContainer__button"
              onClick={() => validateSearchObj(getSearchObjectForCallback())}
              data-analytics_available_call="0"
            >
              {searchLabel}
            </button>
          )}
          <React.Fragment></React.Fragment>
        </React.Fragment>
      )}
    </div>
  );
};

export const SearchResult = (props) => {
  const doctorNotFoundTitle = useTranslate(
    "steps.advancedPrescription.doctor.doctorNotFoundBoxMessage"
  );
  const doctorNotFoundAdd = useTranslate(
    "steps.advancedPrescription.doctor.doctorNotFoundBoxMessageAdd"
  );
  const doctorNotFoundLink = useTranslate(
    "steps.advancedPrescription.doctor.doctorNotFoundBoxMessageLink"
  );
  const doctorNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.doctorName"
  );
  const clinicNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.clinicName"
  );
  const addressTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.address"
  );
  const phoneTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.phone"
  );
  const faxTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.fax"
  );
  const chooseTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.chooseButton"
  );

  const reduxDispatch = useDispatch();
  const handleModal = (show) => {
    reduxDispatch(modalsActions.setShowAddMyDoctorModal(show));
  };
  if (props?.data?.length > 0) {
    return (
      props && (
        <div className="SearchResult">
          <table>
            <thead>
              <tr>
                <th scope="col">{doctorNameTableLabel}</th>
                <th scope="col">{clinicNameTableLabel}</th>
                <th scope="col">{addressTableLabel}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {props.data.map((x, i) => {
                return (
                  <tr>
                    <td
                      key={x.name + "_" + i}
                      data-label={doctorNameTableLabel}
                    >
                      {x.name}
                    </td>
                    <td
                      key={x.clinic + "_" + i}
                      data-label={clinicNameTableLabel}
                    >
                      {x.clinic}
                    </td>
                    <td
                      key={x.address + "_" + i}
                      data-label={addressTableLabel}
                    >
                      {" "}
                      {HtmlParser(
                        x.address +
                          "<br>" +
                          phoneTableLabel +
                          ": " +
                          x.phone +
                          "<br>" +
                          faxTableLabel +
                          ": " +
                          x.fax
                      )}
                    </td>
                    <td key={i} data-label=" ">
                      <button
                        className="SearchResult__button"
                        onClick={() => props.handleSelect(x)}
                        data-analytics_available_call="0"
                      >
                        {chooseTableLabel}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )
    );
  } else {
    return (
      <div className="DoctorNotFound__box">
        <div className="DoctorNotFound__box__container">
          <div className="DoctorNotFound__box__container__img"></div>
          <div className="DoctorNotFound__box__container__plainText">
            {doctorNotFoundTitle}
          </div>
          <div className="DoctorNotFound__box__container__plainText">
            {doctorNotFoundAdd}
            <div
              onClick={() => handleModal(true)}
              className="DoctorNotFound__box__container__linkText"
            >
              {doctorNotFoundLink}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const SearchLoader = () => {
  return (
    <div className="SearchLoader__container">
      <BrandComponent
        componentName="Loader"
        parameter={{
          style: { width: 35, height: 35 },
          greyLoader: true,
        }}
      />
    </div>
  );
};

export const SelectConfirm = (props) => {
  const doctorNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.doctorName"
  );
  const clinicNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.clinicName"
  );
  const addressTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.address"
  );
  const phoneTableHeaderLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.phoneNumber"
  );
  const phoneTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.phone"
  );
  const faxTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.fax"
  );
  const changeTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.changeButton"
  );
  const wrongDoctorTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.wrongDoctor"
  );

  return (
    props && (
      <div className="SearchResult">
        <table>
          <thead>
            <tr>
              <th scope="col">{doctorNameTableLabel}</th>
              {props?.selected?.clinic && (
                <th scope="col">{clinicNameTableLabel}</th>
              )}
              {props?.selected?.address && (
                <th scope="col">{addressTableLabel}</th>
              )}
              {props?.selected?.phone &&
                !props?.selected?.address &&
                !props?.selected?.clinic && (
                  <th scope="col">{phoneTableHeaderLabel}</th>
                )}
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <td key={props?.selected?.name} data-label="Doctor name">
              {props.selected.name}
            </td>
            {props?.selected?.clinic && (
              <td key={props.selected.clinic} data-label="Clinic Name">
                {props.selected.clinic}
              </td>
            )}
            {props?.selected?.address && (
              <td key={props.selected.address} data-label="Address">
                {" "}
                {HtmlParser(
                  props.selected.address +
                    "<br>" +
                    phoneTableLabel +
                    ": " +
                    props.selected.phone +
                    "<br>" +
                    faxTableLabel +
                    ": " +
                    props.selected.fax
                )}
              </td>
            )}
            {props?.selected?.phone &&
              !props?.selected?.address &&
              !props?.selected?.clinic && (
                <td key={props.selected.phone} data-label="phoneNumber">
                  {" "}
                  {props.selected.phone}
                </td>
              )}
            <td data-label=" " className="mobileHide">
              <div
                role={"button"}
                className="SearchResult__button mobileHide"
                onClick={() => props.handleSelect(null)}
                data-analytics_available_call="0"
              >
                {changeTableLabel}
              </div>
            </td>
            <td data-label=" " className="desktopHide">
              <div
                className={
                  "SearchResult__changeButtonMobile" +
                  (props.classProps ? "__" + props.classProps : "")
                }
              >
                <a
                  className="SearchResult__changeButtonMobile__linkText"
                  onClick={() => props.handleSelect(null)}
                >
                  {props.wrongLabel ? props.wrongLabel : wrongDoctorTableLabel}
                </a>
              </div>
            </td>
          </tbody>
        </table>
      </div>
    )
  );
};

const CustomCheckbox = (props) => {
  const isChecked = props.value
    ? typeof props.value === "boolean"
      ? props.value
      : props.value.value
    : false;

  return (
    <div
      tabIndex={0}
      className={
        "CustomCheckbox__checkbox" +
        (isChecked ? " checked" : "") +
        (props.marginTop ? " marginTop" : "")
      }
      onClick={() => {
        if (typeof props.value === "boolean") {
          props.setter(!props.value);
        } else {
          props.setter({
            value: !props.value.value,
            ignoreSplitLogic: false,
          });
        }
      }}
    ></div>
  );
};

export function CallMyDoctorPrescriptionForm(parameter) {
  const PD = usePupillaryDistance({
    prescription: parameter.prescriptionObject,
  });
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const searchDoctors = useSelector(
    (state: any) => state.config?.prescriptionModule?.searchDoctors
  );
  const savePrescription = useSelector(
    (state: any) => state.config?.prescriptionModule?.saveExtendedPrescription
  );
  const previousPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const reduxDispatch = useDispatch();

  const [isResultLoading, setIsResultLoading] = useState(false);
  const callMyDoctorParams = useSelector(
    (state: any) => state.prescription.callMyDoctorParams
  );

  const [prescriptionObject, setPrescriptionObject] = useState(null);

  const [searchResult, setSearchResult] = useState(null);

  const [doctorSelected, setDoctorSelected] = useState(null);

  const didntFindLabel = useTranslate(
    "steps.advancedPrescription.doctor.addManuallyOption.didntFind"
  );
  const addManuallyLabel = useTranslate(
    "steps.advancedPrescription.doctor.addManuallyOption.addManually"
  );
  const changeMethodLabel = useTranslate(
    "steps.advancedPrescription.doctor.changeSubmissionMethod"
  );
  const confirmButtonLabel = useTranslate(
    "steps.advancedPrescription.doctor.confirmAndContinue"
  );

  useEffect(() => {
    return () => {
      reduxDispatch(prescriptionActions.setCallMyDoctorParams(null));
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const searchResultElement = document.querySelector(
        ".addManually__container"
      );
      if (!isResultLoading) {
        searchResultElement?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  }, [isResultLoading]);

  useEffect(() => {
    if (parameter.prescriptionObject) {
      setPrescriptionObject(parameter.prescriptionObject);
      setDoctorSelected({
        address: parameter.prescriptionObject.address,
        clinic: parameter.prescriptionObject.clinic,
        fax: parameter.prescriptionObject.fax,
        name: parameter.prescriptionObject.name,
        phone: parameter.prescriptionObject.phone,
      });
    }
  }, [parameter.prescriptionObject]);

  useEffect(() => {
    if (callMyDoctorParams) {
      setDoctorSelected(callMyDoctorParams);
    }
  }, [callMyDoctorParams]);

  if (doctorSelected) {
    parameter.changeTitleCallMyDoctor(true);
  }
  const getSimplePrescriptionValuesArray = (
    start: number,
    end: number,
    incrementValue: number,
    noValueSymbol?: string,
    toFixed?: number,
    values?: string[]
  ) => {
    let valuesArray = [];
    if (noValueSymbol) {
      valuesArray.push({ name: noValueSymbol, value: "", default: true });
    }

    if (values) {
      //it's the non-numeric baseVert and baseHor fields
      values.map((val) => {
        valuesArray.push({ name: val, value: val, default: false });
      });
    } else {
      for (let i = start; i <= end; i += incrementValue) {
        valuesArray.push({
          name: toFixed ? Number(i).toFixed(toFixed) : i,
          value: i,
          default: false,
        });
      }
    }

    return valuesArray;
  };
  const handleSearch = (obj) => {
    if (searchDoctors && typeof searchDoctors === "function") {
      setIsResultLoading(true);
      searchDoctors(obj)
        .then((res) => {
          if (res.doctors && res.doctors.length) {
            setSearchResult(res.doctors);
          } else {
            setSearchResult([]);
          }
          setIsResultLoading(false);
        })
        .catch((err) => {
          //TODO
          setIsResultLoading(false);
          setSearchResult([]);
        });
    }
  };
  useEffect(() => {
    if (searchResult?.length === 0) {
      try {
        //@ts-ignore
        window.tealium_data2track.push({
          id: "Error",
          Error_Source: "User",
          Error_Code: "RX Configurator: choose doctor",
          Error_Message: "We couldn't find your doctor",
        });
      } catch (error) {
        console.error(
          "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
        );
      }
      console.error("Error while saving prescription");
    }
  }, [searchResult]);
  const handleModal = (show) => {
    reduxDispatch(modalsActions.setShowAddMyDoctorModal(show));
  };
  const handleSelectDoctor = (selected) => {
    setDoctorSelected(selected);
    if (selected === null) {
      parameter.changeTitleCallMyDoctor(false);
    }
  };

  const handleSubmitPrescription = () => {
    if (!PD.state.checkPDSelected()) {
      return;
    }
    if (savePrescription && typeof savePrescription === "function") {
      let submitObj = {
        prescriptionFlow: "CMD",
        prescriptionId:
          previousPrescription && previousPrescription.prescriptionId
            ? previousPrescription.prescriptionId
            : null,
        name: doctorSelected.name,
        clinic: doctorSelected.clinic,
        address: doctorSelected.address,
        phone: doctorSelected.phone,
        fax: doctorSelected.fax,
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      savePrescription(submitObj)
        .then((res) => {
          parameter.onSuccessPrescription(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="CallMyDoctorPrescriptionForm">
      <Subtitle />
      {!doctorSelected && <SearchBy handleSearch={handleSearch} />}
      {!doctorSelected && !isResultLoading && searchResult && (
        <>
          {searchResult.length > 0 && (
            <div className="addManually__container">
              <div className="addManually__container__plainText">
                {didntFindLabel}
                <a
                  className="addManually__container__linkText"
                  onClick={() => handleModal(true)}
                >
                  {addManuallyLabel}
                </a>
              </div>
            </div>
          )}
          <SearchResult data={searchResult} handleSelect={handleSelectDoctor} />
        </>
      )}
      {isResultLoading && <SearchLoader />}
      {doctorSelected && (
        <SelectConfirm
          selected={doctorSelected}
          handleSelect={handleSelectDoctor}
        />
      )}
      {doctorSelected && (
        <div
          className="changeSubmissionMethod"
          style={{ fontSize: "14px" }}
          onClick={() => {
            parameter.closePrescriptionForm();
            setSearchResult(null);
            reduxDispatch(prescriptionActions.setCallMyDoctorParams(null));
          }}
        >
          {changeMethodLabel}
        </div>
      )}
      {doctorSelected && <div className="Separator"></div>}
      {doctorSelected && (
        <BrandComponent
          componentName="PupillaryDistance"
          parameter={{
            PD1: PD.state.PD1,
            PD2: PD.state.PD2,
            showBothPdOptions: PD.state.showBothPdOptions,
            showPDWarning: PD.state.showPDWarning,
            onPD1Change: PD.state.setPD1,
            onPD2Change: PD.state.setPD2,
            setShowBothPdOptions: PD.state.setShowBothPdOptions,
            pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
            CustomCheckbox: CustomCheckbox,
          }}
        />
      )}
      {doctorSelected && (
        <div className="CallMyDoctorPrescriptionForm__submitContainer">
          <button
            className="CallMyDoctorPrescriptionForm__submitButton"
            data-element-id="X_X_LensPanel_Type-ValuesApply"
            onClick={handleSubmitPrescription}
          >
            {confirmButtonLabel}
          </button>
        </div>
      )}
    </div>
  );
}
