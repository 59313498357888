import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "./components/common/BrandComponent";
import {
  loadBrandConfig,
  mapConfigStepToStep,
} from "./configurator/ConfigLoader";
import { useStepAnalytics } from "./configurator/hooks/useStepAnalytics";
import { WorkflowStep } from "./configurator/views/WorkflowStep";
import { configActions } from "./redux/slices/config";
import { modalsActions } from "./redux/slices/modals";
import "./index.scss";
import { loadTranslationConfig } from "./configurator/translations/TranslationHooks";
import { translationActions } from "./redux/slices/translation";
import { cloneDeep, merge } from "lodash";
import { prescriptionActions } from "./redux/slices/prescription";
import { workflow } from "./configurator/models/Workflow";
import { WarrantyOptionConfigModel } from "./components/WarrantyOptions/component";
import { insuranceActions } from "./redux/slices/insurance";
import { Prescription } from "./types";
import { useRIA } from "./configurator/modules/RIA/hooks/useRIA";
import { workflowActions } from "./redux/slices/workflow";
import { ErrorBoundary } from "./components/ErrorBoundary/component";
import { usePreCheck } from "./configurator/hooks";
import useDebuggerUtils from "./configurator/hooks/useDebuggerUtils";
import { useCurrentBrand, useCurrentType, useExternalListener } from "@hooks";

export const CURRENT_BRAND =
  process.env.CURRENT_BRAND === "default" ? null : process.env.CURRENT_BRAND;

interface AppProps {
  config: any;
}
/**
 * This is the app component.
 */

export function App({ config }: AppProps) {
  const [showInsurancePopup, setShowInsurancePopup] = useState(false);

  const showDigitalOptometryModal = useSelector(
    (state: any) => state.modals?.showDigitalOptometryModal
  );

  const exitModalState = useSelector(
    (state: any) => state.modals?.showExitModal
  );
  const showInsuranceErrorPopup = useSelector(
    (state: any) => state.modals?.showInsuranceErrorPopup
  );
  const prescriptionIncompatibleModalState = useSelector(
    (state: any) => state.modals?.showPrescriptionModal
  );
  const openPrescriptionInputModal = useSelector(
    (state: any) => state.modals?.openPrescriptionInputModal
  );
  const modalLearnMoreData = useSelector(
    (state: any) => state.modals?.learnMoreFocus
  );
  const addMyDoctorModalState = useSelector(
    (state: any) => state.modals?.showAddMyDoctorModal
  );
  const uploadFilePreviewModalState = useSelector(
    (state: any) => state.modals?.showPrescriptionUploadFilePreviewModal
  );
  const showSeeDetailsPrescriptionModal = useSelector(
    (state: any) => state.modals?.showPrescriptionReviewSeeDetails
  );
  const showPaymentInstallmentsModal = useSelector(
    (state: any) => state.modals?.showPaymentInstallmentsModal
  );
  const showConfiguratorError = useSelector(
    (state: any) => state.modals?.showConfigError
  );
  const configAction = useSelector((state: any) => state.config?.actionsModule);
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const warrantyOptions: WarrantyOptionConfigModel[] = useSelector(
    (state: any) => state.config?.lensesData?.warrantyOptions
  );
  const cartMode = useSelector((state: any) => state.config?.cartMode);
  const warrantyByCart = useSelector(
    (state: any) => state.config?.data?.warranty?.catEntryId
  );
  const advancedPresIsShow = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const flag = useRef(false);
  const reduxDispatch = useDispatch();

  useExternalListener();
  useStepAnalytics();
  useRIA();
  usePreCheck(config);
  useDebuggerUtils(config);

  const currentBrand = useCurrentBrand();
  const currentType = useCurrentType(config.data.frame.category);
  const [loaded, setLoaded] = useState(false);

  const toggleLockPageScroll = (lock) => {
    document.querySelectorAll("html,body").forEach((elem: HTMLElement) => {
      elem.style.overflow = lock ? "hidden" : "";
      if (lock) {
        elem.classList.add("RXC__is-locked");
      } else {
        elem.classList.remove("RXC__is-locked");
      }
    });
  };

  //RXPANEL-1452
  useEffect(() => {
    if (cartMode && !flag.current && warrantyOptions) {
      const currentWarranty = warrantyOptions.find(
        (warranty) => warranty.id === warrantyByCart
      );
      reduxDispatch(insuranceActions.setWarranty(currentWarranty));
      flag.current = true;
    }
  }, [cartMode]);

  useEffect(() => {
    if (
      workflow.currentStep?.key !== "AdvancedPrescription" &&
      advancedPresIsShow
    ) {
      reduxDispatch(workflowActions.setSelectedPrescriptionMode(false));
    }
  }, [workflow.currentStep]);
  useEffect(() => {
    let configo: any = {};

    if (config.layoutSettings?.showGrayoutTile) {
      let arrayWithGrayOutProperties: [string, string][] = Object.entries(
        config.layoutSettings.showGrayoutTile
      );
      arrayWithGrayOutProperties.forEach((el: any) => {
        config.lensesData.content[el[0]][el[1]].badge = "coming soon";
      });
    }

    if (currentType && !loaded) {
      let parentElement = document.querySelector(config.selector);
      if (parentElement) {
        parentElement.classList.remove("rxc-sunbox-wrapper");
        if (currentType === "SUNGLASSES") {
          parentElement.classList.add("rxc-sunbox-wrapper");
        }
      }

      console.log("lc received config:", config);
      config.lensesData.packages.forEach((pkg) => {
        if (!pkg.lensPackage.insurable) {
          pkg.frame.insPrice = pkg.frame.offerPrice;
          pkg.lensPackage.insPrice = pkg.lensPackage.offerPrice;
        }
      });

      loadBrandConfig(
        config.brand || config.data.brand || currentBrand,
        currentType
      ).then((x) => {
        let constClone = cloneDeep(config);
        let configuratorConfig = cloneDeep(x.default);
        constClone.brand =
          config.brand ||
          constClone.data.brand ||
          currentBrand ||
          x.default.brand;

        let mergedConfigs = merge(configuratorConfig, constClone);
        //we don't want the array of prescriptionFlows to be merged, we want it replaced
        mergedConfigs.prescripitonModule = constClone.prescriptionModule;

        configo = {
          ...mergedConfigs,
          steps: mapConfigStepToStep(mergedConfigs.steps),
        };

        reduxDispatch(configActions.changeOptions(configo));

        if (configo.dynamicPromoModule?.getDiscounts) {
          reduxDispatch(workflowActions.setLoadingDynamicDiscounts(true));

          configo.dynamicPromoModule
            .getDiscounts(configo.data.frame, configo.lensesData)
            .then((res) => {
              let newData = { ...configo.data, frame: res.frame };
              let newLensesData = { ...res.lensesData };
              let newConfigo = {
                ...configo,
                data: newData,
                lensesData: newLensesData,
              };
              reduxDispatch(configActions.changeOptions(newConfigo));
              reduxDispatch(workflowActions.setLoadingDynamicDiscounts(false));
            });
        } else {
          reduxDispatch(workflowActions.setLoadingDynamicDiscounts(false));
        }

        loadTranslationConfig(constClone.brand, currentType).then(
          (translationFallBack) => {
            reduxDispatch(
              translationActions.setTranslations(translationFallBack)
            );
          }
        );
        setLoaded(true);
        toggleLockPageScroll(true);
      });
    }
  }, [currentType]);

  const handleExit = () => {
    toggleLockPageScroll(false);

    window.dispatchEvent(new CustomEvent("BackToPdp"));
  };

  const handleSave = () => {
    window.dispatchEvent(
      new CustomEvent("SaveAndExit", { detail: sessionWarranty })
    );
    handleExit();
  };

  useEffect(() => {
    try {
      (window as any).utag_data.Page_Platform = "RX";
      (window as any).utag_data.Page_DeviceType = "X";
    } catch (error) {
      console.error("utag_data is not defined");
    }
  }, []);

  return !loaded ? (
    <p>loading...</p>
  ) : (
    <BrandComponent componentName="FullPage">
      <ErrorBoundary dispatch={reduxDispatch} modalsActions={modalsActions}>
        <BrandComponent componentName="YourSelection" />
        <BrandComponent componentName="MainHeader" />
        <BrandComponent componentName="MainContent">
          <BrandComponent componentName="ProductContainer" />
          <BrandComponent componentName="ScrollView">
            <WorkflowStep />
          </BrandComponent>
        </BrandComponent>
        {showInsurancePopup && (
          <BrandComponent
            componentName="InsurancePopup"
            parameter={{
              onModalClose: () => setShowInsurancePopup(false),
            }}
          />
        )}
        {showInsuranceErrorPopup && (
          <BrandComponent
            componentName="InsuranceErrorPopup"
            parameter={{
              onModalClose: () =>
                reduxDispatch(modalsActions.setShowInsuranceErrorPopup(false)),
            }}
          />
        )}
        {exitModalState && (
          <BrandComponent
            componentName="ExitModal"
            parameter={{
              onContinueEditing: () => {
                reduxDispatch(modalsActions.setShowExitModal(false));
              },
              onExit: handleExit,
              onSave: configAction.genericSaveLensSelection ? handleSave : null,
            }}
          />
        )}
        {prescriptionIncompatibleModalState && (
          <BrandComponent componentName="IncompatiblePrescriptionModal" />
        )}
        {modalLearnMoreData && (
          <BrandComponent componentName="LearnMoreModal" />
        )}
        {addMyDoctorModalState && (
          <BrandComponent componentName="AddMyDoctorModal" />
        )}
        {uploadFilePreviewModalState && (
          <BrandComponent componentName="PrescriptionUploadFilePreviewModal" />
        )}
        {showDigitalOptometryModal && (
          <BrandComponent componentName="DigitalOptometryModal" />
        )}
        {showSeeDetailsPrescriptionModal && (
          <BrandComponent componentName="PrescriptionReviewSeeDetails" />
        )}
        {showPaymentInstallmentsModal && (
          <BrandComponent componentName="PaymentInstallmentsModal" />
        )}
        {/* {showMissingReadingAddValueModal && (
          <BrandComponent componentName="PrescriptionModalNoAdd" />
        )} */}
        <BrandComponent componentName="PrescriptionModal" />
        <BrandComponent componentName="PrescriptionModalContinue" />
        <BrandComponent componentName="BottomBar" />
        <BrandComponent componentName="CallMyDoctorModal" />
        <BrandComponent componentName="MissingPrescriptionValueModal" />
      </ErrorBoundary>
      {showConfiguratorError && <BrandComponent componentName="ErrorModal" />}
      <div id="RxcModals" />
      {/* <BrandComponent
        componentName="NoPrescriptionFromMyAccountModal"
        parameter={{}}
      /> */}
    </BrandComponent>
  );
}
