import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import { modalsActions } from "../../redux/slices/modals";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import {
  getCompatibleLensesType,
  hasValidPrescription,
  isFramePrescriptionCompatible,
} from "../../configurator/ConfigLoader";
import { advancedManualPrescriptionAdapter } from "../../configurator/adapters/AdvancedManualPrescriptionAdapter";
import { AdvancedManualPrescription } from "../../types";
import { createPortal } from "react-dom";
import {
  usePupillaryDistance,
  useEnablePrismComment,
  usePrescriptionIssueDate,
  useEnablePrism,
  useMandatoryManualInput,
  useCurrentBrand,
} from "@hooks";
import ReferencedSection from "../common/ReferencedSection";
import { usePrescriptionsFromMyAccount } from "../../configurator/hooks/usePrescriptionsFromMyAccount";
import { FormTable } from "./components/FormTable";
import { CustomCheckbox } from "./components/CustomCheckbox";
import { MoreOptions } from "./components/MoreOptions";
import { formTableColumnsHOC } from "./components/FormTable";
import utils from "./utils";
import { Subtitle } from "./components/Subtitle";
import { PrescriptionModeEnum } from "../../configurator/steps/AdvancedPrescription/component";
import { convertISODate } from "../../utils/date";
import { SimpleTextError } from "./components/SimpleTextError";
import { Separator } from "./components/Separator";
import { IssueDateInput } from "./components/IssueDateInput";
import { AgreementCheckbox } from "./components/AgreementCheckbox";
import "./default.module.scss";
import { PrescriptionModalNoAdd } from "../PrescriptionModalNoAdd/component";

export interface FullPrescriptionFormProps {
  hideMoreOptions: boolean;
  currentPackages: any[];
  onSuccessPrescription?: (
    prescriptionObject: AdvancedManualPrescription,
    type: string
  ) => void;
  prescriptionObject: any | null;
  brand?: string;
  userIsLoggedIn?: boolean;
  withIssueDate?: boolean;
  rxType: string;
}

export function FullPrescriptionForm({
  hideMoreOptions,
  currentPackages,
  onSuccessPrescription,
  prescriptionObject,
  brand,
  userIsLoggedIn,
  withIssueDate = true,
  rxType,
}: FullPrescriptionFormProps) {
  const prescriptionExternalFunctions = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const enableMandatory = useMandatoryManualInput();
  const nestedFlow = prescriptionExternalFunctions?.nestedFlows;

  const translation = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const frame = useSelector((state: any) => state.config?.data?.frame);

  //Modals
  const sendWithoutPrescriptionValues = useSelector(
    (state: any) => state.modals?.missingPrescriptionsValueResult
  );
  const showMissingReadingAddValueModal = useSelector(
    (state: any) => state.modals?.showMissingReadingAddValueModal
  );

  //State
  const [showMissingAxisError, setShowMissingAxisError] = useState(false);
  const [visibilityButtonUploaded, setVisibilityButtonUploaded] =
    useState(true);
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);
  const [file, setFile] = useState(null);
  const [prescriptionIdForCalifornia, setPrescriptionIdForCalifornia] =
    useState(null);
  const [alreadyDone, setAlreadyDone] = useState(false);
  const [newPrescriptionName, setNewPrescriptionName] = useState("");
  const [isCalifornian, setIsCalifornian] = useState(false);
  const doctorSelected = useSelector(
    (state: any) => state?.modals?.doctorSelected
  );
  const [selectedNested, setSelectedNested] = useState(
    prescriptionExternalFunctions?.nestedFlows
      ? prescriptionExternalFunctions?.nestedFlows[0]
      : null
  );
  const [isMoreOptionsFormOpen, setIsMoreOptionsFormOpen] = useState(false);
  const [showPrismValuesForm, setShowPrismValuesForm] = useState(false);
  const [showSPHCYLWarning, setShowSPHCYLWarning] = useState(false);
  const [showAxisError, setShowAxisError] = useState(false);
  const [showAxisRangeError, setShowAxisRangeError] = useState(false);
  const [showNewPrescriptionNameError, setShowNewPrescriptionNameError] =
    useState(false);
  const [
    showPrescriptionMissingValuesError,
    setShowPrescriptionMissingValuesError,
  ] = useState(false);
  const [showPrismMissingValuesError, setShowPrismMissingValuesError] =
    useState(false);
  const [sphOD, setSphOD] = useState(0);
  const [sphOS, setSphOS] = useState(0);
  const [cylOD, setCylOD] = useState(0);
  const [cylOS, setCylOS] = useState(0);
  const [axisOD, setAxisOD] = useState(null);
  const [axisOS, setAxisOS] = useState(null);
  const [addOD, setAddOD] = useState(null);
  const [addOS, setAddOS] = useState(null);
  const [vertOD, setVertOD] = useState(null);
  const [vertOS, setVertOS] = useState(null);
  const [baseVertOD, setBaseVertOD] = useState(null);
  const [baseVertOS, setBaseVertOS] = useState(null);
  const [horOD, setHorOD] = useState(null);
  const [horOS, setHorOS] = useState(null);
  const [baseHorOD, setBaseHorOD] = useState(null);
  const [baseHorOS, setBaseHorOS] = useState(null);
  const [comment, setComment] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [saveAccountPrescription, setSaveAccountPrescription] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [prismErrorMessage, setPrismErrorMessage] = useState("");
  const [showIncompatibleError, setShowIncompatibleError] = useState({
    show: false,
    isClear: false,
    link: null,
    noCompatiblePackage: false,
  });
  const [loadedExternally, setLoadedExternally] = useState(prescriptionObject);
  const [isPrescriptionModified, setIsPrescriptionModified] = useState(false);
  const [issueDate, setIssueDate] = useState("");
  const isComponentInitialised = useRef(false);

  const prescriptionName = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionName"
  );
  const lastModified = useTranslate(
    "steps.advancedPrescription.fromMyAccount.lastModified"
  );
  const prescriptionNameError = useTranslate(
    "steps.advancedPrescription.fromMyAccount.newPrescriptionNameError"
  );

  const selectPlaceholder = useTranslate(
    "steps.advancedPrescription.manual.addPlaceholder"
  );

  const tooltipText = useTranslate(
    "steps.advancedPrescription.manual.issueDate.tooltipText"
  );
  const axisRangeError = useTranslate(
    "steps.advancedPrescription.manual.alerts.axisRangeError"
  );
  const axisZeroError = useTranslate(
    "steps.advancedPrescription.manual.alerts.axisZeroError"
  );
  const sphPositiveNegativeWarning = useTranslate(
    "steps.advancedPrescription.manual.alerts.sphPositiveNegativeWarning"
  );
  const missingValues = useTranslate(
    "steps.advancedPrescription.manual.alerts.missingValues"
  );

  const applyButton = useTranslate(
    "steps.advancedPrescription.manual.applyButton"
  );

  const sphereName = useTranslate("steps.advancedPrescription.manual.sphere");
  const cylinderName = useTranslate(
    "steps.advancedPrescription.manual.cylinder"
  );
  const axisName = useTranslate("steps.advancedPrescription.manual.axis");
  const addName = useTranslate("steps.advancedPrescription.manual.add");
  const savePrescriptionInAccount = useTranslate(
    "steps.advancedPrescription.fromMyAccount.savePrescriptionInAccount"
  );
  const missingBaseDir = useTranslate(
    "steps.advancedPrescription.manual.alerts.missingBaseDir"
  );
  const missingPrismValues = useTranslate(
    "steps.advancedPrescription.manual.alerts.missingPrismValues"
  );

  const reduxDispatch = useDispatch();
  const PD = usePupillaryDistance({ prescription: prescriptionObject });
  const enablePrismComment = useEnablePrismComment();
  const enablePrism = useEnablePrism();
  const { saveToMyAccount } = usePrescriptionsFromMyAccount();
  const { isValidIssueDate, convertToISO, isExpiredPrescription } =
    usePrescriptionIssueDate();

  const isReading = rxType === "READING";

  const getFileTypeFromFullName = (fileName: string) => {
    let split = fileName.split(".");
    let lastElem = split[split.length - 1];
    return "." + lastElem;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setVisibilityButtonUploaded(false);
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
        const fileReader = new window.FileReader();
        fileReader.onload = (fileLoad) => {
          const { result } = fileLoad.target;
          let requestObj = {
            fileName: file.name,
            fileData: result,
          };
          prescriptionModule
            .uploadExtendedPrescription(requestObj)
            .then((res) => {
              setIsFileTooBigError(false);
              setIsSuccessful(true);

              setPreloadedFileInfo({
                name: file.name,
                size: file.size,
                type: getFileTypeFromFullName(file.name),
                savedFileName: res.savedFileName,
              });
              setPrescriptionIdForCalifornia(res.prescriptionId);
              setIsUploading(false);

              if (prescriptionModule.downloadExtendedPrescription) {
                prescriptionModule
                  .downloadExtendedPrescription({
                    savedFileName: res.savedFileName,
                  })
                  .then((res) => {
                    setFilePath(res.fileData);
                  })
                  .catch((err) => {
                    setFilePath("");
                  });
              } else {
                console.error("Missing downloadExtendedPrescriptionFunction");
                setFilePath("");
              }
            })
            .catch((err) => {
              setIsSuccessful(false);
              setIsUploading(false);
              setIsFileTooBigError(false);
            });
        };
        fileReader.readAsDataURL(file);
      }
    }
  };
  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      }
      return true;
    }
    return false;
  };
  const handleSwitch = () => {
    setIsCalifornian(!isCalifornian);
  };

  const formValues = {
    prescription: {
      right_eye_sph: {
        value: sphOD,
        setter: setSphOD,
        hasError: formErrors["right_eye_sph"],
      },
      left_eye_sph: {
        value: sphOS,
        setter: setSphOS,
        hasError: formErrors["left_eye_sph"],
      },
      right_eye_cyl: {
        value: cylOD,
        setter: setCylOD,
        hasError: formErrors["right_eye_cyl"],
      },
      left_eye_cyl: {
        value: cylOS,
        setter: setCylOS,
        hasError: formErrors["left_eye_cyl"],
      },
      right_eye_axis: {
        value: axisOD,
        setter: setAxisOD,
        hasError: formErrors["right_eye_axis"],
      },
      left_eye_axis: {
        value: axisOS,
        setter: setAxisOS,
        hasError: formErrors["left_eye_axis"],
      },
      right_eye_add: {
        value: addOD,
        setter: setAddOD,
        hasError: formErrors["right_eye_add"],
      },
      left_eye_add: {
        value: addOS,
        setter: setAddOS,
        hasError: formErrors["left_eye_add"],
      },
    },
    prism: {
      right_eye_vertical: {
        value: vertOD,
        setter: setVertOD,
        hasError: formErrors["right_eye_vertical"],
      },
      left_eye_vertical: {
        value: vertOS,
        setter: setVertOS,
        hasError: formErrors["left_eye_vertical"],
      },
      right_eye_baseDirVert: {
        value: baseVertOD,
        setter: setBaseVertOD,
        hasError: formErrors["right_eye_baseDirVert"],
      },
      left_eye_baseDirVert: {
        value: baseVertOS,
        setter: setBaseVertOS,
        hasError: formErrors["left_eye_baseDirVert"],
      },
      right_eye_horizontal: {
        value: horOD,
        setter: setHorOD,
        hasError: formErrors["right_eye_horizontal"],
      },
      left_eye_horizontal: {
        value: horOS,
        setter: setHorOS,
        hasError: formErrors["left_eye_horizontal"],
      },
      right_eye_baseDirHor: {
        value: baseHorOD,
        setter: setBaseHorOD,
        hasError: formErrors["right_eye_baseDirHor"],
      },
      left_eye_baseDirHor: {
        value: baseHorOS,
        setter: setBaseHorOS,
        hasError: formErrors["left_eye_baseDirHor"],
      },
    },
  };

  const isAxisODZeroErrorCondition = () => {
    return axisOD !== null && cylOD !== 0 && axisOD === 0;
  };

  const isAxisODNullErrorCondition = () => {
    return (axisOD === null || Number.isNaN(axisOD)) && cylOD !== 0;
  };

  const isAddODNullErrorCondition = () => {
    return (
      !isAddDisabled() &&
      (addOD === null || isNaN(addOD)) &&
      (sphOD || cylOD || axisOD)
    );
  };

  const isAxisODRangeErrorCondition = () => {
    return axisOD !== null && cylOD !== 0 && (axisOD < 0 || axisOD > 180);
  };

  const isAxisOSZeroErrorCondition = () => {
    return axisOS !== null && cylOS !== 0 && axisOS === 0;
  };

  const isAxisOSNullErrorCondition = () => {
    return (axisOS === null || Number.isNaN(axisOS)) && cylOS !== 0;
  };

  const isAddOSNullErrorCondition = () => {
    return (
      !isAddDisabled() &&
      (addOS === null || isNaN(addOS)) &&
      (sphOS || cylOS || axisOS)
    );
  };

  const isAxisOSRangeErrorCondition = () => {
    return axisOS !== null && cylOS !== 0 && (axisOS < 0 || axisOS > 180);
  };

  const isNewPrescriptionNameErrorCondition = () => {
    return !newPrescriptionName && saveAccountPrescription ? true : false;
  };

  const isSavePrescriptionCheckboxEnabled = useMemo(() => {
    return userIsLoggedIn && !isPrescriptionModified && prescriptionObject
      ? false
      : true;
  }, [userIsLoggedIn, isPrescriptionModified]);

  const isAgreementCheckboxEnabled = useMemo(
    () =>
      userIsLoggedIn && !isPrescriptionModified && prescriptionObject
        ? false
        : true,
    [userIsLoggedIn, isPrescriptionModified]
  );
  const prescriptionTableColumns = [
    {
      id: "sph",
      type: "sph",
      placeholder: "Sphere SPH",
      required: true,
      name: sphereName,
      min: prescriptionDefaults.sphMin,
      max: prescriptionDefaults.sphMax,
      res: prescriptionDefaults.sphRes,
      rows: [
        {
          id: "right_eye_sph",
        },
        {
          id: "left_eye_sph",
        },
      ],
    },
    {
      id: "cyl",
      type: "cyl",
      placeholder: "Cylinder CYL",
      required: true,
      name: cylinderName,
      min: prescriptionDefaults.cylMin,
      max: prescriptionDefaults.cylMax,
      res: prescriptionDefaults.cylRes,
      rows: [
        {
          id: "right_eye_cyl",
        },
        {
          id: "left_eye_cyl",
        },
      ],
    },
    {
      id: "axis",
      type: "axis",
      placeholder: "",
      required: true,
      name: axisName,
      rows: [
        {
          id: "right_eye_axis",
        },
        {
          id: "left_eye_axis",
        },
      ],
    },
    {
      id: "add",
      type: "add",
      placeholder: selectPlaceholder,
      required: true,
      name: addName,
      min: prescriptionDefaults.addMin,
      max: prescriptionDefaults.addMax,
      res: prescriptionDefaults.addRes,
      rows: [
        {
          id: "right_eye_add",
        },
        {
          id: "left_eye_add",
        },
      ],
    },
  ];

  const getAxisError = () => {
    try {
      //@ts-ignore
      window.tealium_data2track.push({
        id: "Error",
        Error_Source: "User",
        Error_Code: "RX Configurator: prescription values",
        Error_Message: "Missing axes values",
      });
    } catch (error) {
      console.error(
        "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
      );
    }
    console.error("Error while saving prescription");
  };

  const resetAxisErrors = () => {
    let errorsToSet = {};

    if (formErrors["right_eye_axis"] && cylOD === 0) {
      errorsToSet["right_eye_axis"] = false;
    }
    if (formErrors["left_eye_axis"] && cylOS === 0) {
      errorsToSet["left_eye_axis"] = false;
    }

    if (!isAxisODRangeErrorCondition() && !isAxisOSRangeErrorCondition()) {
      setShowAxisRangeError(false);
      errorsToSet["right_eye_axis"] = false;
    }
    if (!isAxisODZeroErrorCondition() && !isAxisOSZeroErrorCondition()) {
      setShowAxisError(false);
      errorsToSet["left_eye_axis"] = false;
    }

    setFormErrors({
      ...formErrors,
      ...errorsToSet,
    });
  };

  const isAddDisabled = () => {
    if (isReading) return false;
    if (workflow.currentStep.params) {
      const paramType = workflow.currentStep.params.find(
        (p) => p.key.toLowerCase() === "type"
      );
      if (paramType?.value) {
        return paramType.value.toLowerCase() !== "progressive";
      }
      if (workflow.currentStep.params?.[0].key === "advancedPrescription") {
        return true;
      }
    }

    return false;
  };

  const toggleMoreOptionsOpen = () =>
    setIsMoreOptionsFormOpen(!isMoreOptionsFormOpen);

  const resetForm = () => {
    setSphOD(0);
    setSphOS(0);
    setCylOD(0);
    setCylOS(0);
    setAxisOD(null);
    setAxisOS(null);
    setAddOD(null);
    setAddOS(null);
    setVertOD(null);
    setVertOS(null);
    setBaseVertOD(null);
    setBaseVertOS(null);
    setHorOD(null);
    setHorOS(null);
    setBaseHorOD(null);
    setBaseHorOS(null);
    setComment(null);
    setAgreement(false);

    PD.state.resetPDState();

    setShowIncompatibleError({
      show: false,
      isClear: false,
      link: null,
      noCompatiblePackage: false,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // if (PD.state.checkPDSelected()) {
    //   setFormSubmitted(true);
    // } else {
    //   setFormSubmitted(false);
    // }
    setFormSubmitted(true);
  };

  const validateSubmittedData = () => {
    let errorsToSet: { [key: string]: boolean } = {};
    if (
      sphOD === 0 &&
      sphOS === 0 &&
      cylOD === 0 &&
      cylOS === 0 &&
      (!isMoreOptionsFormOpen ||
        (isMoreOptionsFormOpen && !showPrismValuesForm))
    ) {
      if (!enableMandatory) {
        reduxDispatch(modalsActions.setShowPrescriptionModalContinue(true));
        return;
      }
      if (isCalifornian) {
        reduxDispatch(modalsActions.setShowMissingPrescriptionsValue(true));
        return;
      }
      errorsToSet["left_eye_sph"] = true;
      errorsToSet["right_eye_sph"] = true;
      // errorsToSet["left_eye_cyl"] = true;
      // errorsToSet["right_eye_cyl"] = true;
      if (
        !isAddDisabled() &&
        (addOD === null ||
          addOD === undefined ||
          addOS === null ||
          addOS === undefined ||
          isNaN(addOD) ||
          isNaN(addOS))
      ) {
        errorsToSet["right_eye_add"] = true;
        errorsToSet["left_eye_add"] = true;
      }
      setShowPrescriptionMissingValuesError(true);
    }
    if (sphOD !== 0 || sphOS !== 0 || cylOD !== 0 || cylOS !== 0) {
      if (!isAddDisabled() && (!addOD || isNaN(addOD)) && !isReading) {
        errorsToSet["right_eye_add"] = true;
      }
      if (!isAddDisabled() && (!addOS || isNaN(addOS)) && !isReading) {
        errorsToSet["left_eye_add"] = true;
      }
    }

    Object.keys(formValues.prescription).map((field) => {
      if (field.indexOf("add") < 0) {
        if (
          formValues.prescription[field]["value"] === null ||
          Number.isNaN(formValues.prescription[field]["value"])
        ) {
          let skip =
            (field === "left_eye_axis" && cylOS === 0) ||
            (field === "right_eye_axis" && cylOD === 0) ||
            (field === "right_eye_add" && isAddDisabled()) ||
            (field === "left_eye_add" && isAddDisabled());

          if (!skip) {
            errorsToSet[field] = true;
            setShowMissingAxisError(true);
          }
        }
      }
    });

    //Axis range
    if (isAxisODRangeErrorCondition()) {
      errorsToSet["right_eye_axis"] = true;
      setShowAxisRangeError(true);
      setShowMissingAxisError(false);
    }
    if (isAxisOSRangeErrorCondition()) {
      errorsToSet["left_eye_axis"] = true;
      setShowAxisRangeError(true);
      setShowMissingAxisError(false);
    }
    //Add
    if (
      !isAddDisabled() &&
      (addOD === null ||
        addOD === undefined ||
        addOS === null ||
        addOS === undefined ||
        isNaN(addOD) ||
        isNaN(addOS))
    ) {
      if (
        (addOD === null || addOD === undefined || isNaN(addOD)) &&
        (sphOD || cylOD || axisOD) &&
        !isReading
      ) {
        errorsToSet["right_eye_add"] = true;
        setShowPrescriptionMissingValuesError(true);
      }
      if (
        (addOS === null || addOS === undefined || isNaN(addOS)) &&
        (sphOS || cylOS || axisOS) &&
        !isReading
      ) {
        errorsToSet["left_eye_add"] = true;
        setShowPrescriptionMissingValuesError(true);
      }
    }

    //Prism
    if (showPrismValuesForm && (isMoreOptionsFormOpen || !enablePrismComment)) {
      errorsToSet["right_eye_vertical"] = !vertOD && !!baseVertOD;
      errorsToSet["left_eye_vertical"] = !vertOS && !!baseVertOS;
      errorsToSet["right_eye_horizontal"] = !horOD && !!baseHorOD;
      errorsToSet["left_eye_horizontal"] = !horOS && !!baseHorOS;

      errorsToSet["right_eye_baseDirVert"] = !!vertOD && !baseVertOD;
      errorsToSet["left_eye_baseDirVert"] = !!vertOS && !baseVertOS;
      errorsToSet["right_eye_baseDirHor"] = !!horOD && !baseHorOD;
      errorsToSet["left_eye_baseDirHor"] = !!horOS && !baseHorOS;

      const isAllValuesError = Object.values(formValues.prism).every(
        (field) => !field.value
      );
      const isSomeValuesError = Object.values(formValues.prism).some(
        (field) => !field.value
      );
      isAllValuesError &&
        Object.keys(formValues.prism).map((field) => {
          return (errorsToSet[field] = isAllValuesError);
        });
      const isError = isSomeValuesError || isAllValuesError;
      setShowPrismMissingValuesError(isError);
    }

    //New name prescription validation
    if (isNewPrescriptionNameErrorCondition() && formSubmitted) {
      errorsToSet["new_prescription_name"] = true;
      setShowNewPrescriptionNameError(true);
    }

    //Agreement validation
    if (!isCalifornian && !agreement && isAgreementCheckboxEnabled) {
      errorsToSet["agreement"] = true;
    }

    //Issue Date
    if (
      withIssueDate &&
      (!isValidIssueDate(issueDate) || isExpiredPrescription(issueDate))
    ) {
      errorsToSet["issue_date"] = true;
    }

    if (Object.values(errorsToSet).some((error) => error)) {
      setFormErrors({
        ...formErrors,
        ...errorsToSet,
      });
    } else {
      if (
        !(showAxisRangeError || showAxisError || !PD.state.checkPDSelected())
      ) {
        if (isReading && ((sphOD && !addOD) || (sphOS && !addOS))) {
          reduxDispatch(modalsActions.setShowMissingReadingAddValueModal(true));
        } else sendPrescriptionData();
      }
    }
    //TODO verificare con Luca
    const isAllValuesError = Object.values(formValues.prism).every(
      (field) => !field.value
    );
    const checkMultipleErrors = () => {
      let count = 0;
      Object.values(errorsToSet).map((error: any) => {
        error === true && count++;
      });
      return count;
    };

    const errorCount = checkMultipleErrors();

    const checkEqualPrismErrors = () => {
      if (errorCount >= 2) {
        if (
          errorsToSet.right_eye_vertical === true &&
          errorsToSet.left_eye_vertical === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_baseDirVert === true &&
          errorsToSet.left_eye_baseDirVert === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_horizontal === true &&
          errorsToSet.left_eye_horizontal === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_baseDirHor === true &&
          errorsToSet.left_eye_baseDirHor === true
        ) {
          return true;
        } else if (
          errorsToSet.left_eye_baseDirVert === true &&
          errorsToSet.left_eye_baseDirHor === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_baseDirVert === true &&
          errorsToSet.right_eye_baseDirHor === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_vertical === true &&
          errorsToSet.right_eye_horizontal === true
        ) {
          return true;
        } else if (
          errorsToSet.left_eye_vertical === true &&
          errorsToSet.left_eye_horizontal === true
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        true;
      }
    };
    const equalPrismErrors = checkEqualPrismErrors();
    if (isAllValuesError) {
      setPrismErrorMessage(missingValues);
    } else if (equalPrismErrors === false) {
      setPrismErrorMessage(missingValues);
    } else if (
      errorsToSet["right_eye_baseDirVert"] ||
      errorsToSet["left_eye_baseDirVert"] ||
      errorsToSet["left_eye_baseDirHor"] ||
      errorsToSet["right_eye_baseDirHor"]
    ) {
      setPrismErrorMessage(missingBaseDir);
    } else if (
      errorsToSet["right_eye_vertical"] ||
      errorsToSet["left_eye_vertical"] ||
      errorsToSet["right_eye_horizontal"] ||
      errorsToSet["left_eye_horizontal"]
    ) {
      setPrismErrorMessage(missingPrismValues);
    }
  };

  const sendPrescriptionData = () => {
    if (prescriptionExternalFunctions) {
      const prescObj = advancedManualPrescriptionAdapter.adapt({
        ...prescriptionObject,
        prescriptionFlow: prescriptionObject?.myAccount
          ? PrescriptionModeEnum.ACCOUNT
          : PrescriptionModeEnum.MANUAL,
        prescriptionId: 12345,
        SPH_OD: sphOD,
        SPH_OS: sphOS,
        CYL_OD: cylOD,
        CYL_OS: cylOS,
        AX_OD: axisOD,
        AX_OS: axisOS,
        ADD_OD: addOD,
        ADD_OS: addOS,
        PD_OD: PD.state.PD1,
        PD_OS: PD.state.showBothPdOptions.value ? PD.state.PD2 : null,
        PRISM_ENABLED: showPrismValuesForm,
        VPRISM_OD: showPrismValuesForm ? vertOD : null,
        VPRISM_OS: showPrismValuesForm ? vertOS : null,
        VBASEDIR_OD: showPrismValuesForm ? baseVertOD : null,
        VBASEDIR_OS: showPrismValuesForm ? baseVertOS : null,
        HPRISM_OD: showPrismValuesForm ? horOD : null,
        HPRISM_OS: showPrismValuesForm ? horOS : null,
        HBASEDIR_OD: showPrismValuesForm ? baseHorOD : null,
        HBASEDIR_OS: showPrismValuesForm ? baseHorOS : null,
        COMMENT: comment,
        name: newPrescriptionName || prescriptionObject?.name,
        lastUpdate: prescriptionObject?.lastUpdate || new Date().toISOString(), //TOD
        issueDate: convertToISO(issueDate),
        rxType,
      });
      //case californianManualInput
      let newPres: any = { ...prescObj };
      if (isCalifornian) {
        if (selectedNested == "UPLOAD") {
          if (isSuccessful) {
            let fileSize = file?.size
              ? (parseInt(file.size) / 1024 / 1024).toFixed(2)
              : 0;
            newPres = {
              ...newPres,
              prescriptionId: prescriptionIdForCalifornia,
              prescriptionFlow: "NESTED_CA",
              fileName: file?.name,
              savedFileName: preloadedFileInfo?.savedFileName,
              fileSize: fileSize,
            };
          }
        } else {
          if (doctorSelected) {
            newPres = {
              ...newPres,
              prescriptionFlow: "NESTED_CA",
              prescriptionId: prescriptionObject.prescriptionId
                ? prescriptionObject.prescriptionId
                : null,
              name: doctorSelected.name,
              clinic: doctorSelected.clinic,
              address: doctorSelected.address,
              phone: doctorSelected.phone,
              fax: doctorSelected.fax,
            };
          }
        }
      }

      if (isFramePrescriptionCompatible(frame.rxValues, prescObj)) {
        const compatiblePackages = currentPackages.filter((f) =>
          hasValidPrescription(f, prescObj)
        );

        if (!compatiblePackages.length) {
          setShowIncompatibleError({
            show: true,
            isClear: true,
            link: null,
            noCompatiblePackage: true,
          });
        } else {
          if (
            saveAccountPrescription &&
            !isNewPrescriptionNameErrorCondition()
          ) {
            saveToMyAccount(prescriptionObject);
          }
          if (
            isFunction(prescriptionExternalFunctions.saveExtendedPrescription)
          ) {
            prescriptionExternalFunctions
              .saveExtendedPrescription(isCalifornian ? newPres : prescObj)
              .then((res) => {
                onSuccessPrescription(
                  res,
                  getCompatibleLensesType(currentPackages, prescObj)
                );
              })
              .catch((err) => {
                console.log(
                  "Error while calling saveExtendedPrescription: " + err
                );
              });
          } else {
            try {
              //@ts-ignore
              window.tealium_data2track.push({
                id: "Error",
                Error_Source: "User",
                Error_Code: "RX Configurator: prescription values",
                Error_Details:
                  "ADD:" +
                  prescObj.ADD +
                  ",SPHOD:" +
                  prescObj.SPH.OD +
                  ",SPHOS:" +
                  prescObj.SPH.OS,
                Error_Message:
                  "Sorry! The frame you ve chosen isn t compatible with your prescription",
              });
            } catch (error) {
              console.error(
                "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
              );
            }
            console.error("Error while saving prescription");
          }
        }
      } else {
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription values",
            Error_Details:
              "ADD:" +
              prescObj.ADD +
              ",SPHOD:" +
              prescObj.SPH.OD +
              ",SPHOS:" +
              prescObj.SPH.OS,
            Error_Message:
              "Sorry! The frame you ve chosen isn t compatible with your prescription",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }

        const compatibleFramesResult =
          prescriptionExternalFunctions?.checkAvailableFrames(frame, prescObj);
        if (!compatibleFramesResult) {
          //error
          console.log("Unable to get compatible frames URL");
          setShowIncompatibleError({
            show: true,
            isClear: true,
            link: null,
            noCompatiblePackage: false,
          });
        } else if (
          compatibleFramesResult &&
          !(compatibleFramesResult instanceof Promise)
        ) {
          setShowIncompatibleError({
            show: true,
            isClear: false,
            link: compatibleFramesResult,
            noCompatiblePackage: false,
          });
        } else if (compatibleFramesResult instanceof Promise) {
          compatibleFramesResult
            .then((compatibleFrameUrl) => {
              if (compatibleFrameUrl) {
                setShowIncompatibleError({
                  show: true,
                  isClear: false,
                  link: compatibleFrameUrl,
                  noCompatiblePackage: false,
                });
              } else {
                setShowIncompatibleError({
                  show: true,
                  isClear: true,
                  link: null,
                  noCompatiblePackage: false,
                });
              }
            })
            .catch((ex) => console.log("Unable to get compatible frames URL"));
        }
      }
    }
  };

  const handleNewPrescriptionName = (e) => {
    e.preventDefault();
    setNewPrescriptionName(e.target.value);
  };

  useEffect(() => {
    if (!isAxisOSZeroErrorCondition()) {
      setShowMissingAxisError(false);
    }
    if (!isAxisODZeroErrorCondition()) {
      setShowMissingAxisError(false);
    }
  }, [axisOD, axisOS]);

  useEffect(() => {
    if (axisOD === 0 || axisOS === 0) {
      setShowMissingAxisError(true);
    }
  }, [axisOD, axisOS]);

  // Use effect useful for load prescription object in case of nested flow
  useEffect(() => {
    if (
      prescriptionObject &&
      prescriptionObject.prescriptionFlow == "NESTED_CA" &&
      prescriptionObject.fileName &&
      !alreadyDone
    ) {
      setIsCalifornian(true);

      setVisibilityButtonUploaded(false);
      setSelectedNested(nestedFlow[0]);
      setIsUploading(false);
      setIsSuccessful(true);
      setFile({
        name: prescriptionObject.fileName,
        size: parseFloat(prescriptionObject.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(prescriptionObject.fileName),
      });
      setPreloadedFileInfo({
        name: prescriptionObject.fileName,
        size: parseFloat(prescriptionObject.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(prescriptionObject.fileName),
        savedFileName: prescriptionObject.savedFileName,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: prescriptionObject.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
    if (
      prescriptionObject &&
      prescriptionObject.prescriptionFlow == "NESTED_CA" &&
      prescriptionObject.name &&
      prescriptionObject.phone &&
      !alreadyDone
    ) {
      setIsCalifornian(true);
      setSelectedNested(nestedFlow[1]);
      reduxDispatch(
        modalsActions.setDoctorSelected({
          name: prescriptionObject.name,
          clinic: prescriptionObject.clinic,
          address: prescriptionObject.address,
          phone: prescriptionObject.phone,
          fax: prescriptionObject.fax,
        })
      );
    }
    setAlreadyDone(true);
  }, [prescriptionObject]);

  //Initialize prescription state with props
  useEffect(() => {
    if (prescriptionObject) {
      let prescObj = prescriptionObject;
      if (prescObj.SPH) {
        if (prescObj.SPH.OD) {
          setSphOD(prescObj.SPH.OD);
        }
        if (prescObj.SPH.OS) {
          setSphOS(prescObj.SPH.OS);
        }
      }
      if (prescObj.CYL) {
        if (prescObj.CYL.OD) {
          setCylOD(prescObj.CYL.OD);
        }
        if (prescObj.CYL.OS) {
          setCylOS(prescObj.CYL.OS);
        }
      }
      if (prescObj.AX) {
        if (prescObj.AX.OD) {
          setAxisOD(parseInt(prescObj.AX.OD));
        }
        if (prescObj.SPH.OS) {
          setAxisOS(parseInt(prescObj.AX.OS));
        }
      }
      if (prescObj.ADD) {
        if (prescObj.ADD.OD !== null && prescObj.ADD.OD !== undefined) {
          setAddOD(parseFloat(prescObj.ADD.OD));
        }
        if (prescObj.ADD.OS !== null && prescObj.ADD.OS !== undefined) {
          setAddOS(parseFloat(prescObj.ADD.OS));
        }
      }

      if (prescObj.issueDate && withIssueDate) {
        const month = String(new Date(prescObj.issueDate).getMonth() + 1);
        const year = new Date(prescObj.issueDate).getFullYear();
        setIssueDate(`${month.length === 1 ? `0${month}` : month}/${year}`);
      }

      let isPrismEnabled =
        typeof prescObj.PRISM_ENABLED === "string"
          ? prescObj.PRISM_ENABLED.toLowerCase() === "true"
          : prescObj.PRISM_ENABLED;
      if (isPrismEnabled) {
        toggleMoreOptionsOpen();
        if (prescObj.HPRISM) {
          if (prescObj.HPRISM.OD) {
            setHorOD(parseFloat(prescObj.HPRISM.OD));
          }
          if (prescObj.HPRISM.OS) {
            setHorOS(parseFloat(prescObj.HPRISM.OS));
          }
        }
        if (prescObj.HBASEDIR) {
          if (prescObj.HBASEDIR.OD) {
            setBaseHorOD(prescObj.HBASEDIR.OD);
          }
          if (prescObj.HBASEDIR.OS) {
            setBaseHorOS(prescObj.HBASEDIR.OS);
          }
        }
        if (prescObj.VPRISM) {
          if (prescObj.VPRISM.OD) {
            setVertOD(parseFloat(prescObj.VPRISM.OD));
          }
          if (prescObj.VPRISM.OS) {
            setVertOS(parseFloat(prescObj.VPRISM.OS));
          }
        }
        if (prescObj.VBASEDIR) {
          if (prescObj.VBASEDIR.OD) {
            setBaseVertOD(prescObj.VBASEDIR.OD);
          }
          if (prescObj.VBASEDIR.OS) {
            setBaseVertOS(prescObj.VBASEDIR.OS);
          }
        }

        if (prescObj.COMMENT) {
          setComment(prescObj.COMMENT);
        }

        setShowPrismValuesForm(true);
      }
      setLoadedExternally(false);
      setTimeout(() => (isComponentInitialised.current = true), 100);
    }
  }, [prescriptionObject]);

  // useEffect(() => {
  //   if (formValues) {
  //     if (
  //       isAxisODNullErrorCondition() &&
  //       isAxisOSNullErrorCondition() &&
  //       isAddODNullErrorCondition() &&
  //       isAddOSNullErrorCondition() &&
  //       formValues?.prescription.right_eye_cyl.value !== 0 &&
  //       formValues?.prescription.right_eye_sph.value !== 0 &&
  //       formValues?.prescription.left_eye_cyl.value !== 0 &&
  //       formValues?.prescription.left_eye_sph.value !== 0
  //     ) {
  //       setShowMissingAxisError(true);
  //     }
  //   }
  // }, [formValues]);

  useEffect(() => {
    if (
      (sphOD > 0 && sphOS < 0) ||
      (sphOD < 0 && sphOS > 0) ||
      (cylOD > 0 && cylOS < 0) ||
      (cylOD < 0 && cylOS > 0)
    ) {
      setShowSPHCYLWarning(true);
    } else {
      if (showSPHCYLWarning) {
        setShowSPHCYLWarning(false);
      }
    }
  }, [sphOD, sphOS, cylOD, cylOS]);

  useEffect(() => {
    let errorsToSet = {};
    if (isAxisODZeroErrorCondition()) {
      errorsToSet["right_eye_axis"] = true;
      setShowAxisError(true);
    }
    if (isAxisOSZeroErrorCondition()) {
      errorsToSet["left_eye_axis"] = true;
      setShowAxisError(true);
    }
    if (isAxisODRangeErrorCondition()) {
      errorsToSet["right_eye_axis"] = true;
      setShowMissingAxisError(false);
      setShowAxisRangeError(true);
    }
    if (isAxisOSRangeErrorCondition()) {
      errorsToSet["left_eye_axis"] = true;
      setShowAxisRangeError(true);
      setShowMissingAxisError(false);
    }

    resetAxisErrors();

    if (Object.keys(errorsToSet).length) {
      setFormErrors({
        ...formErrors,
        ...errorsToSet,
      });
    } else {
      resetAxisErrors();
    }
  }, [axisOD, axisOS]);

  useEffect(() => {
    if (!loadedExternally) {
      if (cylOD === 0) {
        setAxisOD(null);
      }
      if (cylOS === 0) {
        setAxisOS(null);
      }
      resetAxisErrors();
    }
  }, [cylOD, cylOS]);

  useEffect(() => {
    let errorsToSet = {};
    if (isNewPrescriptionNameErrorCondition() && formSubmitted) {
      errorsToSet["new_prescription_name"] = true;
      setShowNewPrescriptionNameError(true);
    }
  }, [formSubmitted]);

  useEffect(() => {
    let errorsToSet = {};
    if (addOD !== null && addOD !== undefined && !isNaN(addOD)) {
      if (formValues.prescription.right_eye_add.hasError) {
        errorsToSet["right_eye_add"] = false;
      }
    }
    if (addOS !== null && addOS !== undefined && !isNaN(addOS)) {
      if (formValues.prescription.left_eye_add.hasError) {
        errorsToSet["left_eye_add"] = false;
      }
    }
    if (Object.keys(errorsToSet).length) {
      setFormErrors({
        ...formErrors,
        ...errorsToSet,
      });
    }
  }, [addOD, addOS]);

  useEffect(() => {
    function checkIfPropertyChanged(property, nameProp) {
      return property != get(prescriptionObject, nameProp);
    }

    if (!isComponentInitialised.current) return;

    if (
      checkIfPropertyChanged(sphOS, "SPH.OS") ||
      checkIfPropertyChanged(sphOD, "SPH.OD") ||
      checkIfPropertyChanged(cylOS, "CYL.OS") ||
      checkIfPropertyChanged(cylOD, "CYL.OD") ||
      checkIfPropertyChanged(axisOD, "AX.OD") ||
      checkIfPropertyChanged(axisOS, "AX.OS") ||
      checkIfPropertyChanged(addOD, "ADD.OD") ||
      checkIfPropertyChanged(addOS, "ADD.OS") ||
      checkIfPropertyChanged(PD.state.PD1, "PD.OD") ||
      checkIfPropertyChanged(PD.state.PD2, "PD.OS")

      //TODO add Prism Values
    )
      setIsPrescriptionModified(true);
  }, [
    sphOS,
    sphOD,
    cylOD,
    cylOS,
    axisOD,
    axisOS,
    addOD,
    addOS,
    vertOD,
    vertOS,
    baseVertOD,
    baseVertOS,
    horOD,
    horOS,
    baseHorOD,
    baseHorOS,
    PD.state.PD1,
    PD.state.PD2,
  ]);

  // useEffect to unset errors on prism values
  useEffect(() => {
    if (vertOD) {
      formErrors["right_eye_vertical"] = false;
    }
    if (vertOS) {
      formErrors["left_eye_vertical"] = false;
    }
    if (baseVertOD) {
      formErrors["right_eye_baseDirVert"] = false;
    }
    if (baseVertOS) {
      formErrors["left_eye_baseDirVert"] = false;
    }
    if (horOD) {
      formErrors["right_eye_horizontal"] = false;
    }
    if (horOS) {
      formErrors["left_eye_horizontal"] = false;
    }
    if (baseHorOD) {
      formErrors["right_eye_baseDirHor"] = false;
    }
    if (baseHorOS) {
      formErrors["left_eye_baseDirHor"] = false;
    }
    var count = 0;
    for (const [key, value] of Object.entries(formErrors)) {
      value === true ? (count += 1) : "";
    }
    count === 0 ? setShowPrismMissingValuesError(false) : "";
  }, [
    vertOD,
    vertOS,
    baseVertOD,
    baseVertOS,
    horOD,
    horOS,
    baseHorOD,
    baseHorOS,
  ]);

  useEffect(() => {
    if (formSubmitted) {
      validateSubmittedData();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (sendWithoutPrescriptionValues) {
      reduxDispatch(modalsActions.setMissingPrescriptionsValueResult(false));
      let newPres: any = {
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      if (selectedNested == "UPLOAD") {
        if (isSuccessful) {
          let fileSize = file?.size
            ? (parseInt(file.size) / 1024 / 1024).toFixed(2)
            : 0;
          newPres = {
            ...newPres,
            prescriptionId: prescriptionIdForCalifornia,
            prescriptionFlow: "NESTED_CA",
            fileName: file?.name,
            savedFileName: preloadedFileInfo?.savedFileName,
            fileSize: fileSize,
          };
        }
      } else {
        if (doctorSelected) {
          newPres = {
            ...newPres,
            prescriptionFlow: "NESTED_CA",
            prescriptionId: 12345,
            name: doctorSelected.name,
            clinic: doctorSelected.clinic,
            address: doctorSelected.address,
            phone: doctorSelected.phone,
            fax: doctorSelected.fax,
          };
        }
      }
      if (
        typeof prescriptionExternalFunctions.saveExtendedPrescription ===
        "function"
      ) {
        prescriptionExternalFunctions
          .saveExtendedPrescription(newPres)
          .then((res) => {
            onSuccessPrescription(res, null);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [sendWithoutPrescriptionValues]);

  useEffect(() => {
    if (showPrescriptionMissingValuesError) {
      getAxisError();
    }
  }, [showPrescriptionMissingValuesError]);

  const ApplyButton = () => (
    <div className="FullPrescriptionForm__innerContainer__apply">
      <GenericButton
        id={"submitButtonId"}
        className={"FullPrescriptionForm__innerContainer__applyButton"}
        noArrow
        type="submit"
        dataElementId="X_X_LensPanel_Type-ValuesApply"
        title={applyButton}
        tabIndex={0}
      >
        {applyButton}
      </GenericButton>
    </div>
  );

  return (
    <div className="FullPrescriptionForm">
      {userIsLoggedIn && prescriptionObject?.name ? (
        <div className="FullPresctiptionForm__innerContainer__accountInfo">
          <p className="FullPrescriptionForm__innerContainer__accountInfo__name">
            {prescriptionName}: {prescriptionObject.name}
          </p>
          <p className="FullPrescriptionForm__innerContainer__accountInfo__date">
            {lastModified +
              convertISODate({ isoDate: prescriptionObject.lastUpdate })}
          </p>
        </div>
      ) : (
        <Subtitle />
      )}
      <form
        className={"FullPrescriptionForm__innerContainer__form"}
        onSubmit={onSubmit}
      >
        <FormTable
          columns={prescriptionTableColumns}
          selector={"prescription"}
          cylOD={cylOD}
          cylOS={cylOS}
          isAddDisabled={isAddDisabled}
          onSubmit={onSubmit}
          formErrors={formErrors}
          selectPlaceholder={selectPlaceholder}
          formValues={formValues}
        />
        {showPrescriptionMissingValuesError && (
          <SimpleTextError message={missingValues} />
        )}
        {showMissingAxisError && <SimpleTextError message={axisZeroError} />}
        {showAxisRangeError && <SimpleTextError message={axisRangeError} />}
        {showSPHCYLWarning && (
          <BrandComponent
            componentName="WarningBanner"
            parameter={{
              text: sphPositiveNegativeWarning,
            }}
          />
        )}
        {showIncompatibleError.show && (
          <BrandComponent
            componentName="IncompatibleError"
            path="FullPrescriptionForm/IncompatibleError"
            parameter={{
              shopCompatible: !showIncompatibleError.isClear,
              link: showIncompatibleError.link,
              noCompatiblePackage: showIncompatibleError.noCompatiblePackage,
              resetCallback: resetForm,
            }}
          />
        )}
        {!hideMoreOptions && (enablePrismComment || enablePrism) && (
          <MoreOptions
            getColumns={utils.getPrismTableColumns(prescriptionDefaults)}
            onSubmit={onSubmit}
            isMoreOptionsFormOpen={isMoreOptionsFormOpen}
            toggleMoreOptionsOpen={toggleMoreOptionsOpen}
            showPrismValuesForm={showPrismValuesForm}
            showPrismMissingValuesError={showPrismMissingValuesError}
            comment={comment}
            setComment={setComment}
            errorMessage={<SimpleTextError message={prismErrorMessage} />}
            checkbox={
              <CustomCheckbox
                value={showPrismValuesForm}
                setter={(v) => {
                  setShowPrismValuesForm(v);
                  if (enablePrism && !enablePrismComment) {
                    setIsMoreOptionsFormOpen(v);
                  }
                }}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
            }
            FormTable={formTableColumnsHOC({
              selector: "prism",
              cylOD,
              cylOS,
              isAddDisabled,
              onSubmit,
              formErrors,
              selectPlaceholder,
              formValues,
            })}
          />
        )}
        {/* {showAxisError && <SimpleTextError message={axisZeroError} />} */}
        {/* {showPrescriptionMissingValuesError && (
          <SimpleTextError message={missingValues} />
        )} */}
        {showMissingReadingAddValueModal &&
          createPortal(
            <PrescriptionModalNoAdd onContinue={sendPrescriptionData} />,
            //@ts-ignore
            document.querySelector("#RxcModals")
          )}
        <Separator />
        <BrandComponent
          componentName="PupillaryDistance"
          parameter={{
            PD1: PD.state.PD1,
            PD2: PD.state.PD2,
            showBothPdOptions: PD.state.showBothPdOptions,
            showPDWarning: PD.state.showPDWarning,
            onPD1Change: PD.state.setPD1,
            onPD2Change: PD.state.setPD2,
            setShowBothPdOptions: PD.state.setShowBothPdOptions,
            pdValueNotSelectedError: PD.state.pdValueNotSelectedError,
            CustomCheckbox: CustomCheckbox,
          }}
        />
        <Separator />

        {
          //California
          translation == "en_US" && (
            <BrandComponent
              componentName="CaliforniaManualInput"
              path="FullPrescriptionForm/CaliforniaManualInput"
              parameter={{
                doctorSelected,
                nestedFlow,
                isCalifornian,
                handleSwitch,
                visibilityButtonUploaded,
                onUploadChange: handleFileChange,
                isUploading,
                file,
                selectedNested,
                isSuccessful,
                isFileTooBigError,
                filePath,
                preloadedFileInfo,
                prescriptionModule,
                prescriptionExternalFunctions,
                onSelectNested: (el) => setSelectedNested(el),
                openModal: () =>
                  reduxDispatch(
                    modalsActions.setShowCallMyDoctorCalifornianModal(true)
                  ),
              }}
            />
          )
        }
        {userIsLoggedIn && (
          //MyAccount
          <>
            <div className="SaveAccountPrescription__checkbox">
              <CustomCheckbox
                value={saveAccountPrescription}
                setter={setSaveAccountPrescription}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                disabled={!isSavePrescriptionCheckboxEnabled}
              />
              <label
                className={`SaveAccountPrescription__checkbox__label ${
                  isSavePrescriptionCheckboxEnabled ? "" : "disabled"
                }`}
                onClick={() =>
                  isSavePrescriptionCheckboxEnabled &&
                  setSaveAccountPrescription(!saveAccountPrescription)
                }
              >
                {savePrescriptionInAccount}
              </label>
            </div>
            {saveAccountPrescription && (
              <>
                <label className="SaveAccountPrescription__label">
                  {prescriptionName}
                </label>
                <div className="SaveAccountPrescription__inputContainer">
                  <input
                    className={
                      !showNewPrescriptionNameError
                        ? "SaveAccountPrescription__inputContainer__input"
                        : "SaveAccountPrescription__inputContainer__input redBorder"
                    }
                    type="text"
                    placeholder={prescriptionName}
                    value={newPrescriptionName}
                    onChange={(e) => handleNewPrescriptionName(e)}
                  />

                  {showNewPrescriptionNameError && (
                    <>
                      <div className="SaveAccountPrescription__inputContainer__errorIcon" />
                      <div className="SaveAccountPrescription__inputContainer__error">
                        {prescriptionNameError}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {withIssueDate && (
          <IssueDateInput
            value={issueDate}
            onChange={(value) => setIssueDate(value)}
            missingError={formErrors["issue_date"]}
          />
        )}
        {!isCalifornian && (
          <AgreementCheckbox
            agreement={agreement}
            setAgreement={setAgreement}
            formErrors={formErrors}
            disabled={!isAgreementCheckboxEnabled}
            tooltipText={tooltipText}
          />
        )}
        {isCalifornian ? (
          (selectedNested == "UPLOAD" && isSuccessful) ||
          (selectedNested == "CMD" && doctorSelected) ? (
            <ApplyButton />
          ) : (
            <></>
          )
        ) : (
          <ApplyButton />
        )}
      </form>

      {brand === "arnette" && (
        <ReferencedSection name={"ScrollView__" + workflow.currentStep} />
      )}
    </div>
  );
}
