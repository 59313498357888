import React, { useState, useEffect, useRef } from "react";
import "./default.module.scss";
import { BrandComponent } from "..//common/BrandComponent";
import parse from "html-react-parser";
export interface StepTitleProps {
  title: string;
  titleMobile?: string;
  subtitle?: string;
  subtitleStyle?: any;
  slider?: any;
  pills?: any;
  pillsSubtitle?: string;
  brandCallback?: (prop) => void;
  pillsCallback?: (prop) => void;
  pillSelected?: any;
  showGrayOutBanner?: boolean;
}

/**
 * Component that displays the title and subtitle of a step. It accepts an input
 * of type ```StepTitleProps``` with the following parameters:
 *  - ```title```: the title of the step
 *  - ```titleMobile``` (_optional_): the title for the mobile view of that step
 *  - ```subtitle``` (_optional_): the subtitle of the step
 */
export function StepTitle({
  title,
  titleMobile,
  subtitle,
  subtitleStyle,
  slider,
  pills,
  pillsSubtitle,
  brandCallback,
  pillsCallback,
  pillSelected,
  showGrayOutBanner,
}: StepTitleProps) {
  const buttonColorChangeAndCallback = (prop) => {
    brandCallback(prop);
  };
  const pillsColorChangeAndCallback = (prop) => {
    pillsCallback(prop);
  };

  return (
    <div className={"StepTitle__container"}>
      <div className={"StepTitle__container__title"}>{title}</div>

      <div className={"StepTitle__container__titleMobile"}>
        {titleMobile ? titleMobile : title}
      </div>

      {subtitle && (
        <div className={"StepTitle__container__subtitle"} style={subtitleStyle}>
          {subtitle}
        </div>
      )}

      {showGrayOutBanner && (
        <div
          className={
            "StepTitle__container__grayOutWrapper" +
            (subtitle ? " subtitle" : "") +
            (pills || slider ? " pills" : "")
          }
        >
          <BrandComponent componentName="GrayOutBanner" />
        </div>
      )}

      {
        //Brands
        slider && (
          <BrandComponent
            componentName="optionsSlider"
            parameter={{
              slider: slider,
              callback: buttonColorChangeAndCallback,
            }}
          />
        )
      }
      {
        //Design types
        pills && (
          <>
            <BrandComponent
              componentName="filterPills"
              parameter={{
                pills: pills,
                callback: pillsColorChangeAndCallback,
                selected: pillSelected,
              }}
            />
          </>
        )
      }
      {pillsSubtitle && (
        <div className={"StepTitle__container__pillsSubtitle"}>
          {parse(pillsSubtitle)}
        </div>
      )}
    </div>
  );
}
