import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import { orderBy } from "lodash";
import { BrandComponent } from "../../../components/common/BrandComponent";
import { useTranslation } from "../../translations/TranslationHooks";
import { workflow } from "../../models/Workflow";
import { useDispatch, useSelector } from "react-redux";
import {
  useAdvancedPrescriptionOptions,
  useCurrentPackages,
  usePrescriptionIssueDate,
  usePrescriptionsFromMyAccount,
  useMandatoryManualInput,
} from "@hooks";
import { prescriptionActions } from "../../../redux/slices/prescription";
import { workflowActions } from "../../../redux/slices/workflow";
import ReferencedSection from "../../../components/common/ReferencedSection";
import "./default.module.scss";

export enum PrescriptionModeEnum {
  MANUAL = "MANUAL",
  CMD = "CMD",
  UPLOAD = "UPLOAD",
  ACCOUNT = "ACCOUNT",
  NESTED_CA = "NESTED_CA",
  LATER = "LATER",
}

export function AdvancedPrescription(props) {
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const enableMandatory = useMandatoryManualInput();
  const prescriptionOptions = useAdvancedPrescriptionOptions();
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const notSkipGUI = useSelector(
    (state: any) => state.prescription?.isLoadedWithoutEdit
  );
  const currentPackages = useCurrentPackages();
  const [isDoctorSelected, setIsDoctorSelected] = useState(false);
  const translate = useTranslation();
  const selectedPrescriptionMode = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const [prescriptionMode, setPrescriptionMode] = useState(null);

  const isManualInput = prescriptionMode === PrescriptionModeEnum.MANUAL;
  const isCallMyDoctor = prescriptionMode === PrescriptionModeEnum.CMD;
  const isUpload = prescriptionMode === PrescriptionModeEnum.UPLOAD;
  const isFromMyAccount = prescriptionMode === PrescriptionModeEnum.ACCOUNT;

  const [prescriptionObject, setPrescriptionObject] = useState(null);
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const { prescriptionsFromMyAccount, isLoggedIn, login } =
    usePrescriptionsFromMyAccount();

  const { validTimeInMonth } = usePrescriptionIssueDate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    dispatch(workflowActions.setSelectedPrescriptionMode(true));
    setPrescriptionMode(PrescriptionModeEnum.UPLOAD);
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      return parsedExt.join();
    }
  };

  const formatUploadCardDescriptionLabel = (label) => {
    let supportedExtensions = getSupportedFileExtensions()
      .split(",")
      .join(", ");
    return label
      .replace("###FILE_FORMATS###", supportedExtensions)
      .replace("###FILE_SIZE###", prescriptionModule?.maxFileSize);
  };

  const closePrescriptionForm = () => {
    if (
      (isCallMyDoctor &&
        prescriptionObject?.prescriptionFlow == PrescriptionModeEnum.CMD) ||
      (isUpload &&
        prescriptionObject?.prescriptionFlow === PrescriptionModeEnum.UPLOAD) ||
      (isManualInput &&
        prescriptionObject?.prescriptionFlow === PrescriptionModeEnum.MANUAL)
    ) {
      reduxDispatch(prescriptionActions.setCurrentPrescription(null));
      setPrescriptionObject(null);
      prescriptionModule.clearExtendedPrescription();
    }
    dispatch(workflowActions.setSelectedPrescriptionMode(false));
    changeTitleCallMyDoctor(false);
    setPrescriptionMode(null);
  };
  const changeTitleCallMyDoctor = (val: boolean) => setIsDoctorSelected(val);

  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (enableMandatory && !prescriptionMode) {
      setPrescriptionMode(PrescriptionModeEnum.MANUAL);
      dispatch(workflowActions.setSelectedPrescriptionMode(true));
    }
  }, [selectedPrescriptionMode]);

  useEffect(() => {
    if (!selectedPrescriptionMode) {
      setPrescriptionMode(null);
    }
  }, [selectedPrescriptionMode]);

  useEffect(() => {
    if (prescriptionOptions) {
      if (
        !prescriptionOptions.prescriptionEnabled ||
        !prescriptionModule ||
        prescriptionModule.prescriptionType === "SIMPLE" ||
        loadPreselectEnabled
      ) {
        //skip advancePrescription step
        if (!loadPreselectEnabled) {
          workflow.goNextStep(workflow.getPrevCurrentParameterStep(), true);
        } else {
          let value = "";
          let sendingMethod = "";
          if (!currentPrescription) {
            value = translate("steps.advancedPrescription.later.review.title");
          } else if (
            currentPrescription.prescriptionFlow === PrescriptionModeEnum.MANUAL
          ) {
            value = translate("steps.advancedPrescription.manual.review.title");
            sendingMethod = "manual";
          } else if (
            currentPrescription.prescriptionFlow ===
              PrescriptionModeEnum.UPLOAD ||
            (currentPrescription.prescriptionFlow ===
              PrescriptionModeEnum.NESTED_CA &&
              currentPrescription.fileName !== undefined)
          ) {
            value = translate("steps.advancedPrescription.upload.review.title");
            sendingMethod = "upload";
          } else if (
            currentPrescription.prescriptionFlow === PrescriptionModeEnum.CMD ||
            (currentPrescription.prescriptionFlow ===
              PrescriptionModeEnum.NESTED_CA &&
              currentPrescription.name !== undefined)
          ) {
            value = translate("steps.advancedPrescription.doctor.review.title");
            sendingMethod = "calldoctor";
          } else if (
            currentPrescription.prescriptionFlow ===
            PrescriptionModeEnum.ACCOUNT
          ) {
            value = translate(
              "steps.advancedPrescription.account.review.title"
            );
            sendingMethod = "account";
          }
          workflow.goNextStep(
            [
              ...workflow.getPrevCurrentParameterStep(),
              {
                key: "advancedPrescription",
                value: value,
                attributes: {
                  showSeeDetails:
                    shouldShowSeeDetailsInReview(currentPrescription),
                  sendingMethod,
                },
              },
            ],
            prescriptionModule ? false : true
          );
        }
      } else {
        reduxDispatch(workflowActions.setLoadingStep(false));
        if (currentPrescription) {
          setPrescriptionObject(currentPrescription);
        }
        return;
      }
    }
  }, [
    prescriptionOptions,
    prescriptionModule,
    currentPrescription,
    loadPreselectEnabled,
  ]);

  useEffect(() => {
    if (!notSkipGUI && prescriptionObject) {
      switch (prescriptionObject.prescriptionFlow) {
        case PrescriptionModeEnum.MANUAL:
        case PrescriptionModeEnum.NESTED_CA:
        case PrescriptionModeEnum.ACCOUNT:
          setPrescriptionMode(PrescriptionModeEnum.MANUAL);
          break;
        case PrescriptionModeEnum.UPLOAD:
          setPrescriptionMode(PrescriptionModeEnum.UPLOAD);
          break;
        case PrescriptionModeEnum.CMD:
          setPrescriptionMode(PrescriptionModeEnum.CMD);
          break;
      }
      dispatch(workflowActions.setSelectedPrescriptionMode(true));
    }

    if (prescriptionOptions?.prescriptionSendLaterEnabled?.value === false) {
      if (
        prescriptionOptions.prescriptionDoctorEnabled.value === true &&
        prescriptionOptions.prescriptionFromMyAccountEnabled.value === false &&
        prescriptionOptions.prescriptionManualEnabled.value === false &&
        prescriptionOptions.prescriptionUploadEnabled.value === false
      ) {
        dispatch(workflowActions.setSelectedPrescriptionMode(true));
        setPrescriptionMode(PrescriptionModeEnum.CMD);
      }
      if (
        prescriptionOptions.prescriptionDoctorEnabled.value === false &&
        prescriptionOptions.prescriptionFromMyAccountEnabled.value === false &&
        prescriptionOptions.prescriptionManualEnabled.value === true &&
        prescriptionOptions.prescriptionUploadEnabled.value === false
      ) {
        dispatch(workflowActions.setSelectedPrescriptionMode(true));
        setPrescriptionMode(PrescriptionModeEnum.MANUAL);
      }
    }
  }, [prescriptionObject, prescriptionOptions]);

  const rxType = useMemo(() => {
    if (!props.step.params) return "";
    if (
      props.step.params?.[0]?.optionalAttributes?.value?.toLowerCase() ===
      "reading"
    )
      return "READING";

    if (
      props.step.params?.[0]?.optionalAttributes?.value?.toLowerCase() ===
      "distance"
    )
      return "DISTANCE";

    if (props.step.params?.[0].value?.toLowerCase() === "progressive")
      return "PROGRESSIVE";

    return "SINGLE_VISION";
  }, [props.step]);

  const configHasPrescription = (type: string) => {
    let ret = false;
    switch (type) {
      case PrescriptionModeEnum.MANUAL:
        ret =
          prescriptionOptions &&
          prescriptionOptions.prescriptionManualEnabled.value;
        break;
      case PrescriptionModeEnum.ACCOUNT:
        ret =
          prescriptionOptions &&
          prescriptionOptions.prescriptionFromMyAccountEnabled.value;
        break;
      case PrescriptionModeEnum.UPLOAD:
        ret =
          prescriptionOptions &&
          prescriptionOptions.prescriptionUploadEnabled.value;
        break;
      case PrescriptionModeEnum.CMD:
        ret =
          prescriptionOptions &&
          prescriptionOptions.prescriptionDoctorEnabled.value;
        break;
      case PrescriptionModeEnum.LATER:
        ret = prescriptionOptions?.prescriptionSendLaterEnabled?.value;
        break;
    }
    return ret;
  };

  const shouldShowSeeDetailsInReview = (prescription: any) => {
    if (!prescription) {
      return false;
    }
    if (["UPLOAD", "NESTED_CA"].includes(prescription.prescriptionFlow)) {
      const validImageExtensions = ["gif", "jpeg", "jpg", "pjpeg", "png"];
      let fileNameSplit = prescription.fileName.split(".");
      const prescriptionFileExtension =
        fileNameSplit[fileNameSplit.length - 1].toLowerCase();
      return validImageExtensions.includes(prescriptionFileExtension);
    } else {
      return true;
    }
  };

  const handleSuccessPrescription = (prescription: any) => {
    reduxDispatch(prescriptionActions.setCurrentPrescription(prescription));
    reduxDispatch(prescriptionActions.setLoadedWithoutEdit(false));
    let value = "";
    let sendingMethod = "";
    if (prescription) {
      if (prescription.prescriptionFlow === PrescriptionModeEnum.MANUAL) {
        value = translate("steps.advancedPrescription.manual.review.title");
        sendingMethod = "manual";
      } else if (
        prescription.prescriptionFlow === PrescriptionModeEnum.UPLOAD ||
        (prescription.fileName &&
          prescription.prescriptionFlow === PrescriptionModeEnum.NESTED_CA)
      ) {
        value = translate("steps.advancedPrescription.upload.review.title");
        sendingMethod = "upload";
      } else if (
        prescription.prescriptionFlow === PrescriptionModeEnum.CMD ||
        (prescription.name &&
          prescription.phone &&
          prescription.prescriptionFlow === PrescriptionModeEnum.NESTED_CA)
      ) {
        value = translate("steps.advancedPrescription.doctor.review.title");
        sendingMethod = "calldoctor";
      } else if (
        prescription.prescriptionFlow === PrescriptionModeEnum.ACCOUNT
      ) {
        value = translate("steps.advancedPrescription.account.review.title");
        sendingMethod = "account";
      }
    }
    workflow.goNextStep([
      {
        key: "advancedPrescription",
        value: value,
        attributes: {
          showSeeDetails: shouldShowSeeDetailsInReview(prescription),
          sendingMethod,
        },
      },
    ]);
  };

  const setPrescriptionFromAccount = (prescription) => {
    setPrescriptionObject({ ...prescription, myAccount: true });
    setPrescriptionMode(PrescriptionModeEnum.MANUAL);
    dispatch(workflowActions.setSelectedPrescriptionMode(true));
  };

  const onChangeMethod = () => {
    setPrescriptionMode(null);
    dispatch(workflowActions.setSelectedPrescriptionMode(false));
  };

  const getManualInputCard = () => {
    return (
      configHasPrescription("MANUAL") && (
        <BrandComponent
          componentName="StepCard"
          key={"manualPrescriptionCard"}
          parameter={{
            title: translate("steps.advancedPrescription.manual.card.title"),
            description: translate(
              "steps.advancedPrescription.manual.card.description"
            ),
            imageUrl: "",
            id: "manualPrescriptionCard",
            noImageCard: true,
            onClick: () => {
              /* if (prescriptionObject?.myAccount) {
                setPrescriptionObject(null);
              } */
              dispatch(workflowActions.setSelectedPrescriptionMode(true));
              setPrescriptionMode(PrescriptionModeEnum.MANUAL);
            },
          }}
        />
      )
    );
  };

  const getAccountCard = () => {
    return (
      configHasPrescription("ACCOUNT") && (
        <BrandComponent
          componentName="StepCard"
          key={"accountPrescriptionCard"}
          parameter={{
            title: translate("steps.advancedPrescription.account.card.title"),
            description: translate(
              "steps.advancedPrescription.account.card.description"
            ),
            imageUrl: "",
            id: "accountPrescriptionCard",
            noImageCard: true,
            onClick: () => {
              dispatch(workflowActions.setSelectedPrescriptionMode(true));
              setPrescriptionMode(PrescriptionModeEnum.ACCOUNT);
            },
          }}
        />
      )
    );
  };

  const getUploadCard = () => {
    return (
      configHasPrescription("UPLOAD") && (
        <Fragment key="uploadPrescriptionCard">
          <BrandComponent
            componentName="StepCard"
            parameter={{
              title: translate("steps.advancedPrescription.upload.card.title"),
              description: formatUploadCardDescriptionLabel(
                translate("steps.advancedPrescription.upload.card.description")
              ),
              imageUrl: "",
              id: "uploadPrescriptionCard",
              noImageCard: true,
              onClick: () => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              },
            }}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept={getSupportedFileExtensions()}
            onChange={handleFileChange}
          />
        </Fragment>
      )
    );
  };

  const getCMDCard = () => {
    return (
      configHasPrescription("CMD") && (
        <BrandComponent
          componentName="StepCard"
          key={"doctorPrescriptionCard"}
          parameter={{
            title: translate("steps.advancedPrescription.doctor.card.title"),
            description: translate(
              "steps.advancedPrescription.doctor.card.description"
            ),
            imageUrl: "",
            id: "doctorPrescriptionCard",
            noImageCard: true,
            onClick: () => {
              dispatch(workflowActions.setSelectedPrescriptionMode(true));
              setPrescriptionMode(PrescriptionModeEnum.CMD);
            },
          }}
        />
      )
    );
  };

  const getLaterCard = () => {
    if (prescriptionOptions.prescriptionSendLaterEnabled) {
      return (
        configHasPrescription("LATER") && (
          <BrandComponent
            componentName="StepCard"
            key={"laterPrescriptionCard"}
            parameter={{
              title: translate("steps.advancedPrescription.later.card.title"),
              description: translate(
                "steps.advancedPrescription.later.card.description"
              ),
              imageUrl: "",
              id: "laterPrescriptionCard",
              noImageCard: true,
              onClick: () => {
                if (
                  prescriptionModule &&
                  typeof prescriptionModule.clearExtendedPrescription ===
                    "function"
                ) {
                  prescriptionModule.clearExtendedPrescription();
                  dispatch(prescriptionActions.setCurrentPrescription(null));
                  dispatch(prescriptionActions.setLoadedWithoutEdit(false));
                }
                workflow.goNextStep([
                  {
                    key: "advancedPrescription",
                    value: translate(
                      "steps.advancedPrescription.later.review.title"
                    ),
                  },
                ]);
              },
            }}
          />
        )
      );
    }
  };

  const getCardsWithCorrectOrder = () => {
    let cards = [
      {
        card: getManualInputCard(),
        order: prescriptionOptions?.prescriptionManualEnabled.order
          ? prescriptionOptions?.prescriptionManualEnabled.order
          : 1,
      },
      {
        card: getAccountCard(),
        order: prescriptionOptions?.prescriptionFromMyAccountEnabled.order
          ? prescriptionOptions?.prescriptionFromMyAccountEnabled.order
          : 1,
      },
      {
        card: getUploadCard(),
        order: prescriptionOptions?.prescriptionUploadEnabled.order
          ? prescriptionOptions?.prescriptionUploadEnabled.order
          : 1,
      },
      {
        card: getCMDCard(),
        order: prescriptionOptions?.prescriptionDoctorEnabled.order
          ? prescriptionOptions?.prescriptionDoctorEnabled.order
          : 1,
      },
      {
        card: getLaterCard(),
        order: prescriptionOptions?.prescriptionSendLaterEnabled.order
          ? prescriptionOptions?.prescriptionSendLaterEnabled.order
          : 1,
      },
    ].filter((card) => card.card);

    return orderBy(cards, "order").map((card) => card.card);
  };

  if (
    !selectedPrescriptionMode &&
    prescriptionOptions &&
    prescriptionOptions.prescriptionEnabled
  ) {
    return (
      <BrandComponent
        componentName="StepContainer"
        parameter={{ title: translate("steps.advancedPrescription.title") }}
      >
        <div className="AdvancedPresctiption__container">
          {getCardsWithCorrectOrder().map((c) => c)}
          <ReferencedSection
            key="reference"
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </div>
      </BrandComponent>
    );
  }

  return (
    <div>
      {isManualInput && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: translate(
              prescriptionObject?.myAccount
                ? "steps.advancedPrescription.manual.accountTitle"
                : "steps.advancedPrescription.manual.title"
            ),
          }}
        >
          <BrandComponent
            componentName="FullPrescriptionForm"
            parameter={{
              hideMoreOptions: prescriptionModule?.hideMoreOptions,
              currentPackages: currentPackages,
              onSuccessPrescription: handleSuccessPrescription,
              prescriptionObject: prescriptionObject,
              userIsLoggedIn: isLoggedIn(),
              withIssueDate: !!validTimeInMonth,
              rxType,
            }}
          />
          <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </BrandComponent>
      )}

      {isCallMyDoctor && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: isDoctorSelected
              ? translate(
                  "steps.advancedPrescription.doctor.selectedDoctorPageTitle"
                )
              : translate("steps.advancedPrescription.doctor.title"),
          }}
        >
          <BrandComponent
            parameter={{
              closePrescriptionForm,
              onSuccessPrescription: handleSuccessPrescription,
              changeTitleCallMyDoctor,
              prescriptionObject: prescriptionObject,
            }}
            componentName="CallMyDoctorPrescriptionForm"
          />
          <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </BrandComponent>
      )}

      {isUpload && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: translate("steps.advancedPrescription.upload.title"),
            prescriptionObject: prescriptionObject,
          }}
        >
          <BrandComponent
            componentName="PrescriptionUploadForm"
            parameter={{
              closePrescriptionForm: closePrescriptionForm,
              onSuccessPrescription: handleSuccessPrescription,
              fileInput: file,
              prescriptionObject: prescriptionObject,
            }}
          />
          <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </BrandComponent>
      )}

      {isFromMyAccount && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: translate("steps.advancedPrescription.fromMyAccount.title"),
          }}
        >
          <BrandComponent
            componentName="SelectFromMyAccount"
            parameter={{
              isLoggedIn,
              prescriptions: prescriptionsFromMyAccount,
              login,
              onSelect: setPrescriptionFromAccount,
              onChangeMethod: () => onChangeMethod(),
            }}
          />
          {/* <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          /> */}
        </BrandComponent>
      )}
    </div>
  );
}
