import React, { useMemo, useState } from "react";
import { usePrescriptionIssueDate } from "@hooks";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import Input from "../../common/Input";
import { SimpleTextError } from "./SimpleTextError";

export const IssueDateInput = (props) => {
  const missingError = useTranslate(
    "steps.advancedPrescription.manual.issueDate.missingError"
  );
  const formatError = useTranslate(
    "steps.advancedPrescription.manual.issueDate.formatError"
  );
  const { isExpiredPrescription, isValidIssueDate } =
    usePrescriptionIssueDate();

  const [error, setError] = useState("");

  const onChange = (e) => {
    const value = e.target.value;
    //delete all case
    if (value === "") {
      props.onChange(value);
      return;
    }
    if ([undefined, null].includes(value)) return;
    //block two or more /, only one is allowed
    if (value.split("/").length - 1 > 1) return;
    const lastDigit = value.slice(value.length - 1, value.length);
    // char / is allowed only after two digits
    if (lastDigit === "/" && value.length !== 3) return;

    //add slash automatically (excluding case user type backspace)
    if (value.length === 2 && props.value.length === 1 && !isNaN(value)) {
      props.onChange(`${value}/`);
      return;
    }
    const [month, year] = value.split("/");
    //block if month is not valid number or < 0
    if (isNaN(month) || Number(month) < 0 || month.length > 2 || month == "00")
      return;
    //block if year is equal 0, is not a valid number,more to 4 digits
    if (year && (Number(year) === 0 || isNaN(year) || year.length > 4)) return;

    props.onChange(value);
  };

  const errorMessage = useMemo(() => {
    if (!props.value) return missingError;
    if (isExpiredPrescription(props.value) || !isValidIssueDate(props.value))
      return formatError;
  }, [props.isError, props.value]);

  const onBlur = (ev) => {
    if (isExpiredPrescription(props.value) || !isValidIssueDate(props.value)) {
      setError(formatError);
    } else {
      setError("");
    }
  };

  return (
    <div className="IssueDateInput">
      <Input
        {...props}
        onChange={onChange}
        placeholder="MM/YYYY"
        label={
          <>
            Date of issue <span className="orange">*</span>
          </>
        }
        type="text"
        isError={error || props.isError}
        onBlur={onBlur}
      />
      {error && <SimpleTextError>{error}</SimpleTextError>}
      {!error && !props.value && props.missingError && (
        <SimpleTextError>{missingError}</SimpleTextError>
      )}
    </div>
  );
};
