import { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";

export function useMoreInfo(optionKey: string) {
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const [ret, setRet] = useState(null);
  const reduxDispatch = useDispatch();

  const hasMoreInfo = (optionValue: any, contentConfig, optionKeyFamily) => {
    return (
      contentConfig &&
      contentConfig[optionKeyFamily || optionKey] &&
      contentConfig[optionKeyFamily || optionKey][optionValue] &&
      contentConfig[optionKeyFamily || optionKey][optionValue].contentIdentifier
    );
  };
  const getMoreInfo = (optionValue: any, contentConfig, optionKeyFamily) =>
    (isBrowser ? "DESK_" : "MOB_") +
    contentConfig[optionKeyFamily || optionKey][optionValue].contentIdentifier;
  const setFocus = (id: string) => {
    reduxDispatch(modalsActions.setOpenLearnMoreModal(id));
  };

  const findFamily = (title: string, contentConfig) => {
    let family = contentConfig["treatmentFamily"];
    let finded = null;
    if (family) {
      Object.keys(family).forEach((e) => {
        if (family[e].title == title) {
          finded = e;
        }
      });
    }
    return finded;
  };

  const getMoreInfoList = (options: any[], contentConfig) => {
    let moreInfoList = [];
    const mapped = options.map((option) => {
      let optionKeyFamily = null;
      let optionValue = option.id;
      if (optionKey === "treatment" || optionKey === "antiReflective") {
        //is GVP
        optionValue = optionValue.split("$")[1];
      }

      if (optionKey === "treatmentsFamily") {
        let splitted = optionValue.split("$");
        optionKeyFamily = splitted.length > 1 ? splitted[0] : "treatmentFamily";
        optionValue = splitted.length > 1 ? splitted[1] : option.id;
        if (splitted.length == 1) {
          let finded = findFamily(option.title, configContent);
          optionValue = finded ? finded : optionValue;
        }
      }

      if (hasMoreInfo(optionValue, contentConfig, optionKeyFamily)) {
        const moreInfo = getMoreInfo(
          optionValue,
          contentConfig,
          optionKeyFamily
        );
        moreInfoList.push(moreInfo);
        option.moreInfo = () => setFocus(moreInfo);
      }
      return option;
    });
    reduxDispatch(modalsActions.setListLearnMoreModal(moreInfoList));
    return mapped;
  };

  useEffect(() => {
    if (configContent) {
      setRet(() => (options: any[]) => getMoreInfoList(options, configContent));
    }
  }, [configContent]);

  return ret;
}
