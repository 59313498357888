import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useMandatoryManualInput() {
  const enableMandatoryCookie = getCookieByName("mandatory", true);
  const enableMandatory = useSelector(
    (state: any) => state.config.prescriptionModule?.mandatory
  );

  if (enableMandatoryCookie !== null) {
    return enableMandatoryCookie as boolean;
  }

  if (enableMandatory !== null && enableMandatory !== undefined) {
    return enableMandatory as boolean;
  }

  return true;
}
