import React, { useMemo, useState } from "react";
import "./default.module.scss";
import Image from "../common/Image";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import { useTranslation } from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { StepBadgeType } from "../StepBadge/types/StepBadgeType";
import { workflowActions } from "../../redux/slices/workflow";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import useDelegatingLearnMore from "../../configurator/hooks/useDelegatingLearnMore";
import { isMobile } from "react-device-detect";
import {
  useCurrentPackages,
  usePriceDiscountBadge,
  useLargeIcons,
} from "@hooks";

export interface StepCardProps {
  title: string;
  description?: string;
  price?: number;
  discount?: number;
  insPrice?: number;
  onClick?: (p: any) => void;
  badge?: StepBadgeType;
  textBadge?: string;
  imageUrl?: string;
  id: string;
  noMarginTitle?: boolean;
  included?: boolean;
  noImageCard?: boolean;
  moreInfo?: Function;
  showPresriptionButton?: boolean;
  showPresriptionButtonFunction?: Function;
  sequence?: number;
  disabled?: boolean;
  discountLoading?: boolean;
  contentIdentifier?: string;
  brand: string;
  transitionId?: string;
}

export function StepCard({
  title,
  description,
  price,
  discount,
  insPrice,
  badge,
  textBadge,
  onClick,
  imageUrl,
  id,
  included,
  showPresriptionButton = false,
  showPresriptionButtonFunction = () => {},
  noImageCard,
  moreInfo,
  disabled,
  discountLoading,
  contentIdentifier,
  brand,
  transitionId, // to handle custom transition id
}: StepCardProps) {
  const currentPackages = useCurrentPackages();
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const { isLargeIconsActive, getIcons } = useLargeIcons();
  const [isMouseOver, setIsMouseOver] = useState(false);

  let promoBadgeID = id;
  let splittedID = promoBadgeID.split("$");
  if (splittedID.length > 1) {
    promoBadgeID = splittedID[1];
  } else {
    promoBadgeID = splittedID[0];
  }

  const promoBadge = usePriceDiscountBadge(promoBadgeID, discount, price);
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );
  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );
  const translation = useTranslation();
  const dispatch = useDispatch();

  var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  console.log(isHTML("StepCard__layout__content__description__text"));
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const onCardClick = () => {
    let isClickable =
      (insuranceEnabled &&
        (price === undefined || price === null || isNaN(price))) ||
      (insPrice !== undefined && insPrice !== null && !isNaN(insPrice));

    if (!insuranceEnabled || isNonPrescriptionAvailable() || isClickable) {
      if (onClick) {
        if (promoBadge) {
          const currentStep = workflow.currentStep;
          const param = new AutomaticDiscountParameter(
            promoBadge.key,
            promoBadge.value,
            {
              key: currentStep.key,
              progressive: currentStep.progressive,
            }
          );
          dispatch(
            workflowActions.setAutomaticDiscountBadgeStepSelection(param)
          );
        }
        onClick(id);
      }
    }
  };

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);

    switch (id) {
      case "uploadPrescriptionCard":
        return "X_X_Verification_Upload";
      case "doctorPrescriptionCard":
        return "X_X_Verification_CallDoctor";
      case "manualPrescriptionCard":
        return "X_X_Verification_Manually";
      default:
        return "X_X_LensPanel_" + analyticsStepName + "-Choose";
    }
  };

  const getDataElementIdForLearnMore = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreOpen";
  };

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const isBadgeVisible = () => {
    return (
      badge &&
      (!promoBadge ||
        (promoBadge &&
          price !== undefined &&
          price !== null &&
          discount !== undefined &&
          discount !== null))
    );
  };

  const isTopPromoBadgeVisible = () => {
    return (
      promoBadge &&
      (price === undefined || price === null) &&
      (discount === undefined || discount === null)
    );
  };
  const fileNameImages = useMemo(() => {
    return getIcons(transitionId || id);
  }, [id]);

  return (
    <div
      className={"StepCard__container"}
      onClick={onCardClick}
      data-element-id={getDataElementIdForCard()}
      data-description={title}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <button className={"StepCard__card" + (disabled ? "_disabled" : "")}>
        <div className={"StepCard__layout"}>
          <div style={{ display: "flex" }}>
            {disabled && <div className="StepCard__layout__disabled" />}
            {!isLargeIconsActive ? (
              imageUrl &&
              !isMobile && (
                <div
                  className={`StepCard__layout__imgcontainer ${
                    noImageCard ? "noImage" : ""
                  }`}
                >
                  <Image
                    imageClass={`StepCard__layout__imgcontainer__img`}
                    url={configProjectImage}
                    fileName={imageUrl}
                    alt={imageUrl}
                  />
                </div>
              )
            ) : (
              //Large Icons
              <div
                // style={
                //   !isHTML(description)
                //     ? { overflowX: "hidden", borderRadius: "8px" }
                //     : { overflowX: "visible" }
                // }
                className={`StepCard__layout__largeimgcontainer ${
                  noImageCard ? "noImage" : ""
                }`}
              >
                <>
                  <Image
                    imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                    url={largeIconBasepath}
                    fileName={fileNameImages?.[0]}
                    alt={imageUrl}
                    style={{
                      display:
                        isMouseOver && fileNameImages?.length === 2
                          ? "none"
                          : "inherit",
                    }}
                  />
                  {fileNameImages?.length === 2 && (
                    <Image
                      imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                      url={largeIconBasepath}
                      fileName={fileNameImages[1]}
                      alt={imageUrl}
                      style={{
                        display: !isMouseOver ? "none" : "inherit",
                      }}
                    />
                  )}
                </>
              </div>
            )}
            {brand === "rayban" && !imageUrl && !isMobile && (
              <div
                className={
                  "StepCard__layout__imgcontainer" +
                  " " +
                  (noImageCard ? "noImage" : "")
                }
              >
                <Image
                  imageClass="StepCard__layout__imgcontainer__img"
                  url={
                    "https://media.costadelmar.com/images/RXconfigurator/blue-light.png"
                  }
                  fileName={
                    "https://media.costadelmar.com/images/RXconfigurator/blue-light.png"
                  }
                  alt={"fallback"}
                />
              </div>
            )}
            {isBadgeVisible() && (
              <BrandComponent
                componentName="StepBadge"
                parameter={{ type: badge, text: textBadge }}
              />
            )}
            {isTopPromoBadgeVisible() && (
              <div className="StepCard__layout__topBadgeWrapper">
                <BrandComponent
                  componentName="StepPriceBadge"
                  parameter={{ badge: promoBadge?.badge }}
                />
              </div>
            )}
            <div
              className={
                brand !== "rayban" || !imageUrl
                  ? "StepCard__layout__content spacer"
                  : "StepCard__layout__content"
              }
            >
              <div
                className={
                  badge
                    ? "StepCard__layout__content__title__badgespacer"
                    : "StepCard__layout__content__title"
                }
              >
                {title}
              </div>
              <div className={"StepCard__layout__content__description"}>
                <p
                  className={"StepCard__layout__content__description__text"}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              {brand === "arnette" && showPresriptionButton && (
                <>
                  <div className="StepCard__layout__prescriptionButton--separator" />
                  <div className="StepCard__layout__prescriptionButton--btn-container">
                    <a
                      className="StepCard__layout__prescriptionButton__btn-style"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        showPresriptionButtonFunction();
                      }}
                    >
                      <span>{translation("steps.type.addToCart")}</span>
                    </a>
                  </div>
                </>
              )}
              {delegatingLearnMore && contentIdentifier ? (
                <>
                  <div className={"StepCard__layout__content__spacer"} />
                  <div
                    className={"StepCard__layout__content__learnMore"}
                    onClick={(e) => {
                      e.stopPropagation();
                      delegateLearnMoreContent(contentIdentifier);
                    }}
                  >
                    {translation("steps.type.moreInfo")}
                  </div>
                  {price != null ? (
                    <div className={"StepCard__layout__content__price"}>
                      {!included ? (
                        <BrandComponent
                          componentName="StepPrice"
                          parameter={{
                            price,
                            discount,
                            insPrice,
                            promo: promoBadge?.badge,
                            ignoreInsurance: isNonPrescriptionAvailable(),
                            discountLoading,
                          }}
                        />
                      ) : (
                        translation("price.included")
                      )}
                    </div>
                  ) : null}
                </>
              ) : !moreInfo &&
                (price === undefined || price === null) ? null : (
                <>
                  {!isMobile && (
                    <div className={"StepCard__layout__content__spacer"} />
                  )}
                  {!isMobile && (
                    <div
                      className={
                        "StepCard__layout__content__learnMorePriceContainer" +
                        (!moreInfo ? " onlyPrice" : "")
                      }
                    >
                      {moreInfo && (
                        <a
                          className={"StepCard__layout__content__learnMore"}
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            moreInfo();
                          }}
                          data-element-id={getDataElementIdForLearnMore()}
                        >
                          {translation("steps.type.moreInfo")}
                        </a>
                      )}

                      {price != null ? (
                        <div className={"StepCard__layout__content__price"}>
                          {!included ? (
                            <BrandComponent
                              componentName="StepPrice"
                              parameter={{
                                price,
                                discount,
                                insPrice,
                                promo: promoBadge?.badge,
                                ignoreInsurance: isNonPrescriptionAvailable(),
                                discountLoading,
                              }}
                            />
                          ) : (
                            translation("price.included")
                          )}
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {isMobile && (included || !isNaN(price)) && (
            <div className={"StepCard__layout__content__spacerMobile"} />
          )}
          {isMobile && (
            <div
              style={{ padding: "0px 16px 8px 0px" }}
              className={
                "StepCard__layout__content__learnMorePriceContainer" +
                (!moreInfo ? " onlyPrice" : "")
              }
            >
              {moreInfo && (
                <a
                  className={"StepCard__layout__content__learnMore"}
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    moreInfo();
                  }}
                  data-element-id={getDataElementIdForLearnMore()}
                >
                  {translation("steps.type.moreInfo")}
                </a>
              )}

              {price != null ? (
                <div className={"StepCard__layout__content__price"}>
                  {!included ? (
                    <BrandComponent
                      componentName="StepPrice"
                      parameter={{
                        price,
                        discount,
                        insPrice,
                        promo: promoBadge?.badge,
                        ignoreInsurance: isNonPrescriptionAvailable(),
                        discountLoading,
                      }}
                    />
                  ) : (
                    translation("price.included")
                  )}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </button>
    </div>
  );
}
