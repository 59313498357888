import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function usePrescriptionsFromMyAccount() {
  const [prescriptions, setPrescriptions] = useState([]);
  const isLoggedinConfig: () => boolean = useSelector(
    (state: any) => state.config.loginModule?.isLoggedIn
  );
  const loginConfig: (cb) => void = useSelector(
    (state: any) => state.config.loginModule?.login
  );
  const retrieveFromMyAccountConfig: () => Promise<any> = useSelector(
    (state: any) => state.config.prescriptionModule?.retrieveFromMyAccount
  );
  const saveToMyAccountConfig: (p) => Promise<any> = useSelector(
    (state: any) => state.config.prescriptionModule?.saveToMyAccount
  );
  const isLoggedIn = () => isLoggedinConfig && isLoggedinConfig();

  const loginCallback = useCallback(
    (success: boolean, errorCallback) => {
      if (success === false) {
        console.warn(`Attempt to login failed!`);
        errorCallback();
      }
      retrieveFromMyAccountConfig &&
        retrieveFromMyAccountConfig()
          .then((prescriptions) => setPrescriptions(prescriptions))
          .catch((err) => {
            console.error(err);
          });
    },
    [retrieveFromMyAccountConfig]
  );

  const login = useCallback(
    (errorCallback) => {
      try {
        loginConfig &&
          loginConfig((success) => loginCallback(success, errorCallback));
      } catch (err) {
        console.error(err);
      }
    },
    [loginConfig, loginCallback]
  );

  const saveToMyAccount = (prescriptionObject) => {
    saveToMyAccountConfig && saveToMyAccountConfig(prescriptionObject);
  };

  useEffect(() => {
    isLoggedIn() &&
      retrieveFromMyAccountConfig &&
      retrieveFromMyAccountConfig()
        .then((prescriptions) => setPrescriptions(prescriptions))
        .catch((err) => {
          console.error(err);
        });
  }, [retrieveFromMyAccountConfig]);

  return {
    prescriptionsFromMyAccount: prescriptions,
    isLoggedIn,
    login,
    saveToMyAccount,
  };
}
